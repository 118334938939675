import React, { useCallback, useState } from "react";
import { Box } from "@material-ui/core";
import MotifAppSelect from "../../theme/motifAppSelect/motifAppSelect";
import MotifAppInput from "../../theme/motifAppInput/motifAppInput";
import content from "../../localization/en/translation.json";
import { patchProject } from "../../store/apis/projectApi";
import { useDispatch } from "react-redux";
import { showActionToast } from "../../store/actions/notification-actions";
import { getProjectDetails } from "../../store/actions/project-actions";
import { AppAlert, AppModal } from "../../theme";

const SharePointSettings = ({
  sharePointBaseUrl,
  isEditable,
  projectId,
  onClose,
  sync,
}: {
  sharePointBaseUrl?: string;
  projectId: string;
  isEditable?: boolean;
  onClose: () => void;
  sync?: boolean;
}) => {
  const dispatch = useDispatch();
  const [_sync, setSync] = useState(sync);
  const [alertState, setAlertState] = useState<boolean>(true);
  const onSave = useCallback(() => {
    (async () => {
      try {
        await patchProject({ projectId, isSharePointSyncEnabled: _sync });
        await dispatch(getProjectDetails({ id: projectId }));
        dispatch(
          showActionToast({
            type: "success",
            message: "SharePoint settings are updated!",
          })
        );
        onClose();
      } catch (e) {
        dispatch(
          showActionToast({
            type: "error",
            message: "SharePoint settings update is failed!",
          })
        );
      }
    })();
  }, [dispatch, onClose, projectId, _sync]);

  const handleCloseAlert = () => {
    setAlertState(!alertState);
  };
  return (
    <AppModal
      size={"md"}
      title={content.sharePointSettingTitle}
      showModal={true}
      showCloseBtnToModal={true}
      confirmBtnText={content.save}
      cancelBtnText={content.cancel}
      disabledValue={!isEditable}
      onConfirm={onSave}
      onModalClose={onClose}
      modalClass={"sharepoint-settings"}
    >
      <>
        <Box
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px",

            display: "flex",
          }}
        >
          <Box>
            {alertState && (
              <AppAlert
                variant="outlined"
                severity="info"
                onClose={handleCloseAlert}
                className="sp-info-alert"
              >
                {content.sharePointSettingSyncFieldDesc}
              </AppAlert>
            )}

            <label>{content.sharePointSettingSyncField}</label>
            <MotifAppSelect
              style={{ width: "100%" }}
              label=""
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              disabled={!isEditable}
              onChange={(e) => setSync(e.target.value as boolean)}
              value={String(_sync)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: "8px",
            paddingBottom: "8px",

            display: "flex",
          }}
        >
          <Box width={"100%"}>
            <label>{content.sharePointSettingSiteURLField}</label>
            <MotifAppInput disabled={true} label="" value={sharePointBaseUrl} />
          </Box>
        </Box>
      </>
    </AppModal>
  );
};

export default SharePointSettings;
