import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  AppModal,
  AppAlert,
  AppButton,
  AppFormErrorText,
  AppIcon,
  MotifAppSelect,
  MotifAppTextArea,
  MotifMultiAppSelect,
} from "../../theme";
import { exclamation, xMark } from "../../theme/icons";
import cloudUpload from "../../assets/images/icons/cloud-upload.svg";
import checkCircle from "../../assets/images/icons/check_circle.svg";
import t from "../../localization/en/translation.json";
import {
  fetchPricingQuestions,
  getProjectEngagement,
  searchUsers,
} from "../../store/apis/projectApi";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import { getStringWithBold } from "../../utils/helper-utility";
import { DropZoneErrorStatuses, ProjectDbsFolderPath } from "../../utils/Constants";
import CloseProjectConfirmationPopup from "../closeProjectConfirmationPopup/CloseProjectConfirmationPopup";
import "./CloseProjectPopup.scss";
const PAGE_SIZE = 10;
const APPROVER_SORT_COLUMN = "displayName";
export type FeedbackType = {
  isTechnologyFeeIncluded?: boolean | undefined;
  isTechnologyLineItemInInvoice?: boolean;
  technologyChargedInterval?: string;
  technologyFeeNotIncludedReason?: string;
  additionalDetails?: string;
  engagementPartnerName?: string;
  engagementPartnerEmail?: string;
  engagementIds?: string[];
  engagementFiles?: { name: string, blobName: string }[];
};

export const CloseProjectPricingFeedback = ({
  priced,
  projectId,
  paceId,
  uploadedFile,
  onFeedback,
  addProjectDocument,
  deleteProjectDocument,
  flushProjectUploadedFile
}: {
  priced: boolean | undefined;
  projectId: string;
  paceId: string | null | undefined;
  uploadedFile: string;
  onFeedback: (feedback: FeedbackType) => void;
  addProjectDocument(payload: any): any;
  deleteProjectDocument(payload: any): any;
  flushProjectUploadedFile(): any;
}) => {
  const [isPricingAvailable, setPricingAvailable] = useState<
    boolean | undefined
  >(() => priced);
  const [isPricingSeparate, setPricingSeparate] = useState<boolean | undefined>(
    () => undefined
  );
  const [isPricingMonthly, setPricingMonthly] = useState<string | undefined>(
    () => undefined
  );
  const [reason, setReason] = useState<string | undefined>(() => undefined);
  const [modes, setModes] = useState<{ label: string; value: string }[]>([]);
  const [additional, setAdditional] = useState("");
  const [reasons, setReasons] = useState<{ label: string; value: string }[]>(
    []
  );
  const [engagements, setEngagements] = useState<
    { label: string; value: string }[]
  >([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [engagementSearchUser, setEngagementSearchUser] = useState([]);
  const [engagementUser, setEngagementUser] = useState({} as any);
  const [engagementIds, setEngagementIds] = useState<string[]>([]);
  const [errorEngagementId, setErrorEngagementId] = useState<string | null>(
    null
  );
  const [errorEngagementSearchUser, setErrorEngagementSearchUser] = useState<
    string | null
  >(null);
  const [documentFiles, setDocumentFiles] = useState<
    { name: string; blobName: string }[]
  >([]);
  const [addedFileName, setAddedFileName] = useState<string>("");
  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPricingQuestions();
        const data = response?.data as {
          pricingQuestionKey: string;
          name: string;
          id: string;
        }[];
        setModes(
          data
            .filter(
              (option) =>
                option.pricingQuestionKey === "TechnologyChargedInterval"
            )
            .map((option) => ({ label: option.name, value: option.id }))
        );
        setReasons(
          data
            .filter(
              (option) =>
                option.pricingQuestionKey === "TechnologyFeeNotIncludedReason"
            )
            .map((option) => ({ label: option.name, value: option.id }))
        );
        const engagementResponse = await getProjectEngagement(paceId);
        const engagementData = engagementResponse?.data as {
          id: string;
          name: string;
        }[];
        setEngagements(
          engagementData.map((option) => ({
            label: option.name,
            value: option.id,
          }))
        );
      } catch { }
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    onFeedback({
      isTechnologyFeeIncluded: isPricingAvailable,
      ...(() =>
        isPricingAvailable
          ? {
            isTechnologyLineItemInInvoice: isPricingSeparate,
            technologyChargedInterval: isPricingMonthly,
          }
          : { technologyFeeNotIncludedReason: reason })(),
      additionalDetails: additional,
      engagementIds: engagementIds,
      engagementPartnerName: engagementUser.name,
      engagementPartnerEmail: engagementUser.email,
      engagementFiles: documentFiles.map(file => { 
        return { 
          name: file.name , 
          blobName: file.blobName
        };
      }),
    });
    if (isPricingAvailable) {
      setReason(undefined);
    } else {
      setPricingSeparate(undefined);
      setPricingMonthly(undefined);
    }
    if (uploadedFile && uploadedFile !== "" && addedFileName !== "") {
      setDocumentFiles((current) => [
        ...current,
        { name: addedFileName, blobName: uploadedFile },
      ]);
      setAddedFileName("");
      flushProjectUploadedFile();
    }
    // eslint-disable-next-line
  }, [
    isPricingAvailable,
    isPricingSeparate,
    isPricingMonthly,
    reason,
    additional,
    engagementIds,
    engagementUser,
    onFeedback,
    uploadedFile,
    addedFileName,
    documentFiles,
  ]);

  const makeSearchUsersCall = async (
    query,
    skipToken = null,
    pageNumber = 1
  ) => {
    const payload = {
      pageSize: PAGE_SIZE + 1,
      pageNumber: pageNumber,
      sortColumn: APPROVER_SORT_COLUMN,
      skipToken: skipToken,
      filters: [
        {
          filterText: query,
        },
      ],
    };

    try {
      setSearchLoader(true);
      const action = {
        type: t.edit_project_search_placeholder,
        payload: payload,
      };
      const result = await searchUsers(action);
      if (result && result.data && result.data.records) {
        setEngagementSearchUser(
          result.data.records.map((option) => ({
            label: option.displayName,
            email: option.mail,
            value: option.id,
          }))
        );
      } else {
        setEngagementSearchUser([]);
      }
    } catch (error) {
    } finally {
      setSearchLoader(false);
    }
  };

  const handleUsersSelection = (selectedArray: any[]) => {
    if (!selectedArray.length) {
      setEngagementUser(null);
      setErrorEngagementSearchUser(
        t.engagement_contract_signing_partner_name_required
      );
    } else {
      setEngagementUser({ name: selectedArray[0].label, email: selectedArray[0].email });
      setErrorEngagementSearchUser(null);
    }
  };

  const renderMenuItem = (option, props) => {
    const stringData = option.label + " " + option.email;
    return (
      <div
        className="async-dropdown"
        dangerouslySetInnerHTML={{
          __html: getStringWithBold(stringData, props.text),
        }}
      ></div>
    );
  };

  const handleEngagementChange = ({
    target: { value },
  }: {
    target: { value: string[] };
  }) => {
    setEngagementIds(value);
    if (value.length === 0) {
      setErrorEngagementId(t.engagement_id_required);
    } else {
      setErrorEngagementId(null);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((acceptedFile) => {
      formData.append("file", acceptedFile);
    });
    formData.append("folderPath", ProjectDbsFolderPath);
    if (acceptedFiles.length > 0) {
      addProjectDocument({ projectId, formData });
      setAddedFileName(acceptedFiles[0].name);
    }
    // eslint-disable-next-line
  }, []);

  const onDragOver = (e) => {
    const event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e) => {
    const event = e as Event;
    event.stopPropagation();
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: [".pdf", ".doc", ".docx", ".ppt"],
    maxFiles: 1,
    maxSize: 26214400,
    onDrop,
    onDragOver,
    onDragEnter,
    noDragEventsBubbling: true,
  });

  const renderFileUploadError = () => {
    const [
      {
        errors: [{ code }],
      },
    ] = fileRejections;

    if (code === DropZoneErrorStatuses.FILE_TOO_LARGE) {
      return t.engagement_document_upload_file_size_validation_message;
    }

    if (code === DropZoneErrorStatuses.FILE_INVALID_TYPE) {
      return t.engagement_document_upload_file_type_validation_message;
    }

    return t.engagement_document_upload_validation_message;
  };

  const handleDeleteDocument = (blobName: string) => {
    deleteProjectDocument({ projectId, blobName, folderPath: ProjectDbsFolderPath });
    setDocumentFiles((current) => current.filter((file) => file.blobName !== blobName));
  };
  const [hoveredFile, setHoveredFile] = useState(null);
  return (
    <div className={"close-project-feedback"}>
      <div className={"row"}>
        <span className="question">
          {t.is_pricing_available}
          (Click{" "}
          <a
            className={"pricing-information-link"}
            href={process.env.REACT_APP_PRICING_SITE}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            pricing guidance
          </a>{" "}
          for details<span className="asterisk">*</span>)
        </span>
        <div className={"feedback-input"}>
          <MotifAppSelect
            value={
              isPricingAvailable === true
                ? "Yes"
                : isPricingAvailable === false
                  ? "No"
                  : undefined
            }
            displayEmpty={true}
            placeholder="Select"
            onChange={({ target: { value } }) =>
              setPricingAvailable((value as string) === "Yes" ? true : false)
            }
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
          />
        </div>
      </div>
      {isPricingAvailable === true ? (
        <>
          <div className={"row"}>
            <span className={"question"}>{t.is_pricing_separate}</span>
            <div className={"feedback-input"}>
              <MotifAppSelect
                value={
                  isPricingSeparate === true
                    ? "Yes"
                    : isPricingSeparate === false
                      ? "No"
                      : "Select"
                }
                displayEmpty={false}
                placeholder="Select"
                onChange={({ target: { value } }) =>
                  setPricingSeparate((value as string) === "Yes" ? true : false)
                }
                options={[
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
              />
            </div>
          </div>
          <div className={"row"}>
            <span className={"question"}>{t.is_pricing_monthly}</span>
            <div className={"feedback-input"}>
              <MotifAppSelect
                value={isPricingMonthly || "Select"}
                displayEmpty={false}
                placeholder="Select"
                onChange={({ target: { value } }) =>
                  setPricingMonthly(value as string)
                }
                options={modes}
              />
            </div>
          </div>
        </>
      ) : (
        isPricingAvailable !== undefined && (
          <div className={"row"}>
            <span className={"question"}>{t.what_reasons_no_pricing}</span>
            <div className={"feedback-input"}>
              <MotifAppSelect
                value={reason}
                displayEmpty={false}
                placeholder="Select"
                onChange={({ target: { value } }) => setReason(value as string)}
                options={reasons}
              />
            </div>
          </div>
        )
      )}
      <MotifAppTextArea
        placeholder={
          isPricingAvailable !== undefined
            ? isPricingAvailable
              ? t.pricing_avail_additional_information
              : t.pricing_not_avail_additional_information
            : "Enter a description..."
        }
        maxLength={500}
        label={t.additional_details}
        isRequired={true}
        onChange={({ target: { value } }) => setAdditional(value)}
        rows={3}
      />
      <div className="divider"></div>
      <AppAlert severity="info" className="info-alert-custom">
        {t.data_bureau_service_message}{" "}
      </AppAlert>
      <div className={"file-upload-wrapper"}>
        <span className="input-label">
          {t.engagement_document_upload_title}
        </span>
        <span className="asterisk">*</span>
        <div
          className={
            !!fileRejections.length
              ? "drag-drop-wrapper-alert"
              : "drag-drop-wrapper"
          }
          style={{ pointerEvents: documentFiles.length >= 5 ? "none" : "auto" }}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {!!fileRejections.length ? (
              <AppIcon icon={exclamation} />
            ) : (
              <img
                src={cloudUpload}
                className="upload-icon-size"
                alt="Upload"
              />
            )}
            <span className="upload-text">
              {!!fileRejections.length
                ? renderFileUploadError()
                : t.engagement_document_upload_area_title_1}{" "}
            </span>
            <span className="upload-options">
              {t.engagement_document_upload_area_title_2}
            </span>
          </div>
        </div>
        {documentFiles !== undefined && documentFiles.length > 0 && (
          <div className={"file-list"}>
            {documentFiles.map((file) => (
              <div
                key={file.name}
                className={"file"}
                onMouseEnter={() => setHoveredFile(file.name)}
                onMouseLeave={() => setHoveredFile(null)}
              >
                <span className={"filename"} title={file.name}>{file.name}</span>
                <AppButton
                  className={"file-icon-cross"}
                  variant="text"
                  onClick={() => handleDeleteDocument(file.blobName)}
                >
                  {hoveredFile === file.name ? (
                    <AppIcon icon={xMark} className="Close-circleCheck" />
                  ) : (
                    <img
                      src={checkCircle}
                      className="Close-circleCheck"
                      alt=""
                    />
                  )}
                </AppButton>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={"row"}>
        <div className={"question"}>
          <span className="input-label">
            {t.engagement_contract_signing_partner_name}
          </span>
          <span className="asterisk">*</span>
          <div className={"search-wrapper"}>
            <AsyncTypeahead
              id="async-search"
              isLoading={searchLoader}
              filterBy={() => true}
              labelKey={(option) => `${option.label}`}
              minLength={3}
              onSearch={(value) => makeSearchUsersCall(value)}
              options={engagementSearchUser}
              onChange={(selected) => handleUsersSelection(selected)}
              emptyLabel={t.no_matches_found}
              renderMenuItemChildren={(option, props) =>
                renderMenuItem(option, props)
              }
              inputProps={{ tabIndex: -1, placeholder: t.enter }}
            >
              {({ onClear, selected }) => (
                <>
                  {errorEngagementSearchUser && (
                    <AppFormErrorText className="errorEngagementSearchUser">
                      {errorEngagementSearchUser}
                    </AppFormErrorText>
                  )}
                  <div className="rbt-aux">
                    {selected.length > 0 && <ClearButton onClick={onClear} />}
                  </div>
                </>
              )}
            </AsyncTypeahead>
          </div>
        </div>
        <div className={"feedback-input"}>
          <MotifMultiAppSelect
            value={engagementIds || []}
            label={t.engagement_id}
            isRequired={true}
            multiple={true}
            onChange={handleEngagementChange}
            displayEmpty={true}
            emptyValue={t.no_matches_found}
            options={engagements.map((engagement) => engagement.value) || []}
          />
          {errorEngagementId && (
            <AppFormErrorText>{errorEngagementId}</AppFormErrorText>
          )}
        </div>
      </div>
    </div>
  );
};

const CloseProjectPopup = ({
  show,
  onClosePopup,
  isPricingFeedback,
  priced,
  onProjectClose,
  projectId,
  paceId,
  uploadedFile,
  addProjectDocument,
  deleteProjectDocument,
  flushProjectUploadedFile
}: {
  show: boolean;
  onClosePopup: () => void;
  isPricingFeedback: boolean;
  priced: boolean | undefined;
  onProjectClose: (feedback: FeedbackType) => void;
  projectId: string | undefined;
  paceId: string | null | undefined;
  uploadedFile: string;
  addProjectDocument;
  deleteProjectDocument;
  flushProjectUploadedFile;
}) => {
  const [feedback, setFeedback] = useState<FeedbackType>({});
  const handleClose = useCallback(() => {
    onProjectClose(feedback);
    onClosePopup();
  }, [feedback, onClosePopup, onProjectClose]);
  const onFeedback = useCallback(
    (feedback: FeedbackType) => setFeedback(feedback),
    [setFeedback]
  );
  const canConfirm = isPricingFeedback
    ? feedback.isTechnologyFeeIncluded
      ? feedback.technologyChargedInterval != null &&
      feedback.isTechnologyLineItemInInvoice != null
      : !!feedback.technologyFeeNotIncludedReason
    : true;
  const areMandatoryFieldsFilled =
    feedback != null &&
    feedback.engagementIds != null &&
    feedback.engagementIds.length > 0 &&
    feedback.engagementPartnerName != null &&
    feedback.engagementFiles != null &&
    feedback.engagementFiles.length > 0;
  const canConfirmWithMandatoryFields = isPricingFeedback
    ? canConfirm && areMandatoryFieldsFilled
    : canConfirm;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };
  const handleConfirmationConfirm = () => {
    setShowConfirmation(false);
    handleClose();
  };
  const handleConfirmPopUpClose = () => {
    isPricingFeedback ? setShowConfirmation(true) : handleClose();
  };
  useEffect(() => {
    setShowConfirmation(!isPricingFeedback && show);
  }, [show, isPricingFeedback]);
  const handleConfirmationCloseModel = () => {
    setShowConfirmation(false);
    onClosePopup();
  };
  return (
    <>
      {!isPricingFeedback ? (
        <CloseProjectConfirmationPopup
          show={showConfirmation}
          onClosePopup={handleConfirmationCloseModel}
          handleClose={handleConfirmationConfirm}
        />
      ) : (
        <>
          <AppModal
            newDesign={true}
            showModal={show}
            onModalClose={() => onClosePopup()}
            onConfirm={() => handleConfirmPopUpClose()}
            cancelBtnText={t.cancel}
            confirmBtnText={t.close_project_modal_client_close_button}
            disabledValue={!canConfirmWithMandatoryFields}
            title={t.close_project_modal_title}
            size="lg"
            fullWidth={false}
            modalClass={"custom-engagement-close-project"}
          >
            <div className="motif-close-project modal-content">
              <AppAlert severity="error" className="error-alert-custom">
                {t.close_project_modal_confirm_warning}{" "}
              </AppAlert>
              <CloseProjectPricingFeedback
                priced={priced}
                onFeedback={onFeedback}
                paceId={paceId}
                projectId={projectId}
                uploadedFile={uploadedFile}
                addProjectDocument={addProjectDocument}
                deleteProjectDocument={deleteProjectDocument}
                flushProjectUploadedFile={flushProjectUploadedFile}
              />
            </div>
          </AppModal>
          <CloseProjectConfirmationPopup
            show={showConfirmation}
            onClosePopup={handleConfirmationClose}
            handleClose={handleConfirmationConfirm}
          />
        </>
      )}
    </>
  );
};
export default CloseProjectPopup;