import { IconProp } from '@fortawesome/fontawesome-svg-core';
import motifExpand from '../assets/images/icons/motif-expand.svg';
import {
  faUser,
  faTimesCircle,
  faCopy,
  faPlayCircle,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChevronLeft,
  faShieldAlt,
  faFlag,
  faUserCircle,
  faCheck,
  faFile,
  faUpload,
  faClipboardCheck,
  faCloudUploadAlt,
  faCheckCircle,
  faDownload,
  faExpand,
  faCompress,
  faPen,
  faRedo,
  faSortAmountUp,
  faSortAmountDown,
  faBan,
  faFileAlt,
  faCaretRight,
  faChartLine,
  faNetworkWired,
  faLongArrowAltLeft,
  faList,
  faClock,
  faPlay,
  faCogs,
  faEye,
  faHandHoldingHeart,
  faComments,
  faExternalLinkAlt,
  faMicroscope,
  faWrench,
  faExpandAlt,
  faCompressAlt
} from "@fortawesome/free-solid-svg-icons";

import {
  faGlobe,
  faXmark,
  faGearComplex,
  faBell,
  faAngleDown,
  faQuestionCircle,
  faPlus,
  faLock,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowUpRight,
  faInfoCircle,
  faTrash,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faChevronRight,
  faCircleInfo,
  faPen as faEdit,
  faSearch,
  faMagnifyingGlass,
  faFilter,
  faArrowRight,
  faAngleUp,
  faExclamationTriangle,
  faTimes,
  faChevronUp,
  faChevronDown,
  faArrowRightFromBracket,
  faGear,
  faCircleCheck,
  faExclamationCircle,
  faArrowDownToLine,
  faCircleChevronUp,
  faCircleChevronDown
} from '@fortawesome/pro-light-svg-icons';

export const cross = faTimes;
export const rightArrow = faChevronRight;
export const leftArrow = faChevronLeft;
export const upArrow = faChevronUp;
export const downArrow = faChevronDown as IconProp;
export const globe = faGlobe as IconProp;
export const confidential = faShieldAlt;
export const helpCircle = faQuestionCircle as IconProp;
export const user = faUser;
export const flag = faFlag;
export const userImage = faUserCircle;
export const info = faInfoCircle as IconProp;
export const check = faCheck;
export const file = faFile;
export const upload = faUpload;
export const search = faSearch;
export const clipboardCheck = faClipboardCheck;
export const cloudUpload = faCloudUploadAlt;
export const checkCircle = faCheckCircle;
export const download = faDownload;
export const crossCircle = faTimesCircle;
export const expand = faExpand;
export const compress = faCompress;
export const add = faPlus as IconProp;
export const copy = faCopy;
export const play = faPlayCircle;
export const back = faArrowLeft as IconProp;
export const edit = faPen;
export const trash = faTrash as IconProp;
export const refresh = faRedo;
export const sortUp = faSortAmountUp;
export const sortDown = faSortAmountDown;
export const dnd = faBan;
export const lock = faLock as IconProp;
export const fileDescription = faFileAlt;
export const envelope = faEnvelope;
export const caretRight = faCaretRight;
export const warning = faExclamationTriangle;
export const exclamation = faExclamationCircle;
export const chartLine = faChartLine;
export const network = faNetworkWired;
export const arrowLeft = faLongArrowAltLeft;
export const list = faList;
export const clock = faClock;
export const bell = faBell as IconProp;
export const angleDown = faAngleDown as IconProp;
export const angleUp = faAngleUp as IconProp;
export const cogs = faCogs;
export const playIconWithoutCircle = faPlay;
export const view = faEye;
export const handHoldingHeart = faHandHoldingHeart;
export const comments = faComments;
export const externalLink = faExternalLinkAlt;
export const scope = faMicroscope;
export const wrench = faWrench;
export const expandAlt = faExpandAlt;
export const compressAlt = faCompressAlt;
export const xMark = faXmark as IconProp;
export const gearComplex = faGearComplex as IconProp;
export const fullScreen = motifExpand;
export const arrowUpRight = faArrowUpRight as IconProp;
export const doubleLeft = faAngleDoubleLeft;
export const doubleRight = faAngleDoubleRight;
export const angleLeft = faAngleLeft;
export const angleRight = faAngleRight;
export const arrowUp = faArrowUp as IconProp;
export const arrowDown = faArrowDown as IconProp;
export const circleInfo = faCircleInfo as IconProp;
export const editPen = faEdit as IconProp;
export const magnifyingGlass = faMagnifyingGlass as IconProp;
export const filter = faFilter as IconProp;
export const arrowRight = faArrowRight as IconProp;
export const logout = faArrowRightFromBracket as IconProp;
export const settings = faGear as IconProp;
export const circleCheck = faCircleCheck as IconProp;
export const arrowDownToLine = faArrowDownToLine as IconProp;
export const chevronCircleUp = faCircleChevronUp as IconProp
export const chevronCircleDown = faCircleChevronDown as IconProp

