import { createAxiosRequest, REQUEST_TYPE } from '../api';

const API_URL = process.env.REACT_APP_API_URL;
const SETTINGS_CONTROLLER_PREFIX = '/api/v1/settings';

export const getServerTime = () => {
  const GET_SERVER_TIME = `${API_URL}${SETTINGS_CONTROLLER_PREFIX}/server-time`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_SERVER_TIME);
}


export const getHelpSupportMenu = () => {
  const GET_HELP_SUPPORT = `${API_URL}${SETTINGS_CONTROLLER_PREFIX}/help-support`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_HELP_SUPPORT);
}


