import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DropDownMenu, { IDropDownProps } from "../dropDownMenu/dropDownMenu";
import eyLogoWithCE from "../../assets/images/ey_logo.svg";
import en_translation from "../../localization/en/translation.json";
import { getMe } from "../../store/actions/user-actions";
import { IUser } from "../../models/user";
import { authProvider } from "../../utils/authProvider";
import * as HeaderConstants from "../header/headerConstant";
import "./headerAdmin.scss";
import NotificationBell from "../notificationBell";
import { GET_ALL_ACTIVE_NOTIFICATIONS_ACTION, getUserProfilePhoto } from "../../store/actions/me-actions";
import { INotification } from "../../models/INotification";
import { AppIcon } from "../../theme";
import { logout, user } from "../../theme/icons";

interface IHeaderProps {
  getCurrentUser: () => any;
  getUserProfilePhoto: () => any;
  getActiveNotification:()=>any;
  currentUser: IUser;
  userPhoto: string;
  notification: INotification; 
}

class HeaderAdmin extends Component<IHeaderProps> {
  userProfileMenuOptions: IDropDownProps;

  constructor(props: IHeaderProps) {
    super(props);

    this.userProfileMenuOptions = {
      controlId: "user-drop-down-menu",
      menuOptions: [],
      onOptionClick: this.onOptionClick,
    };
  }

  componentDidMount() {
    const { getCurrentUser, currentUser, getUserProfilePhoto, userPhoto, getActiveNotification} = this.props;

    !currentUser.givenName && getCurrentUser();
    !userPhoto && getUserProfilePhoto();    
    getActiveNotification();
  }

  userDisplayNameHTML = () => {
    const { currentUser, userPhoto } = this.props;
    return (
      <div className="user-info-menu-container">
        <div className="user-avatar image-cropper">
          {
            userPhoto ? <img
              src={"data:image/png;base64," + userPhoto}
              className="rounded user-avatar-icon"
              alt="user-avatar"
              title={currentUser?.givenName}
            /> :
              <div className="user-avatar-icon">
                <AppIcon icon={user} />
              </div>
          }
        </div>
        <div>
          <div className="user-name"> {currentUser?.givenName}&nbsp;{currentUser?.surname} </div>
          <div className="color-grey"> {currentUser?.mail} </div>
        </div>
      </div>
    );
  };

  userLogOutHTML = () => {
    return (
      <div>
        <span className="menu-item-label">
          <AppIcon icon={logout} className="menu-icon" />
          {en_translation.header_user_logout}
        </span>
      </div>
    );
  };

  onLogOutClick = () => {
    authProvider.logout();
  };

  setUserProfileDropDownMenuItems = () => {
    const { currentUser, userPhoto } = this.props;
    this.userProfileMenuOptions.controlTitle = currentUser?.givenName;
    this.userProfileMenuOptions.menuOptions.length = 0;
    userPhoto && (this.userProfileMenuOptions.imageData = userPhoto);
    this.userProfileMenuOptions.menuOptions.push(
      {
        id: "user-profile-menu-id",
        titleHTML: this.userDisplayNameHTML,
        title: "",
        actionName: HeaderConstants.PROFILE_ACTION,
      },
      {
        id: "user-log-out-id",
        titleHTML: this.userLogOutHTML,
        title: "",
        actionName: HeaderConstants.LOGOUT_ACTION,
      }
    );
  };

  onOptionClick = (option) => {
    if (option === HeaderConstants.LOGOUT_ACTION) {
      this.onLogOutClick();
    }
  };

  tooltipHTML = () => (
    <div>
      {en_translation.header_help_contact_text} <br />
      {en_translation.header_help_contact_number}
    </div>
  );

  render() {
    this.setUserProfileDropDownMenuItems();
    return (
      <header className="admin-header">
        <Link to="/">
          <img src={eyLogoWithCE} className="logo m-l-20" alt="logo" />
        </Link>
        <span className="header-admin-title">
          {en_translation.admin_header_title}
        </span>
        <div className="content">
          <NotificationBell type="admin" />
          <div className="logged-in-admin-user-icon">
            <DropDownMenu
              controlId={this.userProfileMenuOptions.controlId}
              controlTitle={this.userProfileMenuOptions.controlTitle}
              menuOptions={this.userProfileMenuOptions.menuOptions}
              imageData={this.userProfileMenuOptions.imageData}
              onOptionClick={this.onOptionClick}
            />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.meReducer.currentUser,
    userPhoto: state.meReducer.userPhoto,
    notification: state.notificationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUser: () => {
      dispatch(getMe());
    },
    getUserProfilePhoto: () => {
      dispatch(getUserProfilePhoto());
    },
    getActiveNotification:()=>{
      dispatch(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
