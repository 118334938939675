import * as type from "../action-types";

export const updateProjectDetailsForEMG = (payload) => ({
    type: type.UPDATE_PROJECT_EMG_POLICY_PAYLOAD,
    payload
})

export const getProjectEMGPolicy = (payload) => ({
    type: type.GET_PROJECT_EMG_POLICY_REQUEST,
    payload
});

export const getProjectEMGPolicySuccess = (payload) => ({
    type: type.GET_PROJECT_EMG_POLICY_SUCCESS,
    payload
});

export const getProjectEMGPolicyFailure = (payload) => ({
    type: type.GET_PROJECT_EMG_POLICY_FAILURE,
    payload
});

export const acceptProjectEMGPolicy = (payload) => ({
    type: type.ACCEPT_PROJECT_EMG_POLICY_REQUEST,
    payload
});

export const acceptProjectEMGPolicySuccess = (payload) => ({
    type: type.ACCEPT_PROJECT_EMG_POLICY_SUCCESS,
    payload
});

export const acceptProjectEMGPolicyFailure = (payload) => ({
    type: type.ACCEPT_PROJECT_EMG_POLICY_FAILURE,
    payload
});
