import React, { FC } from "react";
import {
  AppTextField,
  AppInput,
  AppModal,
  AppFormErrorText,
} from "../../theme";
import t from "../../localization/en/translation.json";
import { ISectors } from "../../models/IAdminSectors";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";
import "./adminSectorsModal.scss";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  onUpdateDescription: Function;
  selectedSector: ISectors | null;
  description: string;
}

const AdminSectorsModal: FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedSector,
  onUpdateDescription,
  description,
}) => {
  const renderRegionName = () => (
    <div className="admin-sector-modal-input">
      <AppInput
        value={selectedSector ? selectedSector.name : ""}
        autoComplete="off"
        label={t.admin_sector_modal_name}
        disabled
      />
    </div>
  );

  const renderRegionDescription = () => (
    <div className="admin-sector-modal-textarea">
      <AppTextField
        maxLength={250}
        value={description}
        label={t.admin_sector_modal_desc}
        onChange={({ target }) => onUpdateDescription(target.value)}
      />
      {validateFormInputForSpecialChar(description) && (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            description.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  return (
    <AppModal
      size="md"
      showModal={isOpen}
      onModalClose={() => onClose()}
      onConfirm={() => onConfirm({ ...selectedSector, description })}
      cancelBtnText={t.cancel}
      confirmBtnText={t.edit_project_update}
      title={t.admin_sector_modal_title}
      disabledValue={validateFormInputForSpecialChar(description)}
    >
      <div className="admin-sector-modal-content">
        {renderRegionName()}
        {renderRegionDescription()}
      </div>
    </AppModal>
  );
};

export default AdminSectorsModal;
