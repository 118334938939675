import React, { FC } from "react";
import { AppTextField, AppInput, AppModal, AppIcon } from "../../theme";
import t from "../../localization/en/translation.json";
import SearchEditor from "../../components/editableGrid/list/listSearchUserEditor";
import { cross } from "../../theme/icons";
import { IRegionOwners, IRegion } from '../../models/IAdminRegions';
import "./adminRegionsModal.scss";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  selectedRegion: IRegion | null;
  onRegionOwnerAdd: Function;
  onRegionOwnerRemove: Function;
  regionOwners: IRegionOwners[]
}

const AdminRegionsModal: FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedRegion,
  regionOwners,
  onRegionOwnerAdd,
  onRegionOwnerRemove
}) => {
const MAX_REGION_OWNERS = 10;
const renderRegionName = () => (
    <div className="admin-regions-modal-input">
      <AppInput
        value={selectedRegion?.name || ""}
        autoComplete="off"
        label={t.admin_region_modal_name}
        disabled
      />
    </div>
);

const renderRegionDescription = () => (
    <div className="admin-regions-modal-textarea">
      <AppTextField
        maxLength={250}
        value={selectedRegion ? selectedRegion.description : ""}
        label={t.admin_region_modal_desc}
        disabled
       />
    </div>
)

const getAvatar = (ownerName) => {
  const ownerNameArr = ownerName.split(" ")
  const initialLetters = ownerNameArr.map(o => o.charAt(0));

  return initialLetters.filter((l, i) => i <= 1).join("");
}

const renderPillEditor = () => (
  <>
  <h3 className="email-recipients-title">{t.admin_region_modal_recipient_title}</h3>
  <div className="pill-editor admin-region-pill-editor">
    {regionOwners.map(p => (
      <span 
        className="pill">
          <span className="avatar">{getAvatar(p.ownerName)}</span>
          {p.ownerEmail}
          <AppIcon
            className="icon"
            onClick={() => onRegionOwnerRemove(p.ownerEmail)}
            icon={cross}
        />
      </span>))}
    {regionOwners.length < MAX_REGION_OWNERS && (
      <SearchEditor 
        clearOnSelect 
        onUserSelect={onRegionOwnerAdd}
        placeholder={t.admin_pill_search_placeholder} />
    )}
  </div>
  </>
)

  return (
    <AppModal
      size="md"
      showModal={isOpen}
      onModalClose={() => onClose()}
      onConfirm={() => onConfirm({ ...selectedRegion, regionOwners})}
      cancelBtnText={t.cancel}
      confirmBtnText={t.edit_project_update}
      title={t.admin_region_modal_title}
    >
    <div className="admin-regions-modal-content">
     {renderRegionName()}
     {renderRegionDescription()}
     {renderPillEditor()}
     </div>
    </AppModal>
  );
};

export default AdminRegionsModal;
