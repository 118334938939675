import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import AppIcon from '../../theme/appIcon/appIcon';
import { angleDown, bell, gearComplex} from '../../theme/icons';
import tr from '../../localization/en/translation.json';
import './manageWorkflowsHeader.scss';
import {
  TAB_NAME,
  generalSubTabs,
} from '../../containers/projectWorkflow/projectWorkflowConstants';
import AppButtonWithIcon from '../../theme/appButtonWithIcon/appButtonWithIcon';

const SubNavLink = ({ link, projectId }) => (
  <NavLink
    to={`/project/${projectId}/${link.url}`}
    className='sub-nav-link'
    activeClassName='selected'
  >
    {link.name}
  </NavLink>
);

const SubMenuDropDown = ({ links, projectId }) => (
  <div className='dropdown-wrapper'>
    <div className='dropdown-content'>
      {links.map((link) => (
        <SubNavLink
          key={link.key}
          link={link}
          projectId={projectId}
        />
      ))}
    </div>
  </div>
);

const DropdownItem = ({ links, text, icon, projectId }) => (
  <li className={`dropdown`}>
    <AppButtonWithIcon
      leftIcon={<AppIcon icon={icon} />}
      rightIcon={<AppIcon icon={angleDown} className='arrow-icon' />}
      btnText={text}
    />
    <SubMenuDropDown links={links} projectId={projectId} />
  </li>
);
interface IState {
  isAdminProjectPage: boolean;
}
const ManageWorkflowsHeader: React.FC<IState> = ({isAdminProjectPage}) => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const getHeaderName = () => {
    const lastPart = location.pathname?.split("/")?.pop();
    if(lastPart === 'communications') return tr.project_level_notification_communications;
    if(lastPart === 'templates') return tr.project_level_notification_templates;
    if(lastPart === 'error-log') return tr.project_level_workflows_error_log;
    if(lastPart === 'schedules') return tr.project_level_workflows_schedules;
    if(lastPart === 'executions') return tr.project_level_workflows_executions;
    return tr.project_level_workflows_definitions
  };
  return (
    <>
    <div className='nav-menu'>
      <div className='workflow-title'>
        <h3>{tr.manage_workflows_heading}</h3>
      </div>
    </div>
    <div className='nav-menu nav-dropdown-wrap'>
      <div className="tab-action-header">
      {isAdminProjectPage && <h4>{getHeaderName()}</h4>}
      </div>
      <ul className='workflow-btn'>
        <DropdownItem
          match={`/project/${id}/manage-workflows/workflows/*`}
          links={generalSubTabs.filter((link) =>
            link.url.startsWith(`${TAB_NAME.MANAGE_WORKFLOWS_WORKFLOWS}`)
          )}
          text={tr.admin_project_more_menu_workflows}
          icon={gearComplex}
          projectId={id}
        />
        <DropdownItem
          match={`/project/${id}/manage-workflows/email-communications/*`}
          links={generalSubTabs.filter((link) =>
            link.url.startsWith(
              `${TAB_NAME.MANAGE_WORKFLOWS_EMAIL_NOTIFICATIONS}`
            )
          )}
          text={tr.project_email_notifications}
          icon={bell}
          projectId={id}
        />
      </ul>
    </div>
    </>
  );
};

export default ManageWorkflowsHeader;
