import { clock, envelope, exclamation, file, list, playIconWithoutCircle } from "../../theme/icons";

export const AdministrationPrefixURL = "/administration";
export const AdminPrefixURL = "/admin";
export const PORTAL_COMMS = "portalNotification";
export const PORTAL_COMMS_GENERAL_LABEL = "General information";
export const PORTAL_COMMS_GENERAL = "gen-notifications";
export const PORTAL_COMMS_RELEASE_NOTES_LABEL = "Release Update";
export const PORTAL_COMMS_RELEASE_NOTES = "releaseUpdate";
export const PORTAL_COMMS_SURVEY_REMINDER_LABEL = "Survey reminder";
export const PORTAL_COMMS_SURVEY_REMINDER = "survey-reminder";
export const PORTAL_COMMS_ADHOC_NEWS_LABEL = "Adhoc news & update";
export const PORTAL_COMMS_ADHOC_NEWS = "adhoc-hnews";
export const PORTAL_COMMS_ADMIN_SURVEY_LABEL = "Surveys";
export const PORTAL_COMMS_ADMIN_SURVEY = "surveys";
export const PROJECT_WORKFLOW_URL = "/project/manage"

export const tabs = [
  {
    name: "General",
    url: "/general/regions",
    key: "general",
    isRedirect: true,
  },
  {
    name: "Workflows",
    url: "/workflows/definitions",
    key: "workflows",
    isRedirect: true,
  },
  {
    name: "Email Communications",
    url: "/email-communications/communications",
    key: "email-communications",
    isRedirect: true,
  },
  {
    name: "Portal Notifications",
    url: `/${PORTAL_COMMS}/${PORTAL_COMMS_GENERAL}`,
    key: PORTAL_COMMS,
    isRedirect: true,
  },
  { 
    name: PORTAL_COMMS_ADMIN_SURVEY_LABEL,
    url: `/${PORTAL_COMMS_ADMIN_SURVEY}`,
    key: PORTAL_COMMS_ADMIN_SURVEY,
    isRedirect: true,
  },
];

export const generalSubTabs = [
  {
    name: "Regions",
    url: "/general/regions",
    key: "regions",
  },
  {
    name: "Client sizes",
    url: "/general/client-sizes",
    key: "clientSize",
  },
  {
    name: "Sectors",
    url: "/general/sectors",
    key: "sectors",
  },
  {
    name: "Data hosting locations",
    url: "/general/data-hosting-locations",
    key: "dataHostingLocations",
    className: "location-button",
  },
  {
    name: "Types",
    url: "/general/project-types",
    key: "types",
  },
  {
    name: "Communications",
    url: "/email-communications/communications",
    key: "communications",
    icon: envelope
  },
  {
    name: "Templates",
    url: "/email-communications/templates",
    key: "templates",
    icon: file
  },
  {
    name: "Definitions",
    url: "/workflows/definitions",
    key: "definitions",
    icon: list
  },
  {
    name: "Schedules",
    url: "/workflows/schedules",
    key: "schedules",
    icon: clock
  },
  {
    name: "Executions",
    url: "/workflows/executions",
    key: "executions",
    icon: playIconWithoutCircle
  },
  {
    name: "Error Log",
    url: "/workflows/error-log",
    key: "error-log",
    icon: exclamation
  },
  {
    name: PORTAL_COMMS_GENERAL_LABEL,
    url: `/${PORTAL_COMMS}/${PORTAL_COMMS_GENERAL}`,
    key: PORTAL_COMMS_GENERAL,
  },
  {
    name: PORTAL_COMMS_RELEASE_NOTES_LABEL,
    url: `/${PORTAL_COMMS}/${PORTAL_COMMS_RELEASE_NOTES}`,
    key: PORTAL_COMMS_RELEASE_NOTES,
  },
  {
    name: PORTAL_COMMS_ADMIN_SURVEY_LABEL,
    url: `/${PORTAL_COMMS_ADMIN_SURVEY}`,
    key: PORTAL_COMMS_ADMIN_SURVEY,
  },
  {
    name: PORTAL_COMMS_SURVEY_REMINDER_LABEL,
    url: `/${PORTAL_COMMS}/${PORTAL_COMMS_SURVEY_REMINDER}`,
    key: PORTAL_COMMS_SURVEY_REMINDER,
  },
  {
    name: PORTAL_COMMS_ADHOC_NEWS_LABEL,
    url: `/${PORTAL_COMMS}/${PORTAL_COMMS_ADHOC_NEWS}`,
    key: PORTAL_COMMS_ADHOC_NEWS,
  },
];

export const TAB_NAME = {
  GENERAL: "general",
  EMAIL_NOTIFICATIONS: "email-communications",
  WORKFLOWS: "workflows",
  PORTAL_COMMS,
};

export interface ITabMenu {
  name: string;
  url: string;
  key: string;
  isRedirect?: boolean;
}
