import { put, takeLatest, call } from "redux-saga/effects";
import * as type from "../action-types";
import * as api from "../apis/projectApi";
import {
  getProjectDetailsSuccess,
  getProjectDetailsFail,
  getProjectListSuccess,
  getProjectListFail,
  getProjectMetaDataSuccess,
  getProjectMetaDataFail,
  updateProjectAppPublishSuccess,
  updateProjectAppPublishFail,
  getProjectUsersListSuccess,
  getProjectUsersListFail,
  getProjectUsersAccessListSuccess,
  getProjectUsersAccessListFail,
  updateManageUserSuccess,
  updateManageUserFailure,
  createAnnouncementFailure,
  createAnnouncementSuccess,
  getAnnouncementsFailure,
  getAnnouncementsSuccess,
  updateAnnouncementFailure,
  updateAnnouncementSuccess,
  getMyProjectRightSuccess,
  getMyProjectRightFailure,
  deleteAnnouncementFailure,
  deleteAnnouncementSuccess,
  getWrikeSyncSettingsFailure,
  getWrikeSyncSettingsSuccess,
  updateWrikeSyncSettingsSuccess,
  updateWrikeSyncSettingsFailure,
  checkToggleSyncStatusSuccess,
  checkToggleSyncStatusFailure,
  getProjectActiveFeaturesSuccess,
  getProjectActiveFeaturesFailure,
  extendProjectExpirationSuccess,
  extendProjectExpirationFailure,
  getProjectSupportedFeaturesSuccess,
  getProjectSupportedFeaturesFail,
} from "../actions/project-actions";

import {
  addnewUserActionSuccess,
  addnewUserActionFailure,
} from "../actions/manage-user-actions";
import { getMyAccessRight } from "../actions/me-actions";

function* getProjectRecord(action) {
  try {
    const data = yield call(api.getProjectRecord, action.payload);
    yield put(getProjectDetailsSuccess(data));
  } catch ({ message }) {
    yield put(getProjectDetailsFail(message));
  }
}

function* getProjectMetaData(action) {
  try {
    const data = yield call(api.getProjectMetaData, action.payload);
    yield put(getProjectMetaDataSuccess(data));
  } catch ({ message }) {
    yield put(getProjectMetaDataFail(message));
  }
}

function* getProjectSupportedFeatures(action) {
  try {
    const data = yield call(api.getProjectSupportedFeatures, action.payload);
    yield put(getProjectSupportedFeaturesSuccess(data));
  } catch ({ message }) {
    yield put(getProjectSupportedFeaturesFail(message));
  }
}

function* getProjectList() {
  try {
    const data = yield call(api.getProjectList);
    yield put(getProjectListSuccess({ data }));
  } catch ({ message }) {
    yield put(getProjectListFail(message));
  }
}

function* getProjectsSummary(action) {
  try {
    const data = yield call(api.getProjectsSummary);
    yield put(getProjectListSuccess({ data }));
  } catch ({ message }) {
    yield put(getProjectListFail(message));
  }
}

function* updateProjectApplicationPublishedStatus(action) {
  try {
    yield call(api.updateProjectAppPublishedStatus, action.payload);
    yield put(updateProjectAppPublishSuccess());
  } catch ({ message }) {
    yield put(updateProjectAppPublishFail(message));
  }
}

function* getProjectUsersList(action) {
  try {
    const data = yield call(api.getProjectUsers, action.payload);
    yield put(getProjectUsersListSuccess(data));
  } catch ({ message }) {
    yield put(getProjectUsersListFail(message));
  }
}

function* getProjectUsersAccessList(action) {
  try {
    const data = yield call(api.getProjectUserAccess, action.payload);
    yield put(getProjectUsersAccessListSuccess(data));
  } catch ({ message }) {
    yield put(getProjectUsersAccessListFail(message));
  }
}

function* addUserToProject(action) {
  try {
    const data = yield call(api.addUser, action.payload);
    yield put(addnewUserActionSuccess(data));
  } catch ({ message }) {
    yield put(addnewUserActionFailure(message));
  }
}

function* updateUsersOfProject(action) {
  try {
    const data = yield call(api.updateUsers, action.payload);
    yield put(updateManageUserSuccess(data));
    yield put(getMyAccessRight({}));
  } catch ({ message }) {
    yield put(updateManageUserFailure(message));
  }
}

function* createAnnouncement(action) {
  try {
    const data = yield call(api.createProjectAnnouncement, action.payload);
    yield put(createAnnouncementSuccess(data));
  } catch ({ message }) {
    yield put(createAnnouncementFailure(message));
  }
}

function* getAnnouncements(action) {
  try {
    const { data } = yield call(api.getProjectAnnouncements, action.payload);
    yield put(getAnnouncementsSuccess(data));
  } catch ({ message }) {
    yield put(getAnnouncementsFailure(message));
  }
}

function* updateAnnouncement(action) {
  try {
    const data = yield call(api.updateProjectAnnouncement, action.payload);
    yield put(updateAnnouncementSuccess(data));
  } catch ({ message }) {
    yield put(updateAnnouncementFailure(message));
  }
}

function* getMyProjectRights(action) {
  try {
    const { data } = yield call(api.getMyProjectRights, action.payload);
    yield put(getMyProjectRightSuccess(data));
  } catch ({ message }) {
    yield put(getMyProjectRightFailure(message));
  }
}

function* deleteAnnouncement(action) {
  try {
    const data = yield call(api.deleteProjectAnnouncement, action.payload);
    yield put(deleteAnnouncementSuccess({ ...action.payload, ...data }));
  } catch ({ message }) {
    yield put(deleteAnnouncementFailure(message));
  }
}

function* wrikeSyncSettings(action) {
  try {
    const { data } = yield call(api.getWrikeSyncSettings, action.payload);
    yield put(getWrikeSyncSettingsSuccess(data));
  } catch ({ message }) {
    yield put(getWrikeSyncSettingsFailure(message));
  }
}

function* updateWrikeSettings(action) {
  try {
    const { data } = yield call(api.updateWrikeSyncSettings, action.payload);
    yield put(updateWrikeSyncSettingsSuccess(data));
  } catch ({ message }) {
    yield put(updateWrikeSyncSettingsFailure(message));
  }
}

function* checkCe4ProvisioningUpdates(action) {
  try {
    const data = yield call(api.getProjectRecord, action.payload);
    yield put(getProjectDetailsSuccess(data));
  } catch ({ message }) {
    yield put(getProjectDetailsFail(message));
  }
}

function* checkToggleSyncApiStatus(action) {
  try {
    const data = yield call(api.getWrikeToggleSyncStatus, action.payload);
    yield put(checkToggleSyncStatusSuccess(data));
  } catch ({ message }) {
    yield put(checkToggleSyncStatusFailure(message));
  }
}

function* getProjectActiveFeatures(action) {
  try {
    const data = yield call(api.getProjectActiveFeatures, action.payload);
    yield put(getProjectActiveFeaturesSuccess(data));
  } catch ({ message }) {
    yield put(getProjectActiveFeaturesFailure(message));
  }
}

function* extendProjectExpiration(action) {
  try {
    const data = yield call(api.extendProjectApiCall, action.payload);
    yield put(extendProjectExpirationSuccess(data));
  } catch ({ message }) {
    yield put(extendProjectExpirationFailure(message));
  }
}

function* addProjectDocumentRequest(action) {
  try {
    const { data } = yield call(api.addProjectDocument, action.payload);
    yield put({
      type: type.ADD_PROJECT_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.ADD_PROJECT_DOCUMENT_FAILURE,
      payload: message,
    });
  }
}

function* deleteProjectDocumentRequest(action) {
  try {
    const { data } = yield call(api.deleteProjectDocument, action.payload);
    yield put({
      type: type.DELETE_PROJECT_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_PROJECT_DOCUMENT_FAILURE,
      payload: message,
    });
  }
}

export function* projectSagaWatcher() {
  yield takeLatest(
    type.GET_PROJECT_USERS_ACCESS_LIST_REQUEST,
    getProjectUsersAccessList
  );
  yield takeLatest(type.GET_PROJECT_USERS_LIST_REQUEST, getProjectUsersList);
  yield takeLatest(type.GET_PROJECT_DETAILS_REQUEST, getProjectRecord);
  yield takeLatest(type.GET_PROJECT_LIST_REQUEST, getProjectList);
  yield takeLatest(type.GET_PROJECTS_SUMMARY_REQUEST, getProjectsSummary);
  yield takeLatest(type.GET_PROJECT_METADATA_REQUEST, getProjectMetaData);
  yield takeLatest(type.ADD_MANAGE_USER_REQUEST, addUserToProject);
  yield takeLatest(type.UPDATE_MANAGE_USER_REQUEST, updateUsersOfProject);
  yield takeLatest(
    type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_REQUEST,
    updateProjectApplicationPublishedStatus
  );
  yield takeLatest(type.CREATE_ANNOUNCEMENT_REQUEST, createAnnouncement);
  yield takeLatest(type.GET_ANNOUNCEMENTS_CHUNK, getAnnouncements);
  yield takeLatest(type.UPDATE_ANNOUNCEMENT_REQUEST, updateAnnouncement);
  yield takeLatest(type.GET_MY_PROJECT_RIGHT_REQUEST, getMyProjectRights);
  yield takeLatest(type.DELETE_ANNOUNCEMENT_REQUEST, deleteAnnouncement);
  yield takeLatest(type.GET_WRIKE_SYNC_SETTINGS_REQUEST, wrikeSyncSettings);
  yield takeLatest(
    type.UPDATE_WRIKE_SYNC_SETTINGS_REQUEST,
    updateWrikeSettings
  );
  yield takeLatest(type.CHECK_CE4_READY_STATUS, checkCe4ProvisioningUpdates);
  yield takeLatest(type.CHECK_TOGGLE_SYNC_STATUS, checkToggleSyncApiStatus);
  yield takeLatest(type.GET_PROJECT_ACTIVE_FEATURES, getProjectActiveFeatures);
  yield takeLatest(
    type.EXTEND_PROJECT_EXPIRATION_REQUEST,
    extendProjectExpiration
  );
  yield takeLatest(type.GET_PROJECT_SUPPORTED_FEATURES_REQUEST, getProjectSupportedFeatures);
  yield takeLatest(type.ADD_PROJECT_DOCUMENT_REQUEST, addProjectDocumentRequest);
  yield takeLatest(type.DELETE_PROJECT_DOCUMENT_REQUEST, deleteProjectDocumentRequest);
}
