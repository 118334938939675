import React from 'react';
import './appSelectionFilters.scss';
import { MotifAppCheckbox } from '../../theme';
import { BundleDataSetProps } from '../../models/IAppSelectFilter';

interface FiltersTypesProps {
  filterType: string | number;
  filterData: BundleDataSetProps;
  selectAllType: string;
  onChange?: Function;
}

const FiltersTypes: React.FC<FiltersTypesProps> = ({
  filterType,
  filterData,
  selectAllType,
  onChange,
}) => {
  return (
    <div className="filter-check-block">
      <h5>{filterType}</h5>
      <div className="check-boxes-wrap">
        <div className="check-boxes-head">
          <MotifAppCheckbox
            name={selectAllType}
            checked={filterData.isSelectAll}
            onChange={(e: any)=> onChange && onChange(e, 'allCheck', filterData)}
          >
            Select All
          </MotifAppCheckbox>
        </div>
        <ul>
          {filterData.data.map((item) => (
            <li key={item.id}>
              <MotifAppCheckbox
                name={selectAllType}
                checked={item.isChecked}
                onChange={(e: any)=> onChange && onChange(e, 'singleCheck', item)}
              >
                {item.name}
              </MotifAppCheckbox>
              {item.child?.length > 0 && (
                <ul>
                  <>
                    {item.child.map((child) => (
                      <li key={child.id}>
                        <MotifAppCheckbox
                          name={selectAllType}
                          checked={child.isChecked}
                          onChange={(e: any)=> onChange && onChange(e, 'singleCheck', child)}
                        >
                          {child.name}
                        </MotifAppCheckbox>
                      </li>
                    ))}
                  </>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FiltersTypes;
