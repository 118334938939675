import * as type from "../action-types";

export type State = {
  survey: any[];
  surveyDurationDropdown: any[];
  adminRegions: any[];
  adminClientSizes: any[];
  adminSectors: any[];
  adminTypes: any[];
  adminDataHostingLocations: any[];
  isRegionUpdated: boolean;
  isClientSizeUpdated: boolean;
  isSectorUpdated: boolean;
  isTypeUpdated: boolean;
  paginationDetails: null | any;
};

const initialState = {
  adminRegions: [],
  adminClientSizes: [],
  adminSectors: [],
  adminTypes: [],
  adminDataHostingLocations: [],
  isRegionUpdated: false,
  isClientSizeUpdated: false,
  isSectorUpdated: false,
  isTypeUpdated: false,
  paginationDetails: null,
  survey: [],
  surveyDurationDropdown: [],
};

// handles actions
// eslint-disable-next-line
export default (state: State = initialState, action) => {
  switch (action.type) {
    case type.GET_ADMIN_REGIONS_SUCCESS:
      return {
        ...state,
        adminRegions: action.payload.records,
        paginationDetails: action.payload.pagination,
      };

    case type.UPDATE_ADMIN_REGIONS_REQUEST:
      return {
        ...state,
        isRegionUpdated: false,
      };

    case type.UPDATE_ADMIN_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isRegionUpdated: true,
      };

    case type.GET_ADMIN_CLIENT_SIZES_SUCCESS:
      return {
        ...state,
        adminClientSizes: action.payload.records,
        paginationDetails: action.payload.pagination,
      };

    case type.UPDATE_ADMIN_CLIENT_SIZES_REQUEST:
      return {
        ...state,
        isClientSizeUpdated: false,
      };

    case type.UPDATE_ADMIN_CLIENT_SIZES_SUCCESS:
      return {
        ...state,
        loading: false,
        isClientSizeUpdated: true,
      };

    case type.GET_ADMIN_SECTORS_SUCCESS:
      return {
        ...state,
        adminSectors: action.payload.records,
        paginationDetails: action.payload.pagination,
      };

    case type.UPDATE_ADMIN_SECTORS_REQUEST:
      return {
        ...state,
        isSectorUpdated: false,
      };

    case type.UPDATE_ADMIN_SECTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSectorUpdated: true,
      };

    case type.GET_ADMIN_DATA_HOSTING_LOC_SUCCESS:
      return {
        ...state,
        adminDataHostingLocations: action.payload.records,
        paginationDetails: action.payload.pagination,
      };

    case type.GET_ADMIN_TYPES_SUCCESS:
      return {
        ...state,
        adminTypes: action.payload.records,
        paginationDetails: action.payload.pagination,
      };

    case type.UPDATE_ADMIN_TYPES_REQUEST:
      return {
        ...state,
        isTypeUpdated: false,
      };

    case type.UPDATE_ADMIN_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        isTypeUpdated: true,
      };

    case type.GET_ADMIN_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        survey: action.payload,
      };

    case type.DELETE_SURVEY_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        survey: state.survey.filter((sur) => sur.id !== action.payload.id),
      };
    case type.DELETE_SURVEY_CONFIRM_FAILURE:
    case type.GET_ADMIN_SURVEY_FAILURE:
    case type.ADD_ADMIN_SURVEY_FAILURE:
    case type.UPDATE_ADMIN_SURVEY_FAILURE:
    case type.GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        surveyDurationDropdown: action.payload,
      };
    default:
      return state;
  }
};
