import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { getAcceptedPolicies } from '../apis/projectApi';

function* getAcceptedPolicy(action) {
	try {
    const data = yield call(getAcceptedPolicies, action);
    yield put({
      type: type.GET_ACCEPTED_POLICY_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ACCEPTED_POLICY_FAILURE,
      payload: message
    });
  }
}

export function* getAcceptedPolicyActionWatcher() {
  yield takeLatest(type.GET_ACCEPTED_POLICY_REQUEST, getAcceptedPolicy)
}
