import React, { useCallback, useEffect, useState } from 'react';
import './appChipType.scss';
import AppChip from '../appChip/appChip';
import AppIcon from '../appIcon/appIcon';
import { angleDown, angleUp } from '../icons';
import { AppDetail } from '../../components/createProject/models/appDetail';

interface IAppChipTypes {
  chipTypeName: string;
  chipsData: AppDetail[];
  onRemoveChip?: (id: string | number) => void;
  canRemoveChip?: Function;
}

let maxChips = 6;
const AppChipTypes: React.FC<IAppChipTypes> = ({
  chipTypeName,
  chipsData,
  onRemoveChip,
  canRemoveChip
}) => {
  const [chipItems, setChipItems] = useState<AppDetail[]>([]);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [isMoreChipItems, setIsMoreChipItems] = useState<AppDetail[]>([]);
  const hasWindow = typeof window !== 'undefined';

  const handleShowMore = useCallback(() => {
    const windowWidth = hasWindow ? window.innerWidth : undefined;
    if(windowWidth && windowWidth < 1199) maxChips = 4;
    if(windowWidth && windowWidth < 768) maxChips = 2;
    if (chipsData.length > maxChips) {
      const clipData = chipsData.slice(0, maxChips);
      const nonClipData = chipsData.slice(maxChips);
      setChipItems(clipData);
      setIsMoreChipItems(nonClipData);
    } else {
      setChipItems(chipsData);
      setIsShowMore(false);
      setIsMoreChipItems([]);
    }
  }, [chipsData, hasWindow]);

  useEffect(() => {
    handleShowMore();
  }, [chipsData, handleShowMore]);

  return (
    <div className='chip-type-wrapper'>
      <div className='type-name'>
        <span>{chipTypeName}</span>
      </div>
      <div className='chips-wrapper'>
        <div>
          {chipItems?.map((chip, index) => (
            <AppChip
              key={`chip_${index}`}
              chipDetail={chip}
              onRemoveChip={onRemoveChip}
              canRemoveChip={canRemoveChip ? canRemoveChip(chip) : true}
            />
          ))}
          {isMoreChipItems.length > 0 && (
            <div
              className='chip-more'
              onClick={() => setIsShowMore(!isShowMore)}
            >
              <span>{isShowMore ? 'Less' : 'More'}</span>
              <AppIcon icon={isShowMore ? angleUp : angleDown} />
            </div>
          )}
        </div>
        {isShowMore && (
          <div className='more-chips-wrap'>
            {isMoreChipItems?.map((chip, index) => (
              <AppChip
                key={`chip_${index}`}
                chipDetail={chip}
                onRemoveChip={onRemoveChip}
                canRemoveChip={canRemoveChip ? canRemoveChip(chip) : true}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppChipTypes;
