import React from "react";
import { connect } from "react-redux";
import { AppButton, AppIcon } from "../../theme";
import { rightArrow } from "../../theme/icons";
import { RouteComponentProps } from "react-router";
import {
  tabs,
  generalSubTabs,
  TAB_NAME,
  ITabMenu,
  AdminPrefixURL,
  AdministrationPrefixURL,
  PORTAL_COMMS_RELEASE_NOTES,
  PORTAL_COMMS_GENERAL,
  PORTAL_COMMS_SURVEY_REMINDER,
  PORTAL_COMMS_ADHOC_NEWS
} from "./adminGeneralConstants";
import AdminRegions from "../../containers/adminRegions/adminRegions";
import AdminClientSizes from "../../containers/adminClientSizes/adminClientSizes";
import AdminSectors from "../../containers/adminSectors/adminSectors";
import AdminDataHostingLoc from "../../containers/adminDataHostingLocations/adminDataHostingLocations";
import AdminTypes from "../../containers/adminTypes/adminTypes";
import {
  MenuLinksKeys,
  MenuLinks,
} from "../../components/headerAdminMenu/menuLinks";
import AdminErrorLog from "../../components/adminWorkflows/ErrorLog/adminErrorLog";
import AdminExecutions from "../../components/adminWorkflows/Executions/adminExecutions";
import AdminSchedules from "../../components/adminWorkflows/Schedules/adminSchedules";
import AdminDefinitions from "../../components/adminWorkflows/Definitions/adminDefinitions";
import AdminCommunicationTemplates from "../../components/adminWorkflows/CommunicationTemplates/adminCommunicationTemplates";
import AdminCommunications from "../../components/adminWorkflows/Communications/adminCommunications";
import tr from "../../localization/en/translation.json";
import "./adminGeneral.scss";
import {
  clearWorkflowProjectAction,
  getWorkflowProjectAction,
} from "../../store/actions/admin-workflows-actions";
import { IAccessRight } from "../../models/user";
import { ISSPPlatformRole, ISSPUserAdminAccess } from "../../models/IRoles";
import { getAdminConsoleAccess } from "../../utils/helper-utility";
import { CrashBoundary } from "../../components/CrashBoundary";
import GeneralNotifications from "../../components/adminPortalCommunications/General";
import ReleaseNotifications from "../../components/adminPortalCommunications/ReleaseNotifications";
import { EProjectStatuses } from "../adminProjects/adminProjectsModel";
import AdminSurveys from "../../components/adminPortalCommunications/adminSurveys";

import SurveyReminders from "../../components/adminPortalCommunications/SurveyNotifications";
import AdhocNewsUpdate from "../../components/adminPortalCommunications/AdhocNews&Update";

type IProps = RouteComponentProps<any> & {
  adminWorkflowProjectData: {};
  getWorkflowProjectAction: Function;
  clearWorkflowProjectAction: Function;
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
};

interface IState {
  activeSubTabIndex: number;
  tabsMenu: ITabMenu[];
  subTabs: any[];
  activeTabKey: string;
  isAdminProjectPage: boolean;
  userAdminAccess: ISSPUserAdminAccess;
}

class AdminGeneral extends React.Component<IProps, IState> {
  adminProjectURL = MenuLinks.find(
    (menuLink) => menuLink.key === MenuLinksKeys.PROJECTS
  )?.link;
  constructor(props) {
    super(props);

    this.state = {
      activeSubTabIndex: -1,
      tabsMenu: [],
      subTabs: [],
      activeTabKey: "",
      isAdminProjectPage: false,
      userAdminAccess: { portalAdmin: false, portalAdminConsole: false },
    };
  }

  componentDidMount() {
    const {
      location,
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.props.getWorkflowProjectAction(id);
    }
    const pageURL = location.pathname;
    let isAdminProjectPage = false;
    const currentTab = tabs.find((tab) => pageURL.includes(tab.key)) || tabs[0];
    if (this.adminProjectURL && pageURL.includes(this.adminProjectURL)) {
      isAdminProjectPage = true;
    }
    if (currentTab && currentTab.key) {
      let tabsMenu = tabs;
      if (isAdminProjectPage) {
        tabsMenu = tabsMenu.filter((tab) => {
          return [TAB_NAME.EMAIL_NOTIFICATIONS, TAB_NAME.WORKFLOWS].includes(
            tab.key
          );
        });
      }
      const subTabs = generalSubTabs.filter((tab) => {
        return tab.url.includes(currentTab.key);
      });
      const activeSubTabIndex = subTabs.findIndex((t) =>
        pageURL.includes(t.url)
      );
      this.setState({
        activeSubTabIndex,
        subTabs,
        tabsMenu,
        activeTabKey: currentTab.key,
        isAdminProjectPage,
      });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const userAdminAccess = this.getUserAdminConsoleAccess();
    this.setState({ userAdminAccess });
  }

  componentDidUpdate() {
    const { history, adminWorkflowProjectData } = this.props;
    if (
      adminWorkflowProjectData &&
      adminWorkflowProjectData["projectStatus"] &&
      adminWorkflowProjectData["projectStatus"]["projectStatusName"] &&
      adminWorkflowProjectData["projectStatus"][
        "projectStatusName"
      ].toLowerCase() === EProjectStatuses.CLSD
    ) {
      history.push("/");
    }
  }

  componentWillUnmount() {
    this.props.clearWorkflowProjectAction();
  }

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  handleSubTabChange = (url, activeSubTabIndex) => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { isAdminProjectPage } = this.state;
    this.setState({ ...this.state, activeSubTabIndex });
    if (id && this.adminProjectURL && isAdminProjectPage) {
      url = `${this.adminProjectURL}/${id}${url}`;
    } else {
      url = `${AdminPrefixURL}${AdministrationPrefixURL}${url}`;
    }
    history.push(url);
  };

  handleTabRedirection(url: string, isRedirect: boolean = false) {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { subTabs, isAdminProjectPage } = this.state;
    if (id && this.adminProjectURL && isAdminProjectPage) {
      url = `${this.adminProjectURL}/${id}${url}`;
    } else {
      url = `${AdminPrefixURL}${AdministrationPrefixURL}${url}`;
    }
    if (isRedirect) {
      const activeSubTabIndex = subTabs.findIndex((t) => url.includes(t.url));
      this.setState({ ...this.state, activeSubTabIndex });
      history.push(url);
    }
  }

  handleCloseAdminProject = () => {
    const { history } = this.props;
    if (this.adminProjectURL) {
      history.push(this.adminProjectURL);
    }
  };

  renderTabs = () => {
    const { tabsMenu, activeTabKey } = this.state;
    return tabsMenu.map((t, i) => {
      return (
        <AppButton
          variant="text"
          className={`administration-menu-button active ${
            t.key === activeTabKey ? "administration-menu-active" : ""
          }`}
          size="small"
          onClick={() => {
            this.handleTabRedirection(t.url, t.isRedirect);
          }}
          key={`tab-button-${i}`}
        >
          {t.name}
        </AppButton>
      );
    });
  };

  renderTabPanel = () => {
    const { activeSubTabIndex, subTabs, userAdminAccess, isAdminProjectPage } =
      this.state;
    if (activeSubTabIndex < 0) {
      return;
    }
    switch (subTabs[activeSubTabIndex].key) {
      case "regions":
        return <AdminRegions />;
      case "clientSize":
        return <AdminClientSizes />;
      case "sectors":
        return <AdminSectors />;
      case "dataHostingLocations":
        return <AdminDataHostingLoc />;
      case "types":
        return <AdminTypes />;
      case "communications":
        return (
          <AdminCommunications
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
          />
        );
      case "templates":
        return (
          <AdminCommunicationTemplates
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
          />
        );
      case "definitions":
        return (
          <AdminDefinitions
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
          />
        );
      case "schedules":
        return (
          <AdminSchedules
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
          />
        );
      case "executions":
        return <AdminExecutions />;
      case "error-log":
        return (
          <AdminErrorLog
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
          />
        );
      case PORTAL_COMMS_GENERAL:
        return <GeneralNotifications />;
      case PORTAL_COMMS_RELEASE_NOTES:
        return <ReleaseNotifications />;
      case "surveys":
        return <AdminSurveys />;

      case PORTAL_COMMS_SURVEY_REMINDER:
        return <SurveyReminders />;
      case PORTAL_COMMS_ADHOC_NEWS:
        return <AdhocNewsUpdate />;
      default:
        return;
    }
  };

  renderSubTabs = () => {
    const { activeSubTabIndex, subTabs } = this.state;
    return subTabs.map((t, i) => {
      return (
        <AppButton
          className={`general-tab-button ${t.className || ""} ${
            activeSubTabIndex === i ? "active" : ""
          }`}
          size="medium"
          onClick={() => this.handleSubTabChange(t.url, i)}
          key={`general-tab-${i}`}
        >
          {t.name}
          <AppIcon className="icon" icon={rightArrow} />
        </AppButton>
      );
    });
  };

  render() {
    const { adminWorkflowProjectData } = this.props;
    const { isAdminProjectPage } = this.state;
    return (
      <CrashBoundary>
        <div className="administration-tabs">
          <div className="page-hdr-title-container">
            <p className="content-h4">
              {isAdminProjectPage && adminWorkflowProjectData["name"]
                ? `${tr.admin_cps_scope_project}: ${adminWorkflowProjectData["name"]}`
                : ""}
              {!isAdminProjectPage &&
                `${tr.admin_cps_scope_project}: ${tr.admin_cps_scope_portal}`}
            </p>
            {isAdminProjectPage && (
              <AppButton size="medium" onClick={this.handleCloseAdminProject}>
                {tr.Close}
              </AppButton>
            )}
          </div>
          <div className="admin-administration-tab">{this.renderTabs()}</div>
          <div className="administration-section">
            <aside className="sub-tabs">{this.renderSubTabs()}</aside>
            <section className="tab-panel-container admin-project-container">
              {this.renderTabPanel()}
            </section>
          </div>
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({ workflowsReducer, meReducer, meta }) => {
  return {
    adminWorkflowProjectData: workflowsReducer.adminWorkflowProjectData,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflowProjectAction: (payload) => {
    dispatch(getWorkflowProjectAction(payload));
  },
  clearWorkflowProjectAction: () => {
    dispatch(clearWorkflowProjectAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGeneral);
