import React, {useImperativeHandle, useRef} from "react";
import {ProjectRowDataModel} from "./adminProjectsModel";
import {GridApi} from "ag-grid-community";
import {EProjectCategory} from "../createProject/formStateModel";
import {AdditionalPaceIdsForm} from "./additionalPaceIdsForm";
import {TruncateForToolTip} from "./truncateForToolTip";

export const AdditionalPaceIDsPillEditor = React.forwardRef((params: { value: any, data: ProjectRowDataModel, api: GridApi }, ref) => {
    const valueRef = useRef(params.value);

    useImperativeHandle(ref, () => ({
        getValue() {
            return valueRef.current;
        },
        isPopup() {
            return true;
        }
    }));

    const isClientEng = params.data.projectCategoryId === EProjectCategory.CLI_ENG;
    return (
        isClientEng ? (
            <AdditionalPaceIdsForm
                onSuccessfulUpdate={(updateColumnValue) => {
                    params.data.raw_additionalPaceIds = updateColumnValue;
                    valueRef.current =
                        <TruncateForToolTip columnText={updateColumnValue} nullOrEmptyText={"-"}/>;
                    params.api.stopEditing();
                    params.api.refreshCells(); 
                }}
                projectId={params.data.id}
                onCancel={() => params.api.stopEditing(true)}
                persistedPaceIds={params.data.raw_additionalPaceIds?.split(",")?.map((paceId) => paceId.trim()) ?? []}
            />
        ) : (
            <div id="emptyDiv"></div>
        )
    );
});
