import React, { FC } from 'react';
import { NavLink, useLocation, } from 'react-router-dom';
import { IMenuLinks } from './menuLinks';
import { AppButton } from "../../theme";
import './headerAdminMenu.scss';

interface IProps {
  menuLinks: IMenuLinks[]
}

const HeaderAdminMenu: FC<IProps> = ({ menuLinks }) => {
  const location = useLocation();

  const isActive = (menuLink: IMenuLinks): boolean => {
    const startWith = menuLink.subPath || '';
    return (
      location.pathname.startsWith(menuLink.link) ||
      (startWith && location.pathname.startsWith(startWith as string))
    ) as boolean;
  };
  
  return (
    <div className="headerAdminMenu">
      <div className="header-admin-bar">
        {menuLinks.map((menuLink, idx) => (
          menuLink.isActive && <NavLink to={menuLink.link} key={`admin-menu-${idx}}`} activeClassName="active-bar"  isActive={() => isActive(menuLink)} >
            <AppButton>
              {menuLink.title}
            </AppButton>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default HeaderAdminMenu;
