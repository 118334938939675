import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { getAdminBanner, postAdminBanner, getAdminSchedules, postAdminSchedule, updateAdminSchedule, deleteAdminSchedule} from '../apis/projectApi';
import { 
  getMaintenanceStatusSuccess, 
  getMaintenanceStatusFailure, 
  scheduleMaintenanceSuccess, 
  scheduleMaintenanceFailure,
  getAdminScheduleSuccess,
  getAdminScheduleFailure,
  addAdminScheduleFailure,
  addAdminScheduleSuccess,
  updateAdminScheduleFailure,
  updateAdminScheduleSuccess,
  deleteAdminScheduleFailure,
  deleteAdminScheduleSuccess
 } from '../actions/admin-maintenance-actions';

function* getMainteneceStatus(action) {
	try {
    const { data } = yield call(getAdminBanner, action);
    yield put(getMaintenanceStatusSuccess(data));
  } catch ({ message }) {
    yield put(getMaintenanceStatusFailure(message));
  }
}

function* scheduleMaintenece(action) {
	try {
    const { data } = yield call(postAdminBanner, action);
    yield put(scheduleMaintenanceSuccess(data));
  } catch ({ message }) {
    yield put(scheduleMaintenanceFailure(message));
  }
}

function* getAdminScheduleSaga() {
	try {
    const { data } = yield call(getAdminSchedules);
    yield put(getAdminScheduleSuccess(data));
  } catch ({ message }) {
    yield put(getAdminScheduleFailure(message));
  }
}

function* addAdminScheduleSaga(action) {
	try {
    const { data } = yield call(postAdminSchedule, action);
    yield put(addAdminScheduleSuccess(data));
  } catch ({ message }) {
    yield put(addAdminScheduleFailure(message));
  }
}

function* updateAdminScheduleSaga(action) {
	try {
    const { data } = yield call(updateAdminSchedule, action);
    yield put(updateAdminScheduleSuccess(data));
  } catch ({ message }) {
    yield put(updateAdminScheduleFailure(message));
  }
}

function* deleteAdminScheduleSaga(action) {
	try {
    const { data } = yield call(deleteAdminSchedule, action);
    yield put(deleteAdminScheduleSuccess(data));
  } catch ({ message }) {
    yield put(deleteAdminScheduleFailure(message));
  }
}

export function* adminMainteneceSagaWatcher() {
  yield takeLatest(type.GET_MAINTENANCE_STATUS_REQUEST, getMainteneceStatus);
  yield takeLatest(type.SCHEDULE_MAINTENANACE_REQUEST, scheduleMaintenece);
  yield takeLatest(type.GET_ADMIN_SCHEDULE_REQUEST, getAdminScheduleSaga);
  yield takeLatest(type.ADD_ADMIN_SCHEDULE_REQUEST, addAdminScheduleSaga);
  yield takeLatest(type.UPDATE_ADMIN_SCHEDULE_REQUEST, updateAdminScheduleSaga);
  yield takeLatest(type.DELETE_ADMIN_SCHEDULE_REQUEST, deleteAdminScheduleSaga);
}
