import * as type from "../action-types";

const initialState = {
  clientSizes: [],
  loading: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_CLIENT_SIZES:
      return { ...state, loading: true };
    case type.GET_CLIENT_SIZES_SUCCESS:
      return { ...state, clientSizes: action.payload.data, loading: false };
    case type.GET_CLIENT_SIZES_FAILURE:
      return { ...state, clientSizes: [], loading: false };
    default:
      return state;
  }
};
