export const DEFAULT_SORT = {
  sort: "asc",
  colId: "givenName",
}

export const USER_GRID_FIELDS = {
  ROLE: "userPortalRoleName",
  USER_TYPE: "userType",
  SERVICE_LINE: "serviceLineCode",
  SUB_SERVICE_LINE: "subServiceLineCode"
}

export const VALIDATION_RESPONSE_TYPE = {
  WARNING: "Warning",
}
