// eslint-disable-next-line
export default {
    // FontFamily variables -
    fontFamilyRegular: "EYInterstate, sans-serif",
    fontFamilyBold: "EYInterstate-Bold, sans-serif",
    fontFamilyLight: "EYInterstate-Light, sans-serif",
    eyInterstateLight: "EYInterstate-Light",
    eyInterstateRegular: "EYInterstate-Regular",
    eyInterstateBold: "EYInterstate-Bold",

    // Color variables -
    white: "white",
    black: "black",
    textButtonColor: "#585860",
    buttonDisabledBgColor: "#eaeaf2",
    buttonPrimaryBgColor: "#2e2e38",
    buttonDisabledColor: "#747480",
    buttonPrimaryHoverBgColor: "#4D4D5C",
    buttonSecondaryHoverBgColor: "#d7d7dc",
    buttonBorderBgColor: "#bdbdc5",
    buttonSecondaryColor: "#2e2e38",
    tuna: "rgb(46,46,56)",
    thunderbird: "rgb(185, 37, 28)",
    gray: "rgb(128, 128, 128)",
    inputFieldColor: "#656579",
    grayLight: '#7F7F91',

    // FontSize variables -
    fontSize12: "0.75rem",
    fontSize13: "0.813rem",
    fontSize14: "0.875rem",
    fontSize15: "0.938rem",
    fontSize16: "1rem",
    fontSize18: "1.125rem",
    fontSize20: "1.25rem"
}
