import * as type from "../action-types";
import {
  EProjectStatuses,
  ProjectModel,
} from "../../containers/adminProjects/adminProjectsModel";

const initialState = {
  projects: [],
  projectsGridPagination: {},
  searchProjectLoader: false,
  deleteProject: {},
  softDeleteProject: {},
  reactivateProject: {},
  loading: false,
  applications: [],
  userProjects: [],
  searchAdminProject: [],
  logs: [],
  searchProjectLogs: [],
  searchLogLoader: false,
  filePath: "",
  deleteFilePath: false,
  deleteThumbnailPath: false,
  searchLoaderApps: false,
  searchAdminApps: [],
  isLogsFetchComplete: false,
  updatedProjectData: {},
  exportedProjectList: null,
  exportStatus: {},
  thumbnailPath: "",
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ADMIN_PROJECTS:
      return { ...state, projects: [], loading: true };
    case type.GET_ADMIN_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action?.payload?.data?.records || [],
        projectsGridPagination: action?.payload?.data?.pagination || {},
        loading: false,
      };
    case type.GET_ADMIN_PROJECTS_FAILURE:
      return { ...state, projects: [], loading: false };
    case type.UPDATE_ADMIN_PROJECT:
      return { ...state, updatedProjectData: [], loading: true };
    case type.UPDATE_ADMIN_PROJECT_SUCCESS:
      return { ...state, updatedProjectData: action.payload, loading: false };
    case type.UPDATE_ADMIN_PROJECT_FAILURE:
      return {
        ...state,
        updatedProjectData: { error: true, ...action.payload },
        loading: false,
      };
    case type.FLUSH_UPDATED_PROJECT_DATA:
      return { ...state, updatedProjectData: [] };
    case type.SEARCH_ADMIN_PROJECT:
      return { ...state, searchProjectLoader: true };
    case type.SEARCH_ADMIN_PROJECT_SUCCESS:
      return {
        ...state,
        searchAdminProject: action.payload.data,
        searchProjectLoader: false,
      };
    case type.SEARCH_ADMIN_PROJECT_FAILURE:
      return { ...state, searchAdminProject: [], searchProjectLoader: false };
    // TODO - need refactor to use universal notification flow control than admin console scoped notifications//////
    case type.SOFT_DELETE_PROJECT: //
      return { ...state, loading: true }; //
    case type.SOFT_DELETE_PROJECT_SUCCESS: //
      // TODO - search use different key to store the data, it isn't ideal way, but for time being to fix bug with legacy code as it is
      ((state.searchAdminProject ?? []) as ProjectModel[])
        .filter((project) => project.id === action.payload.id)
        .forEach((project) => {
          project.status = EProjectStatuses.CLSD; //
        }); //
      return { ...state, softDeleteProject: action.payload, loading: false }; //
    case type.SOFT_DELETE_PROJECT_FAILURE: //
      return {
        ...state,
        softDeleteProject: { error: true, ...action.payload },
        loading: false,
      }; //
    case type.REACTIVATE_PROJECT: //
      return { ...state, loading: true }; //
    case type.REACTIVATE_PROJECT_SUCCESS: //
      // TODO - search use different key to store the data, it isn't ideal way, but for time being to fix bug with legacy code as it is
      ((state.searchAdminProject ?? []) as ProjectModel[])
        .filter((project) => project.id === action.payload.projectId)
        .forEach((project) => {
          project.status = EProjectStatuses.ACTV; //
        }); //
      return { ...state, reactivateProject: action.payload, loading: false }; //
    case type.REACTIVATE_PROJECT_FAILURE: //
      return {
        ...state,
        reactivateProject: { error: true, ...action.payload },
        loading: false,
      }; //
    case type.DELETE_ADMIN_PROJECT: //
      return { ...state, loading: true }; //
    case type.DELETE_ADMIN_PROJECT_SUCCESS: //
      return { ...state, deleteProject: action.payload, loading: false }; //
    case type.DELETE_ADMIN_PROJECT_FAILURE: //
      return {
        ...state,
        deleteProject: { error: true, ...action.payload },
        loading: false,
      }; //
    case type.FLUSH_ADMIN_PROJECT: //
      return {
        ...state,
        deleteProject: {},
        softDeleteProject: {},
        reactivateProject: {},
      }; //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case type.GET_ADMIN_APPS_REQUEST:
      return { ...state, loading: true };
    case type.GET_ADMIN_APPS_SUCCESS:
      return {
        ...state,
        applications: action.payload.records,
        paginationDetails: action.payload.pagination,
        loading: false,
      };
    case type.GET_ADMIN_APPS_FAILURE:
      return { ...state, loading: false };
    case type.GET_ADMIN_APPS_VIDEO_REQUEST:
      return { ...state, loading: true };
    case type.GET_ADMIN_APPS_VIDEO_SUCCESS:
      return { ...state, filePath: action.payload, loading: false };
    case type.GET_ADMIN_APPS_VIDEO_FAILURE:
      return { ...state, loading: false };
    case type.DELETE_ADMIN_APPS_VIDEO_REQUEST:
      return { ...state, loading: true };
    case type.DELETE_ADMIN_APPS_VIDEO_SUCCESS:
      return { ...state, deleteFilePath: true };
    case type.DELETE_ADMIN_APPS_VIDEO_FAILURE:
      return { ...state, loading: false };

    case type.GET_ADMIN_APPS_THUMBNAIL_REQUEST:
      return { ...state, loading: true };
    case type.GET_ADMIN_APPS_THUMBNAIL_SUCCESS:
      return { ...state, thumbnailPath: action.payload, loading: false };
    case type.GET_ADMIN_APPS_THUMBNAIL_FAILURE:
      return { ...state, loading: false };
      
    case type.DELETE_ADMIN_APPS_THUMBNAIL_REQUEST:
      return { ...state, loading: true };
    case type.DELETE_ADMIN_APPS_THUMBNAIL_SUCCESS:
      return { ...state, deleteThumbnailPath: true };
    case type.DELETE_ADMIN_APPS_THUMBNAIL_FAILURE:
      return { ...state, loading: false };

    case type.DELETE_ADMIN_APP_REQUEST:
      return { ...state, loading: true };
    case type.DELETE_ADMIN_APP_SUCCESS:
      return { ...state, deleteApp: action.payload, loading: false };
    case type.DELETE_ADMIN_APP_FAILURE:
      return {
        ...state,
        deleteApp: { error: true, ...action.payload },
        loading: false,
      };
    case type.GET_PROJECT_LOGS_REQUEST:
      return { ...state, loading: true };
    case type.GET_PROJECT_LOGS_SUCCESS:
      return {
        ...state,
        logs: [...state.logs, ...action.payload],
        isLogsFetchComplete: !action.payload?.length,
        loading: false,
      };
    case type.GET_PROJECT_LOGS_FAILURE:
      return {
        ...state,
        logs: { error: true, ...action.payload },
        loading: false,
      };
    case type.FLUSH_PROJECT_LOGS:
      return { ...state, logs: [], isLogsFetchComplete: false };
    case type.SEARCH_PROJECT_LOGS:
      return { ...state, searchLogLoader: true };
    case type.SEARCH_PROJECT_LOGS_SUCCESS:
      return {
        ...state,
        searchProjectLogs: action.payload.data,
        searchLogLoader: false,
      };
    case type.SEARCH_PROJECT_LOGS_FAILURE:
      return { ...state, searchProjectLogs: [], searchLogLoader: false };
    case type.FLUSH_ADMIN_APP:
      return { ...state, deleteApp: {} };
    case type.GET_ADMIN_PROJECTS_BY_USER_REQUEST:
      return { ...state, userProjects: [], loading: true };
    case type.GET_ADMIN_PROJECTS_BY_USER_SUCCESS:
      return { ...state, userProjects: action.payload.data, loading: false };
    case type.GET_ADMIN_PROJECTS_BY_USER_FAILURE:
      return { ...state, userProjects: [], loading: false };
    case type.FLUSH_ADMIN_PROJECTS_BY_USER:
      return { ...state, userProjects: [] };
    case type.SEARCH_ADMIN_APPS_REQUEST:
      return { ...state, searchLoaderApps: true };
    case type.SEARCH_ADMIN_APPS_SUCCESS:
      return {
        ...state,
        searchAdminApps: action.payload.data.records,
        searchLoaderApps: false,
        loading: false,
      };
    case type.SEARCH_ADMIN_APPS_FAILURE:
      return {
        ...state,
        searchAdminApps: [],
        searchLoaderApps: false,
        loading: false,
      };
    case type.UPDATE_CUSTOMIZATION_INFO_REQUEST:
      return { ...state, isCustomizationInfoUpdated: false };
    case type.UPDATE_CUSTOMIZATION_INFO_SUCCESS:
      return { ...state, isCustomizationInfoUpdated: true };
    case type.EXPORT_PROJECT_LIST:
      return { ...state, exportStatus: { loading: true } };
    case type.EXPORT_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        exportedProjectList: action.payload,
        exportStatus: { success: true },
      };
    case type.EXPORT_PROJECT_LIST_FAILURE:
      return { ...state, exportStatus: { error: true } };
    case type.FLUSH_EXPORT_PROJECT_LIST_STATUS:
      return { ...state, exportStatus: {} };
    default:
      return state;
  }
};
