import React, { useCallback, useEffect, useReducer } from "react";
import { AppFormErrorText, MotifAppSelect } from "../../theme";
import t from "../../localization/en/translation.json";
import { getOptions, validatedInputClass } from "./projectForm";
import {
  lookupModel,
  SubSectorLookupModel,
  SubSubSectorLookupModel,
} from "../../containers/createNewProject/lookupModel";

type PropsType = {
  sector: any;
  subSector: any;
  subSubSector: any;
  sectors: lookupModel[];
  subSectors: SubSectorLookupModel[];
  subSubSectors: SubSubSectorLookupModel[];
  onUpdate: (value: unknown, field: string) => void;
};

const getSubSectors = async (
  sectorId: string,
  subSectors: SubSectorLookupModel[]
): Promise<SubSectorLookupModel[]> => {
  try {
    return subSectors.filter((subSector) => subSector.sectorId === sectorId);
  } catch (e) {
    return [] as SubSectorLookupModel[];
  }
};

const getSubSubSectors = async (
  subSectorId: string,
  subSubSectors: SubSubSectorLookupModel[]
): Promise<SubSubSectorLookupModel[]> => {
  try {
    return subSubSectors.filter(
      (subSubSector) => subSubSector.subSectorId === subSectorId
    );
  } catch (e) {
    return [] as SubSubSectorLookupModel[];
  }
};

enum SectorActions {
  SectorSelected = "sector_selected",
  SubSectorSelected = "sub_sector_selected",
  SubSectorsLoaded = "sub_sectors_loaded",
  SubSubSectorsLoaded = "sub_sub_sectors_loaded",
}

export const SectorSelectComponent = ({
  sector,
  subSector,
  subSubSector,
  sectors,
  subSectors,
  subSubSectors,
  onUpdate,
}: PropsType) => {
  const sectorId = sector.fieldVal?.id ? sector.fieldVal?.id : sector.fieldVal;
  const subSectorId = subSector.fieldVal?.id
    ? subSector.fieldVal?.id
    : subSector.fieldVal;
  const subSubSectorId = subSubSector.fieldVal?.id
    ? subSubSector.fieldVal?.id
    : subSubSector.fieldVal;
  const sectorFieldName = sector.fieldName;
  const subSectorFieldName = subSector.fieldName;
  const subSubSectorFieldName = subSubSector.fieldName;

  const [{ filteredSubSectors, filteredSubSubSectors }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case SectorActions.SectorSelected:
          const { sectorId } = action.payload;
          (async () => {
            const filteredSubSectors = await getSubSectors(
              sectorId,
              subSectors
            );
            dispatch({
              type: SectorActions.SubSectorsLoaded,
              payload: filteredSubSectors,
            });
          })();
          return {
            ...state,
            filteredSubSectors: [],
            filteredSubSubSectors: [],
          };
        case SectorActions.SubSectorsLoaded:
          return { ...state, filteredSubSectors: action.payload };
        case SectorActions.SubSectorSelected:
          const { subSectorId } = action.payload;
          (async () => {
            const filteredSubSubSectors = await getSubSubSectors(
              subSectorId,
              subSubSectors
            );
            dispatch({
              type: SectorActions.SubSubSectorsLoaded,
              payload: filteredSubSubSectors,
            });
          })();
          return { ...state, filteredSubSubSectors: [] };
        case SectorActions.SubSubSectorsLoaded:
          return { ...state, filteredSubSubSectors: action.payload };
        default:
          return state;
      }
    },
    {
      filteredSubSectors: [],
      filteredSubSubSectors: [],
    }
  );

  useEffect(() => {
    if (sectorId) {
      (async () => {
        const filteredSubSectors = await getSubSectors(sectorId, subSectors);
        dispatch({
          type: SectorActions.SubSectorsLoaded,
          payload: filteredSubSectors,
        });
      })();
    }
  }, [sectorId, subSectors]);

  useEffect(() => {
    if (subSectorId) {
      (async () => {
        const filteredSubSubSectors = await getSubSubSectors(
          subSectorId,
          subSubSectors
        );
        dispatch({
          type: SectorActions.SubSubSectorsLoaded,
          payload: filteredSubSubSectors,
        });
      })();
    }
  }, [subSectorId, subSubSectors]);

  const onSectorUpdated = useCallback(
    (value: string) => {
      onUpdate(
        {
          id: value,
          name: sectors.find((sector) => sector.id === value)?.name,
        },
        sectorFieldName
      );
      onUpdate(null, subSectorFieldName);
      onUpdate(null, subSubSectorFieldName);
    },
    [
      sectors,
      sectorFieldName,
      onUpdate,
      subSectorFieldName,
      subSubSectorFieldName,
    ]
  );

  const onSubSectorUpdated = useCallback(
    (value: string) => {
      onUpdate(
        {
          id: value,
          name: filteredSubSectors.find((subSector) => subSector.id === value)
            ?.name,
        },
        subSectorFieldName
      );
      onUpdate(null, subSubSectorFieldName);
    },
    [filteredSubSectors, onUpdate, subSectorFieldName, subSubSectorFieldName]
  );

  const filteredSubSectorOptions = getOptions(filteredSubSectors);
  const filteredSubSubSectorOptions = getOptions(filteredSubSubSectors);

  return (
    <>
      <div
        className={`project-form-field m-r-35 ${
          sector.fieldVal && sector.isvalid ? validatedInputClass : ""
        }`}
      >
        <MotifAppSelect
          id={sectorFieldName}
          value={sectorId ?? ""}
          disabled={sector.disable}
          name={sectorFieldName}
          data-test={sectorFieldName}
          label={t.project_sector}
          isValid={sector.isvalid}
          options={getOptions(sectors)}
          onChange={(e) => {
            onSectorUpdated(e.target.value as string);
          }}
        />
        {!sector.isvalid && (
          <AppFormErrorText>{t.required_field}</AppFormErrorText>
        )}
      </div>
      <div
        className={`project-form-field m-r-35 ${
          subSector.fieldVal && subSector.isvalid ? validatedInputClass : ""
        }`}
      >
        <>
          <MotifAppSelect
            id={subSectorFieldName}
            value={
              !filteredSubSectorOptions.length || !subSectorId
                ? ""
                : subSectorId
            }
            disabled={subSector.disable || !sectorId}
            name={subSectorFieldName}
            data-test={subSectorFieldName}
            label={t.project_sub_sector}
            isValid={subSector.isvalid}
            options={filteredSubSectorOptions}
            onChange={(e) => onSubSectorUpdated(e.target.value as string)}
          />

          {!subSector.isvalid && (
            <AppFormErrorText>{t.required_field}</AppFormErrorText>
          )}
        </>
      </div>
      <div
        className={`project-form-field ${
          subSubSector.fieldVal && subSubSector.isvalid
            ? validatedInputClass
            : ""
        }`}
      >
        <>
          <MotifAppSelect
            id={subSubSectorFieldName}
            value={
              !filteredSubSubSectorOptions.length || !subSubSectorId
                ? ""
                : subSubSectorId
            }
            disabled={subSubSector.disable || !subSectorId}
            name={subSubSectorFieldName}
            data-test={subSubSectorFieldName}
            label={t.project_sub_sub_sector}
            isValid={true}
            options={filteredSubSubSectorOptions}
            onChange={(e) =>
              onUpdate(
                {
                  id: e.target.value,
                  name: filteredSubSubSectors.find(
                    (subSubSector) => subSubSector.id === e.target.value
                  )?.name,
                },
                subSubSectorFieldName
              )
            }
          />
        </>
      </div>
    </>
  );
};
