export const announcementDropdownOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export const ANNOUNCEMENT_SCROLL_TARGET = "announcement-scrollable-area";
export const ANNOUNCEMENT_DETAILS_MIN_LENGTH = 60;
export const ELLIPSIS = "...";
export const ACTIVE_PROJECT_STATE = "Active";
export const PROJECT_STATUS_ALL = "ALL";
export const PROJECT_USAGE_REPORT = "PRJ-USAGE";
export const isImportantDropDown = {
  YES: "Yes",
  NO: "No",
};
export const isEnabledDropdown = {
  YES: "Yes",
  NO: "No",
};

export enum ExpirationPeriodUnit {
  Month = "M",
  Week = "W",
}

export enum ExpirationPeriod {
  Daily = "Daily",
  Weekly = "Weekly",
  Fortnightly = "Fortnightly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Month = "month",
  Week = "week",
}

export enum CheckInTime {
  First = "first",
  Second = "second",
  Third = "third",
}

export enum NumberCount {
  _1 = "1",
  _2 = "2",
  _3 = "3",
}
