import React, { useState } from "react";
import t from "../../../localization/en/translation.json";
import "./announcement.scss";
import { formatDate } from "../../../utils/helper-utility";
import MoreMenu from "../../moreMenu/moreMenu";
import { MoreMenuOption } from "../../moreMenu/moreMenuOptions";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";

interface AnnouncementProps {
  id: string;
  title: string;
  description: string;
  createdBy: string;
  createdDate: string;
  isImportant?: boolean;
  isProjectAdmin: boolean;
  authorId: string;
  deleteAnnouncement: Function;
  editAnnouncement: Function;
}

const Announcement: React.FC<AnnouncementProps> = ({ id, title, description, createdBy, createdDate, isImportant = false, isProjectAdmin = false, authorId, deleteAnnouncement, editAnnouncement }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100;
  const currentUser = useSelector((state: APP_STATE) => state.meReducer.currentUser);

  const options: MoreMenuOption[] = [
    {
      id: "123",
      actionName: t.edit,
      title: t.edit,
    },
    {
      id: "124",
      actionName: t.delete,
      title: t.delete,
    },
  ];
  const isAuthor = currentUser?.id === authorId;
  const filteredOptions = isAuthor || isProjectAdmin ? options : [];
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const onActionStart = (actionName: string, id: string) => {
    if (actionName === t.delete) {
      deleteAnnouncement(true, id);
    } else if (actionName === t.edit) {
      editAnnouncement(true, id);
    }
  };

  const renderDescription = () => {
    if (description.length <= maxLength) {
      return <p className="card-description">{description}</p>;
    }

    return (
      <p className="card-description">
        {isExpanded ? description : `${description.substring(0, maxLength)}...`}
        <span onClick={toggleDescription} className="toggle-description">
          {isExpanded ? t.show_less : t.show_more}
        </span>
      </p>
    );
  };

  return (
    <div className="render-card app-col-xs-12 app-col-lg-12 app-col-xl-12">
      <div className="card-header">
        <h2 className="card-title" title={title}>{title}</h2>
        {isImportant && <span className="critical-pill">{t.announcement_important_label}</span>}
        {filteredOptions.length > 0 && <MoreMenu controlId="more-menu-apps" optionClickFn={(action) => onActionStart(action, id)} options={filteredOptions} stopEventBubbling vertical={true} />}
      </div>
      {renderDescription()}
      <div className="card-footer">
        <span className="createdBy">{createdBy}</span>
        <span className="createdOn">
          {t.edit_project_announcement_created_on} {formatDate(createdDate)}
        </span>
      </div>
    </div>
  );
};

export default Announcement;
