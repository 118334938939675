import moment from "moment";
import React from "react";
import {timeZoneAbbreviated} from "../../../utils/helper-utility";

export const renderDateCell = ({value}) => {
    if (!value) {
        return "-";
    }
    const formatted = `${moment(value).format("MMM D, YYYY h:mm:ss a")} ${
        timeZoneAbbreviated()
    }`;
    return (
        <span title={formatted}>{formatted}</span>
    );
};