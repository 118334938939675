import React, { FC } from 'react';
import { ProjectDetailsModel } from './projectDetailsModel';
import t from '../../localization/en/translation.json';
import './projectDetails.scss';

interface IProps {
	data: ProjectDetailsModel
}

const ProjectDetails: FC<IProps> = ({ data: { title, description,
	projectName, projectType, projectApps, projectNoOfUsers, hideNoOfUsers, hideProjectApps } }) => {

	const emptyValue = "...";

	return (
		<div className="project-details">
			<h3 className="title">{title}</h3>
			<div className="desc">{description}</div>
			<div className="details-table">
				<div className="detail-row">
					<div className="keys">
						{projectName && <span>{t.name}</span>}
					</div>
					<div className="values">
						{projectName && <span>{projectName || emptyValue}</span>}
					</div>
				</div>
				<div className="detail-row">
					<div className="keys">
						<span>{t.project_details_project_type}</span>
					</div>
					<div className="values">
						<span>{projectType || emptyValue}</span>
					</div>
				</div>
				{!hideProjectApps && (<div className="detail-row">
					<div className="keys">
						<span>{t.apps}</span>
					</div>
					<div className="values">
						<span>{projectApps || emptyValue}</span>
					</div>
				</div>)}
				{!hideNoOfUsers && (<div className="detail-row">
					<div className="keys">
						<span>{t.project_details_no_of_users}</span>
					</div>
					<div className="values">
						<span>{projectNoOfUsers || emptyValue}</span>
					</div>
				</div>)}
			</div>
		</div>
	)
}

export default ProjectDetails;
