import { put, takeLatest, call } from "redux-saga/effects";
import * as type from "../action-types";
import { bulkUserUploadRequestSuccess, bulkUserUploadRequestFailure } from "../actions/manage-user-actions";
import { searchManageUser, searchManageUserGrid, getBulkTemplate, uploadBulkUserFile } from "../apis/projectApi";

function* getManageSearchUser(action) {
  try {
    const data = yield call(searchManageUser, action.payload);
    yield put({
      type: type.SEARCH_MANAGE_USER_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_MANAGE_USER_FAILURE,
      payload: message,
    });
  }
}

function* getManageSearchGrid(action) {
  try {
    const data = yield call(searchManageUserGrid, action);
    yield put({
      type: type.SEARCH_MANAGE_USER_GRID_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_MANAGE_USER_GRID_FAILURE,
      payload: message,
    });
  }
}

function* getBulkTemplateDownload(action) {
  try {
    const data = yield call(getBulkTemplate);
    yield put({
      type: type.BULK_TEMPLATE_DOWNLOAD_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.BULK_TEMPLATE_DOWNLOAD_FAILURE,
      payload: message,
    });
  }
}

function* uploadUserBulk(action) {
  try {
    const data = yield call(uploadBulkUserFile, action);
    yield put(bulkUserUploadRequestSuccess(data));
  } catch ({ message }) {
    yield put(bulkUserUploadRequestFailure(message));
  }
}

export function* getManageUserActionWatcher() {
  yield takeLatest(type.SEARCH_MANAGE_USER, getManageSearchUser);
  yield takeLatest(type.SEARCH_MANAGE_USER_GRID, getManageSearchGrid);
  yield takeLatest(type.BULK_TEMPLATE_DOWNLOAD, getBulkTemplateDownload);
  yield takeLatest(type.UPLOAD_MANAGE_USER_BULK_FILE_REQUEST, uploadUserBulk);
}
