import React, { FC } from "react";
import { DEFAULT_TIME, HIGHEST_SINGLE_DIGIT } from "./appTimePickerConstants";
import { AppIcon } from "../../theme";
import { upArrow, downArrow } from "../../theme/icons";
import "./appNumberPicker.scss";


interface IProps {
  value: any;
  min?: number;
  max: number;
  onChange: Function;
}

const AppNumberPicker: FC<IProps> = ({ value = DEFAULT_TIME, min = 0, max, onChange }) => {
  const handleTimeChange = (isIncremented) => {
    let newValue = isIncremented ? (value) + 1 : (value) - 1;

    if (newValue > max) {
      newValue = DEFAULT_TIME
    }
    if (newValue < min) {
      newValue = max;
    }

    onChange(newValue);
  }

  return (
    <div className="app-number-picker">
      <button className="up-arrow" onClick={() => handleTimeChange(true)}>
        <AppIcon icon={upArrow} />
      </button>
      <input className="value-input" type="text" readOnly value={value <= HIGHEST_SINGLE_DIGIT ? `${DEFAULT_TIME}${value}` : value}></input>
      <button className="down-arrow" onClick={() => handleTimeChange(false)} >
        <AppIcon icon={downArrow} />
      </button>
    </div>
  )
};

export default AppNumberPicker;
