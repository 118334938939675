import * as type from "../action-types";

export const getProjectDefaultRolesRequest = (payload: any) => ({
    type: type.GET_PROJECT_DEFAULT_ROLES_REQUEST,
    payload,
});

export const getProjectDefaultRolesRequestSuccess = (payload: any) => ({
    type: type.GET_PROJECT_DEFAULT_ROLES_SUCCESS,
    payload,
});


export const getProjectDefaultRolesRequestFailure = (payload: any) => ({
    type: type.GET_PROJECT_DEFAULT_ROLES_FAILURE,
    payload,
});

export const enrollToProjectRequest = (payload: any) => ({
    type: type.ENROLL_USER_DEMO_PROJECT_REQUEST,
    payload,
});

export const enrollToProjectRequestSuccess = (payload: any) => ({
    type: type.ENROLL_USER_DEMO_PROJECT_SUCCESS,
    payload,
});

export const enrollToProjectRequestFailure = (payload: any) => ({
    type: type.ENROLL_USER_DEMO_PROJECT_FAILURE,
    payload,
});