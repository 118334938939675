import React, {useState} from 'react';
import {PillEditor, SearchPill, SearchPillProps} from "../../components/editableGrid/editor/pillEditor";
import t from "../../localization/en/translation.json";
import {clearSearchedProjectsAction, searchProjectsAction} from "../../store/actions/search-projects-actions";
import {connect, useDispatch} from "react-redux";
import {TypeaheadModel} from "react-bootstrap-typeahead";
import {PAGE_SIZE} from "../createProject/createProjectConstants";
import {AppButton} from "../../theme";
import {patchAdditionalPaceIdsToProject} from "../../store/apis/projectApi";
import {showActionToast} from "../../store/actions/notification-actions";

const mapStateToProps = ({searchProjectsReducer}, ownProps) => ({
    loading: searchProjectsReducer.searchLoader,
    //filter added paceIds from result
    searchData: (searchProjectsReducer.searchProjectsData ?? []).filter(({paceId}) => {
        return !ownProps.pills?.some(({value}) => value === JSON.stringify(paceId))
    })
});

const mapDispatchToProps = dispatch => ({
    resetSearchState: payload => {
        dispatch(clearSearchedProjectsAction(payload))
    },
    // paceId / projectName search query
    onSearch: (query: string, pageNumber) => {
        const payload = {
            totalRecords: 0,
            pageSize: PAGE_SIZE + 1,
            pageNumber: pageNumber,
            sortColumn: "projectName",
            sortDirection: "asc",
            filters: [{
                "filterColumn": "string",
                "filterText": query
            }]
        }
        dispatch(searchProjectsAction(payload));
    }
});
const PaceIDSearchPill = React.memo((function <T extends TypeaheadModel>() {
    return connect(mapStateToProps, mapDispatchToProps)(SearchPill as (props: SearchPillProps<T>) => JSX.Element)
})<{ paceId: number, projectName: string }>());

const PaceIDSearchResultItem = ({paceId, projectName}) => {
    const title = `${paceId} (${projectName.length ? projectName : t.no_project_name})`;
    return (
        <div className="async-dropdown">
            <div className="async-dropdown-name" title={title}>{paceId} <br/>
                {`(${projectName.length ? projectName : t.no_project_name})`}
            </div>
        </div>
    )
}


const MAX_PILLS = 10;
// eslint-disable-next-line
export const AdditionalPaceIdsForm = <T extends { paceId: string, projectName: string }>(
    {projectId, persistedPaceIds = [], onSuccessfulUpdate, onCancel}:
        { projectId: string, persistedPaceIds: string[], onSuccessfulUpdate: (paceIds?: string) => void, onCancel: () => void }) => {
    const [pills, setPills] = useState(() => (persistedPaceIds ?? []).map((paceId) => ({
        value: paceId,
        displayName: paceId
    })));
    const dispatch = useDispatch();
    const [updateTitle, setUpdateTitle] = useState(t.update);
    // since additional paceIds touching is single place so need of using redux to making complicated
    // and reduce complexity with keeping update without reloading whole grid
    const updateAdditionalPaceIds = async () => {
        const additionalPaceIds = pills.map((p) => ({id: parseInt(p.value)}));
        try {
            setUpdateTitle(t.updating);
            await patchAdditionalPaceIdsToProject({projectId, additionalPaceIds});
            const additionalPaceIds_grid_value = pills.map(({value}) => value).join(",");
            onSuccessfulUpdate(additionalPaceIds_grid_value.length?additionalPaceIds_grid_value:undefined);
            dispatch(showActionToast({message: t.additional_paceIds_updated, type: "success"}));
        } catch (e) {
            setUpdateTitle(t.update);
            dispatch(showActionToast({message: t.additional_paceIds_update_failed, type: "error"}));
        }
    }
    const isUpdateDisabled = pills.length === persistedPaceIds.length ? pills.reduce((partial, current, index) => {
        return partial && persistedPaceIds[index] === current.value
    }, true) : false;

    const addSelectedItemToPills = (selected) => setPills(pills.concat(selected.map(({paceId}) => ({
        value: JSON.stringify(paceId),
        displayName: JSON.stringify(paceId)
    }))));
    const cleanThePill = (pill) => setPills(pills.filter(p => p.value !== pill.value));
    const searchResultMenuOptionsFn = (option) =>
        <PaceIDSearchResultItem paceId={option.paceId} projectName={option.projectName}/>;
    return (<div className={"additional-paceIds-popup-container"}>
            <PillEditor pills={pills}
                        title={t.admin_projects_additional_paceIds}
                        onPillRemoved={cleanThePill}
                        maxPillsCount={MAX_PILLS}>
                <PaceIDSearchPill pills={pills} placeholder={"Search Pace Ids"}
                                  filterByFields={["projectName"]}
                                  labelKey={({paceId = ""}) => JSON.stringify(paceId)}
                                  itemFn={searchResultMenuOptionsFn}
                                  onSelect={addSelectedItemToPills}/>
            </PillEditor>
            <div className="list-date-buttons">
                <AppButton disabled={isUpdateDisabled} onClick={updateAdditionalPaceIds}>{updateTitle}</AppButton>
                <AppButton onClick={onCancel}>{t.cancel}</AppButton>
            </div>
        </div>

    );
}