import React, {useRef, useEffect} from "react";
import t from "../../../localization/en/translation.json";
import {AppIcon} from "../../../theme";
import {cross, search as searchIcon} from "../../../theme/icons";
import {
    AsyncTypeahead,
    ClearButton,
    TypeaheadLabelKey,
    TypeaheadMenuProps,
    TypeaheadModel,
    TypeaheadResult
} from "react-bootstrap-typeahead";

export interface SearchPillProps<T extends  TypeaheadModel>{
    pills: {value: string, displayName}[],
    placeholder: string,
    onSelect: (selected: T[])=>void,
    onSearch: (query: string, pageNumber: number)=> void,
    labelKey: (option: T)=> string,
    searchData: T[],
    resetSearchState: (payload: any)=>void,
    itemFn: (option: TypeaheadResult<T>, prop: TypeaheadMenuProps<T>)=> React.ReactNode,
    filterByFields: string[],
    loading: boolean
}

const THREE_SECONDS = 3;
const MIN_LENGTH = 3;

export function SearchPill<T extends  TypeaheadModel>({placeholder, onSelect, labelKey,
                                                          onSearch, searchData, resetSearchState,
                                                          itemFn, filterByFields,loading}:SearchPillProps<T>)
{
    const typeaheadRef = useRef(null);
    const pageNumberRef = useRef(1);
    const queryRef = useRef<string | null>('');
    useEffect(()=> {return ()=>resetSearchState({})},[resetSearchState]);
    return (
(
            <div className="search-wrapper">
                <AppIcon icon={searchIcon} />
                <AsyncTypeahead
                  id="async-search"
                  ref={typeaheadRef}
                  isLoading={loading}
                  filterBy={filterByFields}
                  labelKey={labelKey as TypeaheadLabelKey<T>}
                  minLength={MIN_LENGTH}
                  onSearch={query => {
                      pageNumberRef.current=1;
                      queryRef.current = query;
                      onSearch(query, pageNumberRef.current);
                  }}
                  maxResults={50 * pageNumberRef.current}
                  paginate={true}
                  onPaginate={_ => {
                      if(queryRef.current) {
                          pageNumberRef.current++;
                          onSearch(queryRef.current, pageNumberRef.current);
                      }
                  }}
                  paginationText={t.show_more}
                  options={searchData}
                  onChange={(data)=>{
                      onSelect(data);
                      //@ts-ignore since type definition failed to expose its public method
                      typeaheadRef.current?.clear();
                  }}
                  placeholder={placeholder}
                  delay={THREE_SECONDS}
                  promptText={t.loading}
                  searchText={t.loading}
                  useCache={false}
                  renderMenuItemChildren={(option, props) => itemFn(option, props)}>
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </AsyncTypeahead>

            </div>)
    );
}


type pill = { displayName: string, value: any };
type PillEditorProps = {
    title?: string,
    pills: pill[],
    onPillRemoved: (pill)=> void,
    maxPillsCount: number, children: JSX.Element
};
export const PillEditor = ({pills, title, onPillRemoved, maxPillsCount, children}:
                        PillEditorProps)=>{
return (
    (<>
        {title && <h3 className={"pill-editor-title"}>{title}</h3>}
  <div className="pill-editor additional-paceIds-pill-editor">
    {pills.map(p => (
      <span key={p.value}
        className="pill">
          {p.displayName}
          <AppIcon
            className="icon"
            onClick={() => onPillRemoved(p)}
            icon={cross}
        />
      </span>))}
    {pills.length < maxPillsCount && children}
  </div>
  </>)
);
}