import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { fetchClientSizes } from '../apis/projectApi';

function* getClientSizes(action) {
	try {
    const data = yield call(fetchClientSizes);
    yield put({
      type: type.GET_CLIENT_SIZES_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_CLIENT_SIZES_FAILURE,
      payload: message
    });
  }
}

export function* getClientSizesActionWatcher() {
  yield takeLatest(type.GET_CLIENT_SIZES, getClientSizes)
}
