import React from 'react';
import { PreviewCardModel } from './previewCardModel';
import './previewCard.scss';
import en_translation from '../../localization/en/translation.json';

interface IProps {
    data: PreviewCardModel;
}

class PreviewCard extends React.Component<IProps> {

    render() {
        const {
            description,
            countMessage,
            count,
            activeCount,
            activeCountMessage
         } = this.props.data;

        return (
            <div className="preview">
                <div className="title">
                    {en_translation.preview}
                </div>
                <br />
                <div className="details">
                    <span className="description">{description}</span>
                    <br />
                    {!!count &&
                    <div>
                        <span className="preview-count">{count}</span>
                        {countMessage}
                    </div>
                    }
                    {!!activeCount &&
                    <div>
                        <span className="preview-count">{activeCount}</span>
                        {activeCountMessage}
                    </div>
                    }
                </div>
            </div>);
    }
}

export default PreviewCard;
