export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: "Created",
    field: "created",
     sortable: true,
    width: 250,  
    sortingOrder: ['desc', 'asc', null],
    cellRenderer: "cellRenderDateRows",
  },
  {
    headerName: "Title",
    field: "title",
    sortable: true,
    width: 350,
    wrapText: true,
    cellRenderer: "readMoreTextCellRender",
    filter: "customFilter",
  },
  {
    headerName: "Err Stack",
    field: "wfr_ErrStack",
    width: 550,
    cellRenderer: "readMoreTextCellRender",
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: "Error Source",
    field: "wfr_ErrorSource",
    sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
];
