import * as type from "../action-types";

const initialState = {
  projectTypes: [],
  updateProjectTypeData: {},
  loading: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROJECT_TYPES:
      return { ...state, loading: true };
    case type.GET_PROJECT_TYPES_SUCCESS:
      return { ...state, projectTypes: action.payload.data, loading: false };
    case type.GET_PROJECT_TYPES_FAILURE:
      return { ...state, projectTypes: [], loading: false };
    case type.UPDATE_PROJECT_TYPE_REQUEST:
    case type.ADD_PROJECT_TYPE_REQUEST:
      return { ...state, updateProjectTypeData: {}, loading: true };
    case type.UPDATE_PROJECT_TYPE_SUCCESS:
    case type.ADD_PROJECT_TYPE_SUCCESS:
      return { ...state, updateProjectTypeData: action.payload, loading: false };
    case type.UPDATE_PROJECT_TYPE_FAILURE:
    case type.ADD_PROJECT_TYPE_FAILURE:
      return { ...state, updateProjectTypeData: {}, loading: false };
    case type.FLUSH_PROJECT_TYPE_DATA:
      return { ...state, updateProjectTypeData: {} };
    default:
      return state;
  }
};
