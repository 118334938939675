export const DATE_TIME_SCHEDULER = {
  format: "MM/dd/yyyy",
  minTime: 0,
  maxHrs: 23,
  maxMinutes: 59
}

export const EMAIL_TEMPLATE = {
  distrubutionGroups: ["EY_CapitalEdge_DevOps.GID", "EY_CapitalEdge_Appleads.GID"],
  plan: {
    subject: "Capital Edge Upcoming Maintenance Notification",
    body: `We will be performing maintenance on Capital Edge – see below details.\r\n \r\nWhat: [Fill in a short description of “What” work will be done]\r\nWhy: [Fill in a short description of “Why” the work is being performed]\r\nWhen: [Fill in the planned start date/time & planned end date/time – IN THE TIMEZONE THE WORK WILL BE PERFORMED]\r\nExpectation: [Fill in a short description of what users can expect to be their experience during the maintenance event]` //NOSONAR
  },
  started: {
    subject: "Capital Edge Maintenance Started Notification",
    body: `Capital Edge is undergoing scheduled maintenance – see below details.\r\n \r\nWhat: [Fill in a short description of “What” work is being done]\r\nWhen: [Fill in the start date/time & end date/time – IN THE TIMEZONE THE WORK IS BEING PERFORMED]\r\nExpectation: [Fill in a short description of what users can expect to be their experience during the active maintenance event]` //NOSONAR
  },
  completed: {
    subject: "Capital Edge Upcoming Maintenance Completed Notification",
    body: `Capital Edge scheduled maintenance is complete – see below details. \r\n \r\nWhat: [Fill in a short description of “What” work was completed]\r\nWhen: [Fill in the start date/time & end date/time – IN THE TIMEZONE THE WORK WAS PERFORMED]\r\nExpectation: [Fill in a short description of what users can expect to be their experience now that the maintenance event is over]` //NOSONAR
  }
}

export const MAX_CHAR_LIMIT = 200;