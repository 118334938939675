import React, { useEffect, useState } from "react";
import { AppFormErrorText, MotifAppSelect } from "../../theme";
import t from "../../localization/en/translation.json";

type Price = "Yes" | "No" | undefined;
type PricingFieldsType = { price: Price };
const PricingFields = ({
  value,
  valid,
  onUpdate,
}: {
  value: PricingFieldsType;
  valid: boolean;
  onUpdate: (update: PricingFieldsType) => {};
}) => {
  const [price, setPrice] = useState<Price>(() => value?.price);
  useEffect(() => {
    if (price) {
      onUpdate({ price: price as Price });
    }
  }, [onUpdate, price]);
  return (
    <div className={"price-field"}>
      <div className={"available"}>
        <MotifAppSelect
          id={"pricing"}
          value={price ?? ""}
          disabled={false}
          name={"Pricing"}
          placeholder={t.pricing}
          // displayEmpty={true}
          //   label={t.pricing}
          isValid={valid}
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          onChange={({ target: { value } }) => setPrice(value as Price)}
        />
        {!valid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      </div>
    </div>
  );
};

const PricingProvisionPolicy = ({ value, valid, onUpdate }) => {
  return (
    <>
      <div className="client-engagement-policy-wrapper">
        <span className={"client-engagement-policy"}>
          You selected Client engagement as the project category. That's
          fantastic - we love to see new engagements on-board to Capital Edge!
        </span>

        <span className={"client-engagement-policy"}>
          Pricing: You should be including Capital Edge in your pricing to your
          client. Click{" "}
          <a
            className={"pricing-information-link"}
            href={process.env.REACT_APP_PRICING_SITE}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            pricing guidance
          </a>{" "}
          for details.
        </span>
        <span className={"client-engagement-policy"}>
          Please indicate whether Capital Edge pricing guidance has been
          included in the engagement economics:
        </span>
        <span className={"client-engagement-policy"}>
          Pricing Guidance Included?
        </span>
        <PricingFields value={value} valid={valid} onUpdate={onUpdate} />

        <span className={"client-engagement-policy-helper"}>
          Costs: No technology costs are allocated to engagements at this time;
          however cloud hosting costs for your engagement will be charged back
          to your region based on usage. It is your responsibility to close your
          project in a timely manner to help minimize these costs. To help
          manage this, we will check in with you once a quarter to make sure you
          still need the project.
        </span>
      </div>
    </>
  );
};

export default PricingProvisionPolicy;
