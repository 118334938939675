import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { fetchProject } from '../apis/projectApi';

function* searchProject(action) {
  try {
    const data = yield call(fetchProject, action);
    yield put({
      type: type.SEARCH_PROJECT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_PROJECT_FAILURE,
      payload: message,
    });
  }
}

export function* searchProjectsActionWatcher() {
  yield takeLatest(type.SEARCH_PROJECT, searchProject);
}
