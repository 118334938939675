export const MIN_FILTER_CHAR_LIMIT = 3;
export const ZERO_PREFIX = 0;
export const MAX_MONTH_LENGTH = 2;
export const DATE_FORMAT = "YYYY-MM-DD";
export const FILTER_TYPE = {
  date: "date",
  boolean: "boolean",
  none: "none",
};

export const RADIO_OPTIONS = {
  yes: "Yes",
  no: "No",
};

export const SORT_ORDER = {
  asc: "asc",
  desc: "desc",
};
