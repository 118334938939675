import React, { FC } from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from "@material-ui/styles";
import themeConfig from "../config";

const useStyle = makeStyles({
    root: {
        textAlign: 'center',
        color: themeConfig.black,
        '&$checked': {
            color: themeConfig.black,
        }
    },
    checked: {}
})

const AppRadioButton: FC<RadioProps> = ({ children, ...restProps }) => {
    const classes = useStyle();

    return (
        <FormControlLabel
            classes={{
                root: classes.root
            }}
            control={
                <Radio
                    color="default"
                    size="medium"
                    classes={{ ...classes }}
                    {...restProps}
                />
            }
            label={children}
      />
    )
}

export default AppRadioButton;
