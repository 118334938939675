export const DEFAULT_SORT = {
  sort: "desc",
  colId: "createdOn",
};

export const IS_CUSTOMIZED = {
  yes: "Yes",
  no: "No",
  default: "-",
};

export const MORE_MENU_ACTION_NAME = {
  SOFT_CLOSE: "Soft Close",
  REACTIVATE: "Reactivate",
  VIEWLOG: "View log",
  CLOSEPROJECT: "Close project",
  WORKFLOWS: "Workflows",
  ACCESSRESTRICTIONS: "Access Restrictions",
  ACCESSRESTRICTIONALL: "All",
  ACCESSRESTRICTIONNONE: "None",
  ACCESSRESTRICTIONPROJECTADMIN: "ProjectAdministrator",
};
