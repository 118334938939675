import { clock, envelope, exclamation, file, list, playIconWithoutCircle } from "../../theme/icons";

export const PORTAL_COMMS = "portalNotification";
export const PORTAL_COMMS_GENERAL = "gen-notifications";
export const PROJECT_WORKFLOW_URL = "/manage-workflows/"
export const PROJECT_STATUS_ALL = "ALL";

export const tabs = [
  {
    name: "Workflows",
    url: "/workflows/definitions",
    key: "workflows",
    isRedirect: true,
  },
  {
    name: "Email Communications",
    url: "/email-communications/communications",
    key: "email-communications",
    isRedirect: true,
  }
];

export const generalSubTabs = [
  {
    name: "Communications",
    url: "manage-workflows/email-communications/communications",
    key: "communications",
    icon: envelope
  },
  {
    name: "Templates",
    url: "manage-workflows/email-communications/templates",
    key: "templates",
    icon: file
  },
  {
    name: "Definitions",
    url: "manage-workflows/workflows/definitions",
    key: "definitions",
    icon: list
  },
  {
    name: "Schedules",
    url: "manage-workflows/workflows/schedules",
    key: "schedules",
    icon: clock
  },
  {
    name: "Executions",
    url: "manage-workflows/workflows/executions",
    key: "executions",
    icon: playIconWithoutCircle
  },
  {
    name: "Error Log",
    url: "manage-workflows/workflows/error-log",
    key: "error-log",
    icon: exclamation
  }
];

export const TAB_NAME = {
  EMAIL_NOTIFICATIONS: "email-communications",
  WORKFLOWS: "workflows",
  MANAGE_WORKFLOWS_EMAIL_NOTIFICATIONS: "manage-workflows/email-communications/",
  MANAGE_WORKFLOWS_WORKFLOWS: "manage-workflows/workflows/",
};

export interface ITabMenu {
  name: string;
  url: string;
  key: string;
  isRedirect?: boolean;
}
