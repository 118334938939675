export interface IPagination {
    pageNumber: number;
    pageSize: number;
    totalRecords?: number;
    sortColumn?: string;
    sortDirection?: string;
    isAnyoneHasAcceptedTerms?: boolean;
    totalRecordsWithoutFilter?: number;
    filters?: Ifilters[];
    id?: string;
    tableName?: string;
    apiURL?: string;
}

export interface Ifilters {
    filterColumn: string,
    filterText: string
}

export interface Isort {
    colId: string,
    sort: string
}

export const getPaginationModel = ({ pageNumber, pageSize, totalRecords, sortColumn, sortDirection, isAnyoneHasAcceptedTerms, filters }: IPagination) => {

    const defaultPaginationModel = getDefaultPaginationModel();
    defaultPaginationModel.pageNumber = pageNumber || defaultPaginationModel.pageNumber;
    defaultPaginationModel.pageSize = pageSize || defaultPaginationModel.pageNumber;
    defaultPaginationModel.totalRecords = totalRecords || defaultPaginationModel.totalRecords;
    defaultPaginationModel.sortColumn = sortColumn || defaultPaginationModel.sortColumn;
    defaultPaginationModel.sortDirection = sortDirection || defaultPaginationModel.sortDirection;
    defaultPaginationModel.isAnyoneHasAcceptedTerms = isAnyoneHasAcceptedTerms || defaultPaginationModel.isAnyoneHasAcceptedTerms;
    defaultPaginationModel.filters = filters || defaultPaginationModel.filters;
    return defaultPaginationModel;
}

export const getDefaultPaginationModel = (): IPagination => {
    return {
        pageNumber: 1,
        pageSize: 50,
        totalRecordsWithoutFilter: 0,
    }
}
