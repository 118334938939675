import * as type from "../action-types";

const initialState = {
  searchUsersLoader: false,
  searchUsersData: [],
  skipToken:''
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.SEARCH_USERS:
      return { ...state, searchUsersLoader: true };
    case type.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        skipToken: action.payload.data.pagination.skipToken,
        searchUsersData: action.payload.data.pagination.pageNumber !== 1 ?
          [...state.searchUsersData, ...action.payload.data.records] : action.payload.data.records,
          searchUsersLoader: false
      };
    case type.SEARCH_USERS_FAILURE:
      return { ...state, searchUsersData: [], searchUsersLoader: false };
    default:
      return state;
  }
};