import AppModal from "../../theme/appModal/appModal";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import en_translation from '../../localization/en/translation.json';
import "./emailChangeStyle.scss";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../store/reducers";
import { ME_REDUCER_STATE } from "../../store/reducers/me-reducer";

export const EmailChangeModalPopUp = () => {
  const { currentEmail,previousEmail} = useSelector<
  APP_STATE,
  ME_REDUCER_STATE
>(({ meReducer }) => meReducer);
const DataToReplace = {
  currentEmail,
  previousEmail,
};
  return (
    <AppModal
      size="lg"
      modalClass="emailChange"
      showModal={true}
      showCloseModelIcon={false}
    >
      <div className="modal-content">
        <div>{en_translation.email_change_popup_content.replace(/currentEmail|previousEmail/gi, (matched)=>DataToReplace[matched])
          }</div>
        <br />
        <br />
        <div className="progressEmailChange">
          <CircularProgress />
        </div>
      </div>
    </AppModal>
  );
};
