
import en_translation from "../../localization/en/translation.json";
export interface IMenuLinks {
    title: string;
    link: string;
    key: string;
    isActive: boolean;
    subPath?: string;
}
export const MenuLinksKeys = {
    PROJECTS:"PROJECTS",
    APPS:"APPS",
    USERS:"USERS",
    REPORTS:"REPORTS",
    MAINTENANCE: "MAINTENANCE",
    ADMINISTRATION: "ADMINISTRATION"
};
export const MenuLinks = [{
    title: en_translation.admin_menu_header_projects,
    link: '/admin/projects',
    key: MenuLinksKeys.PROJECTS,
    isActive: true
},{
    title: en_translation.admin_menu_header_apps,
    link: '/admin/apps',
    key: MenuLinksKeys.APPS,
    isActive: true
},{
    title: en_translation.admin_menu_header_users,
    link: '/admin/users',
    key: MenuLinksKeys.USERS,
    isActive: true
},{
    title: en_translation.admin_menu_header_report,
    link: '/admin/reports',
    key: MenuLinksKeys.REPORTS,
    isActive: true
},{
    title: en_translation.admin_menu_header_maintenance,
    link: '/admin/maintenance',
    key: MenuLinksKeys.MAINTENANCE,
    isActive: false
},
{
    title: en_translation.admin_menu_header_administration,
    link: '/admin/administration/workflows/definitions',
    key: MenuLinksKeys.ADMINISTRATION,
    isActive: true,
    subPath: '/admin/administration'
}
]

