import React, { useEffect, useRef, useState } from "react";
import { IProjectIcon } from "../../models/IProjectIcon";

type IProps = {
    icon?: IProjectIcon,
    highlight?: boolean
    select?: boolean
    onClick?: Function;
}

const ProjectIcon: React.FC<IProps> = ({ icon, highlight, select, onClick }) => {

    const [loading, setLoading] = useState(false);
    const iconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();

    const onIconClick = (iconId) => {
        if (onClick) {
            onClick(iconId)
        }
    }

    useEffect(() => {
        const importIcon = async () => {
            try {
                setLoading(true);
                if (icon) {
                    const namedImport = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./icons/${icon.iconName}.svg`)).default;
                    iconRef.current = namedImport;
                }
            } catch (err) {
                throw err;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [icon]);

    if (!loading && iconRef.current) {
        const { current: ImportedIcon } = iconRef;
        return (
            <div
                onClick={() => onIconClick(icon?.id)}
                className={`project-icon${highlight ? " highlight" : ""}${select ? " select" : ""}`}
                style={highlight ? { backgroundColor: icon?.colorCode || "white" } : {}}
            >
                <ImportedIcon />
            </div>
        )
    }
    return null;
}

export default ProjectIcon;
