import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import * as _ from 'lodash';
import { DEFAULT_PLACEHOLDER, DEFAULT_CHUNK, APP_MAINTENANCE_BANNER_ID } from "./appSearchDropdownConstants";
import { AppIcon, AppCheckbox, AppButton, AppTooltip } from "../../theme";
import { downArrow, rightArrow, leftArrow } from "../../theme/icons";
import t from "../../localization/en/translation.json";
import { usePrevious } from "../../utils/hooks"

import "./appSearchDropdown.scss";

interface IProps {
  options: IOption[];
  multiSelect?: boolean;
  placeholder?: string;
  defaultValue: IOption[] | null;
  onValueChange?: Function;
  width: number;
  isDisabled?: boolean;
  idSuffix?: string;
}

interface IOption {
  label: string;
  value: string
}

interface IPos {
  top?: number;
  left: number;
  isBottomOpen: boolean
}

const MENU_HEIGHT = 310;
const TOP_MENU_POS_PUSH = 20;
const BOTTOM_MENU_POS_PUSH = 50;
const HEIGHT_OF_APP_HEADER = 50;

const AppSearchDropdown: FC<IProps> = (props) => {

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);
  const [page, setPage] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(props.defaultValue || []);
  const [dropdownPos, setDropdownPos] = useState<IPos>({ left: 0, isBottomOpen: true });
  const maintenanceBannerTopPosition = document.querySelector(`#${APP_MAINTENANCE_BANNER_ID}`);
  const dropdownRef = useRef<HTMLInputElement>(null);

  const prevState = usePrevious({ page, searchStr, isDropdownOpen });

  const handleScroll = () => {
    setIsDropdownOpen(false);
  }

  const handleMultiOptionSelect = (e, option) => {
    const previousSelectedOptions = _.cloneDeep(selectedOptions);
    const { onValueChange } = props;
    const updatedSelectedOptions = e.target.checked ? [...previousSelectedOptions, option] : previousSelectedOptions.filter(o => o.value !== option.value)

    setSelectedOptions(updatedSelectedOptions);
    onValueChange && onValueChange(updatedSelectedOptions);
  }

  const handleOptionSelect = (option: IOption) => {
    const previousSelectedOptions = _.cloneDeep(selectedOptions);
    const { onValueChange } = props;
    const updatedSelectedOptions = [...previousSelectedOptions, option]

    setSelectedOptions(updatedSelectedOptions);
    setIsDropdownOpen(false);
    onValueChange && onValueChange(updatedSelectedOptions)
  }

  const handleOutsideClick = (e) => {
    if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  }

  const handleDropdownBlur = (e) => {
    if (!e.relatedTarget) {
      return
    }

    const activeElementId = e.relatedTarget.id || "";

    if (activeElementId.includes("react-select")) {
      setIsDropdownOpen(false);
    }
  }

  const toggleDropdown = (e) => {
    const MENU_ITEM_HEIGHT = 40, MENU_CONTAINER_HEIGHT = 110, EMPTY_MENU_HEIGHT = 135;
    const eClient = e.currentTarget.getBoundingClientRect();
    let positionFromTop = eClient.top;
     if (maintenanceBannerTopPosition) { 
      const height = maintenanceBannerTopPosition.clientHeight;
      positionFromTop += height;
    }
  
    const totalItemHeight = filteredOptions.length ? (filteredOptions.length) * MENU_ITEM_HEIGHT + MENU_CONTAINER_HEIGHT : EMPTY_MENU_HEIGHT;
    const actualHeight = totalItemHeight > MENU_HEIGHT ? MENU_HEIGHT : totalItemHeight;
    const isBottomOpen = (positionFromTop + actualHeight + BOTTOM_MENU_POS_PUSH) <= window.innerHeight;
    const top = !isBottomOpen ? positionFromTop - (actualHeight + TOP_MENU_POS_PUSH) : positionFromTop + BOTTOM_MENU_POS_PUSH;
    const left = eClient.left;
    const dropdownPos = { top, left, isBottomOpen };
    setIsDropdownOpen(!isDropdownOpen);
    setDropdownPos(dropdownPos);
  }

  const getSelectedValues = () => {
    const { multiSelect, placeholder } = props;

    if (!selectedOptions.length) {
      return placeholder || DEFAULT_PLACEHOLDER;
    }

    if (!multiSelect) {
      return selectedOptions[0].label;
    }

    return selectedOptions.map((o) => o.label).join(",")
  }

  const checkIfToolTipRequired = (text) => {
    const { width } = props;
    const ctx = document.createElement('canvas').getContext('2d');
    const maxWidth = width * 0.8;

    if (ctx) {
      ctx.font = 'normal 15px EYInterstate-Regular';
      const textMeasurment = ctx.measureText(text);
      return textMeasurment.width >= maxWidth;
    } else if (text.length <= 27) {
      return false;
    }
  }

  const handleNextChunk = () => {
    const { options } = props;
    const maxPage = Math.ceil(options.length / DEFAULT_CHUNK);
    if (page >= maxPage) {
      return
    }

    setPage(page + 1);
  }

  const handlePrevChunk = () => {
    if (page <= 0) {
      return
    }

    setPage(page - 1);
  }

  const handleSearch = (searchStr) => {
    setSearchStr(searchStr)
  }

  const handleSearchFilter = useCallback(() => {
    const { options } = props;
    let updatedFilteredOptions: IOption[] = [];

    if (searchStr.trim().length < 3) {
      return
    } else {
      options.forEach((option) => {
        option.label.toLowerCase().includes(searchStr.trim().toLowerCase()) && updatedFilteredOptions.push(option);
        
      })
    }

    setFilteredOptions(updatedFilteredOptions);
  }, [props, searchStr])

  const getDefaultChunk = useCallback(() => {
    const { options } = props;

    return options.filter((o, i) => i < DEFAULT_CHUNK)
  }, [props])

  const getChunk = useCallback(() => {
    const { options } = props;
    const optionsLength = ((page * DEFAULT_CHUNK) + DEFAULT_CHUNK) >= options.length ? options.length : ((page * DEFAULT_CHUNK) + DEFAULT_CHUNK);

    setFilteredOptions(options.filter((o, i) => i >= (page * DEFAULT_CHUNK) && i < optionsLength));
  }, [props, page])

  const renderDropdownList = () => {
    const { options, multiSelect } = props;
    const { top, left, isBottomOpen } = dropdownPos;
    const availableOptions = filteredOptions;
    const isNextDisabled = !options.length || (page + 1 >= Math.ceil(options.length / DEFAULT_CHUNK));
    const style = { top: `${(top ?? 0) < HEIGHT_OF_APP_HEADER ? HEIGHT_OF_APP_HEADER : top}px`, left: `${left}px`, maxHeight: `${MENU_HEIGHT}px` }

    return (
      <div style={style} className={`app-dropdown-list ${isBottomOpen ? "bottom-list" : "top-list"} ${searchStr.trim().length && "search-results"}`}>
        {multiSelect && <input className="dropdown-search-bar" type="text" value={searchStr} onChange={(e) => handleSearch(e.target.value)} />}
        {!filteredOptions.length ?
          <div className="no-results">{t.manage_users_no_results}</div> :
          <ul className="app-dropdown-options">
            {availableOptions.map((o, i) => {
              let isSelectedOption = !!selectedOptions.find((so) => so.value === o.value);
              return (
                <li key={`option-${i}`} className={`${isSelectedOption ? "selected" : ""} ${!multiSelect ? "single-select" : ""} app-dropdown-option`}>
                  {multiSelect ?
                    <AppCheckbox
                      name={o.value}
                      value={o.value}
                      disabled={false}
                      checked={isSelectedOption}
                      onChange={handleMultiOptionSelect}
                    >
                      {o.label}
                    </AppCheckbox> :
                    <span onClick={() => handleOptionSelect({ value: o.value, label: o.label })}>
                      {o.label}
                    </span>
                  }
                </li>
              )
            })}
          </ul>}
        {<div className="dropdown-paginate">
          <AppButton variant="text" disabled={page <= 0} onClick={handlePrevChunk}>
            <AppIcon icon={leftArrow} />
            {t.prev}
          </AppButton>
          <AppButton disabled={isNextDisabled} variant="text" onClick={handleNextChunk}>
            {t.next}
            <AppIcon icon={rightArrow} />
          </AppButton>
        </div>}
      </div>
    )
  }

  const resetFilters = useCallback(() => {
    setPage(0);
    setFilteredOptions(getDefaultChunk());
  }, [getDefaultChunk])

  const { isDisabled, idSuffix } = props;
  const selectedOptionsText = getSelectedValues();
  const isTooltipNeeded = checkIfToolTipRequired(selectedOptionsText);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousedown", handleOutsideClick);
    setFilteredOptions(getDefaultChunk());

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousedown", handleOutsideClick);
      setSearchStr("");
    }
  }, [getDefaultChunk])

  useEffect(() => {
    if (prevState?.page !== page) {
      getChunk()
    }
    if (prevState?.searchStr !== searchStr.trim()) {
      handleSearchFilter()

      if (!searchStr.trim().length) {
        resetFilters();
      }
    }

    if (prevState?.isDropdownOpen !== isDropdownOpen && isDropdownOpen) {
      setSearchStr("");
    }
  }, [prevState, page, searchStr, isDropdownOpen, getChunk, handleSearchFilter, resetFilters]);

  return (
    <div id={"select-" + (idSuffix || 1)} ref={dropdownRef} onBlur={handleDropdownBlur} className="app-search-dropdown">
      <button disabled={!!isDisabled} className="app-dropdown-button" onClick={toggleDropdown}>
        {isTooltipNeeded ? <AppTooltip
          placement={"top"}
          className="roles-selector"
          title={selectedOptionsText}
        >
          <span className="dropdown-selected-values">{selectedOptionsText}</span>
        </AppTooltip> :
          <span className="dropdown-selected-values">{selectedOptionsText}</span>
        }
        <AppIcon className="dropdown-arrow" icon={downArrow} />
      </button>
      {isDropdownOpen && renderDropdownList()}
    </div>
  )
}

export default AppSearchDropdown;
