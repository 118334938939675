import {
  WFFields,
  CommunicationFields,
  InputFieldsType,
  WORKFLOW_IS_ACTIVE,
  InputFieldCss,
} from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = {
  colId: CommunicationFields.communicationName,
  sort: "asc",
};
export const MORE_MENU_ACTION_NAME = {
  UPDATE: "Update",
  DELETE: "Delete",
  ADD: "Add",
  PREVIEW: "Preview",
};

export const FORM_COMMUNICATIONS = [
  {
    fieldLabel: t.admin_cps_form_id,
    fieldName: WFFields.id,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: true,
    className: InputFieldCss.large,
    isHidden: true,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_communications,
    fieldName: CommunicationFields.communicationName,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isRequired: true,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_title_template,
    fieldName: CommunicationFields.communicationTemplateId,
    fieldType: InputFieldsType.Select,
    fieldOptions: [],
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
    fieldOnChangeMapping: [
      CommunicationFields.communicationEmailSubject,
      CommunicationFields.communicationEmailBody,
      CommunicationFields.cwfiworkflowParameters,
    ],
  },
  {
    fieldLabel: t.admin_cps_form_communication_send_from_mail,
    fieldName: CommunicationFields.sendFromEmail,
    // eslint-disable-next-line no-template-curly-in-string
    fieldVal: "${CALCULATED}",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: true,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_send_to_mail,
    fieldName: CommunicationFields.sendToEmail,
    // eslint-disable-next-line no-template-curly-in-string
    fieldVal: "${CALCULATED}",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: true,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_is_active,
    fieldName: CommunicationFields.isItemActive,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_IS_ACTIVE,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: "",
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_title_schedule,
    fieldName: CommunicationFields.wfrScheduleLookupId,
    fieldType: InputFieldsType.Select,
    fieldOptions: [],
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: "",
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_subject,
    fieldName: CommunicationFields.communicationEmailSubject,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.large,
  },
  {
    fieldLabel: t.admin_cps_form_communication_message_body,
    fieldName: CommunicationFields.communicationEmailBody,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_communication_workflow_parameters,
    fieldName: CommunicationFields.cwfiworkflowParameters,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    isTouched: false,
  },
];
