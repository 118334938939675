export const SOCPUB = "SOCPUB";
export const SOCDOM = "SOCDOM";
export const EDTMON = "EDTMON";
export const COMCALL = "COMCALL";
export const MAPDIR = "MAPDIR";
export const EDTCHART = "EDTCHART";
export const ACTHOM = "ACTHOM";
export const BUSCEN = "BUSCEN";
export const MAPHOS = "MAPHOS";
export const REALESTATEAGENT = "REALESTATEAGENT";
