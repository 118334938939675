import React, { useCallback, useEffect, useState } from "react";
import AppModal from "../../theme/appModal/appModal";
import t from "../../localization/en/translation.json";
import {fetchPricingQuestions} from "../../store/apis/projectApi";
import {AppSelect} from "../../theme";
import AppTextArea from "../../theme/appTextArea/appTextArea";
import "./CloseProjectPopup.scss";

export type FeedbackType = { isTechnologyFeeIncluded?: boolean | undefined, isTechnologyLineItemInInvoice?: boolean, technologyChargedInterval?: string, technologyFeeNotIncludedReason?: string, additionalDetails?: string };
export const CloseProjectPricingFeedback = ({
                                                priced,
                                                onFeedback,
                                            }: { priced:boolean | undefined, onFeedback: (feedback:FeedbackType)=>void}) => {
    const [isPricingAvailable, setPricingAvailable] = useState<boolean | undefined>(() => priced);
    const [isPricingSeparate, setPricingSeparate] = useState<boolean | undefined>(() => undefined);
    const [isPricingMonthly, setPricingMonthly] = useState<string | undefined>(() => undefined);
    const [reason, setReason] = useState<string | undefined>(() => undefined);
    const [modes, setModes] = useState<{ label: string, value: string }[]>([]);
    const [additional, setAdditional] = useState("");
    const [reasons, setReasons] = useState<{ label: string, value: string }[]>([]);
    useEffect(() => {
        (async () => {
                try {
                    const response = await fetchPricingQuestions();
                    const data = response?.data as { pricingQuestionKey: string, name: string, id: string }[];
                    setModes(data.filter((option) => option.pricingQuestionKey === "TechnologyChargedInterval")
                        .map((option) => ({label: option.name, value: option.id})));
                    setReasons(data.filter((option) => option.pricingQuestionKey === "TechnologyFeeNotIncludedReason")
                        .map((option) => ({label: option.name, value: option.id})));
                } catch {

                }
            }
        )();
    }, []);
    useEffect(() => {
        onFeedback( {
            isTechnologyFeeIncluded: isPricingAvailable,
            ...(() => isPricingAvailable ? ({
                    isTechnologyLineItemInInvoice: isPricingSeparate,
                    technologyChargedInterval: isPricingMonthly
                }) :
                {technologyFeeNotIncludedReason: reason})(),
            additionalDetails: additional
        });
        if(isPricingAvailable){
            setReason(undefined);
        }else{
            setPricingSeparate(undefined);
            setPricingMonthly(undefined);
        }
    }, [isPricingAvailable, isPricingSeparate, isPricingMonthly, reason, additional, onFeedback]);

    return (<div className={"close-project-feedback"}>
        <div className={"row"}>
            <span className={"question"}>
                <span>{t.is_pricing_available}</span>
                <span> Click <a
                    className={"pricing-information-link"}
                      href={process.env.REACT_APP_PRICING_SITE}
                     target={"_blank"} rel={"noopener noreferrer"}>pricing guidance</a> for details</span>
            </span>
            <div className={"feedback-input"}>
                <AppSelect value={isPricingAvailable === true ? "Yes" :
                    isPricingAvailable === false ? "No" : undefined}
                           isValid={(isPricingAvailable != null)}
                           displayEmpty={true}
                           onChange={({target: {value}}) => setPricingAvailable((value as string) === "Yes" ? true : false)}
                           options={[{value: "Yes", label: "Yes"},
                               {value: "No", label: "No"}]}/>
            </div>
        </div>
        {
            isPricingAvailable === true ? <>
                    <div className={"row"}>
                        <span className={"question"}>{t.is_pricing_separate}</span>
                        <div className={"feedback-input"}>
                            <AppSelect value={isPricingSeparate === true ? "Yes" :
                                isPricingSeparate === false ? "No" : undefined}
                                       displayEmpty={true}
                                       onChange={({target: {value}}) => setPricingSeparate((value as string) === "Yes" ? true : false)}
                                       options={[{value: "Yes", label: "Yes"},
                                           {value: "No", label: "No"}]}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <span className={"question"}>{t.is_pricing_monthly}</span>
                        <div className={"feedback-input"}>
                            <AppSelect value={isPricingMonthly}
                                       displayEmpty={true}
                                       onChange={({target: {value}}) => setPricingMonthly((value as string))}
                                       options={modes}/>
                        </div>
                    </div>
                </> :
                (isPricingAvailable !== undefined) &&
                <div className={"row"}>
                    <span className={"question"}>{t.what_reasons_no_pricing}</span>
                    <div className={"feedback-input"}>
                        <AppSelect value={reason}
                                   displayEmpty={true}
                                   onChange={({target: {value}}) => setReason((value as string))}
                                   options={reasons}/>
                    </div>
                </div>
        }
        <AppTextArea placeholder={(isPricingAvailable !== undefined) ? isPricingAvailable? t.pricing_avail_additional_information: t.pricing_not_avail_additional_information: ''}
                     maxLength={500}
                     onChange={({target: {value}}) => setAdditional(value)}
                     rows={5}/>
    </div>);
}

const CloseProjectPopup = ({
                               show,
                               onClosePopup,
                               isPricingFeedback,
                               priced,
                               onProjectClose
                           }: { show: boolean, onClosePopup: () => void, isPricingFeedback: boolean, priced: boolean | undefined, onProjectClose: (feedback: FeedbackType) => void }) => {
    const [feedback, setFeedback] = useState<FeedbackType>({});
    const handleClose = useCallback(() => {
            onProjectClose(feedback);
            onClosePopup();
    }, [feedback, onClosePopup, onProjectClose]);
    const onFeedback = useCallback((feedback: FeedbackType)=> setFeedback(feedback),[setFeedback]);
    const canConfirm = isPricingFeedback? feedback.isTechnologyFeeIncluded? feedback.technologyChargedInterval!=null && feedback.isTechnologyLineItemInInvoice!=null : !!feedback.technologyFeeNotIncludedReason: true;
    return (
        <AppModal
            showModal={show}
            onModalClose={() => onClosePopup()}
            onConfirm={() => handleClose()}
            cancelBtnText={t.cancel}
            confirmBtnText={t.close_project_modal_close_button}
            disabledValue={!canConfirm}
            title={t.close_project_modal_title}
            size="sm"
        >
            <div className="modal-content">
                <div className="app-title">
                    <span className="red-highlighted">{t.close_project_modal_warning_text}</span>
                    {t.close_project_modal_content}
                </div>
                <div className="app-title">
                    {t.close_project_modal_warning}
                    <span className="red-highlighted">{t.close_project_impact}</span>
                </div>
                { isPricingFeedback? <CloseProjectPricingFeedback priced={priced} onFeedback={onFeedback}/>: null}
            </div>
        </AppModal>
    );
}

export default CloseProjectPopup;