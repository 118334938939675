import en_translation from '../../localization/en/translation.json';
export const url = {
	PRIVACY: "https://prod.paans.sbp.eyclienthub.com/viewnotice?a=55478B5A-B2A3-4608-B5C9-A05AF9FC2A6C&r=Global&l=EN&pt=1",
	TERMS: "https://prod.paans.sbp.eyclienthub.com/viewnotice?a=55478B5A-B2A3-4608-B5C9-A05AF9FC2A6C&r=Global&l=EN&pt=2",
	EMG: "https://prod.paans.sbp.eyclienthub.com/viewnotice?a=55478B5A-B2A3-4608-B5C9-A05AF9FC2A6C&r=Global&l=EN&pt=3"
}

export const getFooterText = () => {
	// eslint-disable-next-line no-template-curly-in-string
	return  en_translation.footer_title ? en_translation.footer_title.replace('${Current Year}', new Date().getFullYear().toString()) : "";
}