import React, { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { InputProps as StandardInputProps } from "@material-ui/core/Input";
import themeConfig from "../config";
import "./motifAppTextArea.scss";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    fontFamily: themeConfig.eyInterstateLight,
    position: "relative", // Added to position the tooltip
  },
  tooltipContainer: {
    position: "absolute",
    top: "45px",
    right: 0,
    zIndex: 1,
    transform: "translate(-50%, -50%)",
  },
}));

interface IProps {
  onChange?: StandardInputProps["onChange"];
  disabled?: boolean;
  rows?: number;
  name?: string;
  maxLength?: number;
  label?: string | ReactNode;
  value?: string;
  autoComplete?: string;
  placeholder?: string;
  isValid?: boolean;
  tooltip?: ReactNode; // New prop for tooltip
}

const MotifAppTextArea: FC<IProps> = ({
  maxLength = 5000,
  rows,
  isValid,
  autoComplete,
  label,
  placeholder,
  tooltip, // Added tooltip prop
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className="input-label">{label}</span>
      <TextField
        className={`motif-app-text-field ${
          isValid !== undefined && !isValid ? "in-valid" : ""
        }`}
        classes={classes}
        multiline
        placeholder={placeholder}
        variant="filled"
        autoComplete={autoComplete || "off"}
        rows={rows || 2}
        InputLabelProps={{ style: { pointerEvents: "auto" } }}
        inputProps={{
          maxLength,
          style: {
            height: "100%",
          },
        }}
        {...restProps}
      />
      {tooltip && <div className={classes.tooltipContainer}>{tooltip}</div>}
    </div>
  );
};

export default MotifAppTextArea;
