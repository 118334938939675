import { BUNDLE_FILTER_KEY, OWN_BUNDLE_ID } from "./createProjectOperationsConstants";

export const findNonDefaultAppclassIds = (selectedApp, appClassIds) => {
  return appClassIds.find((app) => app.id === selectedApp.id)
}

export const setSelectedIds = (bundles, selectedAppIds) => {
  const currentBundle = bundles.find(bundle => bundle.selected);
  currentBundle.selectedAppIds = selectedAppIds;
  const nonDefaultAppIds = selectedAppIds.filter((selectedApp) => findNonDefaultAppclassIds(selectedApp, currentBundle.appClassIds) ? "" : selectedApp)

  currentBundle.appClassIds = [...currentBundle.appClassIds, ...nonDefaultAppIds]
  return bundles;
}

export const addOwnBundle = (bundles, ownBundle) => {
  return [...ownBundle, ...bundles]
}

export const flushOwnBundle = (bundles) => {
  return bundles.filter(bundle => !bundle.isOwnBundle);
}

export const getUpdatedBundles = (bundles) => {
  return bundles.filter(f => f.key !== BUNDLE_FILTER_KEY);
}

export const getOwnBundleId = (bundles) => {
  return bundles.find(f => f.key === BUNDLE_FILTER_KEY).id || OWN_BUNDLE_ID;
}
