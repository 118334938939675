import * as type from "../action-types";

const initialState = { showPreviewVideo: false, previewUrl: ''};
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.SHOW_PREVIEW_VIDEO:
            return {
                showPreviewVideo: true,
                previewUrl: action.payload
            };
        case type.HIDE_PREVIEW_VIDEO:
            return {
                showPreviewVideo: false,
                previewUrl: ''
            };
        default:
            return state;
    }
}
