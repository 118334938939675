import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import {
  deleteDraftProjectRequest,
  flushDeletedProject,
  flushUpdatedBundles,
  flushCreatedBundle,
  closeProjectRequest,
  flushCloseProject,
} from "../../store/actions/create-project-actions";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import { getProjectRegions } from "../../store/actions/data-locations-actions";
import { getSectorsAction } from "../../store/actions/sectors-actions";
import { getProjectCategoriesAction } from "../../store/actions/project-categories-actions";
import ProjectCard from "../../components/projectCard/projectCard";
import AppNotification from "../../components/appNotification/appNotification";
import en_translation from "../../localization/en/translation.json";
import { Project } from "../../components/createProject/models/project";
import { IUser, IAccessRight } from "../../models/user";
import { lookupModel } from "../createProject/lookupModel";
import { getMe } from "../../store/actions/user-actions";
import { checkCanCreateProject, formatDate, isProjectAdmin } from "../../utils/helper-utility";
import { ISSPPlatformRole } from "../../models/IRoles";
import { EProjectStatuses } from "../../utils/Constants";
import {
  getProjectIcons,
  getAppPlatforms,
} from "../../store/actions/meta-actions";
import { IProjectIcon } from "../../models/IProjectIcon";
import * as ProjectIconConstant from "../../components/projectIcon/constants";
import {
  USER_ACTIONS,
  gridAppPlatformKeys,
  UserType,
} from "../manageUsers/listUsers/listUserConstants";
import { IDefaultProject } from "../../models/IProjectDefaultRoles";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";
import { enrollToProjectRequest } from "../../store/actions/default-project-actions";
import { AppButton, AppIcon, AppTooltip, MotifAppInput } from "../../theme";
import "./home.scss";
import { FeedbackType } from "../../components/CloseProjectPopup/CloseProjectPopup";
import { CrashBoundary } from "../../components/CrashBoundary";
import { getProjectsSummary, getProjectListSuccess } from "../../store/actions/project-actions";
import { add, arrowUp, arrowDown, magnifyingGlass } from "../../theme/icons";
import BottomBanner from "../../components/bottomBanner/bottomBanner";

type AppProps = RouteComponentProps<any> & {
  deletedProject: { success: boolean; message: string };
  onDeleteDraft: () => void;
  deleteDraftProject: any;
  flushDeletedProject: () => void;
  flushUpdatedBundles: () => void;
  flushCreatedBundle: () => void;
  getProjectStatus(): void;
  getProjectList: Function;
  projectList: Project[];
  getCurrentUser: () => any;
  currentUser: IUser;
  closeProject: Function;
  closeProjectSuccess: { data: boolean; success: boolean };
  accessRight: IAccessRight;
  sspPlatformRoles: ISSPPlatformRole[];
  projectStatus: lookupModel[];
  projectRegions: lookupModel[];
  projectCategories: lookupModel[];
  sectors: lookupModel[];
  flushCloseProject: () => void;
  getProjectRegions: Function;
  getSectors: Function;
  getProjectIconRequest: Function;
  getProjectCategories: Function;
  projectIcons: IProjectIcon[];
  defaultProject: IDefaultProject;
  getAppPlatformsRequest: Function;
  appPlatforms: AppPlatformClass[];
  enrollToProjectRequestAction: Function;
  isCreateProjectDisabled: boolean;
  isMaintanenceFlagLoaded: boolean;
  getDefaultProjectsList: Function;
};
interface IState {
  isMaintanenceLoading: boolean;
  searchKey: string;
  loadAllProjects: boolean;
  defaultTilesCount: number;
}

class Home extends Component<AppProps, IState> {
  deleteDraftName = "";
  closeProjectName = "";

  constructor(props: AppProps) {
    super(props);

    this.state = {
      isMaintanenceLoading: true,
      searchKey: "",
      loadAllProjects: false,
      defaultTilesCount: 0
    };
  }

  componentDidMount() {
    const {
      appPlatforms,
      getAppPlatformsRequest,
      projectIcons,
      projectStatus,
      getProjectStatus,
      getProjectIconRequest,
    } = this.props;
    this.getProjectSummaryList();
    !projectStatus.length && getProjectStatus();
    !appPlatforms.length && getAppPlatformsRequest();
    !projectIcons.length && getProjectIconRequest();
    window.addEventListener('resize', this.getVissbleTileCount);
    this.getVissbleTileCount();
  }

  componentDidUpdate(prevProps) {
    const {
      appPlatforms,
      currentUser,
      deletedProject,
      flushDeletedProject,
      closeProjectSuccess,
      flushCloseProject,
      defaultProject,
      isMaintanenceFlagLoaded,
    } = this.props;

    if (prevProps.deletedProject !== deletedProject && deletedProject.success) {
      this.getProjectSummaryList();
      setTimeout(flushDeletedProject, 3000);
    }

    if (
      prevProps.closeProjectSuccess !== closeProjectSuccess &&
      closeProjectSuccess.success
    ) {
      this.getProjectSummaryList();
      setTimeout(flushCloseProject, 3000);
    }

    if (prevProps.defaultProject !== defaultProject) {
      this.enrollForDemoProjectRoles(
        prevProps,
        defaultProject,
        currentUser,
        appPlatforms
      );
    }
    if (isMaintanenceFlagLoaded && this.state.isMaintanenceLoading) {
      this.setState({ isMaintanenceLoading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getVissbleTileCount);
  }

  getProjectSummaryList = () => {
    const { getProjectList } = this.props;
    getProjectList();
  }

  enrollForDemoProjectRoles = (
    prevProps,
    defaultProject,
    currentUser,
    appPlatforms
  ) => {
    const { enrollToProjectRequestAction, projectList } = this.props;

    const {
      defaultRoles = null,
      loading = false,
      error = false,
      projectId = null,
      enrollProjectId = null,
    } = defaultProject;

    if (
      defaultRoles &&
      prevProps.defaultProject.defaultRoles !== defaultRoles &&
      projectId &&
      prevProps.defaultProject.loading &&
      !loading &&
      !error &&
      !enrollProjectId
    ) {
      // we got the default roles.. let enroll the user

      const sspDefaultRoles = defaultRoles.defaultUserRoles?.find(
        (item) => item.key.toLowerCase() === gridAppPlatformKeys.SSP
      );
      const appPlatformRoles = defaultRoles.defaultUserRoles?.filter(
        (item) => item.key.toLowerCase() !== gridAppPlatformKeys.SSP
      );
      const project = projectList.find((item) => item.id === projectId);

      let appPlatformDefaultRoles: any = [];
      appPlatformRoles?.forEach((item) => {
        const appPlatform = appPlatforms.find(
          (appPlt) => appPlt.key === item.key
        );

        if (appPlatform) {
          const apps =
            project?.appDetails
              ?.filter((app) => app.appPlatformId === appPlatform.id)
              .map((app) => app.appClassName) || [];
          appPlatformDefaultRoles.push({
            appPlatformId: appPlatform.id || "",
            appPlatformName: appPlatform.name,
            oldRoles: [],
            apps: apps,
            newRoles: item.roles.map((role) => role.role),
          });
        }
      });

      let userPayload = {
        userId: currentUser.id,
        mail: currentUser.mail,
        appPlatforms: appPlatformDefaultRoles,
        action: USER_ACTIONS.UPDATE,
        [gridAppPlatformKeys.SSP]: {
          oldRoles: [],
          newRoles: sspDefaultRoles?.roles.map((item) => item.role) || [],
        },
      };

      let payload = {
        projectId,
        users: [
          {
            ...userPayload,
          },
        ],
      };

      enrollToProjectRequestAction({
        projectId,
        data: payload,
      });
    }
  };

  deleteDraftProjectID = (id: string, name: string) => {
    this.deleteDraftName = name;
    this.props.deleteDraftProject(id);
  };

  closeProjectID = (id: string, name: string, feedback: FeedbackType) => {
    this.closeProjectName = name;
    this.props.closeProject({ id, feedback });
  };

  handleCreateProject = () => {
    const {
      history,
      flushUpdatedBundles,
      flushCreatedBundle,
      isCreateProjectDisabled,
    } = this.props;
    if (!isCreateProjectDisabled) {
      flushUpdatedBundles();
      flushCreatedBundle();
      history.push("/create");
    }
  };

  checkProjectAdmin(record) {
    const { accessRight, sspPlatformRoles } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspPlatformRoles);
  }

  checkAllPlatformStatus = (project: Project): boolean => {
    const { projectStatus } = this.props;
    let showClosebtn = true;

    if (project.appPlatformDetails) {
      for (const platform of project.appPlatformDetails) {
        if (
          platform.statusId !==
          projectStatus.find((status) => status.key === EProjectStatuses.EROR)
            ?.id &&
          platform.statusId !==
          projectStatus.find((status) => status.key === EProjectStatuses.RDY)
            ?.id
        ) {
          showClosebtn = false;
          break;
        }
      }
    }
    return showClosebtn;
  };

  checkInfrapipelineStatus = (project: Project): boolean => {
    const { projectStatus } = this.props;
    return (
      project.infrastructureStatusId ===
      projectStatus.find((status) => status.key === EProjectStatuses.RDY)
        ?.id ||
      project.infrastructureStatusId ===
      projectStatus.find((status) => status.key === EProjectStatuses.EROR)?.id
    );
  };

  getVissbleTileCount = () => {
    const minTileSize = 290;
    const rowCount = 2;
    const vissbleTileCount = Math.floor((window.innerWidth / minTileSize)) * rowCount;
    this.setState({ defaultTilesCount: vissbleTileCount });
  }

  checkShowLoadMore = () => {
    const { projectList } = this.props;
    const { searchKey, defaultTilesCount } = this.state;
    return defaultTilesCount < projectList.length && !searchKey.length;
  }

  checkShowLoadLess = () => {
    const { projectList } = this.props;
    const { searchKey, loadAllProjects, defaultTilesCount } = this.state;
    return defaultTilesCount <= projectList.length && loadAllProjects && !searchKey.length;
  }

  toggleLoadAllProjects = () => {
    this.setState({ loadAllProjects: !this.state.loadAllProjects });
  }

  onChangeSearchKey = (event) => {
    this.setState({ searchKey: event.target.value })
  }

  getFilteredProjects = () => {
    const { searchKey, loadAllProjects, defaultTilesCount } = this.state;
    const { projectList, accessRight, currentUser } = this.props;

    let filteredProjects = projectList.filter((item) => {
      const projectAccess = accessRight.projects?.find(
        (project) => project.projectId === item.id
      );
      return (
        (projectAccess && !projectAccess.deleted) ||
        (item.isDemoProject &&
          currentUser?.userType?.toLocaleLowerCase() === UserType?.Internal)
      );
    });

    if (searchKey.length) {
      filteredProjects = projectList.filter(item => {
        const searchKeyLower = searchKey.trim().toLowerCase();
        const createdOnDate = item.createdOn ? formatDate(item.createdOn).toLowerCase() : '';
        return (
          item.name.toLowerCase().includes(searchKeyLower) ||
          (item.sectorName?.toLowerCase() ?? '').includes(searchKeyLower) ||
          (item.projectCategoryName?.toLowerCase() ?? '').includes(searchKeyLower) ||
          createdOnDate.includes(searchKeyLower)
        );
      });
    } else {
      const visibleProjectsCount = loadAllProjects ? filteredProjects.length : defaultTilesCount;
      filteredProjects = filteredProjects.slice(0, visibleProjectsCount);
    }

    return filteredProjects;
  }

  render() {
    const {
      deletedProject,
      flushDeletedProject,
      currentUser,
      accessRight,
      closeProjectSuccess,
      flushCloseProject,
      projectIcons,
      defaultProject,
      isCreateProjectDisabled,
    } = this.props;

    const { isMaintanenceLoading, searchKey, loadAllProjects } = this.state;
    const canCreateProject = checkCanCreateProject(accessRight);
    const filteredProjects = this.getFilteredProjects();
    const showLoadMore = this.checkShowLoadMore();
    const showLoadLess = this.checkShowLoadLess();

    return (
      <CrashBoundary>
        <div className="home">
          <div className="head-section">
            <section className="home-banner">
              {/* NEED TO REVISIT THIS BLOCK ONCE BANNER TEXT IS CONFIRMED */}

              {/* <img src={newBanner} alt="home banner"></img>
              <div className="banner-text">
                <p> {en_translation.ce_home_banner_text}</p>
              </div> */}
            </section>
          </div>
          <div className="title-section">
            <span className="tiles-heading">
              {en_translation.ssp_home_page_my_projects}
            </span>
            <div className="top-action-container">
              <div className="search-container">
                <MotifAppInput
                  name={'homeSearch'}
                  className="motif-search-box"
                  maxLength={15}
                  value={searchKey}
                  onChange={this.onChangeSearchKey}
                  placeholder="Search"
                  startAdornment={<AppIcon icon={magnifyingGlass} className="search-box-icon" />}
                />
              </div>
              {currentUser.id && canCreateProject && (
                <AppTooltip
                  placement="bottom"
                  hide={!isCreateProjectDisabled}
                  title={
                    en_translation.admin_portal_maintanence_flag_enabled_create_project_disabled_tooltip
                  }
                >
                  <AppButton
                    onClick={this.handleCreateProject}
                    size="medium"
                    className={
                      isMaintanenceLoading
                        ? "skeleton-loader"
                        : isCreateProjectDisabled
                          ? "manage-user-btn-disabled"
                          : ""
                    }
                  >
                    <AppIcon icon={add} className="create-button-icon" /> {en_translation.home_btn_create_project}
                  </AppButton>
                </AppTooltip>
              )}
            </div>

          </div>
          <div className="tiles-section-container">

            {!!filteredProjects.length &&
              <div className="tiles-section">
                {filteredProjects.map((project: Project) => {
                  const isProjectAdmin = this.checkProjectAdmin(project);
                  const showCloseBtn: boolean =
                    this.checkAllPlatformStatus(project) &&
                    this.checkInfrapipelineStatus(project);
                  const icon = projectIcons.find(
                    (item) => project.iconId && item.id === project.iconId
                  );

                  return (
                    <ProjectCard
                      key={project.id}
                      onDeleteDraft={this.deleteDraftProjectID}
                      onCloseProject={this.closeProjectID}
                      record={project}
                      isProjectAdmin={isProjectAdmin}
                      accessRight={accessRight}
                      showCloseBtn={showCloseBtn}
                      regionName={project.regionName || ""}
                      categoryName={project.projectCategoryName || ""}
                      sectorName={project.sectorName || ""}
                      icon={
                        icon ||
                        projectIcons.find(
                          (item) => item.key === ProjectIconConstant.SOCPUB
                        )
                      }
                      redirectToProjectDetails={
                        defaultProject.redirectToProject &&
                        defaultProject.enrollProjectId === project.id
                      }
                    />
                  );
                })}
              </div>
            }
            {!filteredProjects.length &&
              <div className="no-records">{en_translation.ce_home_no_projects_available}</div>}
          </div>

          <div className="load-more-buttons">
            {
              !loadAllProjects && showLoadMore && (
                <AppButton
                  id="load-more-tiles-button"
                  onClick={this.toggleLoadAllProjects}
                  size="medium"
                >
                  {en_translation.ce_home_load_more_label} <AppIcon icon={arrowDown} className="load-button-icon" />
                </AppButton>
              )
            }
            {
              loadAllProjects && showLoadLess && (
                <AppButton
                  id="show-less-tiles-button"
                  onClick={this.toggleLoadAllProjects}
                  size="medium"
                >
                  {en_translation.ce_home_show_less_label} <AppIcon icon={arrowUp} className="load-button-icon" />
                </AppButton>
              )
            }
          </div>
          <BottomBanner userType={currentUser?.userType} />
          {deletedProject.success && (
            <AppNotification
              message={
                deletedProject.message ||
                `${this.deleteDraftName} ${en_translation.project_deleted}`
              }
              onCloseNotifcation={flushDeletedProject}
              variant="success"
            />
          )}
          {closeProjectSuccess.success && (
            <AppNotification
              message={`${this.closeProjectName} ${en_translation.close_project_message}`}
              variant="success"
              onCloseNotifcation={flushCloseProject}
            />
          )}
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({
  createProjectReducer,
  projects,
  meReducer,
  meta,
  projectStatusReducer,
  dataLocationsReducer,
  projectCategoriesReducer,
  sectorsReducer,
  defaultProject,
  adminMaintenance,
}) => {
  return {
    deletedProject: createProjectReducer.deletedProject,
    projectList: projects.list,
    projectTilesPagination: projects.projectTilesPagination,
    currentUser: meReducer.currentUser,
    closeProjectSuccess: createProjectReducer.closeProject,
    accessRight: meReducer.accessRight,
    sspPlatformRoles: meta.userRoles,
    projectStatus: meta.projectStatuses || projectStatusReducer.projectStatus,
    projectRegions: dataLocationsReducer.projectRegions,
    projectCategories: projectCategoriesReducer.projectCategories,
    sectors: sectorsReducer.sectors,
    projectIcons: meta.icons,
    appPlatforms: meta.appPlatforms,
    defaultProject,
    isCreateProjectDisabled: adminMaintenance.isCreateProjectDisabled,
    isMaintanenceFlagLoaded: adminMaintenance.isMaintanenceFlagLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDraftProject: (payload) => {
      dispatch(deleteDraftProjectRequest(payload));
    },
    flushDeletedProject: () => {
      dispatch(flushDeletedProject());
    },
    getProjectList: () => {
      dispatch(getProjectsSummary());
    },
    getCurrentUser: () => {
      dispatch(getMe());
    },
    flushUpdatedBundles: () => {
      dispatch(flushUpdatedBundles());
    },
    flushCreatedBundle: () => {
      dispatch(flushCreatedBundle());
    },
    closeProject: (payload) => {
      dispatch(closeProjectRequest(payload));
    },
    flushCloseProject: () => {
      dispatch(flushCloseProject());
    },
    getProjectStatus: () => {
      dispatch(getProjectStatusAction());
    },
    getProjectRegions: () => {
      dispatch(getProjectRegions());
    },
    getSectors: () => {
      dispatch(getSectorsAction());
    },
    getProjectCategories: () => {
      dispatch(getProjectCategoriesAction());
    },
    getProjectIconRequest: () => {
      dispatch(getProjectIcons());
    },
    getAppPlatformsRequest: () => {
      dispatch(getAppPlatforms());
    },
    enrollToProjectRequestAction: (payload) => {
      dispatch(enrollToProjectRequest(payload));
    },
    getDefaultProjectsList: (payload) => {
      dispatch(getProjectListSuccess(payload))
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
