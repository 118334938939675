import React from "react";
import {
  AppFormErrorText,
  AppInput,
  AppModal,
  AppSelect,
  AppCheckbox,
} from "../../../theme";
import { FormControl } from "@material-ui/core";
import AppButton from "../../../theme/appButton/appButton";
import t from "../../../localization/en/translation.json";
import {
  use100PerWidthStyle,
  useFlexJConententStartStyle,
  useFlexRowStyle,
  usePaddingTop20pxStyle,
} from "../Parts";
import { validateFormInputForSpecialChar } from "../../../utils/helper-utility";
import { SURVEY_CADENCE } from "./adminSurveysConstants";
import { UserTypes } from "../../../utils/Constants";

export type Data = {
  id?: string;
  name?: string;
  url?: string;
  fiscalQuarter?: string;
  fiscalYear?: string;
  activeDuration?: string;
  cadence?: string;
  fiscalYearQuarter?: string;
  userTypes?: UserTypes;
};
type Props = {
  setOpenModal: (
    value: ((prevState: {} | null) => {} | null) | {} | null
  ) => void;
  initial?: Data;
  handleAddUpdateForm: (arg: string, arg2: string) => void;
  formSubmit: () => void;
  dropdownFYear: any[];
  dropdownDuration?: any[];
  original?: Data;
};

export const AdminSurveyFormModal = ({
  setOpenModal,
  handleAddUpdateForm,
  formSubmit,
  initial: {
    id = undefined,
    name = "",
    url = "",
    fiscalQuarter = "",
    fiscalYear = "",
    activeDuration = "",
    cadence = "",
    fiscalYearQuarter = "",
    userTypes = [] as unknown as UserTypes,
  } = {},
  dropdownFYear = [],
  dropdownDuration = [],
  original = {},
}: Props) => {
  const handlebuttondisabled = () => {
    if (id) {
      return (
        (name === original?.name &&
          url === original?.url &&
          fiscalYearQuarter === original?.fiscalYearQuarter &&
          activeDuration === original?.activeDuration &&
          cadence === original?.cadence &&
          JSON.stringify(userTypes) === JSON.stringify(original.userTypes)) ||
        userTypes.length === 0 ||
        activeDuration === "" ||
        validateFormInputForSpecialChar(name) ||
        validateFormInputForSpecialChar(url)
      );
    }
    return (
      name === "" ||
      url === "" ||
      fiscalYearQuarter === "" ||
      activeDuration === "" ||
      cadence === "" ||
      JSON.stringify(userTypes) === JSON.stringify(original.userTypes) ||
      userTypes.length === 0 ||
      validateFormInputForSpecialChar(name) ||
      validateFormInputForSpecialChar(url)
    );
  };

  return (
    <AppModal
      size={"md"}
      showModal={true}
      onModalClose={() => setOpenModal(false)}
      title={t.admin_survey_title}
      onConfirm={() => setOpenModal(null)}
    >
      <FormControl className={"admin-generic-modal"} fullWidth={true}>
        <div className={`app-row ${use100PerWidthStyle().root}`}>
          <div className={"app-col-xs-2"}>
            <AppInput
              id={"survey_caption"}
              label={t.admin_survey_modal_field_title_name}
              value={name}
              maxLength={"25"}
              onChange={(ele) => handleAddUpdateForm("name", ele.target.value)}
              isValid={!validateFormInputForSpecialChar(name)}
            />
            {validateFormInputForSpecialChar(name) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  name.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
          <div className={"app-col-xs-2"}>
            <AppInput
              id={"survey_url"}
              label={t.admin_survey_modal_field_title_url}
              value={url}
              onChange={(ele) => handleAddUpdateForm("url", ele.target.value)}
              isValid={!validateFormInputForSpecialChar(url)}
            />
            {validateFormInputForSpecialChar(url) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  url.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
        </div>
        <div
          className={`app-row ${use100PerWidthStyle().root} ${
            usePaddingTop20pxStyle().root
          }`}
        >
          <div className={"app-col-xs-2"}>
            <AppSelect
              id={"fiscal_year"}
              label={t.admin_survey_modal_field_title_fy_year}
              value={fiscalYearQuarter}
              onChange={(ele) =>
                handleAddUpdateForm("fiscalYear", ele.target.value as any)
              }
              options={dropdownFYear}
            />
          </div>
        </div>
        <div
          className={`app-row ${use100PerWidthStyle().root} ${
            usePaddingTop20pxStyle().root
          }`}
        >
          <div className={"app-col-xs-2"}>
            <AppSelect
              id={"survey_duration"}
              label={t.admin_survey_modal_field_title_survey_duration}
              value={activeDuration}
              disabled={!fiscalYear}
              onChange={(ele) =>
                handleAddUpdateForm("activeDuration", ele.target.value as any)
              }
              options={dropdownDuration}
            />
          </div>
          <div className={"app-col-xs-2"}>
            <AppSelect
              id={"survey_cadence"}
              label={t.admin_survey_modal_field_title_survey_cadence}
              value={cadence}
              onChange={(ele) =>
                handleAddUpdateForm("cadence", ele.target.value as any)
              }
              options={SURVEY_CADENCE}
            />
          </div>
        </div>
        <div
          className={`app-row ${usePaddingTop20pxStyle().root} ${
            use100PerWidthStyle().root
          }`}
        >
          <div className={"app-col-xs-2"}>
            <AppCheckbox
              id={UserTypes.Internal}
              key={UserTypes.Internal}
              name={UserTypes.Internal}
              value={UserTypes.Internal}
              checked={userTypes?.includes(UserTypes.Internal)}
              onChange={(e) =>
                handleAddUpdateForm(UserTypes.Internal, e.target.checked)
              }
            >
              {t.admin_survey_form_internal_users}
            </AppCheckbox>
            <AppCheckbox
              id={UserTypes.External}
              key={UserTypes.External}
              name={UserTypes.External}
              value={UserTypes.External}
              checked={userTypes?.includes(UserTypes.External)}
              onChange={(e) =>
                handleAddUpdateForm(UserTypes.External, e.target.checked)
              }
            >
              {t.admin_survey_form_external_users}
            </AppCheckbox>
          </div>
        </div>
        <div
          className={`app-row ${usePaddingTop20pxStyle().root} ${
            use100PerWidthStyle().root
          }`}
        >
          <div
            className={`app-col-xs-1 ${useFlexRowStyle().root} ${
              useFlexJConententStartStyle().root
            } ${usePaddingTop20pxStyle().root}`}
          >
            <AppButton
              id={"notification_save"}
              disabled={handlebuttondisabled()}
              style={{ marginRight: "10px" }}
              onClick={formSubmit}
            >
              {id ? t.Update : t.Add}
            </AppButton>
            <AppButton
              id={"notification_cancel_change"}
              variant={"outlined"}
              onClick={() => setOpenModal(false)}
            >
              {t.cancel}
            </AppButton>
          </div>
        </div>
      </FormControl>
    </AppModal>
  );
};
