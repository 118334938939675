import { REQUEST_TYPE, API_URL, createAxiosRequest } from "../api";
const WORKFLOW_API_URL = "/api/v1/workflows/";
export const getWorkflows = ({ payload }) => {
  const url = `${API_URL}${WORKFLOW_API_URL}${payload.apiURL}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const fetchWorkflowsSearch = (action) => {
  const url = `${API_URL}${WORKFLOW_API_URL}${action.payload.searchUrl}/search/${action.payload.query}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url);
};

export const addWorkflows = (action) => {
  let apiURL = `insert/${action.payload.tableName}`;
  if (action.payload.projectId) {
    apiURL = `${action.payload.projectId}/${apiURL}`;
  }
  const url = `${API_URL}${WORKFLOW_API_URL}${apiURL}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, action.payload.formData);
};

export const updateWorkflows = (action) => {
  let apiURL = `update/${action.payload.tableName}`;
  if (action.payload.projectId) {
    apiURL = `${action.payload.projectId}/${apiURL}`;
  }
  const url = `${API_URL}${WORKFLOW_API_URL}${apiURL}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, action.payload.formData);
};

export const deleteWorkflows = (action) => {
  const url = `${API_URL}${WORKFLOW_API_URL}${action.payload}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url);
};

export const getWorkflowProject = ({ payload }) => {
  const url = `${API_URL}${WORKFLOW_API_URL}${payload}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const startWorkflows = ({ payload }) => {
  let apiURL = "StartWorkflow";
  if (payload.id) {
    apiURL = `${payload.id}/${apiURL}`;
  }
  const url = `${API_URL}${WORKFLOW_API_URL}${apiURL}`;
  if (payload.workflowParameters) {
    const replacedValue = JSON.stringify(payload.workflowParameters).replace(
      /[\u00A0-\u2666]/g,
      ""
    );
    const newWorkflowParameters = btoa(replacedValue);
    payload.workflowParameters = newWorkflowParameters;
  }
  payload["baseUrl"] = API_URL;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const getWorkflowTooltipsSettings = ({ payload }) => {
  const url = `${API_URL}${WORKFLOW_API_URL}${payload}/tooltips`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};
