import React, { FC } from 'react';
import en_translation from '../../localization/en/translation.json';
import { getFooterText } from '../footer/footerConstants';
import './footerAdmin.scss';

const FooterAdmin: FC = () => {
	const footerText = getFooterText();
		return (
			<footer className="footer-admin">
				<div className="content">
					<span className="footer-title">{footerText}</span>
					<span>{en_translation.footer_privacy_version}</span>
				</div>
			</footer>
		)
}

export default FooterAdmin;
