import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { ISSPPlatformRole } from "../../models/IRoles";
import { IAccessRight } from "../../models/user";
import {
  IAdminMaintenceActivity,
  IEvents,
} from "../../models/IAdminMaintenance";
import t from "../../localization/en/translation.json";
import AppNumberPicker from "../../components/appNumberPicker/appNumberPicker";
import AppNotification from "../../components/appNotification/appNotification";
import {
  DATE_TIME_SCHEDULER,
  EMAIL_TEMPLATE,
  MAX_CHAR_LIMIT,
} from "./adminMaintenanceConstants";
import AppRichTextBox from "../../components/appRichTextBox/appRichTextBox";
import AppScheduler from "../../components/appScheduler/appScheduler";
import AdminAddEventModal from "../../components/adminAddEventModal/adminAddEventModal";
import {
  getMaintenanceStatusRequest,
  scheduleMaintenanceRequest,
  flushMaintenanceNotification,
  getAdminScheduleRequest,
  addAdminScheduleRequest,
  updateAdminScheduleRequest,
  deleteAdminScheduleRequest,
} from "../../store/actions/admin-maintenance-actions";
import {
  AppButton,
  AppTooltip,
  AppIcon,
  AppDatePicker,
  AppRadioButton,
  AppModal,
  AppCheckbox,
} from "../../theme";
import {
  getPlainText,
  validateFormInputForSpecialChar,
} from "../../utils/helper-utility";
import { info } from "../../theme/icons";
import "./adminMaintenance.scss";
import { CrashBoundary } from "../../components/CrashBoundary";

type IProps = RouteComponentProps<any> & {
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  getMaintenanceStatusRequest: Function;
  scheduleMaintenanceRequest: Function;
  maintenanceActivity: IAdminMaintenceActivity;
  isMainteneceSchduled: boolean;
  flushMaintenanceNotification: Function;
  adminSchedules: IEvents[];
  getAdminScheduleRequest: Function;
  isEventChanged: boolean;
  addAdminScheduleRequest: Function;
  updateAdminScheduleRequest: Function;
  deleteAdminScheduleRequest: Function;
};

interface IState {
  bannerMessage: string;
  isBannerActive: boolean;
  startDate: object;
  endDate: object;
  startHrs: number;
  startMins: number;
  endHrs: number;
  endMins: number;
  isAddEventModalOpen: boolean;
  isDeleteEventModalOpen: boolean;
  isEditEvent: boolean;
  selectedEvent: IEvents | null;
  disableProjectCreation: boolean;
}

class AdminMaintenance extends Component<IProps, IState> {
  today = new Date();
  state = {
    bannerMessage: "",
    isBannerActive: false,
    startDate: this.today,
    endDate: this.today,
    startHrs: this.today.getHours(),
    startMins: this.today.getMinutes(),
    endHrs: this.today.getHours(),
    endMins: this.today.getMinutes(),
    isAddEventModalOpen: false,
    isDeleteEventModalOpen: false,
    isEditEvent: false,
    selectedEvent: null,
    disableProjectCreation: false,
  };

  componentDidMount() {
    const { getMaintenanceStatusRequest, getAdminScheduleRequest } = this.props;

    getMaintenanceStatusRequest(false);
    getAdminScheduleRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      maintenanceActivity,
      isMainteneceSchduled,
      flushMaintenanceNotification,
      isEventChanged,
      getAdminScheduleRequest,
    } = this.props;
    const { isAddEventModalOpen } = this.state;
    if (
      prevProps.maintenanceActivity !== maintenanceActivity &&
      maintenanceActivity
    ) {
      this.setDefaultState();
    }

    if (
      prevProps.isMainteneceSchduled !== isMainteneceSchduled &&
      isMainteneceSchduled
    ) {
      setTimeout(flushMaintenanceNotification, 3000);
    }

    if (prevProps.isEventChanged !== isEventChanged && isEventChanged) {
      getAdminScheduleRequest();
    }

    if (
      prevState.isAddEventModalOpen !== isAddEventModalOpen &&
      !isAddEventModalOpen
    ) {
      this.setState({ selectedEvent: null, isEditEvent: false });
    }
  }

  setDefaultState = () => {
    const { maintenanceActivity } = this.props;
    const {
      message,
      active: isBannerActive,
      scheduledEndDateTime,
      scheduledStartDateTime,
      isCreateProjectDisabled,
    } = maintenanceActivity;
    const startDate = new Date(scheduledStartDateTime * 1000);
    const endDate = new Date(scheduledEndDateTime * 1000);
    const bannerMessage = unescape(message);

    this.setState({
      bannerMessage,
      isBannerActive,
      startDate,
      endDate,
      startHrs: startDate.getHours(),
      startMins: startDate.getMinutes(),
      endHrs: endDate.getHours(),
      endMins: endDate.getMinutes(),
      disableProjectCreation: isCreateProjectDisabled,
    });
  };

  handleBannerMsgChange = (bannerMessage) => {
    this.setState({ bannerMessage });
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    this.setState({ endDate });
  };

  handleStartHrsChange = (startHrs) => this.setState({ startHrs });

  handleEndHrsChange = (endHrs) => this.setState({ endHrs });

  handleStartMinsChange = (startMins) => this.setState({ startMins });

  handleEndMinsChange = (endMins) => this.setState({ endMins });

  handleSchuduleChange = () => {
    const {
      bannerMessage,
      isBannerActive,
      startDate,
      endDate,
      startHrs,
      startMins,
      endHrs,
      endMins,
      disableProjectCreation,
    } = this.state;

    const updatedStartDateTime = startDate.toString().split(" ");
    const updatedEndDateTime = endDate.toString().split(" ");

    updatedStartDateTime.splice(4, 1, `${startHrs}:${startMins}:00`);
    updatedEndDateTime.splice(4, 1, `${endHrs}:${endMins}:00`);

    const scheduledStartDateTime =
      new Date(updatedStartDateTime.join(" ")).getTime() / 1000;
    const scheduledEndDateTime =
      new Date(updatedEndDateTime.join(" ")).getTime() / 1000;
    const message = escape(bannerMessage);
    const scheduleDetails = {
      message,
      active: isBannerActive,
      scheduledStartDateTime,
      scheduledEndDateTime,
      isCreateProjectDisabled: disableProjectCreation,
    };

    this.props.scheduleMaintenanceRequest(scheduleDetails);
  };

  checkMessageCharLimit = () => {
    const { bannerMessage } = this.state;

    return (
      getPlainText(bannerMessage).trim().length <= MAX_CHAR_LIMIT &&
      !validateFormInputForSpecialChar(getPlainText(bannerMessage).trim())
    );
  };

  renderDateTimePicker = (
    date,
    hrs,
    mins,
    dateHandler,
    hrsHandler,
    minsHandler
  ) => {
    return (
      <div className="schedule-duration">
        <div className="project-form-field">
          <AppDatePicker
            value={date}
            onChange={dateHandler}
            format={DATE_TIME_SCHEDULER.format}
          />
        </div>
        <AppNumberPicker
          value={hrs}
          min={DATE_TIME_SCHEDULER.minTime}
          max={DATE_TIME_SCHEDULER.maxHrs}
          onChange={hrsHandler}
        />{" "}
        <span className="seprator">:</span>
        <AppNumberPicker
          value={mins}
          min={DATE_TIME_SCHEDULER.minTime}
          max={DATE_TIME_SCHEDULER.maxMinutes}
          onChange={minsHandler}
        />
      </div>
    );
  };

  handleSendMail = ({ subject, body }) => {
    window.location.href = `mailto:${EMAIL_TEMPLATE.distrubutionGroups.join(
      ";"
    )}?subject=${subject}&body=${encodeURIComponent(body)}`;
  };

  toggleEventModal = (isAddEventModalOpen) =>
    this.setState({ isAddEventModalOpen });

  handleAddEvent = (event) => {
    const { isEditEvent } = this.state;
    const { addAdminScheduleRequest, updateAdminScheduleRequest } = this.props;

    isEditEvent
      ? updateAdminScheduleRequest(event)
      : addAdminScheduleRequest(event);
    this.toggleEventModal(false);
  };

  handleAddOrUpdate = (isEditEvent, event) => {
    const selectedEvent = isEditEvent
      ? event
      : { startDateTime: event.start.getTime() };

    this.setState({ isEditEvent, selectedEvent, isAddEventModalOpen: true });
  };

  handleCloseEvent = ({ event: selectedEvent }) => {
    this.setState({ selectedEvent, isDeleteEventModalOpen: true });
  };

  handleDeleteEvent = () => {
    const { selectedEvent } = this.state;

    if (!selectedEvent) {
      return;
    }

    this.props.deleteAdminScheduleRequest(selectedEvent);
    this.setState({ isDeleteEventModalOpen: false });
  };

  renderEmailButtons = (title, type) => (
    <div className="email-buttons">
      <AppButton type="button" onClick={() => this.handleSendMail(type)}>
        {t.send}
      </AppButton>
      <span className="email-for-text">{title}</span>
    </div>
  );

  renderStatusRadio = () => {
    const { isBannerActive } = this.state;

    return (
      <div className="status-radio-group">
        <div className="status-radio-option">
          <AppRadioButton
            name="banner_status"
            id="active"
            value="active"
            onChange={() => this.setState({ isBannerActive: true })}
            checked={isBannerActive}
          >
            {t.admin_banner_active_button}
          </AppRadioButton>
        </div>
        <div className="status-radio-option">
          <AppRadioButton
            name="banner_status"
            id="inactive"
            value="inactive"
            onChange={() => {
              this.setState({ isBannerActive: false });
              this.setState({ disableProjectCreation: false });
            }}
            checked={!isBannerActive}
          >
            {t.admin_banner_inactive_button}
          </AppRadioButton>
        </div>
      </div>
    );
  };

  render() {
    const {
      isMainteneceSchduled,
      flushMaintenanceNotification,
      adminSchedules,
    } = this.props;
    const {
      bannerMessage,
      startDate,
      endDate,
      startHrs,
      startMins,
      endHrs,
      endMins,
      isAddEventModalOpen,
      isEditEvent,
      selectedEvent,
      isDeleteEventModalOpen,
      disableProjectCreation,
      isBannerActive,
    } = this.state;

    return (
      <CrashBoundary>
        <div className="admin-maintenance">
          <div className="app-row">
            <div className="app-col-lg-12 app-col-md-12">
              <p className="content-h4">{t.admin_portal_maintenance_title}</p>
            </div>
          </div>
          <div className="schedule-container">
            <div className="app-row">
              <div className="app-col-lg-12 app-col-md-12">
                <div className="banner-msg-title">
                  <label>{t.admin_banner_msg_title}</label>
                  <span className="tool-tip">
                    <AppTooltip
                      placement="top"
                      title={t.admin_banner_tooltip}
                      isWhite
                    >
                      <AppIcon icon={info} />
                    </AppTooltip>
                  </span>
                </div>
                <div>
                  <AppRichTextBox
                    onTextChange={this.handleBannerMsgChange}
                    bannerMessage={bannerMessage}
                    errorMsg={
                      !validateFormInputForSpecialChar(
                        getPlainText(bannerMessage).trim()
                      )
                        ? t.admin_rich_text_error
                        : t.all_ssp_validate_special_character_in_text_error.replace(
                            "{1}",
                            getPlainText(bannerMessage).trim().split("")[0]
                          )
                    }
                    isValidMessage={this.checkMessageCharLimit()}
                  />
                </div>
              </div>
            </div>
            <div className="app-row">
              <div className="app-col-lg-12 app-col-md-12 local-time-warning">
                {t.admin_timezone_warning}
              </div>

              <div className="app-col-lg-2 app-col-md-2">
                {this.renderStatusRadio()}
              </div>
              <div className="app-col-lg-3 app-col-md-3">
                <label>{t.admin_banner_start_time}</label>
                {this.renderDateTimePicker(
                  startDate,
                  startHrs,
                  startMins,
                  this.handleStartDate,
                  this.handleStartHrsChange,
                  this.handleStartMinsChange
                )}
              </div>
              <div className="app-col-lg-3 app-col-md-3">
                <label>{t.admin_banner_end_time}</label>
                {this.renderDateTimePicker(
                  endDate,
                  endHrs,
                  endMins,
                  this.handleEndDate,
                  this.handleEndHrsChange,
                  this.handleEndMinsChange
                )}
              </div>
            </div>
            <div className="app-row">
              <div className="app-col-lg-3 app-col-md-3 disableProjectCreation">
                <AppCheckbox
                  name="disableProjectCreation"
                  checked={disableProjectCreation}
                  onChange={(e) =>
                    this.setState({ disableProjectCreation: e.target.checked })
                  }
                  disabled={!isBannerActive}
                >
                  {t.admin_maintenance_disable_project_creation}
                </AppCheckbox>
              </div>
            </div>
            <div className="app-row">
              <div className="app-col-lg-3 app-col-md-3">
                <div className="update-section">
                  <AppButton
                    type="button"
                    onClick={this.handleSchuduleChange}
                    disabled={
                      !getPlainText(bannerMessage).trim().length ||
                      !this.checkMessageCharLimit()
                    }
                  >
                    {t.update}
                  </AppButton>
                  <span className="tool-tip">
                    <AppTooltip
                      placement="top"
                      title={t.admin_banner_update_tooltip}
                      isWhite
                    >
                      <AppIcon icon={info} />
                    </AppTooltip>
                  </span>
                </div>
              </div>
            </div>
            <div className="email-section">
              <label>{t.admin_maintenance_title_email_title}</label>
              {this.renderEmailButtons(
                t.maintenance_plan_email,
                EMAIL_TEMPLATE.plan
              )}
              {this.renderEmailButtons(
                t.maintenance_started_email,
                EMAIL_TEMPLATE.started
              )}
              {this.renderEmailButtons(
                t.maintenance_completed_email,
                EMAIL_TEMPLATE.completed
              )}
            </div>
            <div className="scheduler">
              <label
                className="add-event"
                onClick={() => this.toggleEventModal(true)}
              >
                <span className="plus-icon">+</span>
                {t.admin_maintenace_add_event}
              </label>
              <AppScheduler
                schedules={adminSchedules}
                onAddOrUpdateEvent={this.handleAddOrUpdate}
                onCloseEvent={this.handleCloseEvent}
              />
            </div>
          </div>
          {isAddEventModalOpen && (
            <AdminAddEventModal
              isOpen={isAddEventModalOpen}
              isEdit={isEditEvent}
              onClose={() => this.toggleEventModal(false)}
              onConfirm={this.handleAddEvent}
              selectedEvent={selectedEvent}
            />
          )}
          <AppModal
            size="sm"
            modalClass="delete-maintenanace-event-modal"
            showModal={isDeleteEventModalOpen}
            onModalClose={() =>
              this.setState({ isDeleteEventModalOpen: false })
            }
            onConfirm={this.handleDeleteEvent}
            disabledValue={false}
            cancelBtnText={t.cancel}
            confirmBtnText={t.delete}
            title={t.admin_maintenance_event_delete_title}
          >
            {t.admin_maintenance_event_delete_msg}
          </AppModal>
          {isMainteneceSchduled && (
            <AppNotification
              message={t.admin_banner_post_success}
              variant="success"
              onCloseNotifcation={() => flushMaintenanceNotification()}
            />
          )}
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({ adminMaintenance }) => {
  return {
    maintenanceActivity: adminMaintenance.maintenanceActivity,
    isMainteneceSchduled: adminMaintenance.isMainteneceSchduled,
    adminSchedules: adminMaintenance.adminSchedules,
    isEventChanged: adminMaintenance.isEventChanged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaintenanceStatusRequest: (payload) => {
      dispatch(getMaintenanceStatusRequest(payload));
    },
    scheduleMaintenanceRequest: (payload) => {
      dispatch(scheduleMaintenanceRequest(payload));
    },
    flushMaintenanceNotification: () => {
      dispatch(flushMaintenanceNotification());
    },
    getAdminScheduleRequest: () => {
      dispatch(getAdminScheduleRequest());
    },
    addAdminScheduleRequest: (payload) => {
      dispatch(addAdminScheduleRequest(payload));
    },
    updateAdminScheduleRequest: (payload) => {
      dispatch(updateAdminScheduleRequest(payload));
    },
    deleteAdminScheduleRequest: (payload) => {
      dispatch(deleteAdminScheduleRequest(payload));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminMaintenance)
);
