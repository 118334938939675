import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import * as type from "../action-types";
import {
  getMe,
  getUserPaansStatus,
  getMyAccessRight,
  appErrorHandler,
  getSurveyStatus,
} from "../apis/projectApi";
import {
  getUserPannsSuccess,
  getUserPannsFailue,
  getMyAccessRightSuccess,
  getMyAccessRightFailure,
  appErrorHandlerFailure,
  appErrorHandlerSuccess,
  ON_ALL_ACTIVE_NOTIFICATIONS_FAILED_ACTION,
  ON_ALL_ACTIVE_NOTIFICATIONS_ACTION,
  ON_READ_NOTIFICATION_ACTION,
  READ_NOTIFICATION_FAILURE_ACTION,
  ON_CLOSE_NOTIFICATION_ACTION,
  CLOSE_NOTIFICATION_FAILURE_ACTION,
  getSurveyStatusSuccess,
  getSurveyStatusFailure,
} from "../actions/me-actions";
import {
  getAllActiveNotifications,
  getUserProfilePhoto,
  marKNotificationAsClosed,
  marKNotificationAsRead,
} from "../apis/metaApi";
import { arrayBufferToBase64 } from "../../utils/helper-utility";

function* getMeInternal(action) {
  try {
    const data = yield call(getMe);
    yield put({
      type: type.ME_SUCCESS,
      payload: data?.data,
    });
  } catch ({ message }) {
    yield put({
      type: type.ME_FAILURE,
      payload: message,
    });
  }
}

function* getPaansStatus(action) {
  try {
    const data = yield call(getUserPaansStatus, action.paylaod);
    yield put(getUserPannsSuccess(data));
  } catch ({ message }) {
    yield put(getUserPannsFailue(message));
  }
}
function* getSurveyDetails() {
  try {
    const data = yield call(getSurveyStatus, "GET", {});
    yield put(getSurveyStatusSuccess(data));
  } catch ({ message }) {
    yield put(getSurveyStatusFailure(message));
  }
}
function* delaySurvey(action) {
  try {
    yield call(getSurveyStatus, "PUT", action.payload);
    yield put({
      type: type.DELAY_SURVEY_SUCCESS,
    });
  } catch ({ message }) { }
}
function* getAccessRight(action) {
  try {
    const data = yield call(getMyAccessRight, action.paylaod);
    yield put(getMyAccessRightSuccess(data));
  } catch ({ message }) {
    yield put(getMyAccessRightFailure(message));
  }
}

function* handleAppError(action) {
  try {
    const data = yield call(appErrorHandler, action.payload);
    yield put(appErrorHandlerSuccess(data));
  } catch ({ message }) {
    yield put(appErrorHandlerFailure(message));
  }
}

function* getActiveNotifications() {
  try {
    const { data } = yield call(getAllActiveNotifications);
    yield put(ON_ALL_ACTIVE_NOTIFICATIONS_ACTION(data));
  } catch ({ message }) {
    yield put(ON_ALL_ACTIVE_NOTIFICATIONS_FAILED_ACTION());
  }
}

function* readNotification(action) {
  try {
    yield call(marKNotificationAsRead, action.payload);
    yield put(ON_READ_NOTIFICATION_ACTION(action.payload));
  } catch ({ message }) {
    yield put(READ_NOTIFICATION_FAILURE_ACTION(action.payload));
  }
}

function* closeNotification(action) {
  try {
    yield call(marKNotificationAsClosed, action.payload);
    yield put(ON_CLOSE_NOTIFICATION_ACTION(action.payload));
  } catch ({ message }) {
    yield put(CLOSE_NOTIFICATION_FAILURE_ACTION(action.payload));
  }
}

function* getUserPhoto(action) {
  try {
    const data = yield call(getUserProfilePhoto, action.payload);
    const photoBase64 = arrayBufferToBase64(data);
    yield put({
      type: type.GET_USER_PHOTO_SUCCESS,
      payload: photoBase64,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_USER_PHOTO_FAILURE,
      payload: message,
    });
  }
}

export function* getMeActionWatcher() {
  yield takeLatest(type.ME, getMeInternal);
  yield takeLatest(type.GET_PAANS_POLICY_REQUEST, getPaansStatus);
  yield takeEvery(type.GET_MY_ACCESS_RIGHT_REQUEST, getAccessRight);
  yield takeLatest(type.APP_ERROR_HANDLER_REQUEST, handleAppError);
  yield takeLatest(type.GET_ALL_ACTIVE_NOTIFICATIONS, getActiveNotifications);
  yield takeLatest(type.READ_NOTIFICATION, readNotification);
  yield takeLatest(type.CLOSE_NOTIFICATION, closeNotification);
  yield takeLatest(type.GET_SURVEY_DETAILS, getSurveyDetails);
  yield takeLatest(type.DELAY_SURVEY, delaySurvey);
  yield takeLatest(type.GET_USER_PHOTO, getUserPhoto);
}
