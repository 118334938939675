import { MoreMenuOption } from "../components/moreMenu/moreMenuOptions";
import en_translation from "../localization/en/translation.json";
import { IUser } from "../models/user";

export const MenuActions = {
  PROJECT_USERS: "get_help_project_users",
  LEARN_CAPITAL_EDGE: "learn_capital_edge",
  CHAT_WITH_COPILOT: "chat_with_copilot",
};

export const GetHelpMenuOptions: MoreMenuOption[] = [
  {
    title: en_translation.help_menu_get_help,
    key: MenuActions.PROJECT_USERS,
    actionName: MenuActions.PROJECT_USERS,
    id: MenuActions.PROJECT_USERS,
  },
  {
    title: en_translation.help_menu_learn_capital_edge,
    key: MenuActions.LEARN_CAPITAL_EDGE,
    actionName: MenuActions.LEARN_CAPITAL_EDGE,
    id: MenuActions.LEARN_CAPITAL_EDGE,
  },
  {
    title: en_translation.help_menu_chat_with_copilot,
    key: MenuActions.CHAT_WITH_COPILOT,
    actionName: MenuActions.CHAT_WITH_COPILOT,
    id: MenuActions.CHAT_WITH_COPILOT,
  },
];

export interface IProps {
  currentUser: IUser;
}

export const GetHelpOptionsGuestUser = [
  {
    title: en_translation.guest_help_msg,
    key: "",
    actionName: "",
    id: "",
  },
];

export const UserAccountType = {
  member: en_translation.member,
  guest: en_translation.guest,
};

export const WrikeData = {
  EY_SUPER_USER_ROLE: "EY Super Users",
  KEY: "WRIKE-PMO",
};

export const Ce4Data = {
  PLATFORM_ID: "d0fc3cd1-0f58-4ceb-ae96-167148f0eeea",
};

export interface IhelpAndSupportData {
  description: string;
  id: string;
  key: string;
  name: string;
  url: string;
}
