import * as type from "../action-types";
import { IDefaultProject } from "../../models/IProjectDefaultRoles";

const initialState: IDefaultProject = {
    defaultRoles: null,
    loading: false,
    error: false,
    projectId: null,
    enrollProjectId: null,
    redirectToProject: false,
}

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_PROJECT_DEFAULT_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                projectId: action.payload.projectId,
                defaultRoles: null,
                enrollProjectId: null,
                error: false,
                redirectToProject: false,
            }
        case type.GET_PROJECT_DEFAULT_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                defaultRoles: action.payload.data
            }
        case type.GET_PROJECT_DEFAULT_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                defaultRoles: null,
                error: true,
                projectId: null,
            }
        case type.ENROLL_USER_DEMO_PROJECT_REQUEST:
            return {
                ...state,
                enrollProjectId: action.payload.projectId,
                loading: true,
                error: false
            }
        case type.ENROLL_USER_DEMO_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                redirectToProject: true,
            }
        case type.ENROLL_USER_DEMO_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                enrollProjectId: null,
                projectId: null,
            }
        case type.GET_PROJECT_DETAILS_REQUEST:
            return {
                ...initialState
            }
        default:
            return state;
    }
}