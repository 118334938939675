import { put, takeLatest, call } from "redux-saga/effects";
import * as type from "../action-types";
import {
  addWorkflows,
  deleteWorkflows,
  fetchWorkflowsSearch,
  getWorkflowProject,
  getWorkflowTooltipsSettings,
  getWorkflows,
  startWorkflows,
  updateWorkflows,
} from "../apis/workflowsApi";
import {
  addWorkflowsFailure,
  addWorkflowsSuccess,
  deleteWorkflowsFailure,
  deleteWorkflowsSuccess,
  getCommTemplateCategoryFailure,
  getCommTemplateCategorySuccess,
  getCommTemplatesFailure,
  getCommTemplatesSuccess,
  getWorkflowDefinitionsFailure,
  getWorkflowDefinitionsSuccess,
  getWorkflowProjectFailure,
  getWorkflowProjectSuccess,
  getWorkflowScheduleFailure,
  getWorkflowScheduleSuccess,
  getWorkflowTooltipsSettingsFailure,
  getWorkflowTooltipsSettingsSuccess,
  getWorkflowsFailure,
  getWorkflowsSuccess,
  searchWorkflowsFailure,
  searchWorkflowsSuccess,
  startWorkflowFailure,
  startWorkflowSuccess,
  updateWorkflowsFailure,
  updateWorkflowsSuccess,
} from "../actions/admin-workflows-actions";

function* fetchWorkflows(action) {
  try {
    const { data } = yield call(getWorkflows, action);
    yield put(getWorkflowsSuccess(data));
  } catch ({ message }) {
    yield put(getWorkflowsFailure(message));
  }
}

function* searchWorkflows(action) {
  try {
    const data = yield call(fetchWorkflowsSearch, action);
    yield put(searchWorkflowsSuccess(data));
  } catch ({ message }) {
    yield put(searchWorkflowsFailure(message));
  }
}

function* addWorkflowsData(action) {
  try {
    const data = yield call(addWorkflows, action);
    yield put(addWorkflowsSuccess(data));
  } catch ({ message }) {
    yield put(addWorkflowsFailure(message));
  }
}

function* updateWorkflowsData(action) {
  try {
    const data = yield call(updateWorkflows, action);
    yield put(updateWorkflowsSuccess(data));
  } catch ({ message }) {
    yield put(updateWorkflowsFailure(message));
  }
}

function* deleteWorkflowsData(action) {
  try {
    const data = yield call(deleteWorkflows, action);
    yield put(deleteWorkflowsSuccess(data));
  } catch ({ message }) {
    yield put(deleteWorkflowsFailure(message));
  }
}

function* fetchCommTemplateCategory(action) {
  try {
    const { data } = yield call(getWorkflows, action);
    yield put(getCommTemplateCategorySuccess(data));
  } catch ({ message }) {
    yield put(getCommTemplateCategoryFailure(message));
  }
}

function* fetchWorkflowDefinitions(action) {
  try {
    const { data } = yield call(getWorkflows, action);
    yield put(getWorkflowDefinitionsSuccess(data));
  } catch ({ message }) {
    yield put(getWorkflowDefinitionsFailure(message));
  }
}

function* fetchCommTemplates(action) {
  try {
    const { data } = yield call(getWorkflows, action);
    yield put(getCommTemplatesSuccess(data));
  } catch ({ message }) {
    yield put(getCommTemplatesFailure(message));
  }
}

function* fetchWorkflowSchedule(action) {
  try {
    const { data } = yield call(getWorkflows, action);
    yield put(getWorkflowScheduleSuccess(data));
  } catch ({ message }) {
    yield put(getWorkflowScheduleFailure(message));
  }
}

function* fetchWorkflowProject(action) {
  try {
    const { data } = yield call(getWorkflowProject, action);
    yield put(getWorkflowProjectSuccess(data));
  } catch ({ message }) {
    yield put(getWorkflowProjectFailure(message));
  }
}

function* fetchStartWorkflow(action) {
  try {
    const data = yield call(startWorkflows, action);
    yield put(startWorkflowSuccess(data));
  } catch ({ message }) {
    yield put(startWorkflowFailure(message));
  }
}

function* fetchWorkflowTooltips(action) {
  try {
    const data = yield call(getWorkflowTooltipsSettings, action);
    yield put(getWorkflowTooltipsSettingsSuccess(data));
  } catch ({ message }) {
    yield put(getWorkflowTooltipsSettingsFailure(message));
  }
}

export function* workflowsActionWatcher() {
  yield takeLatest(type.GET_CPS_WORKFLOWS, fetchWorkflows);
  yield takeLatest(type.SEARCH_CPS_WORKFLOWS_REQUEST, searchWorkflows);
  yield takeLatest(type.ADD_CPS_WORKFLOWS_REQUEST, addWorkflowsData);
  yield takeLatest(type.UPDATE_CPS_WORKFLOWS_REQUEST, updateWorkflowsData);
  yield takeLatest(type.DELETE_CPS_WORKFLOWS_REQUEST, deleteWorkflowsData);
  yield takeLatest(type.GET_CPS_COMM_TEMPLATE_CATEGORY, fetchCommTemplateCategory);
  yield takeLatest(type.GET_CPS_WORKFLOW_DEFINITIONS, fetchWorkflowDefinitions);
  yield takeLatest(type.GET_CPS_COMM_TEMPLATES, fetchCommTemplates);
  yield takeLatest(type.GET_CPS_WORKFLOW_SCHEDULE, fetchWorkflowSchedule);
  yield takeLatest(type.GET_CPS_WORKFLOW_PROJECT, fetchWorkflowProject);
  yield takeLatest(type.START_CPS_WORKFLOW, fetchStartWorkflow);
  yield takeLatest(type.GET_WORKFLOW_TOOLTIPS, fetchWorkflowTooltips);
}
