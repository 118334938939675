import React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IAccessRight } from "../../models/user";
import { ISSPPlatformRole } from "../../models/IRoles";
import { IStringFieldState } from "../createProject/formStateModel";
import {
  getPowerBiReports,
  getAdminReports,
  addAdminReport,
} from "../../store/actions/admin-reports-actions";
import { IAdminReports } from "../../models/IAdminReports";
import { IPowerBiReport } from "../../models/IPowerBiReport";
import { showActionToast } from "../../store/actions/notification-actions";
import ReportTile from "./reportTile/reportTile";
import { IAdminReport } from "../../models/IAdminReport";
import ReportVisualization from "./reportVisualization/reportVisulization";
import { getAdminConsoleAccess } from "../../utils/helper-utility";
import {
  AppFormErrorText,
  AppInput,
  AppButton,
  AppTextField,
  AppIcon,
  AppSelect,
} from "../../theme";
import { back, expand } from "../../theme/icons";
import t from "../../localization/en/translation.json";
import "./adminReports.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";

type IProps = RouteComponentProps<any> & {
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  getPowerBiReports: Function;
  getAdminReports: Function;
  addAdminReport: Function;
  showNotification: Function;
  adminReports: IAdminReports;
};

interface IState {
  selectedReport?: IAdminReport;
  showAddModal: boolean;
  formState: {
    reportId: IStringFieldState;
    reportDescription: IStringFieldState;
    reportRlsRoles: IStringFieldState;
  };
  isFormValid: boolean;
}

const validatedInputClass = "validated-input";

class AdminReports extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedReport: undefined,
      showAddModal: false,
      isFormValid: false,
      formState: {
        reportId: {
          fieldName: "reportId",
          fieldVal: "",
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
        reportDescription: {
          fieldName: "reportDescription",
          fieldVal: "",
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
        reportRlsRoles: {
          fieldName: "reportRlsRoles",
          fieldVal: "",
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
      },
    };
  }

  componentDidMount() {
    const { getPowerBiReports, getAdminReports } = this.props;
    getPowerBiReports();
    getAdminReports();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      adminReports,
      getAdminReports,
      showNotification,
      getPowerBiReports,
    } = this.props;
    if (
      !adminReports.error &&
      prevProps.adminReports.loading &&
      !adminReports.loading &&
      adminReports.action === "ADD"
    ) {
      getAdminReports();
      getPowerBiReports();
      showNotification({
        message: t.admin_report_added,
        type: "success",
        status: 200,
        redirectToUnauth: false,
      });
      this.setState({
        isFormValid: false,
        showAddModal: false,
        formState: {
          ...this.state.formState,
          reportId: {
            ...this.state.formState.reportId,
            fieldVal: "",
            isvalid: true,
          },
          reportDescription: {
            ...this.state.formState.reportDescription,
            fieldVal: "",
            isvalid: true,
          },
          reportRlsRoles: {
            ...this.state.formState.reportRlsRoles,
            fieldVal: "",
            isvalid: true,
          },
        },
      });
    } else if (
      !adminReports.error &&
      prevProps.adminReports.loading &&
      !adminReports.loading &&
      adminReports.action === "UPDATE"
    ) {
      getAdminReports();
      getPowerBiReports();
      showNotification({
        message: t.admin_report_updated,
        type: "success",
        status: 200,
        redirectToUnauth: false,
      });
    } else if (
      !adminReports.error &&
      prevProps.adminReports.loading &&
      !adminReports.loading &&
      adminReports.action === "DELETE"
    ) {
      getAdminReports();
      getPowerBiReports();
      showNotification({
        message: t.admin_report_deleted,
        type: "success",
        status: 200,
        redirectToUnauth: false,
      });
    }
  }

  onAddClick = () => {
    this.setState({
      showAddModal: true,
    });
  };

  onReportAdd = () => {
    const { addAdminReport, adminReports } = this.props;

    const {
      isFormValid,
      formState: { reportId, reportDescription, reportRlsRoles },
    } = this.state;
    if (isFormValid) {
      const report = adminReports.powerBiReports.find(
        (x) => x.id === reportId.fieldVal
      );
      const workSpaceId = report?.workSpaceId;
      addAdminReport({
        reportId: reportId.fieldVal,
        description: reportDescription.fieldVal,
        workSpaceId: workSpaceId,
        reportRlsRoles: reportRlsRoles.fieldVal,
      });
    }
  };

  onReportAddCancel = () => {
    this.setState({
      showAddModal: false,
      isFormValid: false,
      formState: {
        ...this.state.formState,
        reportId: {
          ...this.state.formState.reportId,
          fieldVal: "",
          isvalid: true,
        },
        reportDescription: {
          ...this.state.formState.reportDescription,
          fieldVal: "",
          isvalid: true,
        },
        reportRlsRoles: {
          ...this.state.formState.reportRlsRoles,
          fieldVal: "",
          isvalid: true,
        },
      },
    });
  };

  checkDirtyFields = () => {
    const { formState } = this.state;
    const requiredFormState = Object.assign({}, formState);
    delete requiredFormState[formState.reportRlsRoles.fieldName];
    let filledFields = 0;
    Object.entries(requiredFormState).forEach(([key]) => {
      if (
        requiredFormState[key].fieldVal &&
        requiredFormState[key].fieldVal.trim()
      ) {
        filledFields++;
      }
    });

    return filledFields === Object.keys(requiredFormState).length;
  };

  checkValidSpecialCharFields = () => {
    const { formState } = this.state;
    const validSpecialChar = Object.assign({}, formState);
    delete validSpecialChar[formState.reportId.fieldName];
    let validSpecialCharFields = 0;

    Object.entries(validSpecialChar).forEach(([key]) => {
      if (!validateFormInputForSpecialChar(validSpecialChar[key].fieldVal)) {
        validSpecialCharFields++;
      }
    });
    return validSpecialCharFields === Object.keys(validSpecialChar).length;
  };

  onFormChange = (val, fieldName: string) => {
    const { formState } = this.state;
    formState[fieldName].fieldVal = val;
    formState[fieldName].isvalid = val.trim().length;

    this.setState({
      formState: formState,
      isFormValid:
        this.checkDirtyFields() && this.checkValidSpecialCharFields(),
    });
  };

  getReportOptions = (options) =>
    options.map((item) => ({ value: item.id, label: item.title }));

  renderTextArea = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean
  ) => (
    <div
      className={`project-form-field ${
        fieldVal.trim().length && isvalid ? validatedInputClass : ""
      }`}
    >
      <AppTextField
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        disabled={disable}
        label={label}
        isValid={isvalid}
        onChange={(e) => this.onFormChange(e.target.value, e.target.name)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      {validateFormInputForSpecialChar(fieldVal) && (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );
  renderInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean,
    addMargin: boolean
  ) => (
    <div
      className={`project-form-field ${
        fieldVal != null && fieldVal.trim().length && isvalid
          ? validatedInputClass
          : ""
      } ${addMargin && "m-r-35"} `}
    >
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        disabled={disable}
        label={label}
        onChange={(e) => this.onFormChange(e.target.value, e.target.name)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      {validateFormInputForSpecialChar(fieldVal) && (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );
  renderSelect = (
    label: string,
    fieldVal: string | undefined,
    fieldName: string,
    isvalid: boolean,
    options: IPowerBiReport[],
    disable: boolean,
    addMargin?: boolean
  ) => (
    <div
      className={`project-form-field ${addMargin && "m-r-35"} ${
        fieldVal && isvalid ? validatedInputClass : ""
      }`}
    >
      <AppSelect
        id="report-tile"
        value={fieldVal || ""}
        name={fieldName}
        label={label}
        disabled={disable}
        options={this.getReportOptions(options)}
        onChange={(e) => this.onFormChange(e.target.value || "", fieldName)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
    </div>
  );

  onBackToList = () => {
    this.setState({
      selectedReport: undefined,
    });
  };

  onFullScreen = () => {
    const reportContainer = document.getElementById("powerBiAdminReport");
    if (reportContainer) {
      if (reportContainer.requestFullscreen) {
        reportContainer.requestFullscreen();
      }
    }
  };

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  render() {
    const {
      formState: { reportId, reportDescription, reportRlsRoles },
      showAddModal,
      isFormValid,
      selectedReport,
    } = this.state;
    const { adminReports } = this.props;
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const powerBiReports = adminReports.powerBiReports.filter(
      (item) =>
        !adminReports.reports.find((report) => report.reportId === item.id)
    );
    return (
      <CrashBoundary>
        <div className="admin-reports">
          <div>
            <div className="page-hdr-title-container">
            {selectedReport && (<div className='go-back-icon'>
                <span className='ml-5' onClick={this.onBackToList}>
                  <AppIcon icon={back} className='icon-link' /> {t.admin_reports_back_link}
                </span>
              </div>
            )}
              <div className="admin-reports-actions">
                {(selectedReport && (
                  <div className="btn-grp">
                    <AppButton
                      color="secondary"
                      size="large"
                      onClick={this.onFullScreen}
                    >
                      <AppIcon icon={expand} /> {t.fullscreen}
                    </AppButton>
                  </div>
                )) ||
                  (adminConsoleAccess.portalAdmin && (
                    <AppButton
                      color="primary"
                      size="large"
                      onClick={this.onAddClick}
                    >
                      {t.add_reports}
                    </AppButton>
                  )) ||
                  null}
              </div>
            </div>
            {(!selectedReport && (
              <div className="reports-list">
                {adminReports.reports.map((item) => (
                  <ReportTile
                    report={item}
                    key={item.id}
                    canEdit={adminConsoleAccess.portalAdmin}
                    onReportClick={() =>
                      this.setState({
                        selectedReport: item,
                      })
                    }
                  />
                ))}
                {(showAddModal && (
                  <div className="reports-item add-report">
                    {(powerBiReports.length > 0 &&
                      this.renderSelect(
                        t.admin_report_name_label,
                        reportId.fieldVal || "",
                        reportId.fieldName,
                        reportId.isvalid,
                        powerBiReports,
                        reportId.disable
                      )) ||
                      this.renderSelect(
                        t.admin_report_name_label,
                        reportId.fieldVal || "",
                        reportId.fieldName,
                        reportId.isvalid,
                        [{ id: "", title: "Select Report" }],
                        reportId.disable
                      )}
                    {this.renderTextArea(
                      t.admin_report_description_label,
                      reportDescription.fieldVal,
                      reportDescription.fieldName,
                      reportDescription.isvalid,
                      reportDescription.charLimit,
                      reportDescription.disable
                    )}
                    {this.renderInput(
                      t.admin_report_roles_label,
                      reportRlsRoles.fieldVal || "",
                      reportRlsRoles.fieldName,
                      true,
                      reportRlsRoles.charLimit,
                      reportRlsRoles.disable,
                      false
                    )}
                    <div className="btn-grp">
                      <AppButton
                        disabled={!isFormValid}
                        color="primary"
                        onClick={this.onReportAdd}
                      >
                        {t.add}
                      </AppButton>
                      <AppButton
                        color="secondary"
                        onClick={this.onReportAddCancel}
                      >
                        {t.cancel}
                      </AppButton>
                    </div>
                  </div>
                )) ||
                  null}
              </div>
            )) ||
              null}
          </div>
          {(selectedReport && (
            <ReportVisualization report={selectedReport} />
          )) ||
            null}
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({ meReducer, meta, adminReports }) => {
  return {
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    adminReports,
  };
};

const mapDispatchToProps = () => ({
  getPowerBiReports,
  getAdminReports,
  addAdminReport,
  showNotification: showActionToast,
});

export default connect(mapStateToProps, mapDispatchToProps())(AdminReports);
