const customCellRenderer = "custom-cellrenderer";
export const columnConfig = [
  {
    headerName: ' ',
    cellRenderer: 'moreMenuCellRenderer',
    field: 'iconButton',
    minWidth: 43,
    width: 43,
    pinned: 'left',
    lockPosition: true,
    sortable: false
  },
  {
    headerName: 'App name',
    field: 'name',
    lockPosition: true,
    sortable: true,
    width: 360, 
    filter: "customFilter"
  },
  {
    headerName: 'App Platform',
    field: 'appPlatformName',
    sortable: true,
    lockPinned: true,
    filter: "customFilter"
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 200,
    sortable: true,
    lockPinned: true,
    cellRenderer: 'appDescriptionCellRender',
    cellClass: customCellRenderer,
    filter: "customFilter"
  },
  {
    headerName: 'Category',
    field: 'appCategoryName',
    lockPinned: true,
    sortable: true,
    cellRenderer: 'appCategoriesCellRenderer',
    width: 340,
    cellClass: customCellRenderer,
    filter: "customFilter"
  },
  {
    headerName: 'Owner',
    field: 'appOwners',
    lockPinned: true,
    cellRenderer: 'appOwnersCellRenderer',
    cellClass: customCellRenderer,
   // sortable: false,
    width: 280
  },
  {
    headerName: 'Bundles',
    field: 'bundles',
    lockPinned: true,
    cellRenderer: 'appBundlesCellRenderer',
    width: 230,
    cellClass: customCellRenderer,
  //  sortable: false,
  },
  {
    headerName: 'Preview Video',
    field: 'demoSiteURL',
    width: 300,
    lockPinned: true,
    cellRenderer: 'playVideoCellRenderer',
   // sortable: false,
  }
];

export const appOwnersFormConfig = {
  typeAppOwnersRegionId: {
    fieldName: "typeAppOwnersRegionId",
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    fieldTranslate: "project_region",
  },
  typeAppOwnersName: {
    fieldName: "typeAppOwnersName",
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    fieldTranslate: "admin_apps_owner_name",
  },
  typeAppOwnersEmail: {
    fieldName: "typeAppOwnersEmail",
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: true,
    fieldTranslate: "admin_apps_owner_email",
  },
  typeAppOwnersCcEmail: {
    fieldName: "typeAppOwnersCcEmail",
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    fieldTranslate: "admin_apps_cc_email",
  },
}
