import { IAccessRight } from "../../models/user";
import { Project } from "../createProject/models/project";
import { ProjectStatus } from "../projectCard/projectCardConstants";

export const EMG_POLICY = "Engagement Management Guidance";

export const checkAndLoadEMGPolicy = (
    accessRight: IAccessRight,
    updateDetailsForEMG: Function,
    project?: Project,
) => {
    const projectAccess = accessRight.projects?.find(item => item.projectId === (project?.id || "")) || null;
    if (projectAccess &&
        !projectAccess.hasAcceptedEMG &&
        project &&
        project.id &&
        project.projectStatus.projectStatusName.toLowerCase() !== ProjectStatus.DRAFT) {
        updateDetailsForEMG({
            projectId: project.id
        })
    }
}
