import React, { FC } from 'react';
import t from '../../../localization/en/translation.json';
import BundleCard from '../../bundleCard/bundleCard';
import { BundleClass } from '../../bundleCard/models/bundleClass';
import { AppClass } from '../../../components/appCard/models/appClass';
import { AppCategory } from '../../appCard/models/appCategory';
import { getIconPath } from '../../../utils/helper-utility';
import { AppToggleSwitch } from "../../../theme"
import { sortAppOrder } from '../../../utils/Constants';
import './customizeBundle.scss';

interface IProps {
	bundleCard: BundleClass,
	appCategories: AppCategory[],
	appCards: AppClass[],
	onAppToggleClick: Function
}
const CustomizeBundle: FC<IProps> = ({ bundleCard, appCards, appCategories, onAppToggleClick }) => {
	const renderAppTiles = (apps, selectedAppIds) => {
		apps.sort((a, b) => sortAppOrder(a) - sortAppOrder(b));
		return apps.map((item, index) => {
			const appCategory = appCategories.find(f => f.id === item.appCategoryId);
			const iconPath = getIconPath(appCategory);
			const iconBackgroundColor = {
				background: appCategory?.colorCode
			};
			const isChecked = !!selectedAppIds.find(f => f.id === item.id);

			return (
				<div className={`app-tile ${index !== appCards.length - 1 && "border-btm"}`} key={`${isChecked}---${index}`}>
					<div className="icon-n-title">
						<div className="app-icon" style={iconBackgroundColor}>
							{iconPath && <img src={iconPath} className="img-app-card" alt="icon" />}
						</div>
						<div className="app-title">{item.name}</div>
					</div>
					<div className="app-toggle">
							<AppToggleSwitch
								checked={isChecked}
								onChange={event => onAppToggleClick(event.target)}
								id={item.id}
							/>
					</div>
				</div>
			)
		})
	}

	return (
		<div className="customize-bundle">
			<div className="app-row">
				<div className='bundle-wrapper'>
					<BundleCard data={bundleCard} hideCustomizeBundleLabel={true} />
				</div>
			</div>
			{appCards.length > 0 && !bundleCard.isOwnBundle &&
				<div className="app-row overview-container">
					<div className="title">{t.customize_bundle_default_apps}</div>
					<div className="app-tiles-wrapper">
						{renderAppTiles(appCards, bundleCard.selectedAppIds || [])}
					</div>
				</div>
			}
		</div>
	)
}

export default CustomizeBundle;
