export const MAX_DESC_LENGTH = 30;
export const MIN_APP_NAME_LENGTH = 3;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ACTION_NAME = {
    UPDATE: 'Update',
    DELETE: 'Delete'
};
export const ROW_MIN_HEIGHT = 48;
export const REGION_OWNER_HEIGHT = 25;
export const DEFAULT_SORT = { colId: "name", sort: "asc" };


