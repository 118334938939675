import React, { FC } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import theme from "../config";
import './appLoader.scss';

const useStyle = makeStyles({
    root: {
        color: theme.buttonPrimaryBgColor
    }
})

interface IProps extends CircularProgressProps {
    counter: number,
    dispatch: Function
}

const AppLoader: FC<IProps> = ({ counter, ...restProps }) => {
    const classes = useStyle();
    const { dispatch, ...newProps } = restProps;
    return (
        counter > 0 ?
            <div className="app-loader" >
                <CircularProgress classes={{ ...classes }} {...newProps} />
            </div>
            : null
    )
}

const mapStateToProps = ({ loader }) => {
    return {
        counter: loader.counter,
    };
};

export default connect(mapStateToProps)(AppLoader);
