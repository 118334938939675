export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = { colId: "id", sort: "desc" };
export const ROW_FIELD_NAMES = {
    WFR_ERR_STACK: "WFR_ProcessStatus",
    TITLE: "title",
    WFR_ERR_STACK_SOURCE: "WFR_ErrorSource",
    WFR_PROCESSMESSAGE: "wfr_ProcessMessage",
    WFR_WORKFLOWLOOKUP: "wfr_WorkflowLookup",
}

export const MORE_MENU_ACTION_NAME = {
    UPDATE: "Update",
    DELETE: "Delete",
    ADD: "Add",
  };