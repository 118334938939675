import React, { FC } from 'react';
import { BundleClass } from '../bundleCard/models/bundleClass';
import { AppCategory } from '../appCard/models/appCategory';
import { AppClass } from '../appCard/models/appClass';
import t from '../../localization/en/translation.json';
import { getIconPath } from '../../utils/helper-utility';
import './confirmAndCreateProject.scss';

interface IProps {
  projectName?: string,
  projectType?: string,
  bundle?: BundleClass,
  apps: AppClass[],
  appCategories: AppCategory[]
}

const ConfirmAndCreateProject: FC<IProps> = ({ projectName, projectType, bundle, apps, appCategories }) => {

  const renderAppTiles = apps => apps.map((item, index) => {
    const appCategory = appCategories.find(f => f.id === item.appCategoryId);
    const iconPath = getIconPath(appCategory);
    const iconBackgroundColor = {
      background: appCategory?.colorCode
    };

    return (
      <div className="app-tile" key={`step-3-app-${index}`}>
        <div className="icon-n-title">
          <div className="app-icon" style={iconBackgroundColor}>
            {iconPath && <img src={iconPath} className="img-app-card" alt="icon" />}
          </div>
          <div className="app-title">
            <div>{item.name}</div>
            <div className="app-op">{appCategory?.name}</div>
          </div>
        </div>
      </div>
    );
  })

  return (
    <div className="confirm-create-project">
      <div className="title">
        <span className="circle"><span className="tick" /></span>
        {t.confirm_and_create_title}
      </div>
      <div className="content">
        <div className="project-details">
          <div>{projectName}</div>
          <div className="eng-type-title">{t.confirm_and_create_eng_type}</div>
          <div className="eng-type">{projectType}</div>
        </div>
        <div className="bundle-details">
          <div>
            <div>{t.confirm_and_create_bundle_name}</div>
            <div className="bundle-card">
              <div className="bundle-title">{bundle?.name}</div>
              <div className="bundle-desc">{bundle?.description}</div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="app-details">
          <div>{t.confirm_and_create_selected_apps}</div>
          <div className="app-titles">
            {renderAppTiles(apps)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmAndCreateProject;
