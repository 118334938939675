import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { addProjectApps, updateProjectApps } from '../apis/projectApi';

function* addProjectAppsData(action) {
  try {
    const data = yield call(addProjectApps, action);
    yield put({
      type: type.ADD_PROJECT_APPS_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.ADD_PROJECT_APPS_FAILURE,
      payload: message
    });
  }
}

function* updateProjectAppsData(action) {
	try {
    const data = yield call(updateProjectApps, action);
    yield put({
      type: type.UPDATE_PROJECT_APPS_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_PROJECT_APPS_FAILURE,
      payload: message
    });
  }
}

export function* getProjectAppsActionWatcher() {
  yield takeLatest(type.ADD_PROJECT_APPS_REQUEST, addProjectAppsData);
  yield takeLatest(type.UPDATE_PROJECT_APPS_REQUEST, updateProjectAppsData);
}
