import * as type from "../action-types";

const initialState = {
  manageSearchUserData: {},
  apiPassSuccess: false,
  loading: false,
  manageSearchUserGrid: {},
  searchLoader: false,
  bulkTemplateDownloadResponse: {},
  bulkUserUploadErrors: null
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.SEARCH_MANAGE_USER:
      return { ...state, loading: true };
    case type.UPLOAD_MANAGE_USER_BULK_FILE_REQUEST:
      return { ...state, loading: true, bulkUserUploadErrors: null };
    case type.SEARCH_MANAGE_USER_GRID:
      return { ...state, searchLoader: true, manageSearchUserGrid: {} };
    case type.BULK_TEMPLATE_DOWNLOAD:
      return { ...state, bulkTemplateDownloadResponse: {} }
    case type.SEARCH_MANAGE_USER_SUCCESS:
      return {
        ...state,
        manageSearchUserData: action.payload,
        loading: false,
        apiPassSuccess: true,
      };
    case type.SEARCH_MANAGE_USER_FAILURE:
      return { ...state, manageSearchUserData: {}, loading: false, apiPassSuccess: true };
    case type.FLUSH_MANAGE_USER:
      return {
        ...state,
        manageSearchUserData: {},
        loading: true,
        apiPassSuccess: false,
      };
    case type.SEARCH_MANAGE_USER_GRID_SUCCESS:
      return { ...state, manageSearchUserGrid: action.payload, searchLoader: false };
    case type.SEARCH_MANAGE_USER_GRID_FAILURE:
      return { ...state, manageSearchUserGrid: {}, searchLoader: false };
    case type.BULK_TEMPLATE_DOWNLOAD_SUCCESS:
      return { ...state, bulkTemplateDownloadResponse: action.payload };
    case type.BULK_TEMPLATE_DOWNLOAD_FAILURE:
      return { ...state, bulkTemplateDownloadResponse: { error: true } };
    case type.UPLOAD_MANAGE_USER_BULK_FILE_SUCCESS:
      return { ...state, bulkUserUploadErrors: action.payload.data };
    case type.UPLOAD_MANAGE_USER_BULK_FILE_FAILURE:
      return { ...state, bulkUserUploadErrors: { error: true } };
    default:
      return state;
  }
};
