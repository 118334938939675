import * as type from "../action-types";

type load = { id: string }

export const getDataLocationsAction = (payload?: load) => ({
    type: type.GET_DATA_LOCATIONS
});

export const getCountriesCodeAction = ()=>({
    type: type.GET_PROJECT_COUNTRIES_CODE
})

export const getProjectRegions = (payload?: load) => ({
    type: type.GET_PROJECT_REGIONS
});
