import React, { FC } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';

import t from '../../localization/en/translation.json';
import { StepIconProps } from '@material-ui/core/StepIcon';

import Pending from "../../assets/images/icons/Pending.svg";
import Current from "../../assets/images/icons/Current.svg";
import Completed from "../../assets/images/icons/Completed.svg";
import './stepperIndicator.scss';

interface IProps {
  stepIndicatorState: {
    currentStep: number
  }
  handleStepClick: Function,
  stepData: Array<{ step_text: string, title_text: string }>
}

const StepperIndicator: FC<IProps> = ({
  stepIndicatorState,
  handleStepClick,
  stepData = []
}) => {

  const stepElements = () => {
    const currentStep = stepIndicatorState.currentStep - 1;

    return (
      <Stepper alternativeLabel activeStep={currentStep} connector={<StepperConnector />}>
        {stepData.map((item, index) => (
          <Step key={`step${index}`} disabled={false}>
            <StepLabel StepIconComponent={StepIcon} onClick={(e) => handleStepClick(index + 1)}>
              <>
                {item.step_text && <div className="step-label">{item.step_text}</div>}
                <div className="step-title">{item.title_text}</div>
              </>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  return (
    <div className="stepper-indicator">
      {stepElements()}
    </div>
  )
}

const StepIcon = (props: StepIconProps) => {
  const { active, completed } = props;
  return (
    <div className="stepper-icon">
      {completed ? <img src={Completed} alt={t.step_one} /> : active ? <img src={Current} alt={t.step_two} /> : <img src={Pending} alt={t.step_three} />}
    </div>
  );
}

const StepperConnector: any = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 12px)',
  },
  active: {
    '& $line': {
      borderColor: '#3A3A4A',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3A3A4A',
    },
  },
  line: {
    borderColor: '#E8E8EC',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default StepperIndicator;
