import React from "react";
export const TruncateForToolTip = ({
                                       columnText,
                                       nullOrEmptyText = "-"
                                   }: { columnText?: string, nullOrEmptyText: string }) => {
    if (columnText == null || !columnText.length) {
        return <span>{nullOrEmptyText}</span>
    }

    return (<span title={columnText}
                  style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{columnText}</span>)
}