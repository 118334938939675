import React from "react";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";
import {
  CREATE_NOTIFICATION_ACTION,
  UPDATE_SURVEY_NOTIFICATION_ACTION,
  DELETE_SURVEY_NOTIFICATION_ACTION,
  GET_NOTIFICATIONS_ACTION,
  NOTIFICATION,
} from "../../../store/actions/admin-portal-communication-actions";
import "../adminPortalNotification.scss";
import CommonNotification from "../commonNotifications";
import { PORTAL_COMMS_SURVEY_REMINDER_LABEL } from "../../../containers/adminGeneral/adminGeneralConstants";

const SurveyReminders = () => {
  const surveyNotifications = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ portalCommunications }) => portalCommunications.surveyNotifications
  );
  return (
    <CommonNotification
      notificationTitle={PORTAL_COMMS_SURVEY_REMINDER_LABEL}
      notificationType={"Survey"}
      commonNotificationData={surveyNotifications}
      createNotificationAction={CREATE_NOTIFICATION_ACTION}
      GetNotificationAction={GET_NOTIFICATIONS_ACTION}
      updateNotificationAction={UPDATE_SURVEY_NOTIFICATION_ACTION}
      deleteNotificationAction={DELETE_SURVEY_NOTIFICATION_ACTION}
    />
  );
};
export default SurveyReminders;
