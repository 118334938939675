import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './appIcon.scss';

const AppIcon: React.FC<FontAwesomeIconProps> = ({ icon, className, ...rest }) => {
    return (
        <FontAwesomeIcon
            icon={icon}
            className={`${className || ''} app-icon ${rest.onClick ? 'icon-link' : ''}`}
            {...rest}
        />
    )
}

export default AppIcon;