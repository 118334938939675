import { AppModal } from '../../../theme';
import { IDeleteUsersConfirmModalProps } from '../manageUsersModel';
import t from '../../../localization/en/translation.json';

const DeleteUsersConfirmModal = ({
  show,
  onClose,
  onDelete,
}: IDeleteUsersConfirmModalProps) => {
  return (
    <AppModal
      size="xs"
      showModal={show}
      onModalClose={() => onClose()}
      onConfirm={() => onDelete()}
      cancelBtnText={t.cancel}
      confirmBtnText={t.manage_user_modal_delete_button}
    >
        {t.manage_user_modal_text}
    </AppModal>
  );
};

export default DeleteUsersConfirmModal;
