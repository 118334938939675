import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import List from "../../components/editableGrid/list/list";
import { columnConfig } from './columnConfig';
import { getAdminDataHostingLocRequest } from '../../store/actions/admin-general-actions';
import { updatefilterState } from '../../store/actions/list-actions';
import { lookupModel } from '../../containers/createProject/lookupModel';
import { DEFAULT_SORT, ROW_MIN_HEIGHT, DESC_PER_ROW } from "./adminDataHostingLocationsConstants";
import { IPagination, getDefaultPaginationModel, getPaginationModel } from '../../models/IPagination';
import './adminDataHostingLocations.scss';

type IProps = RouteComponentProps<any> & {
  adminDataHostingLocations: lookupModel[];
  getAdminDataHostingLocRequest: Function;
  updatefilterState: Function;
  paginationDetails: IPagination;
}

interface IState {
  paginationPayload: IPagination;
}

class AdminDataHostingLocations extends Component<IProps, IState> {
  gridApi;
  state = {
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: []
    })
  }

  componentDidMount() {
    this.getAdminDataHostingLocations();
    this.updateFilterState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { paginationPayload } = this.state;

    if (prevState.paginationPayload !== paginationPayload) {
      this.getAdminDataHostingLocations();
      this.updateFilterState();
    }
  }

  getAdminDataHostingLocations = () => {
    const { paginationPayload } = this.state;

    this.props.getAdminDataHostingLocRequest(paginationPayload);
  }

  handleGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  }

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  }

  handleSorting = (sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;

    if (paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection) {
      return;
    }

    const updatedPaginationPayload = { ...paginationPayload, sortColumn, sortDirection }
    this.setState({ paginationPayload: updatedPaginationPayload })
  }

  handleFilterChange = (filters, sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;
    const updatedPayload = { ...paginationPayload, filters, sortColumn, sortDirection, pageNumber: 1 };

    this.setState({ paginationPayload: updatedPayload })
  }

  updateFilterState = () => {
    const { filters = [], sortDirection, sortColumn } = this.state.paginationPayload;
    const isDefaultSort = sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length

    this.props.updatefilterState({ filters, sortDirection, sortColumn, isClearAllDisabled })
  }

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = { ...paginationPayload, ...paginationParams };

    this.setState({ paginationPayload: updatedPaginationPayload, })
  }

  renderDescriptionRows = ({ value }) => (<span>{value}</span>)

  handleRowHeight = ({ data }) => {
    if(!data.description || data.description.length <= DESC_PER_ROW) {
      return ROW_MIN_HEIGHT
    }

    return Math.ceil(data.description.length / DESC_PER_ROW) *  ROW_MIN_HEIGHT;
  }

  render() {
    const { adminDataHostingLocations, paginationDetails  } = this.props;
    const { paginationPayload } = this.state;

    if (paginationDetails) {
      paginationPayload.totalRecords = paginationDetails.totalRecords;
      paginationPayload.totalRecordsWithoutFilter = paginationDetails.totalRecords || paginationPayload.totalRecordsWithoutFilter;
    }

    return (
      <div className={`admin-data-hosting-location`}>
          <div className={`admin-data-hosting-location-table`}>
            <List
              columnConfig={columnConfig}
              defaultColDef={defaultColDef} 
              firstColumnBorderRight={true}
              rowData={adminDataHostingLocations}
              paginate={true}
              frameworkComponents={{
                dataHostingLocationDescriptionCellRender: this.renderDescriptionRows
              }}
              handleGridReady={this.handleGridReady}
              onSortChanged={this.handleSorting}
              currentPage={paginationPayload.pageNumber}
              pagination={paginationPayload}
              setItemsPerPage={this.handlePageSizeChange}
              onPageChange={this.handlePageNumberChange}
              onFilterChange={this.handleFilterChange}
              defaultSort={[DEFAULT_SORT]}
              getRowHeight={this.handleRowHeight}
            />

          </div>
        </div>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};
const mapStateToProps = ({ adminGeneralReducer }) => {
  return {
    adminDataHostingLocations: adminGeneralReducer.adminDataHostingLocations,
    paginationDetails: adminGeneralReducer.paginationDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminDataHostingLocRequest: payload => { dispatch(getAdminDataHostingLocRequest(payload)) },
    updatefilterState: payload => {dispatch(updatefilterState(payload))},
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDataHostingLocations));
