import {
  WFFields,
  InputFieldsType,
  WORKFLOW_IS_ACTIVE,
  WORKFLOW_RECURRING_SCHEDULE_TYPE,
  WORKFLOW_REPEAT_INTERVAL,
  CommunicationFields,
  WORKFLOW_SCHEDULE_TYPE,
  InputFieldCss,
} from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = { colId: WFFields.title, sort: "asc" };
export const ROW_FIELD_NAMES = {
  WFR_ERR_STACK: "WFR_ProcessStatus",
  TITLE: WFFields.title,
  WFR_ERR_STACK_SOURCE: "WFR_ErrorSource",
  WFR_TYPE: "wfr_ScheduleType",
};
export const MORE_MENU_ACTION_NAME = {
  UPDATE: "Update",
  DELETE: "Delete",
  ADD: "Add",
};

export const FORM_WORKFLOW_SCHEDULE = [
  {
    fieldLabel: t.admin_cps_form_id,
    fieldName: WFFields.id,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: true,
    className: InputFieldCss.large,
    isHidden: true,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_title,
    fieldName: WFFields.title,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isRequired: true,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_workflow,
    fieldName: CommunicationFields.wfr_WorkflowLookupId,
    fieldType: InputFieldsType.Select,
    fieldOptions: [],
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_schedule_type,
    fieldName: WFFields.scheduleType,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_SCHEDULE_TYPE,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.small,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_no_of_repeats,
    fieldName: WFFields.numberOfRepeats,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.small,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_is_active,
    fieldName: WFFields.isActive,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_IS_ACTIVE,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: "",
    className: InputFieldCss.small,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_is_running,
    fieldName: WFFields.isRunning,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_IS_ACTIVE,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: "false",
    className: InputFieldCss.small,
  },
  {
    fieldLabel: t.admin_cps_form_last_run_time,
    fieldName: WFFields.lastRunTime,
    fieldType: InputFieldsType.Date,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: null,
    className: InputFieldCss.small,
  },
  {
    fieldLabel: t.admin_cps_form_no_of_executions,
    fieldName: WFFields.numberOfRuns,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.small,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_recurring_schedule_end_type,
    fieldName: WFFields.recurringScheduleEndType,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_RECURRING_SCHEDULE_TYPE,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_repeat_frequency,
    fieldName: WFFields.repeatFrequency,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.small,
    isRequired: true,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_repeat_interval,
    fieldName: WFFields.repeatInterval,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_REPEAT_INTERVAL,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.small,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_key,
    fieldName: WFFields.key,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.medium,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_schedule_end_date,
    fieldName: WFFields.scheduleEndDate,
    fieldType: InputFieldsType.DateTime,
    fieldVal: null,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.medium,
  },
  {
    fieldLabel: t.admin_cps_form_schedule_start_date,
    fieldName: WFFields.scheduleStartDate,
    fieldType: InputFieldsType.DateTime,
    fieldVal: null,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.medium,
  },
  {
    fieldLabel: t.admin_cps_form_parameters,
    fieldName: WFFields.parameters,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    isTouched: false,
    className: InputFieldCss.large,
    tooltip: "",
    charLimit: 8000,
  },
];
