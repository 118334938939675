import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import _ from "lodash";
import {
  searchManageUserAction,
  flushManageUserAction,
  addnewUserAction,
  searchManageUserGrid,
  bulkTemplatedownloadAction,
  bulkUserUploadRequest,
} from "../../store/actions/manage-user-actions";
import ListUsers from "./listUsers/listUsers";
import t from "../../localization/en/translation.json";
import ManageUserModal from "../../components/manageUserModal/manageUserModal";
import { ProjectStatus } from "../../components/projectCard/projectCardConstants";
import {
  gridAppPlatformKeys,
  USER_ACTIONS,
} from "./listUsers/listUserConstants";
import {
  updateManageUserReset,
  updateManageUser,
  getProjectDetails,
  getProjectUsersList,
  getProjectUsersAccessList,
  flushManageUsersGrid,
  showLoader,
} from "../../store/actions/project-actions";
import { IUserRole } from "../../models/IUserAccess";
import {
  getAppPlatforms,
  getAppPlatformRoles,
} from "../../store/actions/meta-actions";
import { Link } from "react-router-dom";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import {
  getAzureAppInsights,
  isAppActive,
  isProjectAdmin,
  isEyUser,
  isProjectAdminUser,
  setPageBrTitle,
  getStringWithBold,
} from "../../utils/helper-utility";
import { checkAndLoadEMGPolicy } from "../../components/emgPolicy/constants";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AppButton, AppModal, AppIcon } from "../../theme";
import { add, search, trash } from "../../theme/icons";
import "./manageUsers.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { ManageUsersProps, IState } from "./manageUsersModel";
import { RoutePathKeys, SSPRolesEnum } from "../../utils/Constants";
import BreadcrumbComponent from "../../components/breadcrumb/Breadcrumb";
import AppLabelWithIcon from "../../theme/appLabelWithIcon/appLabelWithIcon";
import { IUser } from "../../models/user";
import DeleteUsersConfirmModal from "./deleteConfirmModal/deleteConfirmModal";

type RouteParams = { id: string };
type IProps = RouteComponentProps<RouteParams> & ManageUsersProps;

class ManageUsers extends Component<IProps, IState> {
  private handleRedirection: Function;
  bulkImportSaveHandler = false;
  uploadFormData;

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchQuery: "",
      isOpen: false,
      isUserAlreadyExist: false,
      isManageUsersGridUpdated: false,
      showGridUpdateCancelDialog: false,
      nextLocation: null,
      updatedUserRoles: [],
      maxRecordsPerPage: 50,
      currentPage: 1,
      showGridError: false,
      reRenderGrid: true,
      bulkImportTemplateSuccess: false,
      bulkImportTemplateFailure: false,
      bulkImportSaveData: false,
      updatedUsersList: [],
      isDeleteConfirm: false,
    };

    this.handleRedirection = () => null;
  }

  componentDidMount() {
    const {
      record,
      updateManageUserReset,
      projectStatuses,
      getProjectStatusAction,
      history,
      match: {
        params: { id },
      },
      getAppPlatforms,
      getProjectDetails,
      getAppPlatformRoles,
    } = this.props;
    updateManageUserReset();

    getProjectDetails({
      id,
    });

    getAppPlatformRoles({
      projectId: id,
    });

    getAppPlatforms();

    !projectStatuses.length && getProjectStatusAction();

    this.refreshGrid();
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.handleRedirection = history.block((targetLocation) => {
      const { isManageUsersGridUpdated, showGridUpdateCancelDialog } =
        this.state;
      if (isManageUsersGridUpdated && showGridUpdateCancelDialog === false) {
        this.setState(
          {
            ...this.state,
            nextLocation: targetLocation.pathname,
          },
          () => {
            this.toggleCancelDialog(true);
          }
        );
        return false;
      }
    });

    const appInsights = getAzureAppInsights();
    if (record && record.id && appInsights) {
      appInsights.updateProjectDetails({
        CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
      });
    }
    record && record.id && this.updatePageBrTitle();

    this.checkForEMGPolicy();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      usersRequested,
      projectUsers,
      userAccessRequested,
      updateManageUsersResponse,
      rolesRequested,
      record,
      history,
      match: {
        params: { id },
      },
      userAddRequested,
      usersUpdateRequested,
      removeUserGridDisplay,
      bulkImportTemplateResponse,
      bulkUserUploadErrors,
    } = this.props;
    if (!_.isEqual(prevProps.record, record)) {
      if (
        record?.projectStatus?.projectStatusName.toLowerCase() ===
        ProjectStatus.DRAFT
      ) {
        history.push(`/project/${id}`);
      } else {
        this.updatePageBrTitle();
      }
    }

    if (
      prevProps.removeUserGridDisplay === false &&
      removeUserGridDisplay === true
    ) {
      this.setState({
        ...this.state,
        updatedUserRoles: [],
      });
    }

    if (prevProps.userAddRequested === true && userAddRequested === false) {
      this.refreshGrid();
    }

    if (
      prevProps.usersUpdateRequested === true &&
      usersUpdateRequested === false &&
      updateManageUsersResponse.length
    ) {
      this.setState(
        {
          ...this.state,
          updatedUserRoles: [],
        },
        () => {
          if (
            updateManageUsersResponse.filter((item) => !item.isAllProcessed)
              .length === 0
          ) {
            history.push(`/project/${id}`);
          } else {
            this.refreshGrid();
          }
        }
      );
    }

    if (
      (prevProps.rolesRequested === true &&
        rolesRequested === false &&
        !usersRequested &&
        !userAccessRequested) ||
      (prevProps.usersRequested === true &&
        usersRequested === false &&
        !rolesRequested &&
        !userAccessRequested) ||
      (prevProps.userAccessRequested === true &&
        userAccessRequested === false &&
        !rolesRequested &&
        !usersRequested)
    ) {
      this.setState({
        ...this.state,
        showGridError: true,
      });
    }

    if (!_.isEqual(projectUsers, prevProps.projectUsers)) {
      this.setState({
        reRenderGrid: true,
      });
    }

    if (
      prevProps.bulkImportTemplateResponse !== bulkImportTemplateResponse &&
      bulkImportTemplateResponse.success === true
    ) {
      const date = new Date().toDateString();
      const time = new Date().toLocaleTimeString("en-US", {
        timeStyle: "short",
      } as any);

      const blob = this.convertB64ToBlob(
        bulkImportTemplateResponse.data.fileContents,
        "application/octet-stream"
      );

      const blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);
      const downloadButton = document.createElement("a");
      downloadButton.style.display = "none";
      downloadButton.href = blobURL;
      downloadButton.setAttribute("download", `Users ${date} ${time}.xlsm`);
      document.body.appendChild(downloadButton);
      downloadButton.click();
      this.setState({ bulkImportTemplateSuccess: true });
      setTimeout(() => {
        document.body.removeChild(downloadButton);
        window.URL.revokeObjectURL(blobURL);
        this.setState({ bulkImportTemplateSuccess: false });
      }, 2000);
    } else if (
      prevProps.bulkImportTemplateResponse !== bulkImportTemplateResponse &&
      bulkImportTemplateResponse.error === true
    ) {
      this.setState({ bulkImportTemplateFailure: true });
      setTimeout(() => {
        this.setState({ bulkImportTemplateFailure: false });
      }, 2000);
    }

    if (
      prevProps.bulkUserUploadErrors !== bulkUserUploadErrors &&
      bulkUserUploadErrors?.success &&
      this.bulkImportSaveHandler
    ) {
      this.refreshGrid();
      this.bulkImportSaveHandler = false;
      this.setState({ bulkImportSaveData: true });
    } else if (
      prevProps.bulkUserUploadErrors !== bulkUserUploadErrors &&
      bulkUserUploadErrors?.error &&
      this.bulkImportSaveHandler
    ) {
      this.bulkImportSaveHandler = false;
      this.setState({ bulkImportSaveData: true });
    }

    const appInsights = getAzureAppInsights();
    if (prevProps.record !== record && record && record.id) {
      if (appInsights) {
        appInsights.updateProjectDetails({
          CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
        });
      }

      this.checkForEMGPolicy();
    }
  }

  convertB64ToBlob(b64Data: string, type: string): Blob {
    const byteCharacters = atob(b64Data);
    // tslint:disable-next-line:no-any
    const byteArrays = [] as any;
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type });
  }

  handleBeforeUnload = (e) => {
    if (this.state.isManageUsersGridUpdated) {
      return (e.returnValue = false);
    }
  };

  componentWillUnmount() {
    const { history } = this.props;
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.handleRedirection();
    this.props.flushManageUsersGrid();
    if (!history.location.pathname.includes(RoutePathKeys.PROJECT)) {
      this.resetPageBrTitle();
    }
    this.props.fetchSearchData({});
  }

  checkForEMGPolicy = () => {
    const { accessRight, record, updateProjectDetailsForEMGAction } =
      this.props;
    checkAndLoadEMGPolicy(
      accessRight,
      updateProjectDetailsForEMGAction,
      record
    );
  };

  refreshGrid = (searchedUser?: string) => {
    this.setState({
      ...this.state,
      showGridError: false,
      searchQuery: searchedUser || "",
    });

    const {
      match: {
        params: { id },
      },
      getProjectUsersList,
      getProjectUsersAccessList,
    } = this.props;
    const { maxRecordsPerPage, currentPage } = this.state;
    getProjectUsersList({
      projectId: id,
      userIds: (searchedUser && [searchedUser]) || [],
      pageNumber: currentPage,
      pageSize: maxRecordsPerPage,
    });

    getProjectUsersAccessList({
      projectId: id,
      userIds: (searchedUser && [searchedUser]) || [],
      pageNumber: currentPage,
      pageSize: maxRecordsPerPage,
    });
  };

  handleAddUserClick = (value) => {
    this.setState({ isOpen: value, bulkImportSaveData: false });
    this.props.flushSearchUserData();
    this.setState({ isUserAlreadyExist: false });
  };

  handleUpdateClick = () => {
    // Update button click handler
    const {
      updateManageUser,
      match: {
        params: { id },
      },
    } = this.props;
    const { updatedUserRoles } = this.state;
    const body = {
      projectId: id,
      users: [...updatedUserRoles],
    };
    this.setState(
      {
        ...this.state,
        isManageUsersGridUpdated: false,
      },
      () => {
        updateManageUser({
          body,
          projectId: id,
        });
      }
    );
  };

  handleSearchChange = (query) => {
    const {
      fetchSearchData,
      match: {
        params: { id },
      },
    } = this.props;

    const searchValue = {
      id: id,
      searchString: query,
    };
    fetchSearchData(searchValue);
  };

  handleSelectValue = (selectedArray) => {
    this.setState(
      {
        ...this.state,
        currentPage: 1,
      },
      () => {
        this.refreshGrid((selectedArray[0] && selectedArray[0].id) || "");
      }
    );
    if(selectedArray.length === 0) this.props.fetchSearchData({});
  };

  handleConfirmForRedirection = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;
    history.push(nextLocation);
  };

  handleAddUserToGrid = (value) => {
    const {
      match: {
        params: { id },
      },
      appPlatformRoles,
      addnewUserAction,
    } = this.props;
    const sspRoles = appPlatformRoles.ssp.roles || [];
    const sspUserRoleRecord = sspRoles.find(
      (item) => item.key === SSPRolesEnum.PRJUSR
    );
    const body = {
      ...value,
      photo: "",
      sspRoleId: sspUserRoleRecord?.id || "",
      roles: [
        {
          projectId: id,
          userRoleId: sspUserRoleRecord?.id || "",
        },
      ],
    };

    addnewUserAction({
      body,
      projectId: id,
    });

    this.setState({
      ...this.state,
      isOpen: false,
    });
  };

  handleSearchUser = (value) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ isUserAlreadyExist: false });
    this.props.searchUserEmail({
      email: {
        emails: [value],
      },
      projectId: id,
    });
  };

  removeUserFromGrid = (removeUsersData: Array<IUser>) => {
      const { projectUserAccess } = this.props;
      const { updatedUserRoles } = this.state;
      const temporaryProjectUserAccess = _.cloneDeep(updatedUserRoles);
      removeUsersData.forEach((user: IUser) => {
      let userAccess: IUserRole = temporaryProjectUserAccess.find(
        (item) => item.userId === user.id
      );
      const oldUserAccess = projectUserAccess.find(
        (item) => item.userId === user.id
      );
      let usedOld = false;
      if (!userAccess) {
        usedOld = true;
        userAccess = _.cloneDeep(oldUserAccess || {});
      }
      userAccess.action = USER_ACTIONS.DELETE;
      
      if (usedOld) {
        temporaryProjectUserAccess.push(userAccess);
      }
    });
      this.setState({
        ...this.state,
        updatedUserRoles: [...temporaryProjectUserAccess],
        isManageUsersGridUpdated: true,
        reRenderGrid: false,
        isDeleteConfirm: false
      },
      () => {
        this.handleUpdateClick();
      });
  };

  toggleCancelDialog = (shown: boolean) => {
    const { showGridUpdateCancelDialog } = this.state;
    if (showGridUpdateCancelDialog !== shown) {
      this.setState({
        ...this.state,
        showGridUpdateCancelDialog: shown,
      });
    }
  };

  renderMenuItem = (option, props) => {
    const stringData = option.givenName + " " + option.surname + ", " + option.mail + ", " + option.officeLocation;
    return (
      <div className="async-dropdown" title={stringData} dangerouslySetInnerHTML={{ __html: getStringWithBold(stringData, props.text) }}>
      </div>
    );
  };

  onUserRoleChange = (userId: string, platform: string, roles: []) => {
    const { projectUserAccess, appPlatformList, record, projectUsers } =
      this.props;
    const { updatedUserRoles } = this.state;
    const user = projectUsers.find((item) => item.id === userId);
    let temporaryProjectUserAccess = _.cloneDeep(updatedUserRoles);
    let userAccess = temporaryProjectUserAccess.find(
      (item) => item.userId === userId
    );
    const oldUserAccess = projectUserAccess.find(
      (item) => item.userId === userId
    );
    let isNewUser = false;
    if (!userAccess) {
      isNewUser = true;
      userAccess = {
        userId,
        mail: user?.mail,
        appPlatforms: [],
      };
    }

    if (platform === gridAppPlatformKeys.SSP) {
      if (!userAccess.ssp) {
        userAccess.ssp = {
          roles: [...(oldUserAccess?.ssp?.roles || [])],
        };
      }
      if (!userAccess.ssp.oldRoles) {
        userAccess.ssp = {
          ...userAccess.ssp,
          oldRoles: userAccess.ssp?.roles.map((item) => item) || [],
        };
      }
      userAccess.ssp.newRoles =
        (Array.isArray(roles) && roles) || (roles && [roles]) || [];

      let changedRoles = _.difference(
        userAccess.ssp.newRoles,
        userAccess.ssp.oldRoles
      );

      if (
        changedRoles.indexOf("") > -1 &&
        userAccess.ssp.oldRoles.length === 0
      ) {
        changedRoles = [];
      }

      if (!changedRoles.length) {
        userAccess.ssp = undefined;
      }
    } else {
      const appPlatform = appPlatformList.find((item) => item.id === platform);
      if (appPlatform) {
        let appPlatformAccess = userAccess.appPlatforms.find(
          (item) => item.appPlatformId === platform
        );
        const appPlatformOldAccess = oldUserAccess?.appPlatforms?.find(
          (item) => item.appPlatformId === platform
        );
        let isNew = false;
        if (!appPlatformAccess) {
          isNew = true;
          const apps =
            record?.appDetails?.filter(
              (item) => item.appPlatformId === platform
            ) || [];
          appPlatformAccess = {
            appPlatformId: platform,
            appPlatformName: appPlatform.name,
            apps: apps.map((item) => item.appClassName) || [],
          };
        }

        if (
          !appPlatformAccess.oldRoles ||
          (Array.isArray(appPlatformAccess.oldRoles) &&
            appPlatformAccess.oldRoles.length === 0)
        ) {
          appPlatformAccess.oldRoles =
            appPlatformOldAccess?.roles?.map((item) => item) || [];
        }

        appPlatformAccess.newRoles =
          (Array.isArray(roles) && roles) || (roles && [roles]) || [];

        let changedRoles = _.difference(
          appPlatformAccess.newRoles,
          appPlatformAccess.oldRoles
        );

        let forceNoChange = false;
        if (
          changedRoles.indexOf("") > -1 &&
          appPlatformAccess.oldRoles.length === 0
        ) {
          changedRoles = [];
          forceNoChange = true;
        }

        if (!changedRoles.length && !forceNoChange) {
          changedRoles = _.difference(
            appPlatformAccess.oldRoles,
            appPlatformAccess.newRoles
          );
        }

        if (isNew && changedRoles.length) {
          userAccess.appPlatforms.push(appPlatformAccess);
        } else if (!changedRoles.length) {
          userAccess.appPlatforms = userAccess.appPlatforms.filter(
            (item) => item.appPlatformId !== platform
          );
        }
      }
    }

    userAccess.action = USER_ACTIONS.UPDATE;

    if (isNewUser) {
      if (userAccess.ssp || userAccess.appPlatforms.length) {
        temporaryProjectUserAccess.push(userAccess);
      }
    } else {
      if (!userAccess.ssp && !userAccess.appPlatforms.length) {
        temporaryProjectUserAccess = temporaryProjectUserAccess.filter(
          (item) => item.userId !== userId
        );
      }
    }

    this.setState({
      ...this.state,
      updatedUserRoles: [...temporaryProjectUserAccess],
      isManageUsersGridUpdated: temporaryProjectUserAccess.length > 0,
      reRenderGrid: false,
    });
  };

  updateMaxRecordsPerPage = (count: number) => {
    this.setState(
      {
        ...this.state,
        maxRecordsPerPage: count,
        currentPage: 1,
      },
      () => {
        this.refreshGrid(this.state.searchQuery);
      }
    );
  };

  onPageChange = (pageNo: number) => {
    this.setState(
      {
        ...this.state,
        currentPage: pageNo,
      },
      () => {
        this.refreshGrid(this.state.searchQuery);
      }
    );
  };

  canGridLoad = () => {
    const {
      record,
      projectUsers,
      appPlatformList,
      appPlatformRoles,
      projectUserAccess,
    } = this.props;
    if (
      !appPlatformList ||
      (Array.isArray(appPlatformList) && appPlatformList.length === 0)
    ) {
      return false;
    } else if (
      !appPlatformRoles ||
      Object.keys(appPlatformRoles).length === 0 ||
      !appPlatformRoles.ssp ||
      appPlatformRoles.ssp.roles.length === 0 ||
      appPlatformRoles.appPlatforms.length === 0
    ) {
      return false;
    } else if (!projectUserAccess || projectUserAccess.length === 0) {
      return false;
    } else if (!record) {
      return false;
    } else if (
      !projectUsers ||
      (Array.isArray(projectUsers) && projectUsers.length === 0)
    ) {
      return false;
    }

    return true;
  };

  areAllPlatformRolesAvailable = () => {
    const { record, appPlatformRoles, projectStatuses, appPlatformList } =
      this.props;
    const ce4PlatformId = appPlatformList.find((p) => p.key === "CE4")?.id;
    const isCe4PlatformAvailble = record?.appPlatformDetails?.find(
      (p) => p.appPlatformClassId === ce4PlatformId
    );
    let nonSilentPlatforms = record?.appPlatformDetails;

    if (!!isCe4PlatformAvailble && nonSilentPlatforms) {
      const ce4Apps = record?.appDetails?.filter(
        (d) => d.appPlatformId === ce4PlatformId
      );

      nonSilentPlatforms =
        ce4Apps && !ce4Apps.length
          ? record?.appPlatformDetails?.filter(
              (p) => p.appPlatformClassId !== ce4PlatformId
            )
          : record?.appPlatformDetails;
    }

    const provisionedAppPltforms = (
      nonSilentPlatforms?.filter((item) => {
        return isAppActive(
          projectStatuses,
          record?.appPlatformDetails,
          item.appPlatformClassId || ""
        );
      }) || []
    ) // filtering applicationPlatformDetail has at least one application
      .filter((applicationPlatformDetail) =>
        record?.appDetails?.some(
          (applicationDetail) =>
            applicationDetail.appPlatformId ===
            applicationPlatformDetail.appPlatformClassId
        )
      );

    const platformRoles = appPlatformRoles.appPlatforms.filter(
      (item) => item?.roles.length > 0
    );
    let platformRolesReadyCount = 0;

    provisionedAppPltforms.forEach((item) => {
      const rolesList = platformRoles.find(
        (platformRole) => item.id === platformRole.appPlatformId
      );

      if (
        rolesList &&
        rolesList.roles?.filter((item) => item.name).length > 0
      ) {
        platformRolesReadyCount += 1;
      }
    });

    return platformRolesReadyCount === provisionedAppPltforms.length;
  };

  isProjectAdmin = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspPlatformRoles);
  };
  isProjectAdminUser = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdminUser(record?.id || "", accessRight, sspPlatformRoles);
  };
  isEyUser = () => {
    return isEyUser(this.props.accessRight);
  };

  bulkImportSave = () => {
    const {
      bulkUserUploadRequest,
      match: {
        params: { id },
      },
    } = this.props;

    const formData = this.uploadFormData;
    this.bulkImportSaveHandler = true;
    this.setState({ bulkImportSaveData: false });
    bulkUserUploadRequest({
      projectId: id,
      saveEnabled: "true",
      formData,
    });
  };

  validateBulkUserFile = (formData) => {
    const {
      bulkUserUploadRequest,
      match: {
        params: { id },
      },
    } = this.props;

    this.uploadFormData = formData;
    bulkUserUploadRequest({
      projectId: id,
      saveEnabled: "false",
      formData,
    });
  };

  updatePageBrTitle() {
    const { record } = this.props;
    if (record && record.name) {
      setPageBrTitle(record.name);
    }
  }

  resetPageBrTitle() {
    setPageBrTitle();
  }

  handleDeleteUser = () => {
    const { updatedUsersList } = this.state;
    if (updatedUsersList?.length && (this.isProjectAdmin() || this.isProjectAdminUser())) {
      this.setState({ isDeleteConfirm: true });
    }
  };

  render() {
    const {
      record,
      projectUsers,
      manageSearchUserData,
      apiPassSuccess,
      projectUserAccess,
      appPlatformList,
      appPlatformRoles,
      match: {
        params: { id },
      },
      manageSearchUserGrid,
      pagination,
      searchLoader,
      projectStatuses,
      updateManageUsersResponse,
      bulkTemplatedownload,
      bulkUserUploadErrors,
      showLoader,
    } = this.props;

    const {
      showGridError,
      currentPage,
      isOpen,
      showGridUpdateCancelDialog,
      updatedUserRoles,
      maxRecordsPerPage,
      bulkImportTemplateSuccess,
      bulkImportTemplateFailure,
      bulkImportSaveData,
      isDeleteConfirm,
    } = this.state;

    const updateErrors = updateManageUsersResponse.filter(
      (item) => !item.isAllProcessed
    );
    const breadcrumbObject = [
      { label: t.app_title, href: "/", active: true },
      {
        label: `${record?.name}`,
        href: `/project/${record?.id}`,
        active: true,
      },
      {
        label: t.step_three_title,
        href: "",
        active: false,
        disabled: true,
      },
    ];
    const isUserProjectAdmin = this.isProjectAdmin();
    const isProjectAdminUser = this.isProjectAdminUser();
    const isEyUser = this.isEyUser();
    if (record && record.id) {
      return (
        <CrashBoundary>
          <div className="manage-users">
            <div className="manage-header">
              <div className="title-wrapper">
                <BreadcrumbComponent
                  items={breadcrumbObject}
                ></BreadcrumbComponent>
              </div>
            </div>
            <div className="content">
              <div className="grid-title">
                <span className="title">{t.edit_project_search_user}</span>
              </div>
              {showGridError && !this.canGridLoad() && (
                <div className="grid-error">
                  {t.manage_users_screen_load_error}
                </div>
              )}
              {showGridError &&
                this.canGridLoad() &&
                !this.areAllPlatformRolesAvailable() && (
                  <div className="grid-error">
                    {t.manage_users_grid_roles_fetch_failed}
                  </div>
                )}
              {updateErrors.length > 0 && (
                <div className="grid-error">
                  {t.manage_users_grid_update_fail}
                </div>
              )}
              <div className="search-wrapper">
                <div className="add-delete-wrapper">
                  {(isUserProjectAdmin || isProjectAdminUser) && (
                    <AppLabelWithIcon leftIcon={<AppIcon icon={add} />} labelText={t.edit_project_add_user} handleOnClick={() => this.handleAddUserClick(true)} />
                  )}
                  <AppLabelWithIcon leftIcon={<AppIcon icon={trash} />} labelText={t.manage_user_delete} handleOnClick={() => this.handleDeleteUser()} isDisabled={!this.state.updatedUsersList.length} />
                </div>
                <div className="project-form-field">
                  <AppIcon icon={search} />
                  <AsyncTypeahead
                    id="async-search"
                    isLoading={searchLoader}
                    filterBy={() => true}
                    labelKey={(option) =>
                      `${option.givenName} ${option.surname}`
                    }
                    minLength={3}
                    onSearch={this.handleSearchChange}
                    options={manageSearchUserGrid}
                    onChange={(selected) => this.handleSelectValue(selected)}
                    placeholder={t.manage_user_search_user}
                    renderMenuItemChildren={(option, props) =>
                      this.renderMenuItem(option, props)
                    }
                  >
                    {({ onClear, selected }) => (
                      <div className="rbt-aux">
                        {!!selected.length && <ClearButton onClick={onClear} />}
                      </div>
                    )}
                  </AsyncTypeahead>
                </div>
              </div>
              <div>
                <ListUsers
                  isProjectAdmin={isUserProjectAdmin}
                  isProjectAdminUser={isProjectAdminUser}
                  isEyUser={isEyUser}
                  projectStatuses={projectStatuses}
                  onPageChange={this.onPageChange}
                  currentPage={currentPage}
                  onUpdateMaxRecordsPerPage={this.updateMaxRecordsPerPage}
                  maxRecordsPerPage={maxRecordsPerPage}
                  pagination={pagination}
                  onRoleChange={this.onUserRoleChange}
                  updatedUserAccess={updatedUserRoles}
                  platformRoles={appPlatformRoles}
                  appPlatformList={appPlatformList}
                  project={record}
                  projectAppList={record?.appDetails || []}
                  projectAppPlatformList={record?.appPlatformDetails || []}
                  userAccess={projectUserAccess}
                  users={projectUsers}
                  reRender={this.state.reRenderGrid}
                  setLoader={showLoader}
                  onRowSelectChange={(data)=> this.setState({ updatedUsersList: data })}
                />
              </div>
            </div>
            <div className="footer">
              <Link to={`/project/${id}`} className="btn">
                <AppButton color="secondary" className="update-btn">{t.cancel}</AppButton>
              </Link>
              {(isUserProjectAdmin || isProjectAdminUser) && (
                <AppButton
                  disabled={!updatedUserRoles.length}
                  onClick={this.handleUpdateClick}
                >
                  {t.edit_project_update}
                </AppButton>
              )}
            </div>
            {isOpen && (
              <ManageUserModal
                isOpen={isOpen}
                handleAddUserClick={this.handleAddUserClick}
                searchUser={this.handleSearchUser}
                manageSearchedUserData={manageSearchUserData}
                apiSuccess={apiPassSuccess}
                handleAddUserToGrid={this.handleAddUserToGrid}
                isUserAlreadyExist={
                  manageSearchUserData.isExistingUser || false
                }
                bulkImportTemplatedownload={bulkTemplatedownload}
                bulkImportTemplateSuccess={bulkImportTemplateSuccess}
                bulkImportTemplateFailure={bulkImportTemplateFailure}
                bulkUserUploadRequest={this.validateBulkUserFile}
                bulkUserUploadErrors={bulkUserUploadErrors}
                bulkImportSave={this.bulkImportSave}
                bulkImportSaveData={bulkImportSaveData}
              />
            )}
            <AppModal
              showModal={showGridUpdateCancelDialog}
              confirmBtnText={t.manage_users_cancel_continue_btn}
              onConfirm={this.handleConfirmForRedirection}
              cancelBtnText={t.cancel}
              confirmBtnClass="warning"
              onModalClose={() => this.toggleCancelDialog(false)}
              title={t.manage_users_cancel_dialog_title}
            >
              {t.manage_users_cancel_redirect_message}
            </AppModal>
            {isDeleteConfirm &&
              <DeleteUsersConfirmModal show={isDeleteConfirm} onClose={()=>this.setState({ isDeleteConfirm: false })} onDelete={()=>this.removeUserFromGrid(this.state.updatedUsersList)} />
            }
          </div>
        </CrashBoundary>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({
  meReducer,
  projectStatusReducer,
  projects: {
    userAccessRequested,
    usersRequested,
    updateManageUsersResponse,
    usersUpdateRequested,
    record,
    users,
    userAccess,
    userGridPagination,
    userAddRequested,
    removeUserGridDisplay,
  },
  manageUserReducer,
  meta: { appPlatforms, roles, rolesRequested, userRoles, projectStatuses },
}) => {
  return {
    record,
    projectUsers: users,
    manageSearchUserData: manageUserReducer.manageSearchUserData,
    apiPassSuccess: manageUserReducer.apiPassSuccess,
    loading: manageUserReducer.loading,
    projectUserAccess: userAccess,
    appPlatformList: appPlatforms,
    appPlatformRoles: roles,
    pagination: userGridPagination,
    manageSearchUserGrid: manageUserReducer.manageSearchUserGrid.data,
    userAddRequested: userAddRequested,
    usersUpdateRequested: usersUpdateRequested,
    searchLoader: manageUserReducer.searchLoader,
    rolesRequested: rolesRequested,
    projectStatuses: projectStatuses || projectStatusReducer.projectStatus,
    accessRight: meReducer.accessRight,
    currentUser: meReducer.currentUser,
    sspPlatformRoles: userRoles,
    updateManageUsersResponse: updateManageUsersResponse,
    usersRequested,
    userAccessRequested,
    removeUserGridDisplay: removeUserGridDisplay,
    bulkImportTemplateResponse: manageUserReducer.bulkTemplateDownloadResponse,
    bulkUserUploadErrors: manageUserReducer.bulkUserUploadErrors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProjectUsersAccessList: (payload) => {
      dispatch(getProjectUsersAccessList(payload));
    },
    getAppPlatforms: (payload) => {
      dispatch(getAppPlatforms(payload));
    },
    getProjectDetails: (payload) => {
      dispatch(getProjectDetails(payload));
    },
    getProjectUsersList: (payload) => {
      dispatch(getProjectUsersList(payload));
    },
    searchUserEmail: (payload) => {
      dispatch(searchManageUserAction(payload));
    },
    flushSearchUserData: () => {
      dispatch(flushManageUserAction());
    },
    addnewUserAction: (payload) => {
      dispatch(addnewUserAction(payload));
    },
    getAppPlatformRoles: (payload) => {
      dispatch(getAppPlatformRoles(payload));
    },
    updateManageUser: (payload) => {
      dispatch(updateManageUser(payload));
    },
    fetchSearchData: (payload) => {
      dispatch(searchManageUserGrid(payload));
    },
    getProjectStatusAction: (payload) => {
      dispatch(getProjectStatusAction(payload));
    },
    updateManageUserReset: () => {
      dispatch(updateManageUserReset());
    },
    bulkTemplatedownload: () => {
      dispatch(bulkTemplatedownloadAction());
    },
    bulkUserUploadRequest: (payload) => {
      dispatch(bulkUserUploadRequest(payload));
    },
    updateProjectDetailsForEMGAction: (payload) => {
      dispatch(updateProjectDetailsForEMG(payload));
    },
    showLoader: (payload) => {
      dispatch(showLoader(payload));
    },
    flushManageUsersGrid: () => dispatch(flushManageUsersGrid()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
