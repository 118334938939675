import { ISSPPlatformRole } from "../../models/IRoles";
import { IServiceLines, ISubServiceLines } from "../../models/IServiceLines";
import t from "../../localization/en/translation.json";

export interface IHeaderComponentParams {
  dataColumn: boolean;
  headerEndComponent?: any;
  enableSorting?: boolean;
}

export interface ICellRendererParams {
  getValue?: Function
  data?: any
  OnValueChange?: Function
  selectValueFieldName?: string,
  classNamePrefix?: string,
  additionalProps?: any
}

export interface IGridColumn {
  headerName: string;
  field: string;
  cellRenderer?: string;
  width?: number;
  headerComponentParams?: IHeaderComponentParams;
  pinned?: string;
  id?: string;
  lockPinned?: boolean;
  lockPosition?: boolean;
  suppressMovable?: boolean;
  cellRendererParams?: ICellRendererParams;
  sortable?: boolean;
  initialSort?: string;
  comparator?: Function;
  sort?: string | null;
  hide?: boolean;
  filter?: string;
  filterType?: string
  headerComponent?: string
}

export const getColumnConfig = (
  roleChanged: any,
  sspRoles: ISSPPlatformRole[],
  userTypeChanged: any,
  userTypes: any[],
  adminConsoleAccess: any,
  filterRoleOptions: Function,
  serviceLines: IServiceLines[],
  serviceLinesChanged: any,
  subServiceLines: ISubServiceLines[],
  subServiceLinesChanged: any,
  filterSubServiceLineOptions: Function,
  isExternalUser: Function,
  onColumnDataRender: Function,
  renderUserTypeWarningTemplate: Function
): IGridColumn[] => [
    {
      headerName: 'User',
      field: 'user',
      width: 300,
      pinned: 'left',
      id: 'user',
      lockPosition: true,
      cellRenderer: 'defaultUserDetailsRenderer',
     // sortable: false
    },
    {
      headerName: 'Given name',
      field: 'givenName',
      width: 200,
      lockPinned: true,
      sortable: true,    
      hide: true
    },
    {
      headerName: 'Role',
      field: 'userPortalRoleName',
      id: 'roleName',
     // sortable: false,
      lockPinned: true,
      cellRenderer: 'defaultSelectCellRenderer',
      cellRendererParams: {
        data: {
          options: sspRoles.map(item => ({
            value: item.id,
            label: item.userRoleName
          })) || [],
          multiple: false,
          placeholder: "None",
          isSelectDisabled: !adminConsoleAccess.portalAdmin,
          filterOptions: filterRoleOptions
        },
        OnValueChange: roleChanged,
        selectValueFieldName: 'roleId'
      },
      width: 250,
      filter: 'customFilter'
    },
    {
      headerName: 'Projects',
      field: 'projects',
      id: 'projects',
    //  sortable: false,
      lockPinned: true,
      width: 200,
    },
    {
      headerName: 'Account Type',
      field: 'accountType',
      id: 'accountType',
      sortable: true,
      lockPinned: true,
      width: 175,
      filter: 'customFilter'
    },
    {
      headerName: '',
      headerComponent: 'userTypeHeader',
      field: 'userType',
      id: 'userType',
     // sortable: false,
      lockPinned: true,
      cellRenderer: 'defaultSelectCellRenderer',
      cellRendererParams: {
        data: {
          options: userTypes.map(item => ({
            value: item,
            label: item
          })) || [],
          multiple: false,
          placeholder: "None",
          isSelectDisabled: !adminConsoleAccess.portalAdmin,
        },
        OnValueChange: userTypeChanged,
        selectValueFieldName: 'userType',
        additionalProps: {
          renderPreTemplete: renderUserTypeWarningTemplate,
          generateClassNameOnRender: onColumnDataRender
        },
      },
      width: 175
    },
    {
      headerName: 'Service Line',
      field: 'serviceLineCode',
      id: 'serviceLineCode',
    //  sortable: false,
      lockPinned: true,
      cellRenderer: 'defaultSelectCellRenderer',
      cellRendererParams: {
        data: {
          options: serviceLines.map(item => ({
            value: item.code,
            label: item.name,
            id: item.id
          })) || [],
          multiple: false,
          placeholder: "N/A",
          isSelectDisabled: !adminConsoleAccess.portalAdmin,
          filterOptions: isExternalUser
        },
        OnValueChange: serviceLinesChanged,
        selectValueFieldName: 'serviceLineCode',
        classNamePrefix: 'custom-select',
        additionalProps: {
          generateClassNameOnRender: onColumnDataRender
        },
      },
      width: 270
    },
    {
      headerName: t.app_ssl_competency_text,
      field: 'subServiceLineCode',
      id: 'subServiceLineCode',
     // sortable: false,
      lockPinned: true,
      cellRenderer: 'defaultSelectCellRenderer',
      cellRendererParams: {
        data: {
          options: subServiceLines.map(item => ({
            value: item.code,
            label: item.name,
            serviceLineId: item.serviceLineId
          })) || [],
          multiple: false,
          placeholder: "N/A",
          isSelectDisabled: !adminConsoleAccess.portalAdmin,
          filterOptions: filterSubServiceLineOptions
        },
        OnValueChange: subServiceLinesChanged,
        selectValueFieldName: 'subServiceLineCode',
        classNamePrefix: 'custom-select',
        additionalProps: {
          generateClassNameOnRender: onColumnDataRender
        },
      },
      width: 300
    },
    {
      headerName: 'Has Accepted Terms',
      field: 'hasAcceptedTerms',
      id: 'hasAcceptedTerms',
      sortable: true,
      width: 250,
      suppressMovable: true,
      filter: 'customFilter',
      filterType: 'boolean'
    },
    {
      headerName: "Action",
      field: 'action',
      width: 230,
     // sortable: false,
      suppressMovable: true,
      hide: !adminConsoleAccess.portalAdmin,
      headerComponent: 'resetAllHeader'
    },
    {
      headerName: "EMG Action",
      field: 'actionEMG',
      width: 160,
     // sortable: false,
      suppressMovable: true,
      hide: !adminConsoleAccess.portalAdmin,
      headerComponent: 'resetEMGHeader'
    },
    {
      headerName: "",
      field: "",
      width: 5
    }
  ];
