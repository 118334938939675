import * as type from "../action-types";

type load = any;
export const updatefilterState = (payload: load) => ({
    type: type.UPDATE_FILTER_STATE,
    payload,
});

export const flushFilterState = () => ({
    type: type.FLUSH_FILTER_STATE
});
