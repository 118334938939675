import React from "react";
import MoreMenu from "../moreMenu/moreMenu";
import { ACTION_NAME } from "../../containers/adminRegions/adminRegionConstants";
import { refresh, trash } from "../../theme/icons";

export const MoreMenuCell = ({ disable, onActionStart }) => (
  <MoreMenu
    disabled={disable}
    controlId="more-menu-apps"
    optionClickFn={onActionStart}
    options={[
      {
        id: "update",
        isDisabled: disable,
        icon: refresh,
        actionName: ACTION_NAME.UPDATE,
        title: ACTION_NAME.UPDATE,
      },
      {
        id: "delete",
        isDisabled: disable,
        icon: trash,
        actionName: ACTION_NAME.DELETE,
        title: ACTION_NAME.DELETE,
      },
    ]}
    vertical
    leftPlacement
  />
);
