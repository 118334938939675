import { Configuration, PublicClientApplication, AccountInfo, BrowserAuthError } from "@azure/msal-browser";
import { DEFAULT_TOKEN_RENEWAL_OFFSET_SECONDS, SessionKeys } from "./Constants";

const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || '',
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
        postLogoutRedirectUri: null,
        redirectUri: process.env.REACT_APP_URL || window.location.href,
        navigateToLoginRequestUrl: true,
    },
    system: {
        loadFrameTimeout: 11000,
        tokenRenewalOffsetSeconds: parseInt(process.env.REACT_APP_TOKEN_RENEWAL_OFFSET_SECONDS ?? DEFAULT_TOKEN_RENEWAL_OFFSET_SECONDS)
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
};

let pca: PublicClientApplication | null = null;

const initializeAuthProvider = async (): Promise<PublicClientApplication> => {
    pca = new PublicClientApplication(msalConfig);
    try {
        await pca.initialize();
        await handleRedirectResponse();
        await handleLogin();
        return pca;
    } catch (error) {
        console.error("Error initializing auth provider:", error);
        throw error;
    }
};

const handleRedirectResponse = async (): Promise<void> => {
    const response = await pca?.handleRedirectPromise();
    if (response && response.account !== null) {
        const { account } = response;
        pca?.setActiveAccount(account);
    } else {
        handleSetAccount();
    }
};

const handleNullAccount = async (): Promise<AccountInfo | null> => {
    const account = handleSetAccount();
    if (account) {
        return account;
    }
    await authProvider.login();
    return pca?.getActiveAccount() || null;
};


const handleSetAccount = async (): Promise<AccountInfo | null> => {
    const currentAccounts = pca?.getAllAccounts();
    if (currentAccounts && currentAccounts?.length === 1) {
        pca?.setActiveAccount(currentAccounts[0]);
        return currentAccounts[0];
    } else if (currentAccounts && currentAccounts?.length > 1) {
        console.warn('Multiple accounts detected. Handling of multiple accounts has not been implemented.');
    }
    return null;
};

let isInteractionInProgress = false;

const handleLogin = async (): Promise<void> => {
    const accounts = pca?.getAllAccounts();
    if (accounts && accounts.length > 0) {
        pca?.setActiveAccount(accounts[0]);
        return;
    }
    if (isInteractionInProgress) {
        console.log("An interaction process is already in progress.");
        return;
    }

    try {
        isInteractionInProgress = true;
        await pca?.loginRedirect({
            scopes: [process.env.REACT_APP_AZURE_AD_APP_SCOPE || '']
        });
    } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === "interaction_in_progress") {
            console.warn("Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API.");
            return;
        }
        console.error("Login error:", error);
    } finally {
        isInteractionInProgress = false;
    }
};


export const authProvider = {
    pca,
    initializeAuthProvider,
    login: async (): Promise<void> => {
        try {
            await handleLogin();
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    },

    logout: (): void => {
        if (!pca) {
            throw new Error("AuthProvider has not been initialized.");
        }
        sessionStorage.removeItem(SessionKeys.CE_SERVER_TIME);
        pca.logoutRedirect();
    },

    getAccount: (): AccountInfo | null => {
        if (!pca) {
            throw new Error("AuthProvider has not been initialized.");
        }

        return pca.getActiveAccount() || null;
    },

    getToken: async (scope?: string): Promise<string> => {
        if (!pca) {
            throw new Error("AuthProvider has not been initialized.");
        }

        try {
            const account = pca.getActiveAccount() || await handleNullAccount();

            const effectiveScope = scope || process.env.REACT_APP_AZURE_AD_API_SCOPE || '';

            const response = await pca.acquireTokenSilent({
                scopes: [effectiveScope],             
                account: account || undefined,
                authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
                extraQueryParameters: {
                    login_hint: account?.idTokenClaims?.preferred_username || ''
                }
            });
            return response.accessToken || "";
        } catch (error) {
            console.error("Error acquiring token:", error);
            throw error;
        }
    }
};
