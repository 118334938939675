import React, { FunctionComponent, useRef, useEffect } from "react";
import ReactSelect, { Props, components as SelectComponents, createFilter } from "react-select";
import _ from "lodash";
import { AppTooltip } from "../../theme";
import "./select.scss";

interface SelectProps extends Props {
    idSuffix?: string;
    onValueChange?: Function
    onCustomMenuScroll?: Function;
    isLargeList?: boolean;
    scrollPos?: number;
    onCustomMenuClose?: Function;
}

export const Select: FunctionComponent<SelectProps> = ({
    menuPosition, menuPlacement, className, classNamePrefix, isMulti, multiValueRemove,
    placeholder, backspaceRemovesValue, isDisabled, options, defaultValue,
    onValueChange, closeMenuOnSelect, idSuffix, components, width, onCustomMenuScroll, isLargeList,
    scrollPos, onCustomMenuClose
}) => {
    const selectRef: any = useRef();

    useEffect(() => {
        const dropDownMenu = document.getElementsByClassName("custom-select-menu-list")[0];
        if(scrollPos && dropDownMenu) {
            dropDownMenu.scrollTo(0, scrollPos);
        }
    } ,[scrollPos]);

    const checkIfToolTipRequired = text => {
        const ctx = document.createElement('canvas').getContext('2d');
        const maxWidth = width * 0.8;
        if (ctx) {
            ctx.font = 'normal 15px EYInterstate-Regular';
            const textMeasurment = ctx.measureText(text);
            return textMeasurment.width >= maxWidth;
        } else if (text.length <= 27) {
            return false;
        }
    }

    const ValueContainer = props => {
        const currentValue = props.getValue() || null;
        if (currentValue) {
            let selectedLabels = "";
            let placement: any = "top";
            if (Array.isArray(currentValue) && currentValue.length > 0) {
                selectedLabels = currentValue.map(item => item.label).join(", ") || props.selectProps.placeholder;
                placement = currentValue.length > 40 ? "auto" : "top";
            } else {
                selectedLabels = currentValue.label || props.selectProps.placeholder;
            }

            const isToolTipRequired = checkIfToolTipRequired(selectedLabels);
            let labels = <div className="value-ellipses">{selectedLabels}</div>;
            if (isToolTipRequired) {
                labels = (
                    <AppTooltip
                        placement={placement}
                        className="roles-selector"
                        title={selectedLabels}
                        isWhite
                    >
                        {labels}
                    </AppTooltip>
                )
            }

            return <SelectComponents.ValueContainer {...props} className="value-container">
                {labels || null}
                {props.children}
            </SelectComponents.ValueContainer >

        } else {
            return <SelectComponents.ValueContainer {...props} className="value-container">
                {props.children}
            </SelectComponents.ValueContainer>
        }
    }

    const renderMenu = (props) => {
        if(!isLargeList) {
            return props.children
        }

        return (
            <div onScroll={handleLongListScroll}>
                {props.children}
            </div>
        )
    }

    const Menu = props => {
        return <SelectComponents.Menu {...props} className="custom-select-menu">
                    {renderMenu(props)}
                </SelectComponents.Menu>
    }

    const handleLongListScroll = () => {
        const customListScrollPos = document.getElementsByClassName("custom-select-menu-list")[0].scrollTop;
        const customListHeight = document.getElementsByClassName("custom-select-menu-list")[0].scrollHeight;
        
        onCustomMenuScroll && onCustomMenuScroll(customListScrollPos, customListHeight)
    }

    const MenuList = props => {
        return <SelectComponents.MenuList {...props} className="custom-select-menu-list">
                 {props.children}
            </SelectComponents.MenuList> 
               
    }

    const Option = props => {
        const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
        const newProps = Object.assign(_.cloneDeep(props), { innerProps: rest });
        return (
            <SelectComponents.Option
                {...newProps}
                className="custom-option"
            >
                {(props.isMulti && (
                    <div className="check-option">
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                        />
                        <span className="checkmark"></span>
                    </div>
                )) || null}
                {props.children}
            </SelectComponents.Option>
        );
    };

    const closeOnScroll = props => {
        if (selectRef.current.state.menuIsOpen &&
            (!props.target.tagName || props.target.classList.contains('ag-center-cols-viewport'))) {
            selectRef.current.setState({ ...selectRef.current.state, menuIsOpen: false });
        }
    }

    if (!components) {
        components = { ValueContainer, Menu, Option, MenuList };
        components.IndicatorSeparator = null;
    }

    const handleMenuClose = () => {
        if(!onCustomMenuClose) {
            return
        }

        onCustomMenuClose()
    }

    const styles = {
        multiValueRemove: (base) => ({ ...base, display: 'none' }),
        control: (base) => ({ ...base, borderRadius: "0", border: "0", backgroundColor: "tranparent", boxShadow: "none" }),
        menu: (base) => ({ ...base, borderRadius: "0" }),
        option: (base, state) => (
            {
                ...base,
                backgroundColor: state.isSelected ? "#e1e1e6" : null,
                color: state.isSelected ? '#000' : base.color
            }),
        menuList: (base) => ({ ...base, maxHeight: '200px', borderRadius: "0", paddingTop: 0, paddingBottom: 0 }),
        menuPortal: (base) => ({ ...base, zIndex: 10 }) 
    };

    return (
        <ReactSelect
            id={"select-" + (idSuffix || 1)}
            ref={selectRef}
            isSearchable={false}
            components={components}
            isClearable={false}
            captureMenuScroll={false}
            closeMenuOnScroll={(props: any) => {
                closeOnScroll(props);
            }}
            styles={styles}
            hideSelectedOptions={false}
            menuPlacement={menuPlacement || "auto"}
            menuPosition={menuPosition || 'fixed'}
            clearValue={() => null}
            closeMenuOnSelect={closeMenuOnSelect || false}
            ClearIndicator={() => null}
            className={`${className || "select-cell-editor"} ${(isDisabled && "disabled") || ""}`}
            classNamePrefix={`${classNamePrefix || ""}`}
            isMulti={isMulti || false}
            filterOption={createFilter({ ignoreAccents: false })}
            multiValueRemove={multiValueRemove || false}
            placeholder={placeholder || 'select'}
            backspaceRemovesValue={backspaceRemovesValue || false}
            isDisabled={isDisabled || false}
            options={options || []}
            defaultValue={defaultValue}
            onMenuClose={handleMenuClose}
            onChange={(selectedOptions) => {
                if (onValueChange) {
                    onValueChange(selectedOptions);
                }
            }}
        />
    )
};
