import * as type from "../action-types";

const initialState = {
  filterState: null,
  currentSelections: []
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
      case type.UPDATE_FILTER_STATE:
        return { ...state, filterState: action.payload}
      case type.FLUSH_FILTER_STATE:
        return { ...state, filterState: null}
    default:
      return state;
  }
};
