import React from 'react';
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IEvents } from "../../models/IAdminMaintenance";
import { AppButton, AppIcon } from "../../theme";
import { cross } from "../../theme/icons";
import t from '../../localization/en/translation.json';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./appScheduler.scss";

interface IProps {
  schedules: IEvents[];
  onAddOrUpdateEvent: Function;
  onCloseEvent: Function;
}

const AppScheduler: React.FC<IProps> = ({ schedules, onAddOrUpdateEvent, onCloseEvent }) => {
    const localizer = momentLocalizer(moment);
    const handleCloseEvent = (e, event) => {
      e.stopPropagation();
      onCloseEvent(event)
    }

    const EventComponent = (event) => { 
      return ( 
        <span className="event-component"> 
          <span className="event-title"> {event.title} </span>
          <AppButton title={t.delete} className="close-button" variant="text" onClick={(e) => handleCloseEvent(e, event)}>
                  <AppIcon icon={cross} />
          </AppButton> 
        </span> 
      ) 
    }
    const events = schedules.map((schedule, i) => {
      return {
        ...schedule,
        id: schedule.eventId,
        start: new Date(schedule.startDateTime),
        end: new Date(schedule.endDateTime),
      }
    })


return (
  <div className="app-scheduler">
  <Calendar
    events={events}
    localizer={localizer}
    components={{
      event: EventComponent
    }}
    style={{ height: 650}}
    selectable
    onSelectEvent={event => onAddOrUpdateEvent(true, event)}
    onSelectSlot={e => onAddOrUpdateEvent(false, e)}
  />
  </div>
);
  }

export default AppScheduler;