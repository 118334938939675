import React from 'react';
import './appLabelWithIcon.scss';
import { IAppLabelWithIcon } from '../../models/IAppLabelWithIcon';

const AppLabelWithIcon: React.FC<IAppLabelWithIcon> = ({
  leftIcon,
  labelText,
  handleOnClick,
  isDisabled = false,
}) => {
  return (
    <button className='label-icon-wrapper' onClick={()=>handleOnClick && handleOnClick()} disabled={isDisabled}>
      {leftIcon}
      <span>{labelText}</span>
    </button>
  );
};

export default AppLabelWithIcon;
