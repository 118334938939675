export const columnConfig = [
  {
    headerName: 'App Platform',
    field: 'appPlatformName',
    width:372,
    sortable: true
  },
  {
    headerName: 'Is Customized',
    field: 'isCustomized',
    width:330,
   // sortable: true,
    cellRenderer: 'isCustomizeCellRenderer'
  },
  {
    headerName: 'Customized By',
    field: 'customizedBy',
    width:416,
   // sortable: true,
    cellEditor: 'userSearchEditor',
    cellRenderer: 'customizeByCellRenderer',
    editable: true
  }
];
