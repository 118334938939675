import { all } from "redux-saga/effects";
import { getMeActionWatcher } from "./me-saga";
import * as metaWatchers from "./meta-saga";
import { getProjectTypesActionWatcher } from "./project-types-saga";
import { getSectorsActionWatcher } from "./sectors-saga";
import { getClientSizesActionWatcher } from "./client-sizes-saga";
import { getDataLocationsActionWatcher } from "./data-locations-saga";
import { getProjectCategoriesActionWatcher } from "./project-categories-saga";
import { getAdminProjectsActionWatcher } from "./admin-projects-saga";
import { searchProjectsActionWatcher } from "./search-projects-saga";
import { getProjectStatusActionWatcher } from "./project-status-saga";
import { postCreateProjectActionWatcher } from "./create_project-saga";
import { projectSagaWatcher } from "./project-saga";
import { getManageUserActionWatcher } from "./manage-user-saga";
import { getProjectAppsActionWatcher } from "./project-apps-saga";
import { adminReportsActionWatcher } from "./admin-reports-saga";
import { adminUsersSagaWatcher } from "./admin-users-saga";
import { getAcceptedPolicyActionWatcher } from "./accepted-policy-saga";
import { adminMainteneceSagaWatcher } from "./admin-maintenanace-saga";
import { adminGeneralSagaWatcher } from "./admin-general-saga";
import { emgPolicySagaWatcher } from "./emg-policy-saga";
import { defaultProjectSagaWatcher } from "./default-project-saga";
import { workflowsActionWatcher } from "./admin-workflows-saga";
import { getUsersActionWatcher } from './users-saga';
import { getSettingsActionWatcher, getHelpSupportActionWatcher } from "./settings-saga";

export default function* rootSaga() {
  yield all([
    getMeActionWatcher(),
    metaWatchers.getAppsActionWatcher(),
    metaWatchers.getBundlesActionWatcher(),
    metaWatchers.getAppCategoriesActionWatcher(),
    metaWatchers.getAppPlatformsActionWatcher(),
    metaWatchers.getAppPlatformRolesActionWatcher(),
    metaWatchers.getSppPlatformRolesActionWatcher(),
    metaWatchers.getProjectIconsActionWatcher(),
    metaWatchers.getServiceLinesActionWatcher(),
    metaWatchers.getSubServiceLinesActionWatcher(),
    metaWatchers.getLookupDataActionWatcher(),
    getProjectTypesActionWatcher(),
    getSectorsActionWatcher(),
    getClientSizesActionWatcher(),
    getDataLocationsActionWatcher(),
    getProjectCategoriesActionWatcher(),
    getAdminProjectsActionWatcher(),
    searchProjectsActionWatcher(),
    getProjectStatusActionWatcher(),
    postCreateProjectActionWatcher(),
    projectSagaWatcher(),
    getManageUserActionWatcher(),
    getProjectAppsActionWatcher(),
    adminReportsActionWatcher(),
    adminUsersSagaWatcher(),
    getAcceptedPolicyActionWatcher(),
    adminMainteneceSagaWatcher(),
    adminGeneralSagaWatcher(),
    emgPolicySagaWatcher(),
    defaultProjectSagaWatcher(),
    workflowsActionWatcher(),
    getUsersActionWatcher(),
    getSettingsActionWatcher(),
    getHelpSupportActionWatcher()
  ]);
}