import { styled } from "@material-ui/core";
import t from "../../../localization/en/translation.json";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/styles";

const MemberInfo = styled((props) => (
  <div {...props} id={"portal-member-display"}>
    {props.tooltiptext
      ? props.tooltiptext
      : t.portal_communication_members_info}
  </div>
))({
  visibility: "collapse",
  fontSize: "0.8rem",
  display: "flex",
  justifyContent: "center",
  background: "#000",
  textAlign: "center",
  marginLeft: 12,
  marginRight: 12,
  width: "100%",
  color: "#fff",
});
type TooltipInfoText = {
  tooltiptext?: string;
  showText:boolean,
  showIcon:boolean
};
export const MemberDisplay = ({ tooltiptext,showText,showIcon }: TooltipInfoText,) => (
  <>
    <MemberInfo tooltiptext={tooltiptext} />
    <div
      className={`app-col-xs-1 ${useFlexRowStyle().root} ${
        useFlexVcenteredStyle().root
      }`}
      style={{ height: 30, paddingBottom: 8 }}
    >
      {showText?<h3>Display</h3>:null}
      {showIcon?
      <InfoIcon
        onMouseEnter={() =>
          ((
            document.getElementById("portal-member-display") ??
            ({} as { style: { visibility: string } })
          ).style.visibility = "visible")
        }
        onMouseLeave={() =>
          ((
            document.getElementById("portal-member-display") ??
            ({} as { style: { visibility: string } })
          ).style.visibility = "hidden")
        }
      />
      :null}
    </div>
  </>
);
export const useFlexRowStyle = makeStyles({
  root: { display: "flex", flexDirection: "row" },
});
export const useFlexVcenteredStyle = makeStyles({
  root: { alignItems: "center" },
});
export const use100PerWidthStyle = makeStyles({ root: { width: "100%" } });
export const useBoxShadowBottomDividerStyle = makeStyles({
  root: { boxShadow: "rgb(206 206 206) 1px 7px 1px -6px" },
});
export const useFlexGrowTo1Style = makeStyles({ root: { flexGrow: 1 } });
export const useMaxWidthTo100Perc = makeStyles({ root: { maxWidth: "100%" } });
export const usePaddingTop10pxStyle = makeStyles({ root: { paddingTop: 10 } });
export const usePaddingTop20pxStyle = makeStyles({ root: { paddingTop: 20 } });
export const useFlexColumnStyle = makeStyles({
  root: { display: "flex", flexDirection: "column" },
});
export const useFlexJConententSpaceBtwnStyle = makeStyles({
  root: { justifyContent: "space-between" },
});
export const useFlexJConententStartStyle = makeStyles({
  root: { justifyContent: "start" },
});
export const useHeight30pxStyle = makeStyles({ root: { height: 30 } });
export const usePaddingBottom8pxStyle = makeStyles({
  root: { paddingBottom: 8 },
});
