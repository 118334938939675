import * as type from "../action-types";

const initialState = {
  addAdminWorkflowsData: {},
  updateAdminWorkflowsData: {},
  deleteAdminWorkflowsData: {},
  adminWorkflowsData: [],
  loading: false,
  paginationDetails: null,
  searchLoader: false,
  searchData: [],
  adminCommTemplateCategoryData: {},
  adminCommTemplatesData: [],
  adminWorkflowScheduleData: [],
  adminWorkflowProjectData: {},
  workflowTooltipsData: [] 
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_CPS_WORKFLOWS:
      return { ...state, loading: true };
    case type.GET_CPS_WORKFLOWS_SUCCESS:
      return {
        ...state,
        adminWorkflowsData: action.payload.records,
        paginationDetails: action.payload.pagination,
        loading: false,
      };
    case type.GET_CPS_WORKFLOWS_FAILURE:
      return { ...state, adminWorkflowsData: [], loading: false };
    case type.SEARCH_CPS_WORKFLOWS_REQUEST:
      return { ...state, searchLoader: true, loading: false };
    case type.SEARCH_CPS_WORKFLOWS_SUCCESS:
      return {
        ...state,
        searchData: action.payload.data.records,
        searchLoader: false,
        loading: false,
      };
    case type.ADD_CPS_WORKFLOWS_REQUEST:
      return { ...state, updateAdminWorkflowsData: {}, loading: true };
    case type.ADD_CPS_WORKFLOWS_SUCCESS:
      return {
        ...state,
        addAdminWorkflowsData: action.payload,
        loading: false,
      };
    case type.ADD_CPS_WORKFLOWS_FAILURE:
      return { ...state, addAdminWorkflowsData: { success: false }, loading: false };
    case type.UPDATE_CPS_WORKFLOWS_REQUEST:
      return {
        ...state,
        addAdminWorkflowsData: {},
        updateAdminWorkflowsData: {},
        loading: true,
      };
    case type.UPDATE_CPS_WORKFLOWS_SUCCESS:
      return {
        ...state,
        updateAdminWorkflowsData: action.payload,
        loading: false,
      };
    case type.UPDATE_CPS_WORKFLOWS_FAILURE:
      return { ...state, updateAdminWorkflowsData: { success: false }, loading: false };
    case type.DELETE_CPS_WORKFLOWS_REQUEST:
      return { ...state, loading: true };
    case type.DELETE_CPS_WORKFLOWS_SUCCESS:
      return {
        ...state,
        deleteAdminWorkflowsData: action.payload,
        loading: false,
      };
    case type.DELETE_CPS_WORKFLOWS_FAILURE:
      return {
        ...state,
        deleteAdminWorkflowsData: { error: true, ...action.payload },
        loading: false,
      };
    case type.FLUSH_DELETE_CPS_WORKFLOWS_DATA:
      return {
        ...state,
        deleteAdminWorkflowsData: {},
        adminWorkflowsData: [],
        paginationDetails: null,
        loading: false,
      };
    case type.SEARCH_CPS_WORKFLOWS_FAILURE:
    case type.FLUSH_SEARCH_CPS_WORKFLOWS:
      return { ...state, searchData: [], searchLoader: false, loading: false };
    case type.FLUSH_CPS_WORKFLOWS_DATA:
      return {
        ...state,
        adminWorkflowsData: [],
        paginationDetails: null,
        addAdminWorkflowsData: {},
        updateAdminWorkflowsData: {},
        deleteAdminWorkflowsData: {},
        searchData: [],
      };
    case type.GET_CPS_COMM_TEMPLATE_CATEGORY:
      return { ...state, loading: true };
    case type.GET_CPS_COMM_TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        adminCommTemplateCategoryData: action.payload.records,
        loading: false,
      };
    case type.GET_CPS_COMM_TEMPLATE_CATEGORY_FAILURE:
      return { ...state, adminCommTemplateCategoryData: [], loading: false };
    case type.FLUSH_CPS_COMM_TEMPLATE_CATEGORY_DATA:
      return {
        ...state,
        adminCommTemplateCategoryData: []
      };
    case type.GET_CPS_WORKFLOW_DEFINITIONS:
      return { ...state, loading: true };
    case type.GET_CPS_WORKFLOW_DEFINITIONS_SUCCESS:
      return {
        ...state,
        adminWorkflowDefinitionsData: action.payload.records,
        loading: false,
      };
    case type.GET_CPS_WORKFLOW_DEFINITIONS_FAILURE:
      return { ...state, adminWorkflowDefinitionsData: [], loading: false };

    case type.FLUSH_CPS_WORKFLOW_DEFINITIONS_DATA:
      return {
        ...state,
        adminWorkflowDefinitionsData: []
      };
    case type.GET_CPS_COMM_TEMPLATES:
      return { ...state, loading: true };
    case type.GET_CPS_COMM_TEMPLATES_SUCCESS:
      return {
        ...state,
        adminCommTemplatesData: action.payload.records,
        loading: false,
      };
    case type.GET_CPS_COMM_TEMPLATES_FAILURE:
      return { ...state, adminCommTemplatesData: [], loading: false };

    case type.FLUSH_CPS_COMM_TEMPLATES_DATA:
      return {
        ...state,
        adminCommTemplatesData: []
      };
    case type.GET_CPS_WORKFLOW_SCHEDULE:
      return { ...state, loading: true };
    case type.GET_CPS_WORKFLOW_SCHEDULE_SUCCESS:
      return {
        ...state,
        adminWorkflowScheduleData: action.payload.records,
        loading: false,
      };
    case type.GET_CPS_WORKFLOW_SCHEDULE_FAILURE:
      return { ...state, adminWorkflowScheduleData: [], loading: false };
    case type.FLUSH_CPS_WORKFLOW_SCHEDULE_DATA:
      return {
        ...state,
        adminWorkflowScheduleData: []
      };
    case type.GET_CPS_WORKFLOW_PROJECT:
      return { ...state, loading: true };
    case type.GET_CPS_WORKFLOW_PROJECT_SUCCESS:
      return {
        ...state,
        adminWorkflowProjectData: action.payload,
        loading: false,
      };
    case type.GET_CPS_WORKFLOW_PROJECT_FAILURE:
      return { ...state, adminWorkflowProjectData: [], loading: false };

    case type.FLUSH_CPS_WORKFLOW_PROJECT_DATA:
      return {
        ...state,
        adminWorkflowProjectData: {}
      };
    case type.START_CPS_WORKFLOW:
      return { ...state, loading: true };
    case type.START_CPS_WORKFLOW_SUCCESS:
      return {
        ...state,
        adminStartWorkflowData: action.payload,
        loading: false,
      };
    case type.START_CPS_WORKFLOW_FAILURE:
      return { ...state, adminStartWorkflowData: { success: false, ...action.payload }, loading: false };
    case type.FLUSH_START_CPS_WORKFLOW_DATA:
      return {
        ...state,
        adminStartWorkflowData: {}
      };
    case type.GET_WORKFLOW_TOOLTIPS:
      return { ...state, loading: true };
    case type.GET_WORKFLOW_TOOLTIPS_SUCCESS:
      return {
        ...state,
        workflowTooltipsData: action.payload?.data,
        loading: false,
      };
    case type.GET_WORKFLOW_TOOLTIPS_FAILURE:
      return { ...state, workflowTooltipsData: [], loading: false };
    default:
      return state;
  }
};
