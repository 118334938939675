import { SurveyTableFields } from "./adminSurveysConstants";
import t from "../../../localization/en/translation.json";


const userTypeRenderer = ({ value, data }) => {
  return value ? value.toString().replace(",", ", ") : "";
};

export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.admin_survey_table_header_name_survey_name,
    field: SurveyTableFields.name,
    width: 200,
    wrapText: true
  },
  {
    headerName: t.admin_survey_table_header_name_fy_year_quarter,
    field: SurveyTableFields.fiscalYearQuarterDescription,
    width: 200,
    wrapText: true,
  },
  {
    headerName: t.admin_survey_table_header_name_survey_url,
    field: SurveyTableFields.url,
    width: 400,
    sortable: false,
    wrapText: true
  },
  {
    headerName: t.admin_survey_table_header_name_user_types,
    field: SurveyTableFields.userTypes,
    width: 200,
    sortable: false,
    wrapText: true,
    cellRenderer: userTypeRenderer,
  },

  {
    headerName: t.admin_survey_table_header_name_modified_on,
    field: SurveyTableFields.modifiedOn,
    sortable: false,
    width: 250,
    wrapText: true,
    cellRenderer: "dateCellRender",
  },
  {
    headerName: t.admin_survey_table_header_name_modified_by,
    field: SurveyTableFields.modifiedByEmailAddress,
    width: 250,
    wrapText: true,
    cellRenderer: "renderEmailCell",
  },
  {
    headerName: t.admin_survey_table_header_name_created_on,
    field: SurveyTableFields.createdOn,
    width: 250,
    wrapText: true,
    cellRenderer: "dateCellRender",
  },
  {
    headerName: t.admin_survey_table_header_name_created_by,
    field: SurveyTableFields.createdByEmailAddress,
    width: 320,
    wrapText: true,
    cellRenderer: "renderEmailCell",
  },
];
