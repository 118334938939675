import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import eyLogoWithCE from "../../assets/images/Logo.svg";
import en_translation from "../../localization/en/translation.json";
import DropDownMenu, { IDropDownProps } from "../dropDownMenu/dropDownMenu";
import GetHelp from "../getHelp/getHelp";
import { getMe } from "../../store/actions/user-actions";
import { IAccessRight, IUser } from "../../models/user";
import { ISSPPlatformRole } from "../../models/IRoles";
import { authProvider } from "../../utils/authProvider";
import * as HeaderConstants from "./headerConstant";
import "./header.scss";
import NotificationBell from "../notificationBell";
import { UserTypes } from "../../utils/Constants";
import { GET_ALL_ACTIVE_NOTIFICATIONS_ACTION, getUserProfilePhoto } from "../../store/actions/me-actions";
import { INotification } from "../../models/INotification";
import { AppIcon } from "../../theme";
import { user, logout, settings } from "../../theme/icons";
import serverConnection from "../../assets/images/icons/server-connection.svg"

interface IHeaderProps {
  getCurrentUser: () => any;
  getUserProfilePhoto: () => any;
  getActiveNotification: () => any;
  currentUser: IUser;
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  userPhoto: string;
  notification: INotification;
}

class Header extends Component<IHeaderProps> {
  userProfileMenuOptions: IDropDownProps;

  constructor(props: IHeaderProps) {
    super(props);

    this.userProfileMenuOptions = {
      controlId: "user-drop-down-menu",
      menuOptions: [],
      onOptionClick: this.onOptionClick,
    };
  }

  componentDidMount() {
    const { getCurrentUser, currentUser, getUserProfilePhoto, userPhoto, getActiveNotification } = this.props;

    !currentUser.givenName && getCurrentUser();
    !userPhoto && getUserProfilePhoto();
    getActiveNotification();
  }

  userDisplayNameHTML = () => {
    const { currentUser, userPhoto } = this.props;
    return (
      <div className="user-info-menu-container">
        <div className="user-avatar image-cropper">
          {
            userPhoto ? <img
              src={"data:image/png;base64," + userPhoto}
              className="rounded user-avatar-icon"
              alt="user-avatar"
              title={currentUser?.givenName}
            /> :
              <div className="user-avatar-icon">
                <AppIcon icon={user} />
              </div>
          }
        </div>
        <div>
          <div className="user-name"> {currentUser?.givenName}&nbsp;{currentUser?.surname} </div>
          <div className="color-grey"> {currentUser?.mail} </div>
        </div>
      </div>
    );
  };

  ipAssetManagerHtml = () => {
    return (
      <div>
        <span className="menu-item-label">
          <img src={serverConnection} className="menu-icon" alt={en_translation.header_ip_asset_manager} />
          {en_translation.header_ip_asset_manager}
        </span>
      </div>
    );
  };

  userLogOutHTML = () => {
    return (
      <div>
        <span className="menu-item-label">
          <AppIcon icon={logout} className="menu-icon" />
          {en_translation.header_user_logout}
        </span>
      </div>
    );
  };

  userAdminConsoleHTML = () => {
    return (
      <div>
        <span className="menu-item-label">
          <AppIcon icon={settings} className="menu-icon" />
          {en_translation.header_admin_console}
        </span>
      </div>
    );
  };

  handleAdminConsoleClick = () => {
    window.open(`${process.env.REACT_APP_URL}/admin/projects`, "_blank");
  };

  handleIpAssetManagerClick = () => {
    window.open(`${process.env.REACT_APP_IP_ASSET_MANAGER_URL}`, "_blank");
  };

  onLogOutClick = () => {
    authProvider.logout();
  };

  setUserProfileDropDownMenuItems = () => {
    const { currentUser, accessRight, sspRoles, userPhoto } = this.props;
    this.userProfileMenuOptions.controlTitle = currentUser?.givenName;
    this.userProfileMenuOptions.menuOptions.length = 0;
    userPhoto && (this.userProfileMenuOptions.imageData = userPhoto);

    this.userProfileMenuOptions.menuOptions.push(
      {
        id: "user-profile-menu-id",
        titleHTML: this.userDisplayNameHTML,
        actionName: HeaderConstants.PROFILE_ACTION,
        title: "",
      },
      {
        id: "user-log-out-id",
        titleHTML: this.userLogOutHTML,
        actionName: HeaderConstants.LOGOUT_ACTION,
        title: "",
      }
    );

    if (accessRight.portalRoles && sspRoles) {
      const portalAdminId = sspRoles.find(
        (role) => role.key === "POR-ADMIN"
      )?.id;
      const adminConsoleUserId = sspRoles.find(
        (role) => role.key === "POR-ADMIN-CONSL"
      )?.id;
      const isAdminConsoleUser = accessRight.portalRoles.find(
        (role) => role === portalAdminId || role === adminConsoleUserId
      );

      if (isAdminConsoleUser) {
        const adminConsoleOption = {
          id: "user-admin-console-id",
          titleHTML: this.userAdminConsoleHTML,
          actionName: HeaderConstants.ADMIN_CONSOLE_ACTION,
          title: "",
        };

        this.userProfileMenuOptions.menuOptions.splice(
          1,
          0,
          adminConsoleOption
        );
      }
    }

    if (
      currentUser.userType?.toLowerCase() ===
      UserTypes.Internal.toLowerCase() &&
      accessRight.hasIpAssetManagerAccess
    ) {
      const ipAssetManagerOption = {
        id: "ip-asset-manager-id",
        titleHTML: this.ipAssetManagerHtml,
        actionName: HeaderConstants.IP_ASSET_MANAGER_ACTION,
        title: "",
      };

      this.userProfileMenuOptions.menuOptions.splice(
        1,
        0,
        ipAssetManagerOption
      );
    }
  };

  tooltipHTML = () => (
    <div>
      {en_translation.header_help_contact_text} <br />
      {en_translation.header_help_contact_number}
    </div>
  );

  onOptionClick = (option) => {
    if (option === HeaderConstants.ADMIN_CONSOLE_ACTION) {
      this.handleAdminConsoleClick();
    } else if (option === HeaderConstants.IP_ASSET_MANAGER_ACTION) {
      this.handleIpAssetManagerClick();
    } else if (option === HeaderConstants.LOGOUT_ACTION) {
      this.onLogOutClick();
    }
  };

  render() {
    const { currentUser } = this.props;
    this.setUserProfileDropDownMenuItems();

    return (
      <header className="header">
        <div className="header-wrapper">
          <Link to="/" className="logo-link">
            <img src={eyLogoWithCE} className="logo" alt="logo" />
            <span className="header-title">
              {en_translation.capital_edge_platform_verbiage}
            </span>
          </Link>

        </div>
        <div className="content header-items">
          <NotificationBell />
          <div className="help-icon">
            <GetHelp currentUser={currentUser} />
          </div>
          <div className="logged-in-user-icon">
            <DropDownMenu
              controlId={this.userProfileMenuOptions.controlId}
              menuOptions={this.userProfileMenuOptions.menuOptions}
              imageData={this.userProfileMenuOptions.imageData}
              onOptionClick={this.onOptionClick}
            />
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.meReducer.currentUser,
    accessRight: state.meReducer.accessRight,
    sspRoles: state.meta.userRoles,
    userPhoto: state.meReducer.userPhoto,
    notification: state.notificationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUser: () => {
      dispatch(getMe());
    },
    getUserProfilePhoto: () => {
      dispatch(getUserProfilePhoto());
    },
    getActiveNotification: () => {
      dispatch(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);