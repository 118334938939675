import React, { Component } from "react";
import _ from "lodash";
import t from "../../../localization/en/translation.json";
import {
  getGridColumns,
  IGridColumn,
  gridAppPlatformKeys,
  USER_ACTIONS,
  UserType,
} from './listUserConstants';
import { IUser } from '../../../models/user';
import { IUserRole } from '../../../models/IUserAccess';
import { AppDetail } from '../../../components/createProject/models/appDetail';
import { AppPlatformDetail } from '../../../components/createProject/models/appPlatformDetail';
import { AppPlatformClass } from '../../../components/appCard/models/appPlatformClass';
import { IRoles } from '../../../models/IRoles';
import { getPaginationModel, IPagination } from '../../../models/IPagination';
import { lookupModel } from '../../createProject/lookupModel';
import List from '../../../components/editableGrid/list/list';
import { Select } from '../../../components/select/select';
import './listUsers.scss';
import { Project } from '../../../components/createProject/models/project';
import { SSPRolesEnum } from '../../../utils/Constants';
import { IRowNode } from 'ag-grid-community';

interface IProps {
  users: IUser[];
  userAccess: IUserRole[];
  projectAppList: AppDetail[];
  projectAppPlatformList: AppPlatformDetail[];
  appPlatformList: AppPlatformClass[];
  platformRoles: IRoles;
  updatedUserAccess: IUserRole[];
  onRoleChange: Function;
  pagination: IPagination;
  maxRecordsPerPage: number;
  onUpdateMaxRecordsPerPage: Function;
  currentPage: number;
  onPageChange: Function;
  projectStatuses: lookupModel[];
  isProjectAdmin: boolean;
  isEyUser: boolean;
  reRender?: boolean;
  setLoader: Function;
  project?: Project;
  isProjectAdminUser: boolean;
  onRowSelectChange: Function;
}

interface IState {
  columns: IGridColumn[];
  data: any;
}

class ListUsers extends Component<IProps, IState> {
  private gridApi: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      columns: [],
      data: [],
    };

    this.gridApi = null;
  }

  componentDidMount() {
    const columns = this.getListColumns();
    const { setLoader } = this.props;
    setLoader(true);
    const data = this.parseData(columns) || [];

    this.setState(
      {
        ...this.state,
        columns,
        data,
      },
      () => {
        setLoader(false);
      }
    );

    window.addEventListener("resize", () => {
      const columns = this.getListColumns();
      const data = this.parseData(columns) || [];

      this.setState({
        ...this.state,
        columns: _.isEqual(this.state.columns, columns)
          ? this.state.columns
          : columns,
        data,
      });
    });
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      projectAppList,
      appPlatformList,
      users,
      userAccess,
      isProjectAdmin,
      platformRoles,
      updatedUserAccess,
      projectStatuses,
    } = this.props;
    if (
      !_.isEqual(prevProps.projectAppList, projectAppList) ||
      !_.isEqual(prevProps.appPlatformList, appPlatformList) ||
      !_.isEqual(prevProps.projectStatuses, projectStatuses) ||
      !_.isEqual(prevProps.users, users) ||
      !_.isEqual(prevProps.userAccess, userAccess) ||
      !_.isEqual(prevProps.platformRoles, platformRoles) ||
      !_.isEqual(prevProps.updatedUserAccess, updatedUserAccess)
    ) {
      const columns = this.getListColumns();
      const { setLoader } = this.props;
      setLoader(true);
      const data = this.parseData(columns) || [];

      this.setState(
        {
          ...this.state,
          columns: _.isEqual(this.state.columns, columns)
            ? this.state.columns
            : columns,
          data,
        },
        () => {
          setLoader(false);
        }
      );
    }

    if (
      (prevProps.isProjectAdmin !== isProjectAdmin && isProjectAdmin) ||
      prevProps.userAccess !== userAccess
    ) {
      this.gridApi?.redrawRows();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      return false;
    });
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.reRender;
  }

  getListColumns = () => {
    const {
      isProjectAdmin,
      isEyUser,
      isProjectAdminUser,
      projectAppList,
      appPlatformList,
      projectStatuses,
      projectAppPlatformList,
      project,
    } = this.props;

    return (
      getGridColumns(
        projectAppList,
        appPlatformList,
        projectStatuses,
        projectAppPlatformList,
        isProjectAdmin,
        isProjectAdminUser,
        isEyUser,
        project,
        this.onPlatformRoleChange,
        this.filterPlatformRoleOptions,
        this.renderSspRoleDropdown,
        this.renderPlatformHeader
      ) || []
    );
  };

  onPlatformRoleChange = (selectedOptions, user, { id, multiSelect }) => {
    const { onRoleChange } = this.props;
    if (onRoleChange) {
      onRoleChange(
        user.id,
        id,
        multiSelect
          ? selectedOptions.map((item) => item.value)
          : selectedOptions.value || ""
      );
    }
  };

  filterPlatformRoleOptions = (user, options, { key, multiSelect }) => {
    const { platformRoles } = this.props;

    const selectedPlatformRoles = platformRoles.appPlatforms.find(
      (item) => item?.appPlatformKey === key
    );

    let selectedRolesOptions =
      selectedPlatformRoles?.roles?.filter((item) => item.name) || [];

    if (key === gridAppPlatformKeys.WRIKE) {
      if (user?.userType?.toLowerCase() === UserType.Internal) {
        selectedRolesOptions = selectedRolesOptions.filter(
          (item) => !item.name.toLocaleLowerCase().includes("client")
        );
      } else if (user?.userType?.toLowerCase() === UserType.External) {
        selectedRolesOptions = selectedRolesOptions.filter((item) =>
          item.name.toLocaleLowerCase().includes("client")
        );
      }
    }

    let rolesOptions = [
      {
        id: "",
        name: t.manage_users_role_placeholder,
      },
      ...selectedRolesOptions,
    ];

    if (multiSelect) {
      rolesOptions = [...selectedRolesOptions];
    }

    return rolesOptions.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  onSspRoleChanged = (selectedOption, user) => {
    const { onRoleChange } = this.props;
    if (onRoleChange) {
      onRoleChange(
        user.id,
        gridAppPlatformKeys.SSP,
        selectedOption.value || ""
      );
    }
  };
  getProjectAdminId() {
    const { platformRoles } = this.props;
    let sspRoles = platformRoles.ssp.roles || [];

    return sspRoles.find((x) => x.key === SSPRolesEnum.PRJADMIN)?.id;
  }
  filterSspRoleOptions = (user, selectedValue) => {
    const { platformRoles } = this.props;
    let sspRoles = platformRoles.ssp.roles || [];
    //if user is external user remove project admin from role options
    const checkForExternalUser = (item) =>
      user?.userType !== "External" || item?.key !== SSPRolesEnum.PRJADMIN;
    const checkDropdowncond = (item) => {
      if (selectedValue === this.getProjectAdminId()) {
        //if selected value is ProjectAdmin
        if (checkForExternalUser(item)) {
          //check if user is external user then remove ProjectAdmin from dropdown
          return item;
        }
      } else if (
        !(this.props.isProjectAdminUser && item.key === SSPRolesEnum.PRJADMIN)
      ) {
        //if not projectAdminUser, check if external user have correct dropdown
        if (checkForExternalUser(item)) {
          return item;
        }
      }
    };
    return sspRoles
      .filter((item) => checkDropdowncond(item))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  };

  private parseData = (columns: IGridColumn[]) => {
    const {
      isProjectAdmin,
      users,
      userAccess,
      platformRoles,
      updatedUserAccess,
      isProjectAdminUser,
    } = this.props;

    const projectPlatforms = columns.filter(
      (item) => item.field !== "userDetails" && item.field !== "actions"
    );

    if (!platformRoles || platformRoles.ssp.roles.length === 0) {
      return [];
    }

    return (
      users?.map((user) => {
        const currentUserUpdatedAccess = updatedUserAccess.find(
          (item) => item.userId === user.id
        );
        const currentUserAccess = userAccess.find(
          (item) => item.userId === user.id
        );
        const isRemoved =
          currentUserUpdatedAccess?.action === USER_ACTIONS.DELETE;
        let userSSpRoles = "";

        if (currentUserUpdatedAccess && currentUserUpdatedAccess.ssp) {
          userSSpRoles =
            (currentUserUpdatedAccess?.ssp?.newRoles &&
              Array.isArray(currentUserUpdatedAccess?.ssp?.newRoles) &&
              currentUserUpdatedAccess?.ssp?.newRoles[0]) ||
            "";
        }
        if (currentUserAccess && currentUserAccess.ssp && !userSSpRoles) {
          userSSpRoles =
            (currentUserAccess?.ssp?.roles &&
              Array.isArray(currentUserAccess?.ssp?.roles) &&
              currentUserAccess?.ssp?.roles[0]) ||
            "";
        }

        const sspRoles = platformRoles.ssp.roles || [];
        let sspUserRole = "";
        if (sspRoles.length > 0) {
          const sspUserRoleRecord = sspRoles.find(
            (item) => item.name.toLocaleLowerCase().indexOf("user") > -1
          );
          if (sspUserRoleRecord) {
            sspUserRole = sspUserRoleRecord.id;
          }
        }

        const platformSelectedRoles = {};
        projectPlatforms
          .filter(
            (item) =>
              item.cellRenderer === "defaultSelectCellRenderer" &&
              item.field !== gridAppPlatformKeys.SSP
          )
          .forEach((projectPlatform) => {
            let userPlatformRoles: Array<string> | string = "";

            if (
              currentUserUpdatedAccess &&
              currentUserUpdatedAccess.appPlatforms
            ) {
              const currentAppPlatform =
                currentUserUpdatedAccess.appPlatforms.find(
                  (item) => item.appPlatformId === projectPlatform.id
                );
              userPlatformRoles = currentAppPlatform?.newRoles || "";
            }

            if (
              currentUserAccess &&
              currentUserAccess.appPlatforms &&
              !userPlatformRoles
            ) {
              const currentAppPlatform = currentUserAccess.appPlatforms.find(
                (item) => item.appPlatformId === projectPlatform.id
              );
              userPlatformRoles = currentAppPlatform?.roles || [];
            }

            if (
              !projectPlatform.multiSelect &&
              Array.isArray(userPlatformRoles)
            ) {
              userPlatformRoles = userPlatformRoles.join(",");
            }

            platformSelectedRoles[projectPlatform.field] = userPlatformRoles;
          });

        const actionTaken = currentUserUpdatedAccess?.action || 0;
        const disbaleAction = () => {
          return (
            (isRemoved ||
              (userSSpRoles === this.getProjectAdminId() &&
                isProjectAdminUser) ||
              (!isProjectAdmin && !isProjectAdminUser)) &&
            "disabled"
          );
        };
        return {
          id: user.id,
          actionTaken: actionTaken,
          isSelectDisabled: actionTaken === USER_ACTIONS.DELETE,
          userType: user.userType,
          accountType: user.accountType,
          ssp: userSSpRoles || sspUserRole,
          totalRows: (users.length && users.length - 1) || 0,
          minimumRows: 2,
          actions: disbaleAction(),
          userDetails: user,
          ...platformSelectedRoles,
        };
      }) || []
    );
  };

  setItemsPerPage = (count: number) => {
    const { onUpdateMaxRecordsPerPage } = this.props;
    onUpdateMaxRecordsPerPage(count);
  };

  OnGridReady = (params) => {
    this.gridApi = params.api;
  };

  renderPlatformHeader = ({ isAppPlatformActive, header, headerAppsCount }) => {
    return (
      <div className={(!isAppPlatformActive && "hdr disabled") || "hdr"}>
        <div className="hdr-top">
          {header}
          {headerAppsCount}
        </div>
        {!isAppPlatformActive && <div className="app-status">{t.pending}</div>}
      </div>
    );
  };

  renderSspRoleDropdown = ({ value, data }) => {
    const selectOptions = this.filterSspRoleOptions(data, value);
    return (
      <Select
        options={selectOptions}
        defaultValue={selectOptions.find((item) => item.value === value)}
        closeMenuOnSelect
        isDisabled={this.disableProjectDropDown(value)}
        width={250}
        placeholder="None"
        onValueChange={(option) => this.onSspRoleChanged(option, data)}
      />
    );
  };

  disableProjectDropDown = (value) => {
    const { isProjectAdmin, isProjectAdminUser } = this.props;
    return value === this.getProjectAdminId() && isProjectAdminUser
      ? true
      : !isProjectAdmin && !isProjectAdminUser;
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    this.props.onRowSelectChange(selectedRows);
  };

  render() {
    const { pagination, maxRecordsPerPage, currentPage, onPageChange } =
      this.props;
    const { columns = [], data = [] } = this.state;

    return (
      <div className="manage-users-grid">
        <List
          rowHeight={72}
          firstColumnBorderRight={true}
          withStickyScroll
          columnConfig={columns}
          defaultColDef={defaultColDef}
          onGridReady={this.OnGridReady}
          rowData={data}
          deltaRowDataMode={true}
          paginate={true}
          currentPage={currentPage}
          pagination={getPaginationModel({
            pageNumber: currentPage,
            pageSize: maxRecordsPerPage,
            totalRecords: pagination?.totalRecords || 0,
          })}
          setItemsPerPage={this.setItemsPerPage}
          onPageChange={onPageChange}
          frameworkComponents={{
            platformHeader: this.renderPlatformHeader,
            renderSspRoleDropdown: this.renderSspRoleDropdown,
          }}
          rowSelection="multiple"
          gridOptions={{
            suppressRowClickSelection: true,
            headerHeight: 44,
            isRowSelectable: (params: IRowNode) => {
              return !params.data.actions;
            },
          }}
          onSelectionChanged={this.onSelectionChanged}
        />
      </div>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};

export default ListUsers;
