import React from "react";
import { connect } from "react-redux";
import { PowerBIEmbed, EmbedType } from "powerbi-client-react";
import { models } from "powerbi-client";
import { IAdminReport } from "../../../models/IAdminReport";
import { IAdminReports } from "../../../models/IAdminReports";
import { getPowerBiToken } from "../../../store/actions/admin-reports-actions";

type IProps = {
    report: IAdminReport;
    adminReports: IAdminReports;
    getPowerBiToken: Function;
}

interface IState {
    isTokenRefreshed: boolean
}

class ReportVisualization extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isTokenRefreshed: false
        };
    }

    componentDidMount() {
        const { getPowerBiToken, report } = this.props;
        getPowerBiToken(report);
    }

    componentDidUpdate(prevProps: IProps) {
        const { adminReports } = this.props;
        if (!adminReports.loading &&
            prevProps.adminReports.loading &&
            !adminReports.error &&
            adminReports.action === "TOKEN" && adminReports.accessToken.token) {
            this.setState({
                isTokenRefreshed: true
            });
        }
    }

    render() {

        const { report, adminReports } = this.props;
        const { isTokenRefreshed } = this.state;

        return (isTokenRefreshed && (
            <div id="powerBiAdminReport">
                <PowerBIEmbed
                    embedConfig={{
                        type: EmbedType.Report,
                        id: report.reportId,
                        embedUrl: report.reportEmbedUrl,
                        accessToken: adminReports.accessToken.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                        }
                    }}

                    cssClassName={"admin-report-power-bi-embed"}
                />
            </div>
        )) || null
    }
}

const mapStateToProps = ({ meReducer, meta, adminReports }) => {
    return {
        adminReports
    };
};

const mapDispatchToProps = () => ({
    getPowerBiToken
});

export default connect(mapStateToProps, mapDispatchToProps())(ReportVisualization);
