import React, { FC, useState, useEffect, useCallback } from 'react';
import CustomizeBundle from './customizeBundle/customizeBundle';
import CustomizeApplications from './customizeApplications/customizeApplications';
import CustomizeBundleDetails from './customizeBundleDetails/customizeBundleDetails';
import { PreviewCardModel } from '../../components/previewCard/previewCardModel';
import { AppClass } from '../../components/appCard/models/appClass';
import { BundleClass } from '../../components/bundleCard/models/bundleClass';
import { AppCategory } from '../appCard/models/appCategory';
import { gridAppPlatformKeys } from "../../containers/manageUsers/listUsers/listUserConstants";
import './customizeBundleContainer.scss';

interface IProps {
  bundleOverview: PreviewCardModel;
  apps: AppClass[];
  appCards: AppClass[];
  bundleCard: BundleClass;
  selectedAppCard: AppClass | null;
  appCategories: AppCategory[];
  selectedAppCategory: AppCategory | null;
  onAppToggleClick: Function,
  selectedAppCardHandler: Function;
  removeAppCardHandler: Function;
  addAppHandler: Function,
  isProjectDrafted: boolean,
  createProjectEnabled: boolean
}

const CustomizeBundleContainer: FC<IProps> = (props) => {
  const [selectedApps, setSelectedApps] = useState<AppClass[]>([]);
  const {
    appCards,
    bundleOverview,
    bundleCard,
    appCategories,
    selectedAppCard,
    selectedAppCategory,
    selectedAppCardHandler,
    removeAppCardHandler,
    onAppToggleClick,
    addAppHandler
  } = props;

  const getSelectedApps = useCallback(() => {
    const selectedApps: AppClass[] = [];
    const { apps, bundleCard } = props;

    bundleCard.selectedAppIds?.forEach(item => {
      const selectedApp = apps.find(elm => elm.id === item.id);
      // as per business requirement active SharePoint can't removed after project creation is initialized
      if (selectedApp?.key === gridAppPlatformKeys.SHARE_POINT && !props.isProjectDrafted && !props.createProjectEnabled) {
        selectedApp.isRemoveDisabled = true;
      }
      selectedApp && selectedApps.push(selectedApp);
    })
    return selectedApps;
  }, [props]);

  useEffect(() => {
    setSelectedApps(getSelectedApps())
  }, [getSelectedApps]);

  return (
    <div className="customize-bundle-container">
      <div className="app-row margin-right-zero customize-bundle-div">
        <div className="app-col-xs-4 app-col-lg-7 app-col-xl-6">
          <div className="app-row full-height">
            <div className="app-col-xs-4 app-col-lg-6 cust-bundle-bg">
              {bundleOverview && bundleCard ?
                <CustomizeBundle
                  appCards={appCards}
                  appCategories={appCategories}
                  bundleCard={bundleCard}
                  onAppToggleClick={onAppToggleClick} /> : null}
            </div>
            <div className="app-col-xs-4 app-col-lg-6 cust-app-bg">
              <CustomizeApplications
                appCards={selectedApps}
                appsCount={selectedApps.length}
                appCategories={appCategories}
                selectedAppCardHandler={selectedAppCardHandler}
                appCardId={selectedAppCard ? selectedAppCard.id : null}
                removeAppCardHandler={removeAppCardHandler}
                addAppHandler={addAppHandler}
              />
            </div>
          </div>
        </div>
        <div className="app-col-xs-4 app-col-lg-5 app-col-xl-6 cust-app-bg cust-details">
          {
            (selectedAppCard && selectedAppCategory && selectedApps.length) &&
            <CustomizeBundleDetails appCard={selectedAppCard} appCategory={selectedAppCategory} />
          }
        </div>
      </div>
    </div>
  )
}

export default CustomizeBundleContainer;
