import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import themeConfig from "../config";
import './appButton.scss';

const containedPrimaryButtonHover = {
    '&:hover': {
        backgroundColor: themeConfig.buttonPrimaryHoverBgColor,
        borderColor: themeConfig.buttonBorderBgColor,
        '&$disabled': {
            backgroundColor: themeConfig.buttonDisabledBgColor,
            color: themeConfig.buttonDisabledColor,
        }
    },
    '&$disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        backgroundColor: themeConfig.buttonDisabledBgColor,
        color: themeConfig.buttonDisabledColor,
    }
};

const containedSecondaryButtonHover = {
    '&:hover': {
        backgroundColor: themeConfig.buttonSecondaryHoverBgColor,
        borderColor: themeConfig.buttonBorderBgColor,
        '&$disabled': {
            backgroundColor: themeConfig.buttonDisabledBgColor,
            color: themeConfig.buttonDisabledColor,
        }
    },
    '&$disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        backgroundColor: themeConfig.buttonDisabledBgColor,
        color: themeConfig.buttonDisabledColor,
    }
};

const useStyle = makeStyles({
    root: {
        textTransform: 'none',
    },
    text: {
        color: themeConfig.textButtonColor,
        paddingLeft: '4px',
        fontSize: themeConfig.fontSize14,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
        '&.text-underline': {
            textDecoration: 'underline',
        }
    },
    containedPrimary: {
        color: themeConfig.white,
        backgroundColor: themeConfig.buttonPrimaryBgColor,
        borderRadius: 4,
        fontFamily: themeConfig.fontFamilyRegular,
        border: '1px solid transparent',
        ...containedPrimaryButtonHover
    },
    containedSecondary: {
        backgroundColor: themeConfig.white,
        color: themeConfig.buttonSecondaryColor,
        border: `1px solid ${themeConfig.buttonBorderBgColor}`,
        borderRadius: 4,
        textTransform: 'none',
        fontFamily: themeConfig.fontFamilyRegular,
        ...containedSecondaryButtonHover
    },
    containedSizeLarge: {
        padding: '13px 35px',
    },
    label: {},
    disabled: {}
})


const AppButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
    const classes = useStyle()
    return (
        <Button
            {...rest}
            variant={rest.variant || "contained"}
            disableElevation
            classes={{
                root: classes.root,
                text: classes.text,
                contained: classes.containedPrimary,
                containedSecondary: classes.containedSecondary,
                containedSizeLarge: classes.containedSizeLarge,
                disabled: classes.disabled,
            }}
            className={`app-button ${rest.className || ""}`}
        >
            {children}
        </Button>
    );
}

export default AppButton;