export const MIN_PROJECT_NAME_LENGTH = 3;
export const PAGE_SIZE = 50;
export const APPROVER_SORT_COLUMN = "displayName";

export enum FormFields {
    projectName = "projectName",
    projectType = "projectType",
    projectClientName = "projectClientName",
    projectSector = "projectSector",
    projectServiceLine = "projectServiceLine",
    projectSize = "projectSize",
    projectLocation = "projectLocation",
    projectDesc = "projectDesc",
    projectMFAEnabled = "projectMFAEnabled",
    projectConfidential = "projectConfidential",
    projectRegion = "projectRegion",
    projectCategory = "projectCategory",
    isPricingIncluded = "isPricingIncluded",
    iconId = "Project Icon",
    area = "area",
    region = "region",
    country = "country",
    serviceLineId = "serviceLineId",
  }