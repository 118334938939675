import React, { FC } from 'react';
import TextField,{TextareaAutosizeProps} from '@material-ui/core/TextareaAutosize';
import "./appTextArea.scss"

interface IProps {
    onChange?: TextareaAutosizeProps['onChange'];
    disabled?: boolean;
    rows?: number;
    name?: string;
    maxLength?: number;
    placeholder?: string;
    value?: string;
    autoComplete?: string;
    isValid?: boolean;
}

const AppTextArea: FC<IProps> = ({ maxLength = 5000, rows, isValid, autoComplete, ...restProps }) => {
  return (
    <TextField
      className={`app-text-area`}
      maxLength={maxLength}
      autoComplete={autoComplete || "off"}
      rows={rows || 2}
      {...restProps}
    />
  );
}

export default AppTextArea