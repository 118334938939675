import * as type from "../action-types";
import { AppClass } from "../../components/appCard/models/appClass";
import { BundleClass } from "../../components/bundleCard/models/bundleClass";
import { AppCategory } from "../../components/appCard/models/appCategory";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";
import { IRoles, ISSPPlatformRole } from '../../models/IRoles';
import { IProjectIcon } from "../../models/IProjectIcon";
import { IServiceLines, ISubServiceLines } from "../../models/IServiceLines"
import { lookupModel } from "../../containers/createProject/lookupModel";

export type META_STATE = {
    apps: AppClass[];
    bundles: BundleClass[];
    appCategories: AppCategory[];
    appPlatforms: AppPlatformClass[];
    loading: boolean;
    roles: IRoles;
    rolesRequested: boolean;
    userRoles: ISSPPlatformRole[];
    icons: IProjectIcon[];
    serviceLines: IServiceLines[];
    subServiceLines: ISubServiceLines[];
    projectStatuses: lookupModel[];
};
const initialState: META_STATE = {
    icons: [],
    userRoles: [],
    rolesRequested: false,
    apps: [],
    bundles: [],
    appCategories: [],
    appPlatforms: [],
    loading: true,
    roles: { ssp: { roles: [] },
    appPlatforms: [] },
    serviceLines: [],
    subServiceLines: [],
    projectStatuses: []
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_ALL_APPS:
        case type.GET_PROJECT_ICONS_REQUEST:
            return { ...state, loading: true };
        case type.GET_ALL_APPS_SUCCESS:
            return { ...state, apps: action.payload.data, loading: false };
        case type.GET_ALL_APPS_FAILURE:
            return { ...state, apps: [], loading: false };
        case type.GET_ALL_BUNDLES:
            return { ...state, loading: true };
        case type.GET_ALL_BUNDLES_SUCCESS:
            return { ...state, bundles: action.payload.data, loading: false };
        case type.GET_ALL_BUNDLES_FAILURE:
            return { ...state, bundles: [], loading: false };
        case type.GET_ALL_APP_PLATFORMS:
            return { ...state, loading: true };
        case type.GET_ALL_APP_PLATFORMS_SUCCESS:
            return { ...state, appPlatforms: action.payload.data, loading: false };
        case type.GET_ALL_APP_PLATFORMS_FAILURE:
            return { ...state, appPlatforms: [], loading: false };
        case type.GET_ALL_APP_CATEGORIES:
            return { ...state, loading: true };
        case type.GET_ALL_APP_CATEGORIES_SUCCESS:
            return { ...state, appCategories: action.payload.data, loading: false };
        case type.GET_ALL_APP_CATEGORIES_FAILURE:
            return { ...state, appCategories: [], loading: false };
        case type.GET_ALL_PLATFORM_ROLES_REQUEST:
            return {
                ...state, rolesRequested: true
            }
        case type.GET_ALL_PLATFORM_ROLES_SUCCESS:
            return { ...state, roles: action.payload.data, rolesRequested: false };
        case type.GET_ALL_PLATFORM_ROLES_FAILURE:
            return { ...state, roles: initialState.roles, rolesRequested: false };
        case type.GET_SSP_ROLES_SUCCESS:
            return {
                ...state,
                userRoles: action.payload.data
            }
        case type.GET_SSP_ROLES_FAILURE:
            return {
                ...state,
                userRoles: state.userRoles || []
            }
        case type.GET_PROJECT_ICONS_SUCCESS:
            return {
                ...state,
                icons: action.payload.data,
                loading: false,
            }
        case type.GET_PROJECT_ICONS_FAILURE:
            return {
                ...state,
                icons: initialState.icons,
                loading: false,
            }

        case type.FLUSH_MANAGE_USERS_GRID:
            return {
                ...state,
                roles: { ssp: { roles: [] },
                        appPlatforms: [] }
            }
        case type.GET_SERVICE_LINES_SUCCESS:
            return {
                ...state,
                serviceLines: action.payload.data
            }
        case type.GET_SERVICE_LINES_FAILURE:
            return {
                ...state,
                serviceLines: state.serviceLines || []
            }
        case type.GET_SUB_SERVICE_LINES_SUCCESS:
            return {
                ...state,
                subServiceLines: action.payload.data
            }
        case type.GET_SUB_SERVICE_LINES_FAILURE:
            return {
                ...state,
                subServiceLines: state.serviceLines || []
            }
        case type.GET_LOOK_UP_DATA_SUCCESS:
            const lookupData = Object.keys(action.payload.data).reduce((accumulator, key) => {
                if (key === "appPlatformClasses") {
                    accumulator["appPlatforms"] = action.payload.data[key];
                } else {
                    accumulator[key] = action.payload.data[key];
                }
                return accumulator;
            }, {});
            return {
                ...state,
                ...lookupData
            }
        case type.GET_LOOK_UP_DATA_FAILURE:
            return {
                ...state,
                userRoles: state.userRoles || [],
                icons: state.icons || [],
                serviceLines: state.serviceLines || [],
                subServiceLines: state.serviceLines || [],
                projectStatuses: state.projectStatuses || [],
                appPlatforms: state.appPlatforms || []
            }

        default:
            return state;
    }
};
