export const FONT_SIZES = ['10px', '12px', '14px', '18px', '24px', '32px', 'inherit'];
export const FONT_FAMILIES = [
  "EYRegular",
  "EYLight",
  "Georgia"
];
export const MODULES = {
  toolbar: [
    [{header: []}],
    [{size: FONT_SIZES}],
    [{font: FONT_FAMILIES}],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{color: []}],
    ['link']
  ],
  clipboard: {
    matchVisual: false,
  }
}

export const FORMATS =  [
  'header', 
  'font', 
  'size',
  'bold', 
  'italic', 
  'underline', 
  'strike',
  'list',
  'color',
  'link'
]

export const BACKSPACE_KEY_CODE = 8;

