import en_translation from "../../localization/en/translation.json";
import bottomBanner from "../../assets/images/bottom-banner.png";
import './bottomBanner.scss';
import { AppButton, AppIcon } from "../../theme";
import { arrowUpRight } from "../../theme/icons";
import { isInternalUser } from "../../utils/helper-utility";


type Props = {
  userType?: string;
};

const BottomBanner: React.FC<Props> = ({ userType }) => {
  const isInternal = isInternalUser(userType);

  const satDeliveryPortalClick = () => {
    window.open(`${process.env.REACT_APP_SAT_DELIVERY_PORTAL_URL}`, "_blank");
  };

  if (!isInternal) {
    return null;
  }

  return (
    <section className="bottom-banner">
      <img src={bottomBanner} alt="delivery portal banner" />
      <div className="banner-text">
        <div>
          <p className="primary-text">{en_translation.ce_home_bottom_banner_primary_text}</p>
          <p className="secondary-text">{en_translation.ce_home_bottom_banner_secondary_text}</p>
        </div>

        <AppButton
          id="sat-lauch-button"
          onClick={satDeliveryPortalClick}
          size="medium"
        >
          {en_translation.ce_home_sat_delivery_portal_label} <AppIcon icon={arrowUpRight} className="sat-lauch-button-icon" />
        </AppButton>
      </div>
    </section>
  );
};

export default BottomBanner;
