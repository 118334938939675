export const columnConfig = [
  {
    headerName: 'Action',
    field: 'action',
    width: 120,
    pinned: 'left'
  },
  {
    headerName: 'Id',
    field: 'id',
    width: 320
  },
  {
    headerName: 'Key',
    field: 'key',
    width: 120,
   // sortable: true
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 320,
   // sortable: true
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 400,
   // sortable: true,
    cellStyle: {'white-space': 'normal'},
    autoHeight: true
  }
];
