import {
  CommunicationFields
} from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const columnConfig = [
  {
    headerName: ' ',
    cellRenderer: 'moreMenuCellRenderer',
    field: 'iconButton',
    minWidth: 43,
    width: 43,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer"
  },
  {
    headerName: t.admin_cps_form_communications,
    field: CommunicationFields.communicationName,
    sortable: true,
    width: 300,  
    wrapText: true,
    autoHeight: true,
    filter: "customFilter"
  },
  {
    headerName: t.admin_cps_form_title_template,
    field: CommunicationFields.communicationTemplateTitle,
    width: 300,
    sortable: true,
    valueGetter: 'data.communicationTemplate.title',
    wrapText: true,
    autoHeight: true,
    filter: 'customFilter'
  },
  {
    headerName: t.admin_cps_form_communication_send_from_mail,
    field: CommunicationFields.sendFromEmail,
    sortable: false,
    width: 200,
    wrapText: true
  },
  {
    headerName: t.admin_cps_form_communication_send_to_mail,
    field: CommunicationFields.sendToEmail,
    sortable: false,
    width: 200,
    wrapText: true
  },
  {
    headerName: t.admin_cps_form_communication_subject,
    field: CommunicationFields.communicationEmailSubject,
    sortable: true,
    width: 300,
    wrapText: true,
    autoHeight: true,
    filter: 'customFilter'
  },
  {
    headerName: t.admin_cps_form_communication_message_body,
    field: CommunicationFields.communicationEmailBody,
    sortable: true,
    width: 300,
    wrapText: true,
    autoHeight: true,
    filter: 'customFilter'
  },
  {
    headerName: t.admin_cps_form_is_active,
    field: CommunicationFields.isItemActive,
    sortable: true,
    width: 150,
    wrapText: true,
    autoHeight: true,
    filter: 'customFilter',
    filterType: 'boolean',
    valueGetter: params => {
      if (params.data.isItemActive === true) {
        return 'Yes';
      } else {
        return 'No';
      }
    }
  },
  {
    headerName: t.admin_cps_form_communication_workflow_parameters,
    field: CommunicationFields.cwfiworkflowParameters,
    sortable: true,
    width: 300,
    wrapText: true,
    autoHeight: true,
    filter: 'customFilter'
  },
  {
    headerName: t.admin_cps_form_title_schedule,
    field: CommunicationFields.wfrScheduleLookupTitle,
    width: 300,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    valueGetter: 'data.wfrScheduleLookup.title',
    filter: 'customFilter'
  }
]
