import _ from 'lodash';

export const genCheckboxData = (initData) => {
  const updatedData = initData.map((item) => ({
    ...item,
    isChecked: false,
  }));
  return {
    isSelectAll: false,
    data: updatedData,
  };
};

export const genCompetencyCheckData = (initData) => {
  const updatedData: Array<any> = [];
  const satData: Array<any> = [];
  initData.forEach((item: any, indx) => {
    if (item.sslGroupName) {
      if (
        updatedData.length &&
        updatedData.some((v: any) => v.name === item.sslGroupName)
      ) {
        updatedData.forEach((v: any) => {
          if (v.name === item.sslGroupName) {
            v.child.push({ ...item, isChecked: false });
          }
        });
      } else {
        updatedData.push({
          name: item.sslGroupName,
          child: [{ ...item, isChecked: false }],
          isChecked: false,
          id: `${item.serviceLineId}_${indx}`,
          serviceLineId: item.serviceLineId,
        });
      }
    }
    if (item.code === '0799') {
      satData.push({ ...item, isChecked: false });
    }
  });
  return {
    isSelectAll: false,
    data: [...updatedData, ...satData],
  };
};

export const handleCheckBoxChange = (type, data, initialData) => {
  const clickedData = _.cloneDeep(data);
  const initData = _.cloneDeep(initialData);
  if (type === 'allCheck') {
    clickedData.isSelectAll = !clickedData.isSelectAll;
    applyCheck(clickedData.data, clickedData.isSelectAll);
    return clickedData;
  } else {
    clickedData.isChecked = !clickedData.isChecked;
    applyCheckChildItems(initData.data, clickedData);
    if (clickedData?.sslGroupName)
      isAllChildChecked(initData.data, clickedData);
    if (clickedData.isChecked && !isAllChecked(initData.data))
      initData.isSelectAll = true;
    else initData.isSelectAll = false;
    return initData;
  }
};

const applyCheck = (data, checked) => {
  data.forEach((item) => {
    item.isChecked = checked;
    if (item?.child?.length) {
      applyCheck(item.child, checked);
    }
  });
};

const applyCheckChildItems = (data, clickedData) => {
  data.forEach((item) => {
    if (item.id === clickedData.id) {
      item.isChecked = clickedData.isChecked;
      if (item?.child?.length) {
        applyCheck(item.child, item.isChecked);
      }
    }
    if (item?.child?.length) {
      applyCheckChildItems(item.child, clickedData);
    }
  });
};

const isAllChecked = (data) => {
  return data.some((item) => {
    if (!item.isChecked) return true;
    if (item?.child?.length) {
      isAllChecked(item.child);
    }
    return false;
  });
};

const isAllChildChecked = (data, clickedData) => {
  data.forEach((item) => {
    if (item.name === clickedData.sslGroupName) {
      item.isChecked = !item.child?.some((v) => !v.isChecked);
    }
  });
};

export const getAllCheckedFilter = (competencyData) => {
  const checkedFilter: any = [];
  competencyData?.data.forEach((item) => {
    if (item.isChecked && !item?.child) checkedFilter.push(item);
    if (item?.child?.length) {
      item.child.forEach((child) => {
        if (child.isChecked) checkedFilter.push(child);
      });
    }
  });
  return checkedFilter;
};

export const getFilteredApps = (checkedBundle, checkedSSl, totalApps) => {
  const bundleApps = getBundleApps(checkedBundle, totalApps);
  const sslApps = getSSlApps(checkedSSl, totalApps);
  const totelApps = _.uniqBy([...bundleApps, ...sslApps],'id');
  return totelApps;
};

const getBundleApps = (checkedBundle, totalApps) => {
  const filteredApps: any = [];
  totalApps.forEach((app) => {
    checkedBundle.forEach((bundle) => {
      if (bundle.appClassIds?.some((bundle) => bundle.id === app.id)) {
        filteredApps.push(app);
      }
    });
  });
  return filteredApps;
};

const getSSlApps = (checkedSSl, totalApps) => {
  const filteredApps: any = [];
  // eslint-disable-next-line
  totalApps.filter((app) => {
    checkedSSl.forEach((ssl) => {
      if (app.subServiceLines?.some((v) => ssl.id === v)) {
        filteredApps.push(app);
      }
    });
  });
  return filteredApps;
};

export const setPreSelectedBundle = (allSelectedFilters, initialData) => {
  const initData = _.cloneDeep(initialData);
  const { checkedBundle } = allSelectedFilters;
  if (!checkedBundle?.length) return initData;
  initData.data.forEach((item) => {
    if (checkedBundle.some((v) => v.id === item.id)) {
      item.isChecked = true;
    }
  });
  if (!isAllChecked(initData.data)) initData.isSelectAll = true;
  return initData;
};

export const setPreSelectedSSl = (allSelectedFilters, initialData) => {
  const initData = _.cloneDeep(initialData);
  const { checkedSSl } = allSelectedFilters;
  if (!checkedSSl?.length) return initData;
  initData.data.forEach((item) => {
    if (checkedSSl.some((v) => v.id === item.id)) {
      item.isChecked = true;
    }
    if (item?.child?.length) {
      item.child.forEach((child) => {
        if (checkedSSl.some((v) => v.id === child.id)) {
          child.isChecked = true;
        }
      });
      if (!isAllChecked(item.child)) item.isChecked = true;
    }
  });
  return initData;
};
