import React from "react";
import { AppCheckbox, AppModal } from "../../theme";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import {
  getProjectEMGPolicy,
  updateProjectDetailsForEMG,
  acceptProjectEMGPolicy,
} from "../../store/actions/emg-policy-actions";
import { flushProjectDetails } from "../../store/actions/project-actions";
import { getMyAccessRight } from "../../store/actions/me-actions";
import { showActionToast } from "../../store/actions/notification-actions";
import { IEMGPolicy } from "../../models/IEMGPolicy";
import { IAccessRight, IUser } from "../../models/user";
import InnerHTMLElement from "../innerHTMLElement/innerHTMLElement";
import { EMG_POLICY } from "./constants";
import en_translation from "../../localization/en/translation.json";
import "./emgPolicy.scss";
import { UserTypes } from "../../utils/Constants";

type IProps = RouteComponentProps<any> & {
  projectId?: string;
  redirectUrl?: string;
  redirectInternally?: boolean;
  getProjectEMGPolicy: Function;
  data: IEMGPolicy;
  accessRight: IAccessRight;
  updateProjectDetailsForEMG: Function;
  acceptProjectEMGPolicy: Function;
  getMyAccessRight: Function;
  isEmgAccepting: boolean;
  acceptingError: boolean;
  showNotification: Function;
  currentUser: IUser;
  flushProjectDetails: Function;
};

interface IState {
  hasAcceptedPolicy: boolean;
}

class EMGPolicy extends React.Component<IProps, IState> {
  state = {
    hasAcceptedPolicy: false,
  };

  componentDidUpdate(prevProps: IProps) {
    const {
      data,
      projectId,
      accessRight,
      isEmgAccepting,
      acceptingError,
      getMyAccessRight,
      showNotification,
    } = this.props;

    if (
      prevProps.projectId !== projectId ||
      prevProps.accessRight !== accessRight
    ) {
      this.checkForEmg();
    }

    if (prevProps.data !== data && data) {
      const policy = this.getEMGPolicy();
      if (!policy) {
        this.redirectToPage();
      }
    }

    if (
      prevProps.isEmgAccepting !== isEmgAccepting &&
      !acceptingError &&
      !isEmgAccepting
    ) {
      getMyAccessRight();
      this.setState({
        hasAcceptedPolicy: false,
      });
      this.redirectToPage();
    } else if (prevProps.acceptingError !== acceptingError && acceptingError) {
      showNotification({
        message: en_translation.emg_error,
        type: "error",
        status: 500,
        redirectURL: "/",
      });
    }
  }

  checkForEmg = () => {
    const { projectId, getProjectEMGPolicy, accessRight, currentUser } =
      this.props;

    if (projectId && accessRight.projects && !accessRight.loading) {
      const projectAccess = accessRight.projects?.find(
        (item) => item.projectId === projectId
      );
      if (
        projectAccess &&
        !projectAccess?.hasAcceptedEMG &&
        currentUser?.userType?.toLowerCase() === UserTypes.Internal.toLowerCase()
      ) {
        getProjectEMGPolicy({
          projectId,
        });
      } else {
        this.redirectToPage();
      }
    }
  };

  redirectToPage = () => {
    const {
      redirectUrl,
      history,
      redirectInternally,
      updateProjectDetailsForEMG,
    } = this.props;
    if (redirectUrl) {
      if (redirectInternally) {
        history.push(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    }
    updateProjectDetailsForEMG(null);
  };

  getEMGPolicy = () => {
    const { data } = this.props;
    return (
      (data && data.policies?.find((item) => item.policyName === EMG_POLICY)) ||
      null
    );
  };

  closeModal = () => {
    const { updateProjectDetailsForEMG, location, history } = this.props;
    updateProjectDetailsForEMG(null);
    this.setState({
      hasAcceptedPolicy: false,
    });
    this.props.flushProjectDetails();
    if (location.pathname !== "/") {
      history.push("/");
    }
  };

  acceptEMGPolicy = () => {
    const { projectId, acceptProjectEMGPolicy } = this.props;
    acceptProjectEMGPolicy({
      projectId,
    });
  };

  onCheckBoxClick = (checked) => {
    this.setState({
      hasAcceptedPolicy: checked,
    });
  };

  render() {
    const { hasAcceptedPolicy } = this.state;
    const policy = this.getEMGPolicy();

    return (
      <AppModal
        modalClass="emg-policy"
        showModal={policy !== null && policy !== undefined}
        confirmBtnText={en_translation.accept}
        onModalClose={this.closeModal}
        cancelBtnText={en_translation.cancel}
        title={en_translation.emg_title}
        onConfirm={this.acceptEMGPolicy}
        disabledValue={!hasAcceptedPolicy}
        footerAdditionalContent={
          <div className="accept-terms">
            <AppCheckbox
              id="emg-policy-check"
              className="agree-check-box"
              name="emg-policy-check"
              checked={hasAcceptedPolicy}
              onChange={(e) => this.onCheckBoxClick(e.target.checked)}
            >
              <InnerHTMLElement htmlText={en_translation.emg_acceptance} />
            </AppCheckbox>
          </div>
        }
      >
        <div className="emg-content">
          <InnerHTMLElement htmlText={policy?.content || ""} />
        </div>
      </AppModal>
    );
  }
}

const mapStateToProps = ({ emgPolicy, meReducer }) => ({
  projectId: emgPolicy.projectId,
  redirectUrl: emgPolicy.redirectUrl,
  redirectInternally: emgPolicy.redirectInternally,
  data: emgPolicy.data,
  accessRight: meReducer.accessRight,
  isEmgAccepting: emgPolicy.isEmgAccepting,
  acceptingError: emgPolicy.acceptingError,
  currentUser: meReducer.currentUser,
});

const mapDisptachToProps = () => ({
  getProjectEMGPolicy,
  updateProjectDetailsForEMG,
  getMyAccessRight,
  acceptProjectEMGPolicy,
  showNotification: showActionToast,
  flushProjectDetails: flushProjectDetails,
});

export default connect(
  mapStateToProps,
  mapDisptachToProps()
)(withRouter(EMGPolicy));
