import React, { FC, useEffect, useState, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PowerBiReports from "../../components/powerbi-reports/powerbiReports";
import { getProjectUsageReports } from "../../store/apis/projectApi";
import t from "../../localization/en/translation.json";
import AppButtonWithIcon from "../../theme/appButtonWithIcon/appButtonWithIcon";
import { fullScreen } from "../../theme/icons";
import { checkAndLoadEMGPolicy } from "../../components/emgPolicy/constants";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import { getProjectDetails } from "../../store/actions/project-actions";
import { setPageBrTitle } from "../../utils/helper-utility";
import { RoutePathKeys } from "../../utils/Constants";
import { APP_STATE } from "../../store/reducers";
import { IAccessRight } from "../../models/user";
import { Project } from "../../components/createProject/models/project";
import Loader from "../../components/loader/loader";
import BreadcrumbComponent from "../../components/breadcrumb/Breadcrumb";

import "./projectUsageReports.scss";

const ProjectUsageReports: FC = () => {
  const location = useLocation();
  const [reportData, setReportData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { projectId, reportId } = useParams<{
    projectId: string;
    reportId: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const record = useSelector<APP_STATE, Project>(
    ({ projects: { record } }) => record
  );
  const accessRight = useSelector<APP_STATE, IAccessRight | null>(
    ({ meReducer }) => meReducer.accessRight
  );

  const getProjectReport = useCallback(async () => {
    try {
      setLoading(true);
      const projectReport = await getProjectUsageReports(projectId, reportId);
      setReportData({
        reportId: projectReport.data.reportId,
        reportEmbedUrl: projectReport.data.reportEmbedUrl,
        token: projectReport.data.reportEmbedToken.token,
        tokenId: projectReport.data.reportEmbedToken.tokenId,
        expiration: projectReport.data.reportEmbedToken.expiration,
      });
      setLoading(false);
    } catch (exception) {
      setLoading(false);
    }
  }, [projectId, reportId]);

  const onFullScreen = () => {
    const reportContainer = document.getElementById(
      "powerBiProjectUsageReport"
    );
    if (reportContainer) {
      if (reportContainer.requestFullscreen) {
        reportContainer.requestFullscreen();
      }
    }
  };

  const updateProjectDetailsForEMGAction = useCallback(
    (payload) => {
      dispatch(updateProjectDetailsForEMG(payload));
    },
    [dispatch]
  );

  const checkForEMGPolicy = useCallback(() => {
    if (accessRight && record) {
      checkAndLoadEMGPolicy(
        accessRight,
        updateProjectDetailsForEMGAction,
        record
      );
    }
  }, [accessRight, record, updateProjectDetailsForEMGAction]);

  const getProjectDetailsonLoad = useCallback(() => {
    dispatch(getProjectDetails({ id: projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (record && record.name) {
      setPageBrTitle(record.name);
      checkForEMGPolicy();
      if (!reportData && !loading) getProjectReport();
    } else {
      getProjectDetailsonLoad();
    }
  }, [
    getProjectReport,
    record,
    checkForEMGPolicy,
    getProjectDetailsonLoad,
    loading,
    reportData,
  ]);

  const resetPageTitle = useCallback(() => {
    if (!history.location.pathname.includes(RoutePathKeys.PROJECT)) {
      setPageBrTitle();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    return () => {
      resetPageTitle();
    };
  }, [location, history, resetPageTitle]);

  const breadcrumbObject = [
    { label: t.app_title, href: "/", active: true },
    {
      label: `${record?.name}`,
      href: `/project/${record?.id}`,
      active: true,
    },

    {
      label: t.project_details_meta_usage_report,
      href: "",
      active: false,
    },
  ];

  return (
    <>
      <div className="project-usage-reports-container">
        <div className="page-hdr-title-container">
          <div className="go-back-icon">
            <BreadcrumbComponent items={breadcrumbObject}></BreadcrumbComponent>
          </div>
        </div>
        <div className="page-hdr-title-container">
          <div className="page-hdr-title">{t.view_report}</div>
          <div className="btn-grp">
            <AppButtonWithIcon
              leftIcon={<img src={fullScreen} alt="fullscreen-img" />}
              btnText={t.fullscreen}
              handleOnClick={onFullScreen}
            ></AppButtonWithIcon>
          </div>
        </div>
        <div id="powerBiProjectUsageReport">
          <PowerBiReports report={reportData} />
        </div>
        <Loader isLoading={loading} />
      </div>
    </>
  );
};

export default ProjectUsageReports;
