import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  } from "@material-ui/core";
import themeConfig from "../config";
import { AppIcon } from "../../theme";
import { angleDown } from "../../theme/icons";
import "./motifAppSelect.scss";

const useStyle = makeStyles({
  root: {
    width: "100%",
    fontFamily: themeConfig.eyInterstateLight,
    color: themeConfig.gray,
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});

const ClickableIcon = ({ onClick, style }) => (
  <div onClick={onClick} style={{right: style.right}} className="dropdown-icon">
    <AppIcon icon={angleDown}  />
  </div>
);

interface ISelectProps {
  id?: string;
  value?: string;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  options: Array<{ value: string | number | boolean; label: string }>;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  className?: string;
  startAdornment?: any;
  endAdornment?: any;
  showWarning?: boolean;
  isRequired?: boolean;
  style?: any;
  displayEmpty?: boolean;
}

const MotifAppSelect: FC<ISelectProps> = ({
  value,
  isValid,
  options,
  startAdornment,
  endAdornment,
  className,
  label,
  showWarning = false,
  isRequired,
  placeholder = "Select",
  displayEmpty = true,
  ...restProps
}) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (!restProps.disabled)
      setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const startAdornmentElement = startAdornment ? (
    <InputAdornment position="start">{startAdornment}</InputAdornment>
  ) : null;

  const endAdornmentElement = endAdornment ? (
    <InputAdornment position="end">{endAdornment}</InputAdornment>
  ) : null;
  return (
    <FormControl variant="outlined" classes={{ root: classes.root }} fullWidth>
      <div className="select-wrapper">
        <span className="input-label">{label}</span>
        {isRequired ? <span className="asterisk">*</span> : null}
        <Select
          className={`motif-select ${className} ${
            isValid !== undefined && !isValid ? "in-valid" : ""
          } 
          ${showWarning ? "show-warning" : ""}`}
          displayEmpty
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          IconComponent={() => (
            <ClickableIcon onClick={handleOpen}  style={{ right: endAdornment ? "30px" : "10px" }}/>
          )}
          renderValue={(selectedValue) => {
            if (!selectedValue) {
              return placeholder;
            }

            const selectedOption = options.find(
              (option) => option.value === selectedValue
            );
            return selectedOption ? selectedOption.label : placeholder;
          }}
          placeholder={placeholder}
          value={value}
          {...restProps}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {startAdornmentElement && (
          <span className="start-tooptip">{startAdornmentElement}</span>
        )}
        {endAdornmentElement && (
          <span
            className={`end-tooltip ${
              isValid !== undefined && !isValid ? "in-valid" : ""
            }`}
          >
            {endAdornmentElement}
          </span>
        )}
      </div>
    </FormControl>
  );
};

export default MotifAppSelect;
