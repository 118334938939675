import React from 'react';
import './appSelectionFilters.scss';
import t from "../../localization/en/translation.json";

interface FiltersHeaderProps {
    appliedFilters: string | number;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({appliedFilters}) => {
  return (
    <div className="filters-header">
      <h5>
        {t.app_filter_title} <span>{appliedFilters}</span>
      </h5>
      <p>{t.app_filter_title_slug}</p>
    </div>
  );
};

export default FiltersHeader;
