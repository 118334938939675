import { put, takeLatest, call } from "redux-saga/effects";
import * as type from "../action-types";
import {
  fetchAdminProjects,
  deleteAdminProject,
  fetchAdminApps,
  addAdminAppVideo,
  getAdminProjectLog,
  deleteAdminAppVideo,
  deleteAdminApp,
  getAdminProject,
  fetchAdminProjectsByUser,
  fetchAdminAppsSearch,
  updateAdminProject,
  updateCustomizationInfo,
  getExportProjectList,
  softDeleteProjectApiCall,
  reactivateProjectApiCall,
  setProjectAccessRestrictions,
  addAdminAppThumbnail,
  deleteAdminAppThumbnail,
} from "../apis/projectApi";

function* getAdminProjects(action) {
  try {
    const data = yield call(fetchAdminProjects, action);
    yield put({
      type: type.GET_ADMIN_PROJECTS_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ADMIN_PROJECTS_FAILURE,
      payload: message,
    });
  }
}

function* updateAdminProjects(action) {
  try {
    const data = yield call(updateAdminProject, action);
    yield put({
      type: type.UPDATE_ADMIN_PROJECT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_ADMIN_PROJECT_FAILURE,
      payload: message,
    });
  }
}

function* searchAdminProject(action) {
  try {
    const data = yield call(getAdminProject, action);
    yield put({
      type: type.SEARCH_ADMIN_PROJECT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_ADMIN_PROJECT_FAILURE,
      payload: message,
    });
  }
}

function* softDeleteProject(action) {
  const {
    payload: { id: projectId },
  } = action;
  try {
    const data = yield call(softDeleteProjectApiCall, projectId);
    yield put({
      type: type.SOFT_DELETE_PROJECT_SUCCESS,
      payload: { ...data, projectId },
    });
  } catch ({ message }) {
    yield put({
      type: type.SOFT_DELETE_PROJECT_FAILURE,
      payload: message,
    });
  }
}

function* reactivateProject(action) {
  const {
    payload: { id: projectId },
  } = action;
  try {
    const data = yield call(reactivateProjectApiCall, projectId);
    yield put({
      type: type.REACTIVATE_PROJECT_SUCCESS,
      payload: { ...data, projectId },
    });
  } catch ({ message }) {
    yield put({
      type: type.REACTIVATE_PROJECT_FAILURE,
      payload: message,
    });
  }
}

function* delAdminProject(action) {
  try {
    const data = yield call(deleteAdminProject, action);
    yield put({
      type: type.DELETE_ADMIN_PROJECT_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_ADMIN_PROJECT_FAILURE,
      payload: message,
    });
  }
}

function* getAdminApps(action) {
  try {
    const { data } = yield call(fetchAdminApps, action);
    yield put({
      type: type.GET_ADMIN_APPS_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ADMIN_APPS_FAILURE,
      payload: message,
    });
  }
}

function* addAdminAppsVideoRequest(action) {
  try {
    const { data } = yield call(addAdminAppVideo, action);
    yield put({
      type: type.GET_ADMIN_APPS_VIDEO_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ADMIN_APPS_VIDEO_FAILURE,
      payload: message,
    });
  }
}

function* addAdminAppsThumbnailRequest(action) {
  try {
    const { data } = yield call(addAdminAppThumbnail, action);
    yield put({
      type: type.GET_ADMIN_APPS_THUMBNAIL_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ADMIN_APPS_THUMBNAIL_FAILURE,
      payload: message,
    });
  }
}

function* getProjectLogs(action) {
  try {
    const { data } = yield call(getAdminProjectLog, action);
    yield put({
      type: type.GET_PROJECT_LOGS_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_PROJECT_LOGS_FAILURE,
      payload: message,
    });
  }
}

function* searchProjectLogs(action) {
  try {
    const { data } = yield call(getAdminProjectLog, action);
    yield put({
      type: type.SEARCH_PROJECT_LOGS_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_PROJECT_LOGS_FAILURE,
      payload: message,
    });
  }
}

function* deleteAdminAppsVideoRequest(action) {
  try {
    const { data } = yield call(deleteAdminAppVideo, action);
    yield put({
      type: type.DELETE_ADMIN_APPS_VIDEO_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_ADMIN_APPS_VIDEO_FAILURE,
      payload: message,
    });
  }
}

function* deleteAdminAppsThumbnailRequest(action) {
  try {
    const { data } = yield call(deleteAdminAppThumbnail, action);
    yield put({
      type: type.DELETE_ADMIN_APPS_THUMBNAIL_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_ADMIN_APPS_THUMBNAIL_FAILURE,
      payload: message,
    });
  }
}

function* getAdminProjectsByUser(action) {
  try {
    const data = yield call(fetchAdminProjectsByUser, action);
    yield put({
      type: type.GET_ADMIN_PROJECTS_BY_USER_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_ADMIN_PROJECTS_BY_USER_FAILURE,
      payload: message,
    });
  }
}
function* delAdminApp(action) {
  try {
    const data = yield call(deleteAdminApp, action);
    yield put({
      type: type.DELETE_ADMIN_APP_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_ADMIN_APP_FAILURE,
      payload: message,
    });
  }
}

function* searchAdminApps(action) {
  try {
    const data = yield call(fetchAdminAppsSearch, action);
    yield put({
      type: type.SEARCH_ADMIN_APPS_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_ADMIN_APPS_FAILURE,
      payload: message,
    });
  }
}

function* updateProjectCustomizationInfo(action) {
  try {
    const data = yield call(updateCustomizationInfo, action.payload);
    yield put({
      type: type.UPDATE_CUSTOMIZATION_INFO_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_CUSTOMIZATION_INFO_FAILURE,
      payload: message,
    });
  }
}

function* exportProjectList(action) {
  try {
    const { data } = yield call(getExportProjectList, action);
    yield put({
      type: type.EXPORT_PROJECT_LIST_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.EXPORT_PROJECT_LIST_FAILURE,
      payload: message,
    });
  }
}

function* projectAccess(action) {
  try {
    const {
      payload: {
        accessRestrictionKey,
        id,
        paginationPayload,
        isProjectSearched,
        searchString,
      },
    } = action;
    const { data } = yield call(setProjectAccessRestrictions, id, {
      AccessRestrictionKey: accessRestrictionKey,
    });
    if (data && !isProjectSearched) {
      const action = { payload: paginationPayload };
      const gridData = yield call(fetchAdminProjects, action);
      yield put({
        type: type.GET_ADMIN_PROJECTS_SUCCESS,
        payload: gridData,
      });
    } else if (data && isProjectSearched && searchString) {
      yield put({
        type: type.SEARCH_ADMIN_PROJECT,
        payload: searchString,
      });
    }
    yield put({
      type: type.UPDATE_ADMIN_PROJECT_SUCCESS,
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_ADMIN_PROJECT_FAILURE,
    });
  }
}

export function* getAdminProjectsActionWatcher() {
  yield takeLatest(type.GET_ADMIN_PROJECTS, getAdminProjects);
  yield takeLatest(type.UPDATE_ADMIN_PROJECT, updateAdminProjects);
  yield takeLatest(type.SEARCH_ADMIN_PROJECT, searchAdminProject);
  yield takeLatest(type.DELETE_ADMIN_PROJECT, delAdminProject);
  yield takeLatest(type.SOFT_DELETE_PROJECT, softDeleteProject);
  yield takeLatest(type.REACTIVATE_PROJECT, reactivateProject);
  yield takeLatest(type.GET_ADMIN_APPS_REQUEST, getAdminApps);
  yield takeLatest(type.GET_ADMIN_APPS_VIDEO_REQUEST, addAdminAppsVideoRequest);
  yield takeLatest(type.GET_ADMIN_APPS_THUMBNAIL_REQUEST, addAdminAppsThumbnailRequest);
  yield takeLatest(type.GET_PROJECT_LOGS_REQUEST, getProjectLogs);
  yield takeLatest(type.SEARCH_PROJECT_LOGS, searchProjectLogs);
  yield takeLatest(type.DELETE_ADMIN_APPS_VIDEO_REQUEST, deleteAdminAppsVideoRequest);
  yield takeLatest(type.DELETE_ADMIN_APPS_THUMBNAIL_REQUEST, deleteAdminAppsThumbnailRequest);
  yield takeLatest(type.DELETE_ADMIN_APP_REQUEST, delAdminApp);
  yield takeLatest(type.GET_ADMIN_PROJECTS_BY_USER_REQUEST, getAdminProjectsByUser);
  yield takeLatest(type.SEARCH_ADMIN_APPS_REQUEST, searchAdminApps);
  yield takeLatest(type.UPDATE_CUSTOMIZATION_INFO_REQUEST, updateProjectCustomizationInfo);
  yield takeLatest(type.EXPORT_PROJECT_LIST, exportProjectList);
  yield takeLatest(type.SET_PROJECT_ACCESS_RESTRICTIONS, projectAccess);
}
