import * as type from "../action-types";

type load = any;

export const searchManageUserAction = (payload: load) => ({
  type: type.SEARCH_MANAGE_USER,
  payload,
});

export const flushManageUserAction = () => ({
  type: type.FLUSH_MANAGE_USER,
});

export const addnewUserAction = (payload: load) => ({
  type: type.ADD_MANAGE_USER_REQUEST,
  payload,
});

export const addnewUserActionSuccess = (payload?: load) => ({
  type: type.ADD_MANAGE_USER_SUCCESS,
  payload,
});

export const addnewUserActionFailure = (payload: load) => ({
  type: type.ADD_MANAGE_USER_FAILURE,
  payload,
});

export const searchManageUserGrid = (payload: load) => ({
  type: type.SEARCH_MANAGE_USER_GRID,
  payload,
});

export const bulkTemplatedownloadAction = () => ({
  type: type.BULK_TEMPLATE_DOWNLOAD,
});

export const bulkUserUploadRequest = (payload) => ({
  type: type.UPLOAD_MANAGE_USER_BULK_FILE_REQUEST,
  payload
});

export const bulkUserUploadRequestSuccess = (payload) => ({
  type: type.UPLOAD_MANAGE_USER_BULK_FILE_SUCCESS,
  payload
});

export const bulkUserUploadRequestFailure = (payload) => ({
  type: type.UPLOAD_MANAGE_USER_BULK_FILE_FAILURE,
  payload
});
