import {debounce} from "lodash";

const EVENTS_ARRAY = [
    "keypress",
    "keydown",
    "click",
    "contextmenu",
    "dblclick",
    "mousemove",
    "scroll",
    "touchmove",
    "touchstart"
];

const DEFAULT_ACTIVITY_REPORT_IN_SEC = 30 * 60;
const TEN_SECOND_WINDOW = 10;

export interface Options {
  activityReportInSec?: number;
  detectFramesInSec?: number;
  onActivity?: ()=>Promise<void>;
  onEnterFrame?: ()=>Promise<void>;
  onReportUserIsIdle?: ()=>Promise<void>;
  onIdleForLonger: ()=>Promise<void>;
}

const lastActivity_key = "LastActivity";

// TODO - we can rewrite idle check from passive interval checks to native UA api IdleDetector once available in edge and firefox- https://developer.mozilla.org/en-US/docs/Web/API/IdleDetector
export class Idle {
    userMouseIsInFrame: boolean = false;
    private checkIdleWhenPageIsVisible: boolean = true;
    attachedFrames: HTMLElement[] = [];
    activityReportInSec: number = DEFAULT_ACTIVITY_REPORT_IN_SEC;
    detectFramesInSec: number = -1;
    scheduleRef: NodeJS.Timeout| null = null;
    onActivity: ()=>Promise<void> = async()=>{};
    onEnterFrame: ()=>Promise<void> = async()=>{};
    onReportUserIsIdle: ()=>Promise<void> = async()=>{};
    onIdleForLonger: ()=> Promise<void> = async()=>{};

    constructor (options: Options) {
        this.overrideDefaultsWithOptions(options);

        EVENTS_ARRAY.forEach((event) => {
            window.addEventListener(event, debounce(this.onUserActivity, TEN_SECOND_WINDOW * 1000,{leading: true, trailing:false}), false);
        });

        this.scheduleRef= this.schedule();

        if (this.detectFramesInSec > 0) {
            setInterval(this.attachListenerToNewFrames, this.detectFramesInSec * 1000);
        }
    }

    startMonitorSleepIdle(){
        const localStorage = window.localStorage;
        const IdleMonitor = ()=>{
            const lastActivity_str = localStorage.getItem(lastActivity_key);
            if(lastActivity_str) {
                const diff = Date.now() - JSON.parse(lastActivity_str);
                if(diff > ((this.activityReportInSec + parseInt(process.env.REACT_APP_IDLE_LOGOUT_POPUP_TIMEOUT_IN_SECONDS ?? ''))*1000))
                {
                    this.onIdleForLonger();
                }
            }
        };
        IdleMonitor();
        localStorage.setItem(lastActivity_key, JSON.stringify(Date.now()));
        //aggressive checking of idle timeout because of browser sleep or closing of page may occur, it's hack, not good solution
        const ONE_SECOND_INTERVAL = 1000;
        setInterval(IdleMonitor,ONE_SECOND_INTERVAL);
    }

    resetLastActivityFromStorage(){
        window.localStorage.removeItem(lastActivity_key);
    }

    private schedule() {
        return setInterval(this.onTimerTick.bind(this), this.activityReportInSec * 1000);
    }

    attachListenerToNewFrames = () => {
        const frames = Array.prototype.slice.call(window.document.getElementsByTagName('frame'));
        const iframes = Array.prototype.slice.call(window.document.getElementsByTagName('iframe'));
        const allFrames = frames.concat(iframes);
        for (const frame of allFrames) {
            if (this.attachedFrames.indexOf(frame) < 0) {
                frame.addEventListener("mouseenter", this.onMouseEntersFrame.bind(this), this, false);
                this.attachedFrames.push(frame);
                // We can't know if the mouse is within the new frame already, so we guess it is.
                // If its not we would find out upon any user activity
                this.userMouseIsInFrame = true;
            }
        }
    }

    onUserActivity = () => {
        this.onActivity();

        // user is active => user is not in a frame
        this.userMouseIsInFrame = false;
        this.reschedule();
        window.localStorage.setItem(lastActivity_key, JSON.stringify(Date.now()));
    }

    private reschedule() {
        if (this.scheduleRef != null) {
            clearInterval(this.scheduleRef);
        }
        this.scheduleRef = this.schedule();
    }

    public whenAllInstancesAreHiddenInUA(){
       this.checkIdleWhenPageIsVisible=false
    }

    public whenAtLeastOneInstanceIsVisible(){
        this.reschedule();
        this.checkIdleWhenPageIsVisible=true;
    }

    public onMouseEntersFrame() {
        this.onEnterFrame();
        this.userMouseIsInFrame = true;
    }

    private onTimerTick() {
        if (!this.userMouseIsInFrame
              && ((this.checkIdleWhenPageIsVisible && this.isPageVisible()) || !this.checkIdleWhenPageIsVisible)) {
            this.onReportUserIsIdle();
        }
    }

    private overrideDefaultsWithOptions(options: Options) {
        this.activityReportInSec = options.activityReportInSec || this.activityReportInSec;
        this.detectFramesInSec = options.detectFramesInSec || this.detectFramesInSec;
        this.onActivity = options.onActivity || this.onActivity;
        this.onEnterFrame = options.onEnterFrame || this.onEnterFrame;
        this.onReportUserIsIdle = options.onReportUserIsIdle || this.onReportUserIsIdle;
        this.onIdleForLonger = options.onIdleForLonger || this.onIdleForLonger;
    }

    public isPageVisible() {
        // return true for "visible" / "prerender" / "undefined" (unsupported browsers)
        return document.visibilityState !== "hidden";
    }

}
enum Channel{
    IDLE_TO="idleTimeout",
    IDLE_FOR_LONGER = "idleForLonger",
    BATON="baton"
}
const batonUUID = new Date().getTime().toString(16) // radix 16 conversion on number to convert number to string sequence
                   + Math.floor((Math.random()*1000)).toString(16);
const idleForLongerChannel = new BroadcastChannel(Channel.IDLE_FOR_LONGER);
export const IdleForLongerTime = new Event("IdleForLongerTime");
const itsIdleForLong = ()=>document.dispatchEvent(IdleForLongerTime);
idleForLongerChannel.onmessage = itsIdleForLong;

const idleTimeoutChannel  = new BroadcastChannel(Channel.IDLE_TO);
export const IdleEvent = new Event("Idle");
const itsIdle = ()=> document.dispatchEvent(IdleEvent);
idleTimeoutChannel.onmessage = itsIdle;

const timeout = parseInt(process.env.REACT_APP_IDLE_TIMEOUT_IN_SECONDS??'');
export const idle = new Idle({activityReportInSec: timeout,
    detectFramesInSec: timeout,
    onReportUserIsIdle: async()=>{
    idleTimeoutChannel.postMessage("idle");
    itsIdle();
},
 onIdleForLonger: async()=>{
   idleForLongerChannel.postMessage("idleForLong");
   itsIdleForLong();
 }
});

class BatonChannel extends  BroadcastChannel{
    private onMessageHandler;
    constructor(onMessage: ((this: BroadcastChannel, ev: MessageEvent) => any) | null) {
        super(Channel.BATON);
        this.onMessageHandler = onMessage;
        this.onmessage = onMessage;
    }
    post(message: any) {
        this.postMessage(message);
        // we need to handle replicate behaviour of onmessage on sender page as well,
       // since boardCastChannel only send messages
      // to other objects of same boardCastChannels only
        if (this.onMessageHandler) {
            this.onMessageHandler({data:message});
        }
    }
}

const batonChannel = new BatonChannel(({data})=> {
if(data != null){
    idle.whenAtLeastOneInstanceIsVisible();
}else{
    idle.whenAllInstancesAreHiddenInUA();
}});

const slight_delay = 500;
// slight delay added to avoid collision in boardcast channel message queue since each tab is separate process
// trigger message to channel independent of others
setTimeout(()=>{
    batonChannel.post(batonUUID);
},slight_delay);

document.onvisibilitychange=()=>{
  if(idle.isPageVisible()){
      // slight delay added to avoid collision in boardcast channel message
      setTimeout(()=>{
          batonChannel.post(batonUUID);
      },slight_delay);
  } else {
      batonChannel.post(null);
  }
};