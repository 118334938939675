import React from "react";
import { ICellRendererParams } from 'ag-grid-community';

const CONFIDENTIAL_USER = "Confidential User";

export class UserDetailsCellRenderer extends React.Component<ICellRendererParams> {
    render() {
        const { value } = this.props;
        let userName: any = `${value?.givenName} ${value?.surname}`;
        if (userName.includes(CONFIDENTIAL_USER)) {
            userName = <i>{userName}</i>;
        }

        return (value && (
            <div className="user-details">
                <div className="user-name ellipsis">{userName}</div>
                {value.officeLocation && <div className="ellipsis">{value.officeLocation}</div>}
                {value.mail && <div className="ellipsis">{value.mail}</div>}
            </div>
        )) || null;
    }
}
