import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHelpSupportRequest } from "../../store/actions/settings-actions";
import { Link, useHistory } from "react-router-dom";
import { APP_STATE } from "../../store/reducers";
import HsImg from "../../assets/images/help_support.svg";
import HsLineImg from "../../assets/images/help_support_line.png";
import { back } from "../../theme/icons";
import { AppIcon } from "../../theme";
import { IUser } from "./../../models/user";
import en from "../../localization/en/translation.json";
import { IhelpAndSupportData } from "./../../models/IHelp";
import { returnSvgImage } from "./HelpSupportIcons";
import "./HelpAndSupport.scss";

const HelpAndSupport = () => {
  const [tilesData, setTilesData] = useState<IhelpAndSupportData[]>([]);

  const userDetails: IUser = useSelector(
    (state: APP_STATE) => state.meReducer.currentUser
  );

  const helpSupport: IhelpAndSupportData[] = useSelector(
    (state: APP_STATE) => state.settings.helpSupport
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (helpSupport.length > 0) {
      setTilesData(helpSupport);
    }
  }, [helpSupport]);

  useEffect(() => {
    if (helpSupport.length === 0) {
      dispatch(getHelpSupportRequest());
    }
  }, [dispatch, helpSupport]);

  const getTileUrl = (key: string) => {
    if (tilesData.length > 0) {
      const filteredItems = tilesData.find((item) => item.key === key);
      return filteredItems ? filteredItems.url : "";
    }
    return "";
  };

  const handleGoBack = () => {
    history.goBack(); // Navigate to the previous page
  };

  return (
    <div className="hs-page-wrapper pb-5">
      <div className="page-header">
        <Link className="ml-5 back-text" to="/" onClick={handleGoBack}>
          <AppIcon icon={back} className="icon-link" /> {"  "}
          <p>{en.back_to_reports}</p>
        </Link>
        <p className="page-title">{en.Help_and_Support_page_title}</p>
      </div>
      {tilesData.length > 0 && (
        <>
          <div className="hs-banner">
            <img src={HsImg} className="help-img" alt="Help and Support" />
            <img src={HsLineImg} className="hs-img-line" alt="img" />
          </div>
          <h3 className="section-title mt-1 mb-1">
            {en.help_and_support_title.replace(
              "{1}",
              userDetails?.givenName || "user"
            )}
          </h3>
          <div className="row justify-content-center py-4">
            <div className="hs-tiles-container">
              <div className="app-row">
                <a
                  href={getTileUrl("CAPITAL-EDGE-HUB")}
                  className="app-col-lg-6 mt-3 px-2"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="help-container">
                    <div className="icon-wrapper app-col-lg-3">
                      {returnSvgImage("telescope")}
                    </div>
                    <div className="app-col-lg-9 content-wrapper">
                      <h4 className="d-flex mb-1">
                        <span>{en.explore_capital_edge_title}</span>

                        <span className="align-right r-24">
                          {returnSvgImage("arrow")}
                        </span>
                      </h4>
                      <p className="help-description">
                        {en.explore_capital_edge_description}
                      </p>
                    </div>
                  </div>
                </a>

                <a
                  href={getTileUrl("SERVICE-NOW")}
                  className="app-col-lg-6 mt-3 px-2"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="help-container">
                    <div className="icon-wrapper app-col-lg-3">
                      {returnSvgImage("handheart")}
                    </div>
                    <div className="app-col-lg-9 content-wrapper">
                      <h4 className="d-flex mb-1">
                        <span>{en.support_portal_title}</span>

                        <span className="align-right r-24">
                          {returnSvgImage("arrow")}
                        </span>
                      </h4>
                      <p className="help-description">
                        {en.support_portal_description}
                      </p>
                    </div>
                  </div>
                </a>

                <a
                  href={getTileUrl("CHATBOT")}
                  className="app-col-lg-12"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="help-container flex-nowrap">
                    <div className="app-col-lg-4 icon-wrapper">
                      {returnSvgImage("comments")}
                    </div>
                    <div className="app-col-lg-8 content-wrapper">
                      <div className="pl-2">
                        <h4 className="d-flex mb-1">
                          <span>{en.help_copilot_title}</span>

                          <span className="align-right r-0">
                            {returnSvgImage("arrow")}
                          </span>
                        </h4>
                        <p className="help-description">
                          {en.help_copilot_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HelpAndSupport;
