import * as type from "../action-types";

type load = { id: string }

export const getMaintenanceStatusRequest = (payload: boolean) => ({
    type: type.GET_MAINTENANCE_STATUS_REQUEST,
    payload,
});

export const getMaintenanceStatusSuccess = (payload: load) => ({
    type: type.GET_MAINTENANCE_STATUS_SUCCESS,
    payload,
});


export const getMaintenanceStatusFailure = (payload: load) => ({
    type: type.GET_MAINTENANCE_STATUS_FAILURE,
    payload,
});

export const scheduleMaintenanceRequest = (payload: load) => ({
    type: type.SCHEDULE_MAINTENANACE_REQUEST,
    payload,
});

export const scheduleMaintenanceSuccess = (payload: load) => ({
    type: type.SCHEDULE_MAINTENANACE_SUCCESS,
    payload,
});


export const scheduleMaintenanceFailure = (payload: load) => ({
    type: type.SCHEDULE_MAINTENANACE_FAILURE,
    payload,
});

export const flushMaintenanceNotification = () => ({
    type: type.FLUSH_MAINTENANCE_NOTIFICATION
});

export const getAdminScheduleRequest = () => ({
    type: type.GET_ADMIN_SCHEDULE_REQUEST
});

export const getAdminScheduleSuccess = (payload: load) => ({
    type: type.GET_ADMIN_SCHEDULE_SUCCESS,
    payload,
});

export const getAdminScheduleFailure = (payload: load) => ({
    type: type.GET_ADMIN_SCHEDULE_FAILURE,
    payload,
});

export const addAdminScheduleRequest = (payload: load) => ({
    type: type.ADD_ADMIN_SCHEDULE_REQUEST,
    payload
});

export const addAdminScheduleSuccess = (payload: load) => ({
    type: type.ADD_ADMIN_SCHEDULE_SUCCESS,
    payload,
});

export const addAdminScheduleFailure = (payload: load) => ({
    type: type.ADD_ADMIN_SCHEDULE_FAILURE,
    payload,
});

export const updateAdminScheduleRequest = (payload: load) => ({
    type: type.UPDATE_ADMIN_SCHEDULE_REQUEST,
    payload
});

export const updateAdminScheduleSuccess = (payload: load) => ({
    type: type.UPDATE_ADMIN_SCHEDULE_SUCCESS,
    payload,
});

export const updateAdminScheduleFailure = (payload: load) => ({
    type: type.UPDATE_ADMIN_SCHEDULE_FAILURE,
    payload,
});

export const deleteAdminScheduleRequest = (payload: load) => ({
    type: type.DELETE_ADMIN_SCHEDULE_REQUEST,
    payload
});

export const deleteAdminScheduleSuccess = (payload: load) => ({
    type: type.DELETE_ADMIN_SCHEDULE_SUCCESS,
    payload,
});

export const deleteAdminScheduleFailure = (payload: load) => ({
    type: type.UPDATE_ADMIN_SCHEDULE_FAILURE,
    payload,
});

