import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import _ from "lodash";
import { IPansPolicy } from "../../models/user";
import PrivacyAndTerms from "../../components/privacyAndTerms/privacyAndTerms";
import { getAcceptedPolicyAction } from "../../store/actions/accepted-policy-actions";
import { getUserPanns, clearUrlParams } from "../../store/actions/me-actions";
import { IAcceptPolicies } from "../../models/IAcceptPolicies";
import { POLICY_ERROR_CODE, POLICY_URL } from "../../utils/Constants";
import { getProjectList } from "../../store/actions/project-actions";
import { Project } from "../../components/createProject/models/project";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import "./privacyPolicy.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { showActionToast } from "../../store/actions/notification-actions";
import en_translation from "../../localization/en/translation.json";
import { IUrlParams } from "../../models/urlParamsStorage";

type IProps = RouteComponentProps<any> & {
  paansPolicy: IPansPolicy;
  getAcceptedPolicy: Function;
  acceptedPolicyData: IAcceptPolicies;
  getUserPanns: Function;
  getProjectList: Function;
  projects: Project[];
  updateProjectDetailsForEMG: Function;
  showNotification: Function;
  loading: boolean;
  isProjectLoading: boolean;
  urlParams: IUrlParams;
  clearUrlParams: Function;
};

interface IState {
  policyPageNo: number;
  isChecked: boolean;
  isEMGShown: boolean;
  showNotificationError: boolean;
}

class PrivacyPolicy extends Component<IProps, IState> {
  state = {
    policyPageNo: 1,
    isChecked: false,
    isEMGShown: false,
    showNotificationError: false,
  };

  componentDidMount() {
    const { getProjectList } = this.props;
    getProjectList();
    // const policyAppPlatformParams = urlParams;
    this.checkPannsPolicy();
    // if (
    //   policyAppPlatformParams.policyErrorCode?.toLowerCase() ===
    //   POLICY_ERROR_CODE
    // ) {
    //   getProjectList();
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      paansPolicy,
      acceptedPolicyData,
      history,
      getUserPanns,
      projects,
      urlParams,
    } = this.props;
    const policyAppPlatformParams = urlParams;
    if (prevProps.paansPolicy !== paansPolicy) {
      this.checkPannsPolicy();
    } else if (
      prevProps.acceptedPolicyData !== acceptedPolicyData &&
      acceptedPolicyData.success
    ) {
      getUserPanns();
      if (
        policyAppPlatformParams.policyErrorCode?.toLowerCase() !==
        POLICY_ERROR_CODE
      ) {
        history.goBack();
        setTimeout(() => {
          if (window.location.pathname === POLICY_URL) {
            history.push("/");
          }
        }, 10);
      } else {
        this.loadEMGPolicy(policyAppPlatformParams);
      }
    } else if (
      prevProps.acceptedPolicyData !== acceptedPolicyData &&
      !acceptedPolicyData.success
    ) {
      history.push("/policy-error");
    }

    if (
      prevProps.projects !== projects &&
      policyAppPlatformParams.policyErrorCode?.toLowerCase() !==
        POLICY_ERROR_CODE &&
      prevProps.acceptedPolicyData !== acceptedPolicyData &&
      acceptedPolicyData.success
    ) {
      this.loadEMGPolicy(policyAppPlatformParams);
    }
  }

  componentWillUnmount = () => {
    this.setState({
      showNotificationError: false,
    });
  };

  getUserAllotedProject = (policyAppPlatformParams) => {
    const { loading } = this.props;
    if (loading) {
      setTimeout(
        async () => await this.getUserAllotedProject(policyAppPlatformParams),
        1000
      );
    } else {
      this.loadEMGPolicy(policyAppPlatformParams);
    }
  };
  loadEMGPolicy = (policyAppPlatformParams) => {
    const {
      projects,
      updateProjectDetailsForEMG,
      paansPolicy,
      showNotification,
      loading,
      clearUrlParams,
    } = this.props;
    const { showNotificationError } = this.state;

    if (loading) this.getUserAllotedProject(policyAppPlatformParams);
    const project = projects.find(
      (item) =>
        item.projectFriendlyId === policyAppPlatformParams.projectFriendlyId
    );

    if (project && paansPolicy && paansPolicy.hasAcceptedAll) {
      updateProjectDetailsForEMG({
        projectId: project.id,
        redirectUrl: policyAppPlatformParams.policyRedirectURL,
        redirectInternally: false,
      });
      clearUrlParams();
      this.setState({
        isEMGShown: true,
      });
    } else if (!showNotificationError && !project && !loading) {
      showNotification({
        message: en_translation.policy_permission_error,
        type: "error",
        status: 500,
        redirectURL: "/",
      });
      clearUrlParams();
      this.setState({
        showNotificationError: true,
      });
    }
  };

  checkPannsPolicy = () => {
    const { paansPolicy, history, urlParams } = this.props;

    if (paansPolicy && paansPolicy.hasAcceptedAll) {
      const policyAppPlatformParams = urlParams;
      if (
        policyAppPlatformParams.policyErrorCode?.toLowerCase() !==
        POLICY_ERROR_CODE
      ) {
        history.push("/");
      } else {
        this.loadEMGPolicy(policyAppPlatformParams);
      }
    }
  };

  onCheckBoxClick = (isChecked) => this.setState({ isChecked });

  onNextClick = () => {
    const { paansPolicy, getAcceptedPolicy } = this.props;

    if (this.state.policyPageNo === 1) {
      this.setState({ isChecked: false, policyPageNo: 2 });
    } else {
      const payload = _.cloneDeep(paansPolicy);

      payload.hasAcceptedAll = true;
      if (payload.policies.length === 2) {
        payload.policies[0].hasAccepted = true;
        payload.policies[1].hasAccepted = true;
      }
      getAcceptedPolicy(payload);
    }
  };

  render() {
    const { policyPageNo, isChecked, isEMGShown } = this.state;
    const { paansPolicy } = this.props;

    return (
      <CrashBoundary>
        <div className="privacy-policy">
          <div className="privacy-policy-conatiner">
            {paansPolicy.policies &&
              paansPolicy.policies.length > 0 &&
              !isEMGShown && (
                <PrivacyAndTerms
                  policyPageNo={policyPageNo}
                  isChecked={isChecked}
                  onCheckBoxClick={(ischecked) =>
                    this.onCheckBoxClick(ischecked)
                  }
                  onNextClick={this.onNextClick}
                  paansPolicy={paansPolicy}
                />
              )}
          </div>
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({
  meReducer,
  acceptedPolicyReducer,
  projects,
  urlParamStorageReducer,
}) => {
  return {
    paansPolicy: meReducer.paansPolicy,
    acceptedPolicyData: acceptedPolicyReducer.acceptedPolicyData,
    projects: projects.list,
    loading: projects.loading,
    isProjectLoading: projects.isProjectLoading,
    urlParams: urlParamStorageReducer.urlParams,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAcceptedPolicy: (payload) => {
      dispatch(getAcceptedPolicyAction(payload));
    },
    getUserPanns: () => {
      dispatch(getUserPanns());
    },
    getProjectList: () => {
      dispatch(getProjectList());
    },
    updateProjectDetailsForEMG: (payload) => {
      dispatch(updateProjectDetailsForEMG(payload));
    },
    showNotification: (payload) => {
      dispatch(showActionToast(payload));
    },
    clearUrlParams: () => {
      dispatch(clearUrlParams());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
