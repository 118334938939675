import React, { useEffect, useMemo } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from './store/actions/user-actions';
import Loader from './components/loader/loader';

const AuthenticationLoader = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const dispatch = useDispatch();
  const meLoading = useSelector((state: any) => state.meReducer.loading);

  const loading = useMemo(() => meLoading || inProgress !== 'none', [meLoading, inProgress]);

  useEffect(() => {
    const checkAccount = async () => {
      if (isAuthenticated && inProgress === 'none') {
        dispatch(getMe());
      }
    };

    checkAccount();
  }, [isAuthenticated, inProgress, accounts, dispatch]);

  return loading ? <Loader isLoading={true} /> : <>{children}</>;
};

export default AuthenticationLoader;
