import React, { useCallback, useEffect, useReducer } from "react";
import { AppFormErrorText, AppIcon, MotifAppSelect } from "../../theme";
import t from "../../localization/en/translation.json";
import { IGeoFieldState } from "../../containers/createProject/formStateModel";
import { getOptions, validatedInputClass } from "./projectForm";
import {
  CountryLookupModel,
  lookupModel,
  RegionLookupModel,
} from "../../containers/createProject/lookupModel";
import AppTooltip from "../../theme/appTooltip/appTooltip";
import { exclamation, info } from "../../theme/icons";

type value = IGeoFieldState;
type propsType = {
  area: value;
  region: value;
  country: value;
  areas: lookupModel[];
  regions: RegionLookupModel[];
  countries: CountryLookupModel[];
  onUpdate: (value: unknown, field: string) => void;
};
const getRegions = async (
  areaId: string,
  regions: RegionLookupModel[]
): Promise<lookupModel[]> => {
  try {
    return regions.filter((region) => region.areaId === areaId);
  } catch (e) {
    return [] as lookupModel[];
  }
};
const getCountries = async (
  regionId: string,
  countries: CountryLookupModel[]
): Promise<lookupModel[]> => {
  try {
    return countries.filter((country) => country.regionId === regionId);
  } catch (e) {
    return [] as lookupModel[];
  }
};

enum CountryCodeActions {
  areaSelected = "area_selected",
  regionSelected = "region_selected",
  regionsLoaded = "regions_loaded",
  countriesLoaded = "countries_loaded",
}
export const CountryCodeFields = ({
  area,
  region,
  country,
  areas,
  regions,
  countries,
  onUpdate,
}: propsType) => {
  const areaId = area.fieldVal?.id;
  const regionId = region.fieldVal?.id;
  const countryId = country.fieldVal?.id;
  const areaFiledName = area.fieldName;
  const regionFieldName = region.fieldName;
  const countryFieldName = country.fieldName;
  const [{ filteredRegions, filteredCountries }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case CountryCodeActions.areaSelected:
          const { areaId } = action.payload;
          (async () => {
            const filteredRegions = await getRegions(areaId, regions);
            dispatch({
              type: CountryCodeActions.regionsLoaded,
              payload: filteredRegions,
            });
          })();
          return { ...state, filteredRegions: [], filteredCountries: [] };
        case CountryCodeActions.regionsLoaded:
          return { ...state, filteredRegions: action.payload };
        case CountryCodeActions.regionSelected:
          const { regionId } = action.payload;
          (async () => {
            const filteredCountries = await getCountries(regionId, countries);
            dispatch({
              type: CountryCodeActions.countriesLoaded,
              payload: filteredCountries,
            });
          })();
          return { ...state, filteredCountries: [] };
        case CountryCodeActions.countriesLoaded:
          return { ...state, filteredCountries: action.payload };
      }
    },
    {
      filteredRegions: [],
      filteredCountries: [],
    },
    (initialState) => initialState
  );

  useEffect(() => {
    if (areaId) {
      (async () => {
        const filteredRegions = await getRegions(areaId, regions);
        dispatch({
          type: CountryCodeActions.regionsLoaded,
          payload: filteredRegions,
        });
      })();
    }
  }, [areaId, regions]);

  useEffect(() => {
    if (regionId) {
      (async () => {
        const filteredCountries = await getCountries(regionId, countries);
        dispatch({
          type: CountryCodeActions.countriesLoaded,
          payload: filteredCountries,
        });
      })();
    }
  }, [regionId, countries]);

  const onAreaUpdated = useCallback(
    (value: string) => {
      onUpdate(
        { id: value, name: areas.find((area) => area.id === value)?.name },
        areaFiledName
      );
      // reset region and country field
      onUpdate(null, regionFieldName);
      onUpdate(null, countryFieldName);
    },
    [areas, areaFiledName, onUpdate, regionFieldName, countryFieldName]
  );

  const onRegionUpdated = useCallback(
    (value: string) => {
      onUpdate(
        {
          id: value,
          name: filteredRegions.find((region) => region.id === value)?.name,
        },
        regionFieldName
      );
      // reset country field
      onUpdate(null, countryFieldName);
    },
    [filteredRegions, onUpdate, regionFieldName, countryFieldName]
  );

  const filteredRegionOptions = getOptions(filteredRegions);
  const filteredCountryOptions = getOptions(filteredCountries);

  const displayCountryTooltip = (isValid) => {
    return isValid ? (
      region.fieldVal?.id == null ? (
        <AppTooltip placement="top" title={t.country_field_tooltip}>
          <AppIcon icon={info} />
        </AppTooltip>
      ) : (
        <AppTooltip placement="top" title={t.country_code_information}>
          <AppIcon icon={info} />
        </AppTooltip>
      )
    ) : (
      <AppTooltip title={t.required_field}>
        <AppIcon icon={exclamation} />
      </AppTooltip>
    );
  };

  const displayRegionTooltip = (isValid) => {
    return (
      area.fieldVal?.id == null && (
        <AppTooltip title={t.region_field_tooltip}>
          {isValid ? <AppIcon icon={info} /> : <AppIcon icon={exclamation} />}
        </AppTooltip>
      )
    );
  };
  return (
    <>
      <div
        className={`project-form-field m-r-35 ${
          area.fieldVal && area.isvalid ? validatedInputClass : ""
        }`}
      >
        <MotifAppSelect
          id={areaFiledName}
          value={areaId ?? ""}
          disabled={area.disable}
          name={areaFiledName}
          data-test={areaFiledName}
          label={t.project_area}
          isValid={area.isvalid}
          options={getOptions(areas)}
          onChange={(e) => {
            onAreaUpdated(e.target.value as string);
          }}
        />
        {!area.isvalid && (
          <AppFormErrorText>{t.required_field}</AppFormErrorText>
        )}
      </div>
      <div
        className={`project-form-field m-r-35 ${
          region.fieldVal && region.isvalid ? validatedInputClass : ""
        }`}
      >
        <>
          <MotifAppSelect
            id={regionFieldName}
            value={!filteredCountryOptions.length || !regionId ? "" : regionId}
            disabled={region.disable || !areaId}
            name={regionFieldName}
            data-test={regionFieldName}
            label={t.project_region}
            isValid={region.isvalid}
            options={filteredRegionOptions}
            onChange={(e) => onRegionUpdated(e.target.value as string)}
            endAdornment={displayRegionTooltip(region.isvalid)}
          />

          {!region.isvalid && (
            <AppFormErrorText>{t.required_field}</AppFormErrorText>
          )}
        </>
      </div>
      <div
        className={`project-form-field ${
          country.fieldVal && country.isvalid ? validatedInputClass : ""
        }`}
      >
        <>
          <MotifAppSelect
            id={countryFieldName}
            value={
              !filteredCountryOptions.length || !countryId ? "" : countryId
            }
            disabled={country.disable || !regionId}
            name={countryFieldName}
            data-test={countryFieldName}
            label={t.project_country}
            isValid={country.isvalid}
            options={filteredCountryOptions}
            onChange={(e) =>
              onUpdate(
                {
                  id: e.target.value,
                  name: filteredCountries.find(
                    (country) => country.id === e.target.value
                  )?.name,
                },
                countryFieldName
              )
            }
            endAdornment={displayCountryTooltip(country.isvalid)}
          />

          {!country.isvalid && (
            <AppFormErrorText>{t.required_field}</AppFormErrorText>
          )}
        </>
      </div>
    </>
  );
};
