import React, { FC, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import en_translation from "../../../localization/en/translation.json";
import * as actionstype from "../../../store/actions/meta-actions";
import * as createActionsType from "../../../store/actions/create-project-actions";
import AppCard from "../../appCard/appCard";
import { AppCategory } from "../../appCard/models/appCategory";
import { AppClass } from "../../appCard/models/appClass";
import { AppPlatformClass } from "../../appCard/models/appPlatformClass";
import BundleCard from "../../bundleCard/bundleCard";
import { BundleClass } from "../../bundleCard/models/bundleClass";
import { BundleEvent } from "../../bundleCard/models/bundleEvent";
import ProjectDetails from "../../projectDetails/projectDetails";
import { ProjectDetailsModel } from "../../projectDetails/projectDetailsModel";
import PreviewCard from "../../previewCard/previewCard";
import { PreviewCardModel } from "../../previewCard/previewCardModel";
import {
  AppFormErrorText,
  AppButton,
  AppModal,
  AppInput,
  AppTextField,
} from "../../../theme";
import { validateFormInputForSpecialChar } from "../../../utils/helper-utility";
import { usePrevious } from "../../../utils/hooks";
import { APP_STATE } from "../../../store/reducers";
import { sortAppOrder } from "../../../utils/Constants";
import "./bundleAppSelect.scss";

interface IProps {
  projectDetail: ProjectDetailsModel;
  onBundleChange: Function;
  customizeHandler: Function;
  onAppSelect: Function;
  navigateToStep2handler: boolean;
}

const BundleAppSelect: FC<IProps> = (props) => {
  const [currentBundlePreview, setCurrentBundlePreview] = useState<PreviewCardModel>(
    {
      description: "",
      countMessage: "",
      count: 0,
      activeCount: 0,
      activeCountMessage: ""
    });
  const [currentAppCards, setCurrentAppCards] = useState<AppClass[]>([]);
  const [currentAppPlatforms, setCurrentAppPlatforms] = useState<AppPlatformClass[]>([]);
  const [ownBundleName, setOwnBundleName] = useState<string>("");
  const [ownBundleDescription, setOwnBundleDescription] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const apps = useSelector<APP_STATE, AppClass[]>(({ meta }) => meta.apps);
  const appCategories = useSelector<APP_STATE, AppCategory[]>(({ meta }) => meta.appCategories);
  const appPlatforms = useSelector<APP_STATE, AppPlatformClass[]>(({ meta }) => meta.appPlatforms);
  const masterBundles = useSelector<APP_STATE, BundleClass[]>(({ meta }) => meta.bundles);

  const dispatch = useDispatch();

  const bundles = useSelector<APP_STATE, BundleClass[]>(({ createProjectReducer }) => createProjectReducer.bundles);
  const isOwnBundleCreated = useSelector<APP_STATE, boolean>(({ createProjectReducer }) => createProjectReducer.isOwnBundleCreated);
  const ownBundleId = useSelector<APP_STATE, string>(({ createProjectReducer }) => createProjectReducer.ownBundleId);

  const { projectDetail } = props;
  const ownBundle = bundles.find((bundle) => bundle.isOwnBundle);
  const selectedBundle = bundles.find((bundle) => bundle.selected);
  const bundleId = selectedBundle?.id;

  const prevProps = usePrevious({ masterBundles, isOwnBundleCreated });

  const filterAppCardsByBundleId = useCallback((bundleId: string): AppClass[] => {
    const bundle = bundles.find((f) => f.id === bundleId);
    if (!bundle) {
      return [];
    }
    const { appClassIds } = bundle;

    return apps.filter((f) =>
      appClassIds
        .map((m) => m.id)
        .slice()
        .includes(f.id)
    );
  }, [apps, bundles]);

  const filterBundlePreviewDataByBundleId = useCallback((
    bundleId: string,
    appCount: number
  ): PreviewCardModel => {
    const bundleCard = bundles.find(
      (bundle) => bundle.id === bundleId
    );
    const bundlePreview: PreviewCardModel = {
      description: "",
      countMessage: "",
      count: appCount,
    };

    if (bundleCard) {
      const { selectedAppIds = [] } = bundleCard;

      bundlePreview.activeCount = selectedAppIds.length;
      bundlePreview.activeCountMessage =
        selectedAppIds.length > 1
          ? en_translation.active_apps_count_text
          : en_translation.active_app_count_text;
      bundlePreview.count = bundleCard.isOwnBundle ? 0 : appCount;
      bundlePreview.description = bundleCard.description;
      bundlePreview.countMessage =
        appCount > 1
          ? en_translation.apps_in_module
          : en_translation.app_in_module;
    }
    return bundlePreview;
  }, [bundles]);

  const setSelectedBundleCard = useCallback((bundleEvent: BundleEvent) => {
    const { onBundleChange, customizeHandler } = props;

    bundles.forEach((item) => {
      if (bundleEvent.selected?.status) {
        item.selected = item.id === bundleEvent.selected.id;
      }
    });
    if (bundleEvent.selected?.status) {
      let apps = filterAppCardsByBundleId(bundleEvent.selected.id);
      const updatedCurrentBundlePreview = filterBundlePreviewDataByBundleId(bundleEvent.selected.id, apps.length);
      apps = _.sortBy(apps, sortAppOrder);
      setCurrentAppCards(apps);
      setCurrentBundlePreview(updatedCurrentBundlePreview);
      setCurrentAppPlatforms(appPlatforms);

      onBundleChange({
        currentBundlePreview: updatedCurrentBundlePreview,
        currentAppCards: apps,
        currentAppPlatforms: appPlatforms,
        ownBundleName,
        ownBundleDescription,
        isModalOpen,
        dataLoaded,
        bundles
      })
    }

    if (bundleEvent.customize?.status) {
      customizeHandler();
    }
  }, [
    dataLoaded,
    filterAppCardsByBundleId,
    filterBundlePreviewDataByBundleId,
    isModalOpen,
    ownBundleDescription,
    ownBundleName,
    appPlatforms,
    bundles,
    props
  ]);

  const setDefaultBundleCard = useCallback(() => {
    const { projectDetail, navigateToStep2handler } = props;
    let defaultBundle;

    defaultBundle = bundles.find((bundle) => bundle.selected);
    if (
      navigateToStep2handler &&
      (!defaultBundle || !defaultBundle?.selectedAppIds?.length)
    ) {
      defaultBundle =
        projectDetail.id &&
        bundles.find((bundle) => bundle.id === projectDetail.id);
    }

    if (!defaultBundle) {
      defaultBundle = bundles[0];
    }
    const bundleData = {
      selected: { id: defaultBundle.id, status: true },
    };

    setSelectedBundleCard(bundleData);
  }, [props, bundles, setSelectedBundleCard]);

  const handleActiveCard = (activeApp, selected) => {
    const selectedBundle = bundles.find((bundle) => bundle.selected);

    if (!selectedBundle) {
      return;
    }
    let updatedActiveApps;
    const { selectedAppIds = [] } = selectedBundle;

    if (selected) {
      updatedActiveApps = selectedAppIds.find((app) => app.id === activeApp.id)
        ? [...selectedAppIds]
        : [...selectedAppIds, { id: activeApp.id }];
    } else {
      updatedActiveApps = selectedAppIds.filter(
        (app) => app.id !== activeApp.id
      );
    }
    if (selectedBundle.isOwnBundle) {
    }
    dispatch(createActionsType.updateActiveApps(updatedActiveApps));
    setActiveAppsPreview(updatedActiveApps);
    props.onAppSelect(updatedActiveApps, currentAppCards);
  };

  const setActiveAppsPreview = (selectedAppIds) => {
    currentBundlePreview.activeCount = selectedAppIds.length;
    currentBundlePreview.activeCountMessage =
      selectedAppIds.length > 1
        ? en_translation.active_apps_count_text
        : en_translation.active_app_count_text;
    setCurrentBundlePreview({ ...currentBundlePreview })
  };

  const getActiveStatus = (itemId) => {
    const selectedBundle = bundles.find((bundle) => bundle.selected);

    if (!selectedBundle) {
      return false;
    }
    const { selectedAppIds = [] } = selectedBundle;

    return !!selectedAppIds.find((app) => app.id === itemId);
  }

  const handleCreateOwnBundle = () => {
    const ownBundle = {
      id: ownBundleId,
      name: ownBundleName,
      description: ownBundleDescription,
      selected: true,
      key: ownBundleName,
      isOwnBundle: true,
      appClassIds: [],
    };

    dispatch(createActionsType.createOwnBundle([ownBundle]));
    handleModalState(false);
  };

  const navigateToBuildBundleScreen = useCallback((ownBundle) => {
    const bundleData = {
      selected: { id: ownBundle.id, status: true },
      customize: { id: ownBundle.id, status: true },
    };

    setSelectedBundleCard(bundleData);
  }, [setSelectedBundleCard]);

  const handleBuildBundleNameChange = ({ target: { value: ownBundleName } }) => {
    setOwnBundleName(ownBundleName);
  };

  const handleBuildBundleDescChange = ({
    target: { value: ownBundleDescription },
  }) => {
    setOwnBundleDescription(ownBundleDescription);
  };

  const handleModalState = (isModalOpen) => {
    setIsModalOpen(isModalOpen);
  };

  const resetModaldata = () => {
    setOwnBundleName("");
    setOwnBundleDescription("")
  };

  const handleModalClose = () => {
    resetModaldata();
    handleModalState(false);
  };

  const renderDescriptionInput = (label: string, value: string, name: string) => (
    <div className="project-form-field text-area-field own-bundle-desc">
      <AppTextField
        maxLength={4000}
        name={name}
        value={value}
        label={label}
        isValid={!validateFormInputForSpecialChar(value)}
        onChange={handleBuildBundleDescChange}
      />
      {validateFormInputForSpecialChar(value) && (
        <AppFormErrorText>
          {en_translation.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            value.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  const renderNameInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit
  ) => (
    <div
      className={`project-form-field ${fieldVal.trim().length && isvalid ? "validated-input" : ""
        }`}
    >
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        label={label}
        isValid={isvalid && !validateFormInputForSpecialChar(fieldVal)}
        onChange={handleBuildBundleNameChange}
      />
      {!isvalid ? (
        <AppFormErrorText>{en_translation.required_field}</AppFormErrorText>
      ) : (
        validateFormInputForSpecialChar(fieldVal) && (
          <AppFormErrorText>
            {en_translation.all_ssp_validate_special_character_in_text_error.replace(
              "{1}",
              fieldVal.trim().split("")[0]
            )}
          </AppFormErrorText>
        )
      )}
    </div>
  );

  useEffect(() => {
    !apps.length && dispatch(actionstype.getApps());
    !bundles.length && dispatch(actionstype.getBundles());
    !appCategories.length && dispatch(actionstype.getAppCategories());
    !appPlatforms.length && dispatch(actionstype.getAppPlatforms());
    bundles.length && setDefaultBundleCard();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !dataLoaded &&
      apps.length &&
      bundles.length &&
      appCategories.length &&
      appPlatforms.length
    ) {
      setDataLoaded(true);
      setDefaultBundleCard();
    }

    if (prevProps && prevProps.masterBundles !== masterBundles) {
      dispatch(createActionsType.getUpdatedBundles(_.cloneDeep(masterBundles)));
    }

    if (prevProps && prevProps.isOwnBundleCreated !== isOwnBundleCreated && isOwnBundleCreated) {
      props.onBundleChange({
        currentBundlePreview,
        currentAppCards,
        currentAppPlatforms,
        ownBundleName,
        ownBundleDescription,
        isModalOpen,
        dataLoaded,
        bundles
      });
      navigateToBuildBundleScreen(bundles[0]);
    }
  }, [
    dispatch,
    props,
    prevProps,
    currentAppCards,
    currentAppPlatforms,
    currentBundlePreview,
    dataLoaded,
    isModalOpen,
    navigateToBuildBundleScreen,
    ownBundleDescription,
    ownBundleName,
    setDefaultBundleCard,
    apps,
    appCategories,
    appPlatforms,
    bundles,
    isOwnBundleCreated,
    masterBundles
  ]);

  return (
    <div className="step2">
      <div className="row page-top">
        <div className="column single-column">
          <div className="eng-data">
            <ProjectDetails data={projectDetail} />
          </div>
        </div>
        <div className="triple-column">
          <div className="bundle-section">
            <div className="step2-header">
              <div className="select-label">
                {en_translation.select_bundle_label}
              </div>
              <div className="bundle-info">
                <div className="bundle-count">
                  <b>{bundles.length}</b> {en_translation.total_bundles}
                </div>
                <AppButton
                  disabled={!!ownBundle}
                  onClick={() => handleModalState(true)}
                  size="large"
                >
                  {en_translation.build_your_own}
                </AppButton>
              </div>
            </div>
            <div className="bundle-wrapper">
              {bundles.map((item, i) => (
                <BundleCard
                  key={i}
                  data={item}
                  bundleEventFn={setSelectedBundleCard}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row page-mid">
        <div className="column"></div>
        <div className="triple-column">
          <div className="bundle-wrapper">
            {bundles.map((item, index) => (
              <div key={index} className="arrow-box">
                <div className={item.selected ? "bottom-arrow" : ""}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row page-bottom">
        <div className="column single-column">
          <PreviewCard data={currentBundlePreview} />
        </div>
        <div className="triple-column">
          <div className="app-wrapper custom-scrollbar">
            {currentAppCards.map((item, index) => (
              <AppCard
                footerEnabled
                bundleId={bundleId}
                key={`${bundleId}-${item.id}`}
                data={item}
                appCategories={appCategories}
                moreMenuEnabled={false}
                showDemoBtn={true}
                descriptionEnabled={true}
                onActiveCard={handleActiveCard}
                isActive={getActiveStatus(item.id)}
                isDemoDisabled={selectedBundle?.isOwnBundle}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="build-bundle-modal">
        <AppModal
          showModal={isModalOpen}
          onModalClose={handleModalClose}
          onConfirm={handleCreateOwnBundle}
          cancelBtnText={en_translation.cancel}
          confirmBtnText={en_translation.create}
          title={en_translation.build_own_bundle_modal_title}
          disabledValue={
            !(
              ownBundleName.length &&
              ownBundleDescription.length &&
              !validateFormInputForSpecialChar(ownBundleName) &&
              !validateFormInputForSpecialChar(ownBundleDescription)
            )
          }
        >
          {renderNameInput(
            en_translation.build_own_bundle_name,
            ownBundleName,
            en_translation.build_own_bundle_name,
            true,
            100
          )}
          {renderDescriptionInput(
            en_translation.build_own_bundle_description,
            ownBundleDescription,
            en_translation.build_own_bundle_description
          )}
        </AppModal>
      </div>
    </div>
  );
}

export default BundleAppSelect;
