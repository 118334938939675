import { notificationStatus } from "../../utils/Constants";
import * as type from "../action-types";
import { NOTIFICATION } from "../actions/admin-portal-communication-actions";

export type PORTAL_COMMUNICATION_STATE = {
  status: string | null;
  generalNotifications: NOTIFICATION[];
  releaseNotesNotifications: NOTIFICATION[];
  surveyNotifications: NOTIFICATION[];
  adhocNewsNotifiocation: NOTIFICATION[];
};

const initialState = {
  status: null,
  generalNotifications: [] as NOTIFICATION[],
  releaseNotesNotifications: [] as NOTIFICATION[],
  surveyNotifications: [] as NOTIFICATION[],
  adhocNewsNotifiocation: [] as NOTIFICATION[],
};

// handles actions
// eslint-disable-next-line
export default (state: PORTAL_COMMUNICATION_STATE = initialState, action) => {
  const {Fetching,Saving}=notificationStatus
  switch (action.type) {
    case type.GET_NOTIFICATIONS:
      return {
        ...state,
        generalNotifications: [],
        releaseNotesNotifications: [],
        surveyNotifications: [],
        adhocNewsNotifiocation: [],
        status:Fetching,
      };
    case type.GET_GENERAL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        generalNotifications: action.payload,
        status: null,
      };
    case type.CREATE_GENERAL_NOTIFICATION:
    case type.UPDATE_GENERAL_NOTIFICATION:
      return {
        ...state,
        status: Saving,
      };
    case type.CREATE_GENERAL_NOTIFICATION_SUCCESS:
      //eslint-disable-next-line
      const _ = state.generalNotifications?.unshift(action.payload as never);
      return {
        ...state,
        generalNotifications: [...state.generalNotifications],
        status: null,
      };
    case type.UPDATE_GENERAL_NOTIFICATION_SUCCESS:
      const generalNotifications = state.generalNotifications?.filter(
        (noitification) => noitification.id !== action.payload.id
      );
      generalNotifications?.unshift(action.payload.data);
      return {
        ...state,
        status: null,
        generalNotifications: generalNotifications ?? [],
      };
    case type.DELETE_GENERAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        generalNotifications: state.generalNotifications.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };
    case type.GET_RELEASE_NOTES_NOTIFICATIONS:
      return {
        ...state,
        generalNotifications: [],
        releaseNotesNotifications: [],
        surveyNotifications: [],
        adhocNewsNotifiocation: [],
        status: Fetching,
      };
    case type.GET_RELEASE_NOTES_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        releaseNotesNotifications: action.payload,
        status: null,
      };
    case type.CREATE_RELEASE_NOTES_NOTIFICATION:
    case type.UPDATE_RELEASE_NOTES_NOTIFICATION:
      return {
        ...state,
        status: Saving,
      };
    case type.CREATE_RELEASE_NOTES_NOTIFICATION_SUCCESS:
      //eslint-disable-next-line
      const __ = state.releaseNotesNotifications?.unshift(
        action.payload as never
      );
      return {
        ...state,
        releaseNotesNotifications: [...state.releaseNotesNotifications],
        status: null,
      };
    case type.UPDATE_RELEASE_NOTES_NOTIFICATION_SUCCESS:
      const releaseNotifications = state.releaseNotesNotifications?.filter(
        (noitification) => noitification.id !== action.payload.id
      );
      releaseNotifications?.unshift(action.payload.data);
      return {
        ...state,
        status: null,
        releaseNotesNotifications: releaseNotifications ?? [],
      };
    case type.DELETE_RELEASE_NOTES_NOTIFICATION_SUCCESS:
      return {
        ...state,
        releaseNotesNotifications: state.releaseNotesNotifications.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };
    case type.GET_SURVEY_NOTIFICATIONS:
      return {
        ...state,
        generalNotifications: [],
        releaseNotesNotifications: [],
        surveyNotifications: [],
        adhocNewsNotifiocation: [],
        status: Fetching,
      };
    case type.GET_SURVEY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        surveyNotifications: action.payload,
        status: null,
      };
    case type.CREATE_SURVEY_NOTIFICATION:
    case type.UPDATE_SURVEY_NOTIFICATION:
      return {
        ...state,
        status: Saving,
      };
    case type.CREATE_SURVEY_NOTIFICATION_SUCCESS:
      //eslint-disable-next-line
      state.surveyNotifications?.unshift(action.payload as never);
      return {
        ...state,
        surveyNotifications: [...state.surveyNotifications],
        status: null,
      };
    case type.UPDATE_SURVEY_NOTIFICATION_SUCCESS:
      const surveyNotifications = state.surveyNotifications?.filter(
        (noitification) => noitification.id !== action.payload.id
      );
      surveyNotifications?.unshift(action.payload.data);
      return {
        ...state,
        status: null,
        surveyNotifications: surveyNotifications ?? [],
      };
    case type.DELETE_SURVEY_NOTIFICATION_SUCCESS:
      return {
        ...state,
        surveyNotifications: state.surveyNotifications.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };
    case type.GET_ADHOC_NEWS_NOTIFICATIONS:
      return {
        ...state,
        generalNotifications: [],
        releaseNotesNotifications: [],
        surveyNotifications: [],
        adhocNewsNotifiocation: [],
        status: Fetching,
      };
    case type.GET_ADHOC_NEWS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        adhocNewsNotifiocation: action.payload,
        status: null,
      };
    case type.CREATE_NOTIFICATION:
    case type.UPDATE_ADHOC_NEWS_NOTIFICATION:
      return {
        ...state,
        status: Saving,
      };
    case type.CREATE_ADHOC_NEWS_NOTIFICATION_SUCCESS:
      //eslint-disable-next-line
      state.adhocNewsNotifiocation?.unshift(action.payload as never);
      return {
        ...state,
        adhocNewsNotifiocation: [...state.adhocNewsNotifiocation],
        status: null,
      };
    case type.UPDATE_ADHOC_NEWS_NOTIFICATION_SUCCESS:
      const adhocNewsNotifiocation = state.adhocNewsNotifiocation?.filter(
        (noitification) => noitification.id !== action.payload.id
      );
      adhocNewsNotifiocation?.unshift(action.payload.data);
      return {
        ...state,
        status: null,
        adhocNewsNotifiocation: adhocNewsNotifiocation ?? [],
      };
    case type.DELETE_ADHOC_NEWS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        adhocNewsNotifiocation: state.adhocNewsNotifiocation.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };

    case type.GET_NOTIFICATIONS_FAILURE:
    case type.UPDATE_GENERAL_NOTIFICATION_FAILURE:
    case type.DELETE_GENERAL_NOTIFICATION_FAILURE:
    case type.UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE:
    case type.DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE:
    case type.UPDATE_SURVEY_NOTIFICATION_FAILURE:
    case type.DELETE_SURVEY_NOTIFICATION_FAILURE:
    case type.CREATE_NOTIFICATION_FAILURE:
    case type.UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE:
    case type.DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE:
      return {
        ...state,
        status: null,
      };

    default:
      return state;
  }
};
