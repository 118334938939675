import React from "react";
import "./appButtonWithIcon.scss";
import { IAppButtonWithIcon } from "../../models/IAppButtonWithIcon";

const AppButtonWithIcon: React.FC<IAppButtonWithIcon> = ({
  leftIcon,
  rightIcon,
  btnText,
  handleOnClick,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  return (
    <button
      className="app-button-with-icon"
      onClick={handleOnClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {leftIcon}
      <span
        className="btn-text"
        style={{ paddingRight: rightIcon ? "12px" : "0px" }}
      >
        {btnText}
      </span>
      {rightIcon}
    </button>
  );
};

export default AppButtonWithIcon;
