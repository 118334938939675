import React from "react";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";
import {
  DELETE_RELEASE_NOTES_NOTIFICATION_ACTION,
  GET_NOTIFICATIONS_ACTION,
  CREATE_NOTIFICATION_ACTION,
  UPDATE_RELEASE_NOTES_NOTIFICATION_ACTION,
  NOTIFICATION,
} from "../../../store/actions/admin-portal-communication-actions";
import "../adminPortalNotification.scss";
import CommonNotification from "../commonNotifications";
import { PORTAL_COMMS_RELEASE_NOTES_LABEL } from "../../../containers/adminGeneral/adminGeneralConstants";

const ReleaseNotifications = () => {
  const releaseNotesNotification = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ portalCommunications }) => portalCommunications.releaseNotesNotifications
  );
  return (
    <CommonNotification
      notificationTitle={PORTAL_COMMS_RELEASE_NOTES_LABEL}
      notificationType={"ReleaseNotes"}
      commonNotificationData={releaseNotesNotification}
      createNotificationAction={CREATE_NOTIFICATION_ACTION}
      GetNotificationAction={GET_NOTIFICATIONS_ACTION}
      updateNotificationAction={UPDATE_RELEASE_NOTES_NOTIFICATION_ACTION}
      deleteNotificationAction={DELETE_RELEASE_NOTES_NOTIFICATION_ACTION}
    />
  );
};

export default ReleaseNotifications;
