import React, { FC, useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import t from "../../localization/en/translation.json";
import { AppClass } from '../appCard/models/appClass';
import List from "../../components/editableGrid/list/list";
import { columnConfig, projectColumnConfig } from './columnConfig';
import { ProjectDetailsRowDataModel } from '../../containers/adminProjects/adminProjectsModel';
import { AppButton, AppModal, AppTooltip, AppIcon } from "../../theme";
import { copy, play } from "../../theme/icons"
import "./adminProjectsAppsModal.scss";

interface IProps {
  showModal: boolean,
  onAppsModalClose: Function;
  ProjectName: string;
  appDetails?: AppClass | {};
  showPreviewModal?: Function;
  projectRowDataModel?: ProjectDetailsRowDataModel[];
}

const AdminProjectsAppsModal: FC<IProps> = ({ showModal, onAppsModalClose, ProjectName, appDetails,
  showPreviewModal, projectRowDataModel
}) => { //NOSONAR

  let modalColumnConfig, rowData, title, gridApi;

  if (projectRowDataModel && projectRowDataModel.length) {
    modalColumnConfig = projectColumnConfig;
    rowData = projectRowDataModel;
    title = `${t.admin_projects_modal_details} ${ProjectName}`;
  } else {
    modalColumnConfig = columnConfig;
    rowData = appDetails;
    title = `${t.admin_projects_apps_details} ${ProjectName}`;
  }
  const [showLinkCopiedNotifiacation, setShowLinkCopiedNotifiacation] = useState(false);
  const [gridScrollPos, setGridScrollPos] = useState(0);
  const [stickyScroll, setStickyScroll] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", showStickyScroll);
    return () => {
      window.removeEventListener("scroll", showStickyScroll);
      window.removeEventListener("resize", onPageResize);
    }
    // eslint-disable-next-line
  }, []);

  const showStickyScroll = () => {
    const modalBody = document.querySelector(".admin-projects-apps-modal .MuiPaper-root");

    if (modalBody && stickyScroll !== ((modalBody.scrollTop + modalBody.clientHeight) < gridScrollPos)) {
      setStickyScroll(modalBody.scrollTop + modalBody.clientHeight < gridScrollPos);
    }
  }

  const checkScrollPosition = () => {
    const appTable: HTMLElement | null = document.querySelector(".project-app-table");
    const modalBody: HTMLElement | null = document.querySelector(".admin-projects-apps-modal .MuiPaper-root");
    const scrollElm: HTMLElement | null = document.querySelector(".app-sticky-scroll .ag-body-horizontal-scroll");
    const scrollMargin = 6;

    if (!appTable || !modalBody) {
      return
    }
    setGridScrollPos(appTable.getBoundingClientRect().top + appTable.clientHeight + modalBody.scrollTop);

    if (scrollElm && modalBody) {
      scrollElm.style.width = `${modalBody.clientWidth - scrollElm.clientHeight - scrollMargin}px`;
      scrollElm.style.top = `${modalBody.getBoundingClientRect().top + modalBody.clientHeight - scrollElm.clientHeight}px`;
    }
  }

  const onPageResize = () => {
    checkScrollPosition();
    gridApi?.sizeColumnsToFit();
  }

  const onGridReady = ({ api }) => {
    gridApi = api;
    gridApi?.sizeColumnsToFit();
    window.addEventListener("resize", onPageResize);
  };

  const copyLinkClick = () => {
    setShowLinkCopiedNotifiacation(true);
    setTimeout(() => setShowLinkCopiedNotifiacation(false), 3000);
  }

  const renderPreviewVideoRows = ({ value }) => {
    if (!value?.length) {
      return null
    }

    return (
      <React.Fragment>
        <div className="ag-react-container">
        <AppButton
          className="apps-play-button btn-link"
          onClick={() => showPreviewModal && showPreviewModal(value)}
          variant="text">
          <AppIcon icon={play} />
          {t.play_video_text}
        </AppButton>
        <CopyToClipboard text={value} onCopy={copyLinkClick}>
          <AppButton className="apps-copy-button btn-link" variant="text">
            <AppIcon icon={copy} />{t.copy_link_text}
          </AppButton>
        </CopyToClipboard>
        </div>
      </React.Fragment>
    )
  }

  const renderAppCategories = ({ value, data }) => {
    return (
      <div>
        <span style={{ backgroundColor: data.appCategoryColorCode }} className="category-color"></span>
        <span className="category-title">{value}</span>
      </div>
    )
  }

  const defaultColDef = {
    resizable: false,
    flex:1
    };


  const renderOwners = ({ value: owners }) => {
    if (!owners || !owners?.length) {
      return null
    }

    const ownerEmailIds = owners.map(owner => owner?.appOwnerEmail);
    const tooltipMsg =
      <div>
        {owners.map(owner => <div key={owner.appOwnerName}>{owner.regionName}: {owner.appOwnerName}</div>)}
      </div>;
      

    return (
      <React.Fragment>
           <div className="ag-react-container">
        <a
          className="apps-owner-button"
          href={`mailto:${ownerEmailIds.join(";")}`}
        >
          {owners[0]?.appOwnerName}
        </a>
        {owners.length > 1 &&
          <AppTooltip
            className="owner-count-tooltip"
            title={tooltipMsg}
            isWhite
          >
            <span className="owner-count">+ {owners.length - 1}</span>
          </AppTooltip>
        }
        </div>
      </React.Fragment>
    )
  }

  return (
    <AppModal
      size="lg"
      modalClass="admin-projects-apps-modal"
      showModal={showModal}
      onModalClose={() => onAppsModalClose()}
      title={title}
    >
      <div className={`project-app-table ${stickyScroll ? "app-sticky-scroll" : ""}`}>
        <List
          columnConfig={modalColumnConfig}
          defaultColDef={defaultColDef} 
          rowData={rowData}
          frameworkComponents={{
            playVideoCellRenderer: renderPreviewVideoRows,
            appCategoriesCellRenderer: renderAppCategories,
            appOwnersCellRenderer: renderOwners
          }}
          clientSideSorting
          onRowDataChanged={checkScrollPosition}
          onGridReady={onGridReady}
          paginate={false}
        />
      </div>
      {showLinkCopiedNotifiacation && <span className="link-copied-notification">{t.link_copied}</span>}
    </AppModal>
  );
};

export default AdminProjectsAppsModal;
