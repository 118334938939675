import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import {
    getProjectEMGPolicySuccess,
    getProjectEMGPolicyFailure,
    acceptProjectEMGPolicySuccess,
    acceptProjectEMGPolicyFailure
} from "../actions/emg-policy-actions";
import { getEMGPolicy, accpetEMGPolicy } from '../apis/projectApi';

function* getEMGProjectPolicy(action) {
    try {
        const data = yield call(getEMGPolicy, action);
        yield put(getProjectEMGPolicySuccess(data.data));
    } catch ({ message }) {
        yield put(getProjectEMGPolicyFailure(message));
    }
}

function* acceptEMGProjectPolicy(action) {
    try {
        const data = yield call(accpetEMGPolicy, action);
        yield put(acceptProjectEMGPolicySuccess(data.data));
    } catch ({ message }) {
        yield put(acceptProjectEMGPolicyFailure(message));
    }
}

export function* emgPolicySagaWatcher() {
    yield takeLatest(type.GET_PROJECT_EMG_POLICY_REQUEST, getEMGProjectPolicy);
    yield takeLatest(type.ACCEPT_PROJECT_EMG_POLICY_REQUEST, acceptEMGProjectPolicy);
}
