import * as type from "../action-types";
import { IAcceptPolicies } from '../../models/IAcceptPolicies';

const initialState: {
  acceptedPolicyData: IAcceptPolicies | {},
  loading: boolean
} = {
  acceptedPolicyData: {},
  loading: false
};

// handles actions
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ACCEPTED_POLICY_REQUEST:
      return { ...state, loading: true };
    case type.GET_ACCEPTED_POLICY_SUCCESS:
      return { ...state, acceptedPolicyData: { ...action.payload }, loading: false };
    case type.GET_ACCEPTED_POLICY_FAILURE:
      return { ...state, acceptedPolicyData: { ...action.payload, success: false }, loading: false };
    default:
      return state;
  }
};
