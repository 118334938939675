import React, { FC } from 'react';
import t from '../../../localization/en/translation.json';
import { AppClass } from '../../appCard/models/appClass';
import { AppCategory } from '../../appCard/models/appCategory';
import './customizeBundleDetails.scss';

interface IProps {
  appCard: AppClass;
  appCategory:AppCategory;
}

const CustomizeBundleDetails: FC<IProps> = (props) => {
  let iconPath;
  try {
      iconPath = require('../../../assets/images/icons/'+props.appCategory?.iconName);
  } catch (err) {
      iconPath='';
  }
  const iconBackgroundColor = {
    background: props.appCategory?.colorCode
};
    return (
        <div className="customize-bundle-details">
            <div className="app-row">
               <div className="app-col-xs-4 app-col-lg-12">
                 <div className="cust-bundle-detail-header">
                  <div className="app-row margin-zero">
                    <div className="cust-app-card-icon">
                      <div className="icon" style={iconBackgroundColor}>
                      {
                                iconPath && <img src={iconPath} className="img-app-card" alt="icon" />
                      }
                      </div>
                    </div>
                    <div className="cust-app-card-title">
                      {props.appCard.name}
                    </div>
                  </div>
                 </div>
               </div>
            </div>
            <div className="app-row">
              <div className="app-col-xs-4 app-col-lg-12">
                <div className="cust-bundle-header-title cust-category"> {t.customize_bundle_details_category}</div>
                <p>{props.appCategory.name} </p>
                </div>
              </div>
              <div className="app-row">
              <div className="app-col-xs-4 app-col-lg-12">
                  <div className="cust-bundle-header-title cust-details-view-title">{t.customize_bundle_details} </div>
                  <p>{props.appCard.description}</p>
                </div>
              </div>
        </div>
    )
}

export default CustomizeBundleDetails;
