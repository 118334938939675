import React, { FC } from "react";
import { AppIcon } from "../../theme";
import { user } from "../../theme/icons";
import MoreMenu from "../moreMenu/moreMenu";
import { MoreMenuOption } from "../moreMenu/moreMenuOptions";
import "./dropDownMenu.scss";

export interface IDropDownProps {
  controlId: string;
  controlTitle?: string;
  imageData?: string;
  menuOptions: MoreMenuOption[];
  onOptionClick: (actionName: string) => void;
}

const DropDownMenu: FC<IDropDownProps> = (props) => {
  const { controlId, controlTitle, menuOptions, imageData, onOptionClick } =
    props;

  return (
    <div className="dropdown-menu">
      <MoreMenu
        className="user-profile-menu-pop"
        controlId={controlId}
        optionClickFn={onOptionClick}
        options={menuOptions}
        stopEventBubbling
        menuOpener={
          <>
            {imageData ? (
              <div className="user-avatar image-cropper">
                <img
                  src={"data:image/png;base64," + imageData}
                  className="rounded"
                  alt={controlTitle}
                  title={controlTitle}
                />
                <div className="image-overlay"></div>
              </div>
            ) : (
              <div className="user-icon">
                <AppIcon icon={user} />
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default DropDownMenu;
