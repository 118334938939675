import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { fetchProjectStatus } from '../apis/projectApi';

function* getProjectStatus(action) {
	try {
    const data = yield call(fetchProjectStatus);
    yield put({
      type: type.GET_PROJECT_STATUS_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_PROJECT_STATUS_FAILURE,
      payload: message
    });
  }
}

export function* getProjectStatusActionWatcher() {
  yield takeLatest(type.GET_PROJECT_STATUS, getProjectStatus)
}
