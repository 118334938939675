import { NotificationColumnField } from "./adminPortalComunicationConstant";
import t from "../../localization/en/translation.json";
export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
    sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.portal_communication_filed_notification_caption,
    cellRenderer: "renderTitleCell",
    width: 300, //, sortable: true, filter: "customFilter"
  },
  {
    headerName: t.admin_portal_communication_notification_activeFrom,
    field: NotificationColumnField.activeFrom,
    cellRenderer: "dateCellRender",
    width: 250, //, sortable: true, filter: "customFilter", filterType: "date"
  },
  {
    headerName: t.admin_portal_communication_notification_activeTo,
    field: NotificationColumnField.activeTo,
    cellRenderer: "dateCellRender",
    width: 250, //, sortable: true, filter: "customFilter", filterType: "date"
  },
  {
    headerName: t.admin_portal_communication_notification_modifiedOn,
    field: NotificationColumnField.modifiedOn,
    cellRenderer: "dateCellRender",
    width: 250, //, sortable: true, filter: "customFilter", filterType: "date"
  },
  {
    headerName: t.admin_portal_communication_notification_modifiedBy,
    field: NotificationColumnField.modifiedBy,
    cellRenderer: "renderEmailCell",
    tooltipField: "modifiedByEmailAddress",
    width: 350, //, sortable: true, filter: "customFilter"
  },
  {
    headerName: t.admin_portal_communication_notification_createdOn,
    field: NotificationColumnField.createdOn,
    cellRenderer: "dateCellRender",
    width: 250, //, sortable: true, filter: "customFilter", filterType: "date"
  },
  {
    headerName: t.admin_portal_communication_notification_createdBy,
    field: NotificationColumnField.createdBy,
    cellRenderer: "renderEmailCell",
    tooltipField: "createdByEmailAddress",
    width: 350, //, sortable: true, filter: "customFilter"
  },
];
