import React, { FC } from 'react';
import t from '../../localization/en/translation.json';
import { AppButton, AppTooltip } from "../../theme";
import './stepIndicator.scss';

export enum Status {
	Start,
	Inprogress,
	Complete
}

interface IProps {
	stepIndicatorState: {
		currentStep: number,
		step1Progress: Status,
		step2Progress: Status,
		step3Progress: Status
	}
	handleStepClick: Function,
	createProjectEnable: boolean,
	projectName?: string,
	disableAll: boolean,
	disableContinueBtn: boolean,
	showContinueBtn: boolean,
	showBackBtn: boolean,
	onBackBtnClick: Function
	onContinueBtnClick: Function,
	isProjectDrafted: boolean
}

const StepIndicator: FC<IProps> = ({
	stepIndicatorState,
	handleStepClick,
	createProjectEnable,
	projectName,
	showContinueBtn,
	showBackBtn,
	disableAll,
	onBackBtnClick,
	onContinueBtnClick,
	disableContinueBtn,
	isProjectDrafted
}) => {
	const stepData = [
		{
			step_text: t.step_one,
			title_text: t.step_one_title
		},
		{
			step_text: t.step_two,
			title_text: t.step_two_title
		}
	];

	if (createProjectEnable || (!createProjectEnable && isProjectDrafted)) {
		stepData.push({
			step_text: t.step_three,
			title_text: t.step_three_confim_title
		})
	}

	const getStepTooltipStatus = (step) => {
		return !(stepIndicatorState.currentStep < step && stepIndicatorState[`step${step - 1}Progress`] !== Status.Complete);
	}

	const stepElements = () => {

		return stepData.map((item, index) => (
			<div className="step" key={`step${index}`}>
				<div
					className={`indicator-wrapper ${(stepIndicatorState.currentStep === (index + 1) && "active-step")}`}
					id={`${index + 1}`}
					onClick={(e) => handleStepClick(e.currentTarget.id)}>
					<AppTooltip
						placement="bottom"
						hide={getStepTooltipStatus(index + 1)}
						title={t.step_unaccesible_msg}
						isWhite
					>
						<>
							<span className="radio-btn">
								{stepIndicatorState[`step${index + 1}Progress`] === Status.Inprogress
									&& <span className={"circle in-progress"} />}
								{stepIndicatorState[`step${index + 1}Progress`] === Status.Complete
									&& <span className="circle complete"><span className="tick" /></span>}
							</span>
							<span className={`text-tile ${(stepIndicatorState.currentStep === (index + 1) && "border-btm")}`}>
								<span className="step">{item.step_text}</span>
								<span className="step-title">{item.title_text}</span>
							</span>
						</>
					</AppTooltip>
				</div>
				{index !== (stepData.length - 1) && <span className="arrow" />}
			</div>
		));
	}

	return (
		<div className="step-indicator">
			<div className="title-wrapper">
				<span className="title">
					{
						!createProjectEnable && <span>
							{t.home_btn_edit_project} {projectName}
						</span>
					}
					{createProjectEnable && t.create_new}
				</span>
				{createProjectEnable && <span className="sub-title">{t.completed_steps} {stepIndicatorState.currentStep - 1}/{stepData.length}</span>}
			</div>
			{stepElements()}
			<div className="step-btns">
				{showBackBtn && <AppButton className="right-btns back-btn" variant="text" disabled={disableAll} onClick={() => onBackBtnClick()}>
					{t.back}</AppButton>}
				{showBackBtn && showContinueBtn ? <span className="divider">|</span> : ""}
				{showContinueBtn && <AppButton className="right-btns" variant="text" disabled={disableAll || disableContinueBtn} onClick={() => onContinueBtnClick()}>
					{t.continue}
				</AppButton>}
			</div>
		</div>
	)
}

export default StepIndicator;
