import { put, takeLatest, call } from "redux-saga/effects";
import * as type from "../action-types";
import {
  getAdminRegions,
  updateAdminRegions,
  getAdminClientSizes,
  updateAdminClientSizes,
  getAdminSectors,
  updateAdminSectors,
  getAdminDataHostingLoc,
  getAdminTypes,
  updateAdminTypes,
  addNotification,
  updateNotification,
  deleteNotification,
  fetchNotifications,
  getAllAdminSurveys,
  deleteAdminSurvey,
  addAdminSurvey,
  updateAdminSurvey,
  fetchSurveyListDropdownItems,
} from "../apis/projectApi";
import {
  getAdminRegionsSuccess,
  getAdminRegionsFailure,
  updateAdminRegionsFailure,
  updateAdminRegionsSuccess,
  getAdminClientSizesSuccess,
  getAdminClientSizesFailure,
  updateAdminClientSizesSuccess,
  updateAdminClientSizesFailure,
  getAdminSectorsFailure,
  getAdminSectorsSuccess,
  updateAdminSectorsFailure,
  updateAdminSectorsSuccess,
  getAdminDataHostingLocSuccess,
  getAdminDataHostingLocFailure,
  getAdminTypesFailure,
  getAdminTypesSuccess,
  updateAdminTypesFailure,
  updateAdminTypesSuccess,
  getAdminSurveySuccess,
  deleteSurveyConfirmSuccess,
  getAdminSurveyFailure,
  deleteSurveyFailure,
  addAdminSurveyFailure,
  UpdateAdminSurveyFailure,
  setSurveyDurationQuarter,
} from "../actions/admin-general-actions";
import {
  ON_CREATE_GENERAL_NOTIFICATION,
  ON_CREATE_RELEASE_NOTES_NOTIFICATION,
  ON_CREATE_SURVEY_NOTIFICATION,
  ON_CREATE_ADHOC_NEWS_NOTIFICATION,
  ON_CREATE_NOTIFICATION_FAILURE,
  ON_DELETE_GENERAL_NOTIFICATION,
  ON_DELETE_GENERAL_NOTIFICATION_FAILURE,
  ON_DELETE_RELEASE_NOTES_NOTIFICATION,
  ON_DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE,
  ON_DELETE_SURVEY_NOTIFICATION,
  ON_DELETE_SURVEY_NOTIFICATION_FAILURE,
  ON_DELETE_ADHOC_NEWS_NOTIFICATION,
  ON_DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE,
  ON_GENERAL_NOTIFICATIONS_ACTION,
  ON_NOTIFICATIONS_FAILURE,
  ON_RELEASE_NOTES_NOTIFICATIONS_ACTION,
  ON_SURVEY_NOTIFICATIONS_ACTION,
  ON_ADHOC_NEWS_NOTIFICATIONS_ACTION,
  ON_UPDATE_GENERAL_NOTIFICATION,
  ON_UPDATE_GENERAL_NOTIFICATION_FAILURE,
  ON_UPDATE_RELEASE_NOTES_NOTIFICATION,
  ON_UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE,
  ON_UPDATE_SURVEY_NOTIFICATION,
  ON_UPDATE_SURVEY_NOTIFICATION_FAILURE,
  ON_UPDATE_ADHOC_NEWS_NOTIFICATION,
  ON_UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE,
} from "../actions/admin-portal-communication-actions";
import { showActionToast } from "../actions/notification-actions";
import { GET_ALL_ACTIVE_NOTIFICATIONS_ACTION } from "../actions/me-actions";
import t from "../../localization/en/translation.json";

function* getRegions(action) {
  try {
    const { data } = yield call(getAdminRegions, action);
    yield put(getAdminRegionsSuccess(data));
  } catch ({ message }) {
    yield put(getAdminRegionsFailure(message));
  }
}

function* updateRegions(action) {
  try {
    const { data } = yield call(updateAdminRegions, action);
    yield put(updateAdminRegionsSuccess(data));
  } catch ({ message }) {
    yield put(updateAdminRegionsFailure(message));
  }
}

function* getClientSizes(action) {
  try {
    const { data } = yield call(getAdminClientSizes, action);
    yield put(getAdminClientSizesSuccess(data));
  } catch ({ message }) {
    yield put(getAdminClientSizesFailure(message));
  }
}

function* updateClientSizes(action) {
  try {
    const { data } = yield call(updateAdminClientSizes, action);
    yield put(updateAdminClientSizesSuccess(data));
  } catch ({ message }) {
    yield put(updateAdminClientSizesFailure(message));
  }
}

function* getSectors(action) {
  try {
    const { data } = yield call(getAdminSectors, action);
    yield put(getAdminSectorsSuccess(data));
  } catch ({ message }) {
    yield put(getAdminSectorsFailure(message));
  }
}

function* updateSectors(action) {
  try {
    const { data } = yield call(updateAdminSectors, action);
    yield put(updateAdminSectorsSuccess(data));
  } catch ({ message }) {
    yield put(updateAdminSectorsFailure(message));
  }
}

function* getDataHostingLoc(action) {
  try {
    const { data } = yield call(getAdminDataHostingLoc, action);
    yield put(getAdminDataHostingLocSuccess(data));
  } catch ({ message }) {
    yield put(getAdminDataHostingLocFailure(message));
  }
}

function* getTypes(action) {
  try {
    const { data } = yield call(getAdminTypes, action);
    yield put(getAdminTypesSuccess(data));
  } catch ({ message }) {
    yield put(getAdminTypesFailure(message));
  }
}

function* updateTypes(action) {
  try {
    const { data } = yield call(updateAdminTypes, action);
    yield put(updateAdminTypesSuccess(data));
  } catch ({ message }) {
    yield put(updateAdminTypesFailure(message));
  }
}

function* getNotifications(action) {
  try {
    const {notificationType}=action
    const { data } = yield call(fetchNotifications,notificationType);
    switch (notificationType) {
      case "AdHocNews":
        yield put(ON_ADHOC_NEWS_NOTIFICATIONS_ACTION(data));
        break;
        case "General":
          yield put(ON_GENERAL_NOTIFICATIONS_ACTION(data));
          break;
        case "ReleaseNotes":
          yield put(ON_RELEASE_NOTES_NOTIFICATIONS_ACTION(data));
          break;
          case "Survey":
            yield put(ON_SURVEY_NOTIFICATIONS_ACTION(data));
            break;
        }
  } catch ({ message }) {
    yield put(ON_NOTIFICATIONS_FAILURE());
  }
}

function* updateGeneralNotification(action) {
  try {
    const { data } = yield call(updateNotification, action.payload);
    yield put(ON_UPDATE_GENERAL_NOTIFICATION({ data, id: action.payload.id }));
    yield put(
      showActionToast({
        message: "General information notification is updated!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_UPDATE_GENERAL_NOTIFICATION_FAILURE());
  }
}

function* deleteGeneralNotification(action) {
  try {
    yield call(deleteNotification, action.payload);
    yield put(ON_DELETE_GENERAL_NOTIFICATION(action.payload));
    yield put(
      showActionToast({
        message: "General information notification is deleted!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_DELETE_GENERAL_NOTIFICATION_FAILURE());
  }
}

function* updateReleaseNotesNotification(action) {
  try {
    const { data } = yield call(updateNotification, action.payload);
    yield put(
      ON_UPDATE_RELEASE_NOTES_NOTIFICATION({ data, id: action.payload.id })
    );
    yield put(
      showActionToast({
        message: "Release notification is updated!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE());
  }
}

function* updateSurveyNotification(action) {
  try {
    const { data } = yield call(updateNotification, action.payload);
    yield put(ON_UPDATE_SURVEY_NOTIFICATION({ data, id: action.payload.id }));
    yield put(
      showActionToast({
        message: "Survey reminder notification is updated!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_UPDATE_SURVEY_NOTIFICATION_FAILURE());
  }
}

function* deleteSurveyNotification(action) {
  try {
    yield call(deleteNotification, action.payload);
    yield put(ON_DELETE_SURVEY_NOTIFICATION(action.payload));
    yield put(
      showActionToast({
        message: "Survey reminder notification is deleted!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_DELETE_SURVEY_NOTIFICATION_FAILURE());
  }
}

function* createNotification(action) {
  try {
    const { data } = yield call(addNotification, action.payload);
    const {type}=action.payload
   switch (type) {
      case "AdHocNews":
        yield put(ON_CREATE_ADHOC_NEWS_NOTIFICATION(data));
        yield put(
          showActionToast({
            message: "Adhoc news & update notification is created!",
            type: "success",
          })
        );
        break;
        case "General":
          yield put(ON_CREATE_GENERAL_NOTIFICATION(data));
          yield put(
            showActionToast({
              message: "General information notification is created!",
              type: "success",
            })
          );
          break;
        case "ReleaseNotes":
          yield put(ON_CREATE_RELEASE_NOTES_NOTIFICATION(data));
          yield put(
            showActionToast({
              message: "Release notification is created!",
              type: "success",
            })
          );
          break;
          case "Survey":
            yield put(ON_CREATE_SURVEY_NOTIFICATION(data));
            yield put(
              showActionToast({
                message: "Survey reminder notification is created!",
                type: "success",
              })
            );
            break;
        }
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_CREATE_NOTIFICATION_FAILURE());
  }
}

function* updateAdhocNewsNotification(action) {
  try {
    const { data } = yield call(updateNotification, action.payload);
    yield put(
      ON_UPDATE_ADHOC_NEWS_NOTIFICATION({ data, id: action.payload.id })
    );
    yield put(
      showActionToast({
        message: "Adhoc news & update notification is updated!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE());
  }
}

function* deleteAdhocNewsNotification(action) {
  try {
    yield call(deleteNotification, action.payload);
    yield put(ON_DELETE_ADHOC_NEWS_NOTIFICATION(action.payload));
    yield put(
      showActionToast({
        message: "Adhoc news & update notification is deleted!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE());
  }
}
function* deleteReleaseNotesNotification(action) {
  try {
    yield call(deleteNotification, action.payload);
    yield put(ON_DELETE_RELEASE_NOTES_NOTIFICATION(action.payload));
    yield put(
      showActionToast({
        message: "Release notification is deleted!",
        type: "success",
      })
    );
    yield put(GET_ALL_ACTIVE_NOTIFICATIONS_ACTION());
  } catch ({ message }) {
    yield put(ON_DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE());
  }
}

function* getSurveyList() {
  try {
    const { data } = yield call(getAllAdminSurveys);
    yield put(getAdminSurveySuccess(data));
  } catch ({ message }) {
    yield put(getAdminSurveyFailure());
  }
}

function* deleteSurvey(action) {
  try {
    yield call(deleteAdminSurvey, action);
    yield put(deleteSurveyConfirmSuccess(action.payload));
    yield put(
      showActionToast({
        message: t.admin_survey_delete_success,
        type: "success",
      })
    );
  } catch ({ message }) {
    yield put(deleteSurveyFailure());
  }
}

function* addSurvey(action) {
  try {
    yield call(addAdminSurvey, action);
    yield call(getSurveyList);
    yield put(
      showActionToast({
        message: t.admin_survey_add_success,
        type: "success",
      })
    );
  } catch ({ message }) {
    yield put(addAdminSurveyFailure());
  }
}

function* UpdateSurvey(action) {
  try {
    yield call(updateAdminSurvey, action);
    yield call(getSurveyList);
    yield put(
      showActionToast({
        message: t.admin_survey_update_success,
        type: "success",
      })
    );
  } catch ({ message }) {
    yield put(UpdateAdminSurveyFailure());
  }
}

function* getSurveyDropdownItems(payload) {
  try {
    const { data } = yield call(fetchSurveyListDropdownItems, payload);
    yield put(setSurveyDurationQuarter(data));
  } catch ({ message }) {
    yield put(getAdminSurveyFailure());
  }
}
export function* adminGeneralSagaWatcher() {
  yield takeLatest(type.GET_ADMIN_REGIONS_REQUEST, getRegions);
  yield takeLatest(type.UPDATE_ADMIN_REGIONS_REQUEST, updateRegions);
  yield takeLatest(type.GET_ADMIN_CLIENT_SIZES_REQUEST, getClientSizes);
  yield takeLatest(type.UPDATE_ADMIN_CLIENT_SIZES_REQUEST, updateClientSizes);
  yield takeLatest(type.GET_ADMIN_SECTORS_REQUEST, getSectors);
  yield takeLatest(type.UPDATE_ADMIN_SECTORS_REQUEST, updateSectors);
  yield takeLatest(type.GET_ADMIN_DATA_HOSTING_LOC_REQUEST, getDataHostingLoc);
  yield takeLatest(type.GET_ADMIN_TYPES_REQUEST, getTypes);
  yield takeLatest(type.UPDATE_ADMIN_TYPES_REQUEST, updateTypes);

  yield takeLatest(type.GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(type.UPDATE_GENERAL_NOTIFICATION, updateGeneralNotification);
  yield takeLatest(type.DELETE_GENERAL_NOTIFICATION, deleteGeneralNotification);

  yield takeLatest(
    type.UPDATE_RELEASE_NOTES_NOTIFICATION,
    updateReleaseNotesNotification
  );
  yield takeLatest(
    type.DELETE_RELEASE_NOTES_NOTIFICATION,
    deleteReleaseNotesNotification
  );

  yield takeLatest(type.UPDATE_SURVEY_NOTIFICATION, updateSurveyNotification);
  yield takeLatest(type.DELETE_SURVEY_NOTIFICATION, deleteSurveyNotification);

  yield takeLatest(
    type.CREATE_NOTIFICATION,
    createNotification
  );
  yield takeLatest(
    type.UPDATE_ADHOC_NEWS_NOTIFICATION,
    updateAdhocNewsNotification
  );
  yield takeLatest(
    type.DELETE_ADHOC_NEWS_NOTIFICATION,
    deleteAdhocNewsNotification
  );
  yield takeLatest(type.GET_ADMIN_SURVEY, getSurveyList);
  yield takeLatest(type.DELETE_SURVEY_CONFIRM, deleteSurvey);
  yield takeLatest(type.ADD_ADMIN_SURVEY, addSurvey);
  yield takeLatest(type.UPDATE_ADMIN_SURVEY, UpdateSurvey);
  yield takeLatest(
    type.GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS,
    getSurveyDropdownItems
  );
}
