import { IAdminReport, IAdminReportToken } from "../../models/IAdminReport";
import { IPowerBiReport } from "../../models/IPowerBiReport";
import * as type from "../action-types";

const initialState: {
    powerBiReports: IPowerBiReport[],
    reports: IAdminReport[],
    loading: boolean,
    action: string,
    error: boolean,
    accessToken: IAdminReportToken
} = {
    powerBiReports: [], reports: [], loading: false, action: "", error: false, accessToken: {
        token: "",
        tokenId: ""
    }
}
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_POWERBI_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                action: "POWERBI_LIST"
            }
        case type.GET_ADMIN_REPORTS_REQUEST:
            return {
                ...state,
                loading: true,
                action: "LIST"
            }
        case type.ADD_ADMIN_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                action: "ADD",
                error: false
            }
        case type.UPDATE_ADMIN_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                action: "UPDATE",
                error: false
            }
        case type.DELETE_ADMIN_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                action: "DELETE",
                error: false
            }
        case type.GET_POWERBI_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                action: "TOKEN",
                error: false
            }
        case type.GET_POWERBI_TOKEN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.data,
                loading: false,
            }
        case type.GET_POWERBI_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                powerBiReports: action.payload.data
            }
        case type.GET_POWERBI_REPORTS_FAILURE:
            return {
                ...state,
                loading: false,
                powerBiReports: []
            }
        case type.GET_ADMIN_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: action.payload.data
            }
        case type.GET_ADMIN_REPORTS_FAILURE:
            return {
                ...state,
                reports: [],
                loading: false,
            }
        case type.ADD_ADMIN_REPORT_SUCCESS:
        case type.UPDATE_ADMIN_REPORT_SUCCESS:
        case type.DELETE_ADMIN_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            }
        case type.GET_POWERBI_TOKEN_FAILURE:
        case type.ADD_ADMIN_REPORT_FAILURE:
        case type.UPDATE_ADMIN_REPORT_FAILURE:
        case type.DELETE_ADMIN_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}
