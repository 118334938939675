import React, { FC } from 'react';
import t from '../../localization/en/translation.json';
import { AppButton, AppIcon } from "../../theme";
import { add } from "../../theme/icons";
import './customizeBundleHeader.scss';

interface IProps {
	addAppHandler: Function;
	isOwnBundle?: boolean;
}

const CustomizeBundleHeader: FC<IProps> = ({ addAppHandler, isOwnBundle }) => {
	return (
		<div className="customize-bundle-header">
			<div className="title-wrapper">
				<span className="title">{isOwnBundle ? t.build_bundle_page_title : t.customize_bundle_header_title}</span>
			</div>
			<div className="app-btn-wrapper">
				<AppButton className="app-btn" variant="text" onClick={() => addAppHandler()}>
					<AppIcon icon={add} /> {t.customize_bundle_header_add_application}
				</AppButton>
			</div>
		</div>
	)
}

export default CustomizeBundleHeader;
