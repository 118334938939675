import * as type from "../action-types";

const initialState = {
  projectStatus: [],
  loading: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROJECT_STATUS:
      return { ...state, loading: true };
    case type.GET_PROJECT_STATUS_SUCCESS:
      return { ...state, projectStatus: action.payload.data, loading: false };
    case type.GET_PROJECT_STATUS_FAILURE:
      return { ...state, projectStatus: [], loading: false };
    default:
      return state;
  }
};
