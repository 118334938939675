import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import rootReducer from "./store/reducers";
import rootSaga from "./store/sagas";
import App from "./App";
import httpService from "./utils/interceptor";
import { authProvider } from "./utils/authProvider";
import { MsalProvider } from "@azure/msal-react";
import Loader from "./components/loader/loader";
import { PublicClientApplication } from "@azure/msal-browser";
import "./IdleTimeout";
import AuthenticationLoader from "./AuthenticationLoader";
import { getServerTime } from "./store/actions/settings-actions";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
httpService.setupInterceptors(store);
const publicURL = process.env.PUBLIC_URL || "";
const basename = publicURL.split("ey.com");
const theme = createTheme({
  typography: {
    fontFamily: "EYInterstate-Regular, sans-serif",
  },
});

const RootComponent = () => {
  const [pca, setPca] = useState<PublicClientApplication | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const initializeApp = async (): Promise<void> => {
      setLoading(true);
      try {
        store.dispatch(getServerTime());
        const pcaInstance = await authProvider.initializeAuthProvider();
        setPca(pcaInstance);
      } catch (error) {
        console.error('Error initializing app:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeApp();
  }, []);

  if (loading || pca === null) {
    return <Loader isLoading={true} />;
  }

  return (
    <Provider store={store}>
      <Router basename={(basename.length > 1 && basename[1]) || "/"}>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={pca}>
            <AuthenticationLoader>
              <App />
            </AuthenticationLoader>
          </MsalProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById('root'));
