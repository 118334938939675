import React from "react";
export const returnSvgImage = (key) => {
  if (key === "telescope") {
    return (
      <svg
        width="67"
        height="66"
        viewBox="0 0 67 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.5844 40.8223L50.0006 60.4993M30.4168 40.8223L17.0006 60.4993M39.0006 36.2994C39.0006 39.337 36.5382 41.7994 33.5006 41.7994C30.4631 41.7994 28.0006 39.337 28.0006 36.2994C28.0006 33.2619 30.4631 30.7994 33.5006 30.7994C36.5382 30.7994 39.0006 33.2619 39.0006 36.2994ZM47.659 12.4632L15.2522 21.1466C14.5084 21.3459 14.1365 21.4455 13.8911 21.6664C13.6754 21.8607 13.5266 22.1183 13.4663 22.4024C13.3976 22.7253 13.4973 23.0972 13.6966 23.841L16.1165 32.8724C16.3158 33.6163 16.4155 33.9882 16.6364 34.2335C16.8307 34.4493 17.0883 34.598 17.3723 34.6584C17.6952 34.727 18.0671 34.6274 18.811 34.4281L51.2178 25.7447L47.659 12.4632ZM60.4297 26.1234C57.4544 26.9206 55.9667 27.3192 54.6751 27.0447C53.539 26.8032 52.5085 26.2082 51.7313 25.3451C50.8478 24.3638 50.4492 22.8762 49.6519 19.9008L49.2249 18.307C48.4276 15.3317 48.029 13.844 48.3036 12.5525C48.545 11.4164 49.14 10.3859 50.0032 9.60869C50.9844 8.72515 52.4721 8.32653 55.4474 7.52929C56.1913 7.32998 56.5632 7.23033 56.8861 7.29896C57.1701 7.35933 57.4277 7.50807 57.622 7.72386C57.8429 7.96918 57.9425 8.34109 58.1419 9.08492L61.9853 23.4289C62.1846 24.1728 62.2843 24.5447 62.2157 24.8676C62.1553 25.1516 62.0065 25.4092 61.7908 25.6035C61.5454 25.8244 61.1735 25.9241 60.4297 26.1234ZM10.1303 33.907L13.8492 32.9106C14.593 32.7112 14.9649 32.6116 15.2102 32.3907C15.426 32.1964 15.5747 31.9388 15.6351 31.6548C15.7038 31.3319 15.6041 30.96 15.4048 30.2161L14.4083 26.4973C14.209 25.7535 14.1094 25.3816 13.8885 25.1362C13.6942 24.9205 13.4366 24.7717 13.1525 24.7113C12.8296 24.6427 12.4577 24.7424 11.7139 24.9417L7.99508 25.9381C7.25125 26.1374 6.87933 26.2371 6.63401 26.458C6.41823 26.6523 6.26949 26.9099 6.20911 27.1939C6.14048 27.5168 6.24014 27.8887 6.43945 28.6326L7.4359 32.3514C7.63521 33.0952 7.73486 33.4671 7.95575 33.7124C8.15004 33.9282 8.40767 34.077 8.69169 34.1373C9.01459 34.206 9.3865 34.1063 10.1303 33.907Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else if (key === "handheart") {
    return (
      <svg
        width="67"
        height="66"
        viewBox="0 0 67 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 55.2391H24.1783C25.1142 55.2391 26.0444 55.3505 26.9517 55.5732L34.5365 57.4164C36.1822 57.8173 37.8966 57.8563 39.5596 57.5333L47.9457 55.9018C50.161 55.4702 52.1989 54.4094 53.7959 52.8559L59.7292 47.0842C61.4236 45.4387 61.4236 42.7686 59.7292 41.1204C58.2037 39.6364 55.788 39.4694 54.0621 40.7278L47.1471 45.7728C46.1568 46.4967 44.9519 46.8865 43.7125 46.8865H37.0351L41.2854 46.8863C43.6811 46.8863 45.6216 44.9986 45.6216 42.6682V41.8246C45.6216 39.8896 44.2678 38.2024 42.3387 37.7346L35.7786 36.1393C34.711 35.8803 33.6177 35.7495 32.5186 35.7495C29.8654 35.7495 25.0627 37.9462 25.0627 37.9462L17 41.3179M6 40.1495L6 56.0995C6 57.6396 6 58.4097 6.29973 58.9979C6.56338 59.5154 6.98408 59.9361 7.50153 60.1997C8.08978 60.4995 8.85985 60.4995 10.4 60.4995H12.6C14.1401 60.4995 14.9102 60.4995 15.4985 60.1997C16.0159 59.9361 16.4366 59.5154 16.7003 58.9979C17 58.4097 17 57.6396 17 56.0995V40.1495C17 38.6093 17 37.8393 16.7003 37.251C16.4366 36.7335 16.0159 36.3129 15.4985 36.0492C14.9102 35.7495 14.1401 35.7495 12.6 35.7495H10.4C8.85986 35.7495 8.08978 35.7495 7.50153 36.0492C6.98408 36.3129 6.56338 36.7335 6.29973 37.251C6 37.8392 6 38.6093 6 40.1495ZM47.7763 9.87817C46.135 6.44381 42.3511 4.62438 38.6712 6.38049C34.9913 8.13661 33.4235 12.3013 34.9642 15.9572C35.9164 18.2167 38.6445 22.6045 40.5897 25.6268C41.3085 26.7435 41.6678 27.3019 42.1927 27.6285C42.643 27.9087 43.2066 28.0597 43.7366 28.0422C44.3545 28.0218 44.9449 27.7179 46.1257 27.1101C49.3214 25.4653 53.8779 23.0295 55.8323 21.5488C58.9945 19.153 59.7778 14.7493 57.4103 11.4016C55.0428 8.05395 50.9148 7.72455 47.7763 9.87817Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
  if (key === "comments") {
    return (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fat"
        data-icon="comments"
        className="svg-inline--fa fa-comments fa-4x "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path
          fill="currentColor"
          d="M16 176C16 90 99.4 16 208 16s192 74 192 160s-83.4 160-192 160c-35.3 0-69.6-8.4-101.3-24c-2.3-1.1-5-1.1-7.3 .1C73.3 325.9 44.8 336 16 336l.1-.1c18.7-14.2 32.2-33.9 40.2-55.4c1-2.7 .5-5.8-1.4-8C31.1 244.8 16 212.1 16 176zM208 0C93.1 0 0 78.8 0 176c0 39.2 15.7 74.1 39.6 103.2C32.4 296.5 21.2 312 6.5 323.1C1 327.2-1.3 334.4 .8 340.9C2.9 347.5 9.1 352 16 352c31 0 60.8-10.3 87.3-23.9C136.1 343.6 171.5 352 208 352c114.9 0 208-78.8 208-176S322.9 0 208 0zM448 176.5c100.7 6.8 176 77.8 176 159.5c0 36.1-15.1 68.9-38.9 96.5c-1.9 2.2-2.4 5.3-1.4 8c8 21.5 21.4 41.2 40.2 55.4l.1 .1c-28.8 0-57.3-10.1-83.4-23.9c-2.3-1.2-5-1.2-7.3-.1c-31.7 15.7-65.9 24-101.3 24c-89 0-161-49.7-184.2-114.9c-5.4 .8-10.8 1.4-16.3 1.9C255.8 457.4 336.3 512 432 512c36.5 0 71.9-8.4 104.7-23.9C563.2 501.7 593 512 624 512c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.7-5.7-17.8c-14.7-11.2-25.9-26.6-33.1-43.9C624.3 410.1 640 375.2 640 336c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5l0 .5z"
        ></path>
      </svg>
    );
  }
  if (key === "arrow") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 17L17 7M17 7H7M17 7V17"
          stroke="#1A1A24"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};
