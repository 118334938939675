import * as type from "../action-types";

const initialState = {
  projectCategories: [],
  loading: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROJECT_CATEGORIES:
      return { ...state, loading: true };
    case type.GET_PROJECT_CATEGORIES_SUCCESS:
      return { ...state, projectCategories: action.payload.data, loading: false };
    case type.GET_PROJECT_CATEGORIES_FAILURE:
      return { ...state, projectCategories: [], loading: false };
    default:
      return state;
  }
};
