import React, { useEffect } from 'react';
import './appSelectionFilters.scss';
import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import t from '../../localization/en/translation.json';
import FiltersHeader from './filtersHeader';
import FiltersTypes from './filtersTypes';
import FiltersFooter from './filtersFooter';
import { APP_STATE } from '../../store/reducers';
import { useSelector } from 'react-redux';
import {
  genCheckboxData,
  genCompetencyCheckData,
  getAllCheckedFilter,
  handleCheckBoxChange,
  setPreSelectedBundle,
  setPreSelectedSSl,
} from './helpers';
import {
  AppSelectionProps,
  BundleDataSetProps,
  FilterInitAllDataProps,
} from '../../models/IAppSelectFilter';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const initialAllFilter = {
  bundle: { isSelectAll: false, data: [] },
  competency: { isSelectAll: false, data: [] },
};

const AppSelectionFilters: React.FC<AppSelectionProps> = ({
  closeFilter,
  handleAppliedFilter,
  allSelectedFilters,
}) => {
  const [filterInitAllData, setFilterInitAllData] =
    React.useState<FilterInitAllDataProps>(initialAllFilter);
  const [bundleData, setBundleData] = React.useState<BundleDataSetProps>();
  const [competencyData, setCompetencyData] =
    React.useState<BundleDataSetProps>();
  const [allAppliedFilter, setAllAppliedFilter] = React.useState<number>(0);
  const metaData = useSelector((state: APP_STATE) => state.meta);

  useEffect(() => {
    if (metaData.bundles.length) {
      const bundleData = genCheckboxData(metaData.bundles);
      if (allSelectedFilters) {
        const upData = setPreSelectedBundle(allSelectedFilters, bundleData);
        setBundleData(upData);
      } else setBundleData(bundleData);
      setIntialFilters('bundle', bundleData);
    }
    if (metaData.subServiceLines.length) {
      const sslData = genCompetencyCheckData(metaData.subServiceLines);
      if (allSelectedFilters) {
        const upData = setPreSelectedSSl(allSelectedFilters, sslData);
        setCompetencyData(upData);
      } else setCompetencyData(sslData);
      setIntialFilters('competency', sslData);
    }
  }, [metaData, allSelectedFilters]);

  useEffect(() => {
    if (bundleData && competencyData) {
      let totalAppliedFilters: number = 0;
      totalAppliedFilters = bundleData.data.filter(
        (item) => item.isChecked
      ).length;
      totalAppliedFilters =
        totalAppliedFilters + getAllCheckedFilter(competencyData)?.length;
      setAllAppliedFilter(totalAppliedFilters);
    }
  }, [bundleData, competencyData]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    data: BundleDataSetProps
  ) => {
    if (e.target.name === 'bundle') {
      const updatedData = handleCheckBoxChange(type, data, bundleData);
      setBundleData(updatedData);
    }
    if (e.target.name === 'ssl') {
      const updatedData = handleCheckBoxChange(type, data, competencyData);
      setCompetencyData(updatedData);
    }
  };

  const setIntialFilters = (dataType: string, data: BundleDataSetProps) => {
    setFilterInitAllData((f) => ({
      ...f,
      [dataType]: data,
    }));
  };

  const handleClearAll = () => {
    setBundleData(filterInitAllData.bundle);
    setCompetencyData(filterInitAllData.competency);
    handleAppliedFilter([], [], true);
  };

  const handleApply = () => {
    const checkedBundle = bundleData
      ? bundleData.data.filter((item) => item.isChecked)
      : [];
    const checkedSSl = getAllCheckedFilter(competencyData);
    handleAppliedFilter(checkedBundle, checkedSSl, false);
  };

  return (
    <div className="filters-wrapper">
      <Dialog
        fullScreen
        open={true}
        onClose={closeFilter}
        TransitionComponent={Transition}
        maxWidth="sm"
        className="filters-dialog"
      >
        <FiltersHeader appliedFilters={allAppliedFilter ?? 0} />
        <div className="filter-body">
          {bundleData && (
            <FiltersTypes
              filterType={t.app_filter_typ_bundle}
              filterData={bundleData}
              selectAllType="bundle"
              onChange={handleOnChange}
            />
          )}
          {competencyData && (
            <FiltersTypes
              filterType={t.app_ssl_competency_text}
              filterData={competencyData}
              selectAllType="ssl"
              onChange={handleOnChange}
            />
          )}
        </div>
        <FiltersFooter
          handleClearAll={handleClearAll}
          handleApply={handleApply}
        />
      </Dialog>
    </div>
  );
};

export default AppSelectionFilters;
