import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
interface MatchParams {
  id: string;
}
interface IRouteHandlerProps extends RouteComponentProps<MatchParams> {
  component: React.ComponentType<any>;
  condition: (projectId: string | undefined) => boolean;
  redirectPath?: string;
}

class RouteHandler extends Component<IRouteHandlerProps> {
  render(): ReactNode {
    const { component: Component, condition, redirectPath = '/', ...rest } = this.props;
    const projectId = this.props.match.params.id;
    if (condition(projectId)) {
      return <Component {...rest} />;
    } else {
      return <Redirect to={redirectPath} />;
    }
  }
}

export default RouteHandler;
