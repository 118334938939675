import React, { Component } from "react";
import {
  AppModal,
  AppInput,
  AppTextField,
  AppFormErrorText,
} from "../../theme";
import t from "../../localization/en/translation.json";
import { IEvents } from "../../models/IAdminMaintenance";
import AppNumberPicker from "../../components/appNumberPicker/appNumberPicker";
import { AppDatePicker } from "../../theme";
import { DATE_TIME_SCHEDULER } from "./adminAddEventModalConstants";
import { formatSplitSpliceJoin, validateFormInputForSpecialChar } from "../../utils/helper-utility";
import "./adminAddEventModal.scss";

interface IProps {
  isOpen: boolean;
  isEdit: boolean;
  onClose: Function;
  onConfirm: Function;
  startDate?: string;
  endDate?: string;
  startHrs?: number;
  selectedEvent?: IEvents | null;
}

class AdminAddEventModal extends Component<IProps> {
  today = new Date();
  state = {
    title: "",
    description: "",
    startDate: this.today,
    endDate: this.today,
    startHrs: this.today.getHours(),
    startMins: this.today.getMinutes(),
    endHrs: this.today.getHours(),
    endMins: this.today.getMinutes(),
  };

  componentDidMount() {
    this.getDefaultState();
  }

  getDefaultState = () => {
    const { selectedEvent } = this.props;

    if (!selectedEvent) {
      return;
    }

    const {
      title = "",
      description = "",
      startDateTime,
      endDateTime,
    } = selectedEvent;
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime || startDateTime);

    this.setState({
      title,
      description,
      startDate,
      endDate,
      startHrs: startDate.getHours(),
      startMins: startDate.getMinutes(),
      endHrs: endDate.getHours(),
      endMins: endDate.getMinutes(),
    });
  };

  handleStartDate = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDate = (endDate) => {
    this.setState({ endDate });
  };

  handleTitleChange = (title) => this.setState({ title });

  handleDescriptionChange = (description) => this.setState({ description });

  handleStartHrsChange = (startHrs) => this.setState({ startHrs });

  handleEndHrsChange = (endHrs) => this.setState({ endHrs });

  handleStartMinsChange = (startMins) => this.setState({ startMins });

  handleEndMinsChange = (endMins) => this.setState({ endMins });

  handleConfirm = () => {
    const {
      title,
      description,
      startMins,
      startDate,
      startHrs,
      endMins,
      endHrs,
      endDate,
    } = this.state;
    const { onConfirm, selectedEvent = {} } = this.props;
    
    const startDateTime =new Date(formatSplitSpliceJoin(startDate?.toString()," ",4,1,`${startHrs}:${startMins}:00`)).getTime();
    const endDateTime = new Date(formatSplitSpliceJoin(endDate?.toString()," ",4,1,`${endHrs}:${endMins}:00`)).getTime();

    const event = {
      ...selectedEvent,
      title,
      description,
      startDateTime,
      endDateTime,
    };

    onConfirm({ events: [event] });
  };

  renderDateTimePicker = (
    date,
    hrs,
    mins,
    dateHandler,
    hrsHandler,
    minsHandler
  ) => {
    return (
      <div className="schedule-duration">
        <div className="project-form-field">
          <AppDatePicker
            value={date}
            onChange={dateHandler}
            format={DATE_TIME_SCHEDULER.format}
          />
        </div>
        <AppNumberPicker
          value={hrs}
          min={DATE_TIME_SCHEDULER.minTime}
          max={DATE_TIME_SCHEDULER.maxHrs}
          onChange={hrsHandler}
        />{" "}
        <span className="seprator">:</span>
        <AppNumberPicker
          value={mins}
          min={DATE_TIME_SCHEDULER.minTime}
          max={DATE_TIME_SCHEDULER.maxMinutes}
          onChange={minsHandler}
        />
      </div>
    );
  };

  render() {
    const {
      startDate,
      startHrs,
      startMins,
      endDate,
      endHrs,
      endMins,
      title,
      description,
    } = this.state;
    const { onClose, isOpen, isEdit } = this.props;

    return (
      <AppModal
        size="md"
        showModal={isOpen}
        onModalClose={() => onClose()}
        onConfirm={this.handleConfirm}
        disabledValue={
          !title ||
          !title.trim().length ||
          validateFormInputForSpecialChar(title) ||
          validateFormInputForSpecialChar(description)
        }
        cancelBtnText={t.cancel}
        confirmBtnText={!!isEdit ? t.update : t.add}
        title={t.admin_maintenance_event_modal_title}
      >
        <div className="admin-add-event-modal">
          <div className="project-form-field">
            <AppInput
              value={title}
              name={t.admin_maintenance_event_title}
              autoComplete="off"
              maxLength="100"
              label={t.admin_maintenance_event_title}
              onChange={(e) => this.handleTitleChange(e.target.value)}
              isValid={!validateFormInputForSpecialChar(title)}
            />
            {validateFormInputForSpecialChar(title) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  title.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
          <div className="project-form-field text-area-field">
            <AppTextField
              maxLength={1000}
              name={t.admin_maintenance_event_desc}
              value={description}
              label={t.admin_maintenance_event_desc}
              isValid={!validateFormInputForSpecialChar(description)}
              onChange={(e) => this.handleDescriptionChange(e.target.value)}
            />
            {validateFormInputForSpecialChar(description) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  description.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
          <div className="app-row">
            <div className="app-col-lg-12 app-col-md-12 local-time-warning">
              {t.admin_timezone_warning}
            </div>
            <div className="app-col-lg-5 app-col-md-5">
              <label>{t.admin_banner_start_time}</label>
              {this.renderDateTimePicker(
                startDate,
                startHrs,
                startMins,
                this.handleStartDate,
                this.handleStartHrsChange,
                this.handleStartMinsChange
              )}
            </div>
            <div className="app-col-lg-5 app-col-md-5">
              <label>{t.admin_banner_end_time}</label>
              {this.renderDateTimePicker(
                endDate,
                endHrs,
                endMins,
                this.handleEndDate,
                this.handleEndHrsChange,
                this.handleEndMinsChange
              )}
            </div>
          </div>
        </div>
      </AppModal>
    );
  }
}

export default AdminAddEventModal;
