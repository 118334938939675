import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import en_translation from '../../localization/en/translation.json';
import ProjectFooter from '../../components/projectFooter/projectFooter';
import CustomizeBundleHeader from '../../components/customizeBundleHeader/customizeBundleHeader';
import CustomizeBundleContainer from '../../components/customizeBundleContainer/customizeBundleContainer';
import AppDrawer from '../../components/appDrawer/appDrawer';
import { AppClass } from '../../components/appCard/models/appClass';
import { BundleClass } from '../../components/bundleCard/models/bundleClass';
import { AppCategory } from '../../components/appCard/models/appCategory';
import { PreviewCardModel } from '../../components/previewCard/previewCardModel';
import * as actionstype from '../../store/actions/meta-actions';
import * as createActionsType from "../../store/actions/create-project-actions";
import { AppDetail } from '../../components/createProject/models/appDetail';
import { Project } from '../../components/createProject/models/project';

interface IProps {
  bundleCard: BundleClass;
  saveReturnCustomizationModule: Function;
  cancelHandler: Function;
  continueHandler: Function;
  getAppCards: () => AppClass[];
  getBundleCards: () => BundleClass[];
  getAppCategories: () => AppCategory[];
  updateActiveApps: (apps: any) => AppClass[];
  apps: AppClass[];
  bundles: BundleClass[];
  appCategories: AppCategory[];
  projectEditEnable: boolean;
  createProjectEnable: boolean;
  saveReturnProjectDetails: Function;
  isProjectDrafted: boolean;
  onAppToggle: Function;
  setIsDirty: Function;
  appDetail?: AppDetail[];
  getEditAppClass: Function;
  record?: Project;
}

interface IState {
  bundleOverview: PreviewCardModel;
  appCards: AppClass[];
  bundleCard: BundleClass | null;
  appsCount: number;
  selectedAppCard: AppClass | null;
  selectedAppCategory: AppCategory | null;
  showAppDrawer: boolean;
}

class CustomizeBundle extends React.Component<IProps, IState> {

  dataLoaded = false;
  redirectTohome = false;
  editLAApp: AppClass[] = [];
  constructor(props: IProps) {
    super(props);
    this.state = {
      bundleCard: null,
      bundleOverview: { description: "", countMessage: "", count: 0 },
      appCards: [],
      appsCount: 0,
      selectedAppCard: null,
      selectedAppCategory: null,
      showAppDrawer: false
    };
    this.dataLoaded = false;
    this.setActiveBundleCard = this.setActiveBundleCard.bind(this);
  }

  setActiveBundleCard = (bundleCard: BundleClass, appCardId: string | null) => {
    const apps = this.filterAppCardsByBundleId(bundleCard);
    !this.props.createProjectEnable && this.setEditLAApp(apps);
    const bundleCardAppId = bundleCard.selectedAppIds && bundleCard.selectedAppIds.length > 0 ? bundleCard.selectedAppIds[0].id : null;
    appCardId = appCardId ? appCardId : bundleCardAppId;
    const selectedAppCard = appCardId ? this.filterAppCard(appCardId) : null;
    const selectedAppCategory = selectedAppCard && appCardId ? this.filterAppCardCategory(selectedAppCard.appCategoryId) : null

    this.props.onAppToggle(bundleCard);
    this.setState({
      bundleCard: _.cloneDeep(bundleCard),
      bundleOverview: this.filterBundlePreviewDataByBundleId(bundleCard, apps.length),
      appCards: apps,
      selectedAppCard,
      selectedAppCategory
    });
  }

  componentDidMount() {
    const { apps, bundles, appCategories, getAppCards, getAppCategories, getBundleCards } = this.props;
    !apps.length && getAppCards();
    !bundles.length && getBundleCards();
    !appCategories.length && getAppCategories();

    if (!this.dataLoaded && apps.length && bundles.length && appCategories.length) {
      this.dataLoaded = true;
      const bundleCard = { ...this.props.bundleCard };
      if (bundleCard && !_.isEmpty(bundleCard)) {
        this.setComponentState(bundleCard, null);
      }
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { apps, bundles, appCategories } = this.props;
    if (!this.dataLoaded && apps.length && bundles.length && appCategories.length) {
      this.dataLoaded = true;
      const bundleCard = { ...this.props.bundleCard };
      if (bundleCard) {
        this.setComponentState(bundleCard, null);
      }
    }
  }

  componentWillUnmount() {
    !this.redirectTohome && !this.props.createProjectEnable && this.state.bundleCard
      && this.props.saveReturnProjectDetails(this.state.bundleCard, true);
  }

  setComponentState = (bundleCard: BundleClass, appCardId: string | null) => {
    this.setActiveBundleCard(bundleCard, appCardId);
  };

  selectedAppCardHandler = (appCard: AppClass) => {
    if (this.state.bundleCard) {
      this.setComponentState(this.state.bundleCard, appCard.id);
    }
  }

  setEditLAApp = (apps: AppClass[]) => {
    const { appDetail } = this.props;
    appDetail && appDetail.forEach(item => {
      if (item.id) {
        const appCard = apps.find((app) => app.id === item.appClassId);
        if (!appCard) {
          this.editLAApp.push(this.props.getEditAppClass(item));
        }
      }
    });
  }

  removeAppCardHandler = (appCard: AppClass) => {
    const { bundleCard } = this.state;
    if (bundleCard) {
      bundleCard.selectedAppIds = bundleCard.selectedAppIds?.filter(item => item.id !== appCard.id);
      this.setComponentState(bundleCard, null);
    }
    this.setState({ bundleCard });
  }

  filterBundlePreviewDataByBundleId = (bundleCard: BundleClass, appCount: number): PreviewCardModel => {
    const bundlePreview: PreviewCardModel = { description: "", countMessage: "", count: appCount }
    if (bundleCard) {
      bundlePreview.count = appCount;
      bundlePreview.description = bundleCard.description;
      bundlePreview.countMessage = appCount > 1 ? en_translation.customize_bundle_apps_bundle : en_translation.customize_bundle_app_bundle;
    }
    return bundlePreview;
  };

  filterBundleCard = (bundleId: string): BundleClass | null => {
    const bundle = this.props.bundles.find(f => f.id === bundleId);
    return bundle ? { ...bundle } : null;
  }
  filterAppCardsByBundleId = (bundleClass: BundleClass): AppClass[] => {
    return bundleClass ? this.props.apps.filter(f => bundleClass?.appClassIds.map(m => m.id).slice().includes(f.id)) : [];
  }

  filterAppCard = (appCardId: string): AppClass | null => {
    let appCard = this.props.apps.find(f => f.id === appCardId);
    if (!this.props.createProjectEnable && !appCard && this.editLAApp.length) {
      appCard = this.editLAApp.find(f => f.id === appCardId);
    }
    return appCard ? appCard : null;
  }

  filterAppCardCategory = (appCardId: string): AppCategory | null => {
    const appCardCategory = this.props.appCategories.find(f => f.id === appCardId);
    return appCardCategory ? { ...appCardCategory } : null;
  }

  onAppToggleClick = ({ id, checked }) => {
    const { bundleCard } = this.state;

    if (bundleCard) {
      if (!bundleCard.selectedAppIds) {
        bundleCard.selectedAppIds = [];
      }
      if (checked) {
        bundleCard.selectedAppIds.push({ id });
        if (bundleCard.selectedAppIds.length === 1) {
          this.setComponentState(bundleCard, null);
        }
      } else {
        bundleCard.selectedAppIds = bundleCard.selectedAppIds.filter(item => item.id !== id);
        this.setComponentState(bundleCard, null);
      }
    }
    this.setState({ bundleCard });
    this.props.setIsDirty();
  }

  saveHandler = () => {
    this.props.updateActiveApps(this.state.bundleCard?.selectedAppIds || []);
    this.props.saveReturnCustomizationModule(this.state.bundleCard);
  }

  updateHandler = (update: boolean) => {
    this.redirectTohome = true;
    !this.props.createProjectEnable && this.state.bundleCard
      && this.props.saveReturnProjectDetails(this.state.bundleCard, false, update);
  }

  cancelHandler = () => {
    this.props.cancelHandler();
  }

  addAppHandler = () => {
    this.setState({ showAppDrawer: true });
  }

  closeAppDrawerHandler = (selectedAppIds) => {
    const { bundleCard, selectedAppCard } = this.state;

    if (bundleCard) {
      bundleCard.selectedAppIds = selectedAppIds;
      const appId = bundleCard.selectedAppIds?.find(f => f.id === selectedAppCard?.id) ?
        selectedAppCard?.id || null : null;
      this.setComponentState(bundleCard, appId);
    }
    this.setState({ showAppDrawer: false, bundleCard });
  }

  render() {
    const { appCards, bundleOverview, bundleCard, selectedAppCard, selectedAppCategory, showAppDrawer } = this.state;
    const { appCategories, apps, createProjectEnable, isProjectDrafted, record } = this.props;
    const allApps = !this.props.createProjectEnable && this.editLAApp.length ? _.concat(apps, this.editLAApp) : apps;
    return (
      <div className="create-project">
        {
          createProjectEnable && <CustomizeBundleHeader
            addAppHandler={this.addAppHandler}
            isOwnBundle={!!bundleCard?.isOwnBundle}
          />
        }
        {
          bundleCard ?
            <CustomizeBundleContainer
              apps={allApps}
              appCards={appCards}
              bundleOverview={bundleOverview}
              bundleCard={bundleCard}
              createProjectEnabled={this.props.createProjectEnable}
              isProjectDrafted={isProjectDrafted}
              selectedAppCard={selectedAppCard}
              selectedAppCategory={selectedAppCategory}
              appCategories={appCategories}
              onAppToggleClick={this.onAppToggleClick}
              selectedAppCardHandler={this.selectedAppCardHandler}
              removeAppCardHandler={this.removeAppCardHandler}
              addAppHandler={this.addAppHandler} />
            : null
        }
        {showAppDrawer && 
          <AppDrawer
            open={showAppDrawer}
            apps={apps}
            createProjectEnabled={this.props.createProjectEnable}
            bundleCard={bundleCard}
            appCategories={appCategories}
            closeHandler={this.closeAppDrawerHandler}
            record={record} />
        }
        <ProjectFooter
          saveBtnText={(createProjectEnable && en_translation.save_and_return_to_step2) || en_translation.save_as_draft}
          disableSaveBtn={createProjectEnable && !bundleCard?.selectedAppIds?.length}
          saveHandler={createProjectEnable ? this.saveHandler : () => this.updateHandler(true)}
          cancelHandler={this.cancelHandler}
          showSaveBtn={createProjectEnable || isProjectDrafted}
          showUpdateBtn={!createProjectEnable && !isProjectDrafted}
          updateHandler={() => this.updateHandler(true)}
          createHandler={() => this.updateHandler(false)}
          disableUpdatebtn={!createProjectEnable && !bundleCard?.selectedAppIds?.length} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    apps: state.meta.apps,
    bundles: state.createProjectReducer.bundles,
    appCategories: state.meta.appCategories
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAppCards: (): AppClass[] => { return dispatch(actionstype.getApps()) },
    getBundleCards: (): BundleClass[] => { return dispatch(actionstype.getBundles()) },
    updateActiveApps: (payload): AppClass[] => { return dispatch(createActionsType.updateActiveApps(payload)) },
    getAppCategories: (): AppCategory[] => { return dispatch(actionstype.getAppCategories()) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeBundle);
