import * as type from "../action-types";

const initialState = {
  searchLoader: false,
  searchProjectsData: []
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.SEARCH_PROJECT:
      return { ...state, searchLoader: true };
    case type.SEARCH_PROJECT_SUCCESS:
      return {
        ...state,
        searchProjectsData: action.payload.data.pagination.pageNumber !== 1 ?
          [...state.searchProjectsData, ...action.payload.data.records] : action.payload.data.records,
        searchLoader: false
      };
    case type.SEARCH_PROJECT_FAILURE:
      return { ...state, searchProjectsData: [], searchLoader: false };
    case type.CLEAR_SEARCHED_PROJECT:
      return { ...state, searchProjectsData: [], searchLoader: false };
    default:
      return state;
  }
};
