import * as type from "../action-types";

type load = { id: string };

export const getAdminRegionsRequest = (payload: load) => ({
  type: type.GET_ADMIN_REGIONS_REQUEST,
  payload,
});

export const getAdminRegionsSuccess = (payload: load) => ({
  type: type.GET_ADMIN_REGIONS_SUCCESS,
  payload,
});

export const getAdminRegionsFailure = (payload: load) => ({
  type: type.GET_ADMIN_REGIONS_FAILURE,
  payload,
});

export const updateAdminRegionsRequest = (payload: load) => ({
  type: type.UPDATE_ADMIN_REGIONS_REQUEST,
  payload,
});

export const updateAdminRegionsSuccess = (payload: load) => ({
  type: type.UPDATE_ADMIN_REGIONS_SUCCESS,
  payload,
});

export const updateAdminRegionsFailure = (payload: load) => ({
  type: type.UPDATE_ADMIN_REGIONS_FAILURE,
  payload,
});

export const getAdminClientSizesRequest = (payload: load) => ({
  type: type.GET_ADMIN_CLIENT_SIZES_REQUEST,
  payload,
});

export const getAdminClientSizesSuccess = (payload: load) => ({
  type: type.GET_ADMIN_CLIENT_SIZES_SUCCESS,
  payload,
});

export const getAdminClientSizesFailure = (payload: load) => ({
  type: type.GET_ADMIN_CLIENT_SIZES_FAILURE,
  payload,
});

export const updateAdminClientSizesRequest = (payload: load) => ({
  type: type.UPDATE_ADMIN_CLIENT_SIZES_REQUEST,
  payload,
});

export const updateAdminClientSizesSuccess = (payload: load) => ({
  type: type.UPDATE_ADMIN_CLIENT_SIZES_SUCCESS,
  payload,
});

export const updateAdminClientSizesFailure = (payload: load) => ({
  type: type.UPDATE_ADMIN_CLIENT_SIZES_FAILURE,
  payload,
});

export const getAdminSectorsRequest = (payload: load) => ({
  type: type.GET_ADMIN_SECTORS_REQUEST,
  payload,
});

export const getAdminSectorsSuccess = (payload: load) => ({
  type: type.GET_ADMIN_SECTORS_SUCCESS,
  payload,
});

export const getAdminSectorsFailure = (payload: load) => ({
  type: type.GET_ADMIN_SECTORS_FAILURE,
  payload,
});

export const updateAdminSectorsRequest = (payload: load) => ({
  type: type.UPDATE_ADMIN_SECTORS_REQUEST,
  payload,
});

export const updateAdminSectorsSuccess = (payload: load) => ({
  type: type.UPDATE_ADMIN_SECTORS_SUCCESS,
  payload,
});

export const updateAdminSectorsFailure = (payload: load) => ({
  type: type.UPDATE_ADMIN_SECTORS_FAILURE,
  payload,
});

export const getAdminDataHostingLocRequest = (payload: load) => ({
  type: type.GET_ADMIN_DATA_HOSTING_LOC_REQUEST,
  payload,
});

export const getAdminDataHostingLocSuccess = (payload: load) => ({
  type: type.GET_ADMIN_DATA_HOSTING_LOC_SUCCESS,
  payload,
});

export const getAdminDataHostingLocFailure = (payload: load) => ({
  type: type.GET_ADMIN_DATA_HOSTING_LOC_FAILURE,
  payload,
});

export const getAdminTypesRequest = (payload: load) => ({
  type: type.GET_ADMIN_TYPES_REQUEST,
  payload,
});

export const getAdminTypesSuccess = (payload: load) => ({
  type: type.GET_ADMIN_TYPES_SUCCESS,
  payload,
});

export const getAdminTypesFailure = (payload: load) => ({
  type: type.GET_ADMIN_TYPES_FAILURE,
  payload,
});

export const updateAdminTypesRequest = (payload: load) => ({
  type: type.UPDATE_ADMIN_TYPES_REQUEST,
  payload,
});

export const updateAdminTypesSuccess = (payload: load) => ({
  type: type.UPDATE_ADMIN_TYPES_SUCCESS,
  payload,
});

export const updateAdminTypesFailure = (payload: load) => ({
  type: type.UPDATE_ADMIN_TYPES_FAILURE,
  payload,
});

export const GetAdminSurvey = () => ({
  type: type.GET_ADMIN_SURVEY,
});

export const getAdminSurveySuccess = (payload: load) => ({
  type: type.GET_ADMIN_SURVEY_SUCCESS,
  payload,
});
export const getAdminSurveyFailure = () => ({
  type: type.GET_ADMIN_SURVEY_FAILURE,
});

export const deleteSurveyConfirm = (payload) => ({
  type: type.DELETE_SURVEY_CONFIRM,
  payload,
});

export const deleteSurveyConfirmSuccess = (payload) => ({
  type: type.DELETE_SURVEY_CONFIRM_SUCCESS,
  payload,
});

export const deleteSurveyFailure = () => ({
  type: type.DELETE_SURVEY_CONFIRM_FAILURE,
});

export const addAdminSurvey = (payload) => ({
  type: type.ADD_ADMIN_SURVEY,
  payload,
});

export const addAdminSurveyFailure = () => ({
  type: type.ADD_ADMIN_SURVEY_FAILURE,
});

export const UpdateAdminSurvey = (payload) => ({
  type: type.UPDATE_ADMIN_SURVEY,
  payload,
});

export const UpdateAdminSurveyFailure = () => ({
  type: type.UPDATE_ADMIN_SURVEY_FAILURE,
});

export const getSurveyDurationQuarter = (id) => ({
  type: type.GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS,
  id,
});

export const setSurveyDurationQuarter = (payload) => ({
  type: type.GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS_SUCCESS,
  payload,
});
