import * as type from "../action-types";
import { INotification } from "../../models/INotification";

const initialState: INotification = { message: '', type: '', status: 0, redirectToUnauth: false, redirectURL: "", useHtml: false };
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.SHOW_NOTIFICATION:
            return {
                message: action.payload.message || '',
                type: action.payload.type || '',
                status: action.payload.status || 0,
                redirectToUnauth: action.payload.redirectToUnauth || false,
                code: action.payload.code || '',
                redirectURL: action.payload.redirectURL || "",
                useHtml: action.payload.useHtml || false
            };
        case type.HIDE_NOTIFICATION:
            return {
                message: '',
                type: '',
                status: 0,
                redirectToUnauth: false,
                redirectURL: "",
                useHtml: false
            };
        default:
            return state;
    }
}
