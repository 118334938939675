import React, { FC, useState } from "react";
import MoreMenu from "../moreMenu/moreMenu";
import { helpCircle } from "../../theme/icons";
import { AppIconButton, AppIcon } from "../../theme";
import {
  IProps,
  GetHelpOptionsGuestUser,
  UserAccountType,
} from "../../models/IHelp";
import "./getHelp.scss";
import { UserTypes } from "../../utils/Constants";
import { useHistory } from "react-router-dom";

const GetHelp: FC<IProps> = ({ currentUser }) => {
  const id = "dropdown-get-help";
  const triggerId = "dropdown-trigger-get-help";
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleClickDropdownItem = (key: string) => {
    if (currentUser.accountType?.toLowerCase() === UserAccountType.guest) {
      return;
    }
  };

  const handleToggle = () => {
    if (
      currentUser.userType?.toLowerCase() === UserTypes.External.toLowerCase()
    ) {
      setIsOpen(!isOpen);
    } else if (
      currentUser.userType?.toLowerCase() === UserTypes.Internal.toLowerCase()
    ) {
      history.push("/help-support");
    }
  };

  return (
    <div className="get-help-wrapper">
      {currentUser.userType?.toLowerCase() ===
        UserTypes.External.toLowerCase() && (
        <MoreMenu
          className="help-menu-pop"
          controlId="help-more-menu"
          optionClickFn={handleClickDropdownItem}
          options={GetHelpOptionsGuestUser}
          stopEventBubbling
          menuOpener={
            <AppIconButton
              type="button"
              onClick={handleToggle}
              aria-controls={id}
              id={id}
              aria-expanded={isOpen}
              aria-label={triggerId}
              className="help-btn"
            >
              <AppIcon icon={helpCircle} />
            </AppIconButton>
          }
        />
      )}
      {currentUser.userType?.toLowerCase() ===
        UserTypes.Internal.toLowerCase() && (
        <AppIconButton
          type="button"
          onClick={handleToggle}
          aria-controls={id}
          id={id}
          aria-expanded={isOpen}
          aria-label={triggerId}
          className="help-btn"
        >
          <AppIcon icon={helpCircle} />
        </AppIconButton>
      )}
    </div>
  );
};

export default GetHelp;
