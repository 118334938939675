import { ExecutionFieds } from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";

export const columnConfig = [
  {
    headerName: " ",
    cellRenderer: "moreMenuCellRenderer",
    field: "iconButton",
    minWidth: 43,
    width: 43,
    pinned: "left",
    lockPosition: true,
   // sortable: false,
    cellClass: "update-client-size-cell-renderer",
  },
  {
    headerName: t.admin_cps_form_executions_workflow,
    field: ExecutionFieds.wfr_WorkflowLookup,
    sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_executions_schedule,
    field: ExecutionFieds.wfr_ScheduleLookup,
    sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_executions_run_start_time,
    field: ExecutionFieds.wfr_RunStartTime,
    sortable: true,
    width: 250,
    wrapText: true,
    cellRenderer: "cellRenderDateRows",
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_executions_run_end_time,
    field: ExecutionFieds.wfr_RunEndTime,
    sortable: true,
    width: 250,
    wrapText: true,
    cellRenderer: "cellRenderDateRows",
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_executions_process_message,
    field: ExecutionFieds.wfr_ProcessMessage,
    sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_executions_parameters,
    field: ExecutionFieds.wfr_Parameters,
    sortable: true,
    width: 300,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_executions_process_status,
    field: ExecutionFieds.wfr_ProcessStatus,
    sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
  },
  {
    headerName: t.admin_cps_form_executions_correlation_id,
    field: ExecutionFieds.wfr_CorrelationId,
    sortable: true,
    width: 350,   
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
  {
    headerName: t.admin_cps_form_executions_requester_user,
    field: ExecutionFieds.wfr_RequesterId,
    sortable: true,
    width: 200,
    wrapText: true,
    filter: "customFilter",
    filterType: "none",
  },
];
