import { IEMGPolicy } from "../../models/IEMGPolicy";
import * as type from "../action-types";

const initialState: {
    projectId: string | null,
    data: IEMGPolicy | null,
    loading: boolean,
    redirectUrl: string | null,
    redirectInternally: boolean,
    isEmgAccepting: boolean,
    acceptingError: boolean
} = {
    projectId: null,
    redirectUrl: null,
    redirectInternally: false,
    data: null,
    loading: false,
    isEmgAccepting: false,
    acceptingError: false,
};
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.UPDATE_PROJECT_EMG_POLICY_PAYLOAD:
            return {
                ...state,
                projectId: (action.payload && action.payload.projectId) || null,
                redirectUrl: (action.payload && action.payload.redirectUrl) || null,
                redirectInternally: (action.payload && action.payload.redirectInternally) || false,
                data: null
            }
        case type.GET_PROJECT_EMG_POLICY_REQUEST:
            return { ...state, loading: true };
        case type.GET_PROJECT_EMG_POLICY_SUCCESS:
            return { ...state, loading: false, data: action.payload.data || action.payload };
        case type.GET_PROJECT_EMG_POLICY_FAILURE:
            return { ...state, loading: false };
        case type.ACCEPT_PROJECT_EMG_POLICY_REQUEST:
            return { ...state, isEmgAccepting: true, acceptingError: false };
        case type.ACCEPT_PROJECT_EMG_POLICY_SUCCESS:
            return {
                ...state,
                isEmgAccepting: false,
                acceptingError: false
            };
        case type.ACCEPT_PROJECT_EMG_POLICY_FAILURE:
            return { ...state, isEmgAccepting: false, acceptingError: true };
        default:
            return state;
    }
};
