import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { getServerTime, getHelpSupportMenu } from '../apis/settingsApi';
import moment from "moment";
import { SessionKeys } from '../../utils/Constants';
import {
 getHelpSupportRequestSuccess,
 getHelpSupportRequestFailure} from "../actions/settings-actions"

const getDuration = (serverTimeStored) => {
  return moment().utc().valueOf() - serverTimeStored;
}

function* getServerTimestamp(action) {
  try {
    let serverDuration = sessionStorage.getItem(SessionKeys.CE_SERVER_TIME);
    let duration: number | null = serverDuration ? parseInt(serverDuration) : null;
    if (!duration) {
      const { serverTime } = yield call(getServerTime);
      duration = getDuration(serverTime);
      sessionStorage.setItem(SessionKeys.CE_SERVER_TIME, duration.toString());
    }
  
    yield put({
      type: type.GET_SERVER_TIME_SUCCESS,
      payload: duration,
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_SERVER_TIME_FAILURE,
      payload: message
    });
  }
}

export function* getSettingsActionWatcher() {
  yield takeLatest(type.GET_SERVER_TIME, getServerTimestamp)
}

function* getHelpSupport() {
  try {
      const data = yield call(getHelpSupportMenu);
      yield put(getHelpSupportRequestSuccess(data));
  } catch ({ message }) {
      yield put(getHelpSupportRequestFailure());
  }
}

export function* getHelpSupportActionWatcher() {
  yield takeLatest(type.GET_HELP_AND_SUPPORT, getHelpSupport);
}
