import * as type from "../action-types";
import {deepFreeze} from "../../utils/helper-utility";

const initialState = {
    dataLocations: [],
    loading: false,
    projectRegions: [],
    projectAreas: [],
    projectCountries: []
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case type.GET_DATA_LOCATIONS:
            return {...state, loading: true};
        case type.GET_DATA_LOCATIONS_SUCCESS:
            return {...state, dataLocations: action.payload.data, loading: false};
        case type.GET_DATA_LOCATIONS_FAILURE:
            return {...state, dataLocations: [], loading: false};
        case type.GET_PROJECT_COUNTRIES_CODE_SUCCESS:
            const [areas, regions, countries] = action.payload;
            return {
                ...state,
                projectAreas: deepFreeze(areas.data),
                projectRegions: deepFreeze(regions.data),
                projectCountries: deepFreeze(countries.data),
                loading: false
            };
        case type.GET_PROJECT_REGIONS_SUCCESS:
            return {...state, projectRegions: action.payload.data, loading: false};
        default:
            return state;
    }
};
