import React, { FC } from "react";
import { AppButton, AppCheckbox } from "../../theme";
import t from "../../localization/en/translation.json";
import InnerHTMLElement from "../innerHTMLElement/innerHTMLElement";
import { IPansPolicy } from "../../models/user";
import "./privacyAndTerms.scss";
import { completeStateClass } from "../../utils/Constants";
import Pending from "../../assets/images/icons/Pending.svg";
import Current from "../../assets/images/icons/Current.svg";
import Completed from "../../assets/images/icons/Completed.svg";

interface IProps {
  policyPageNo: number;
  isChecked: boolean;
  onCheckBoxClick: Function;
  onNextClick: Function;
  paansPolicy: IPansPolicy;
}

const PRIVACY_POLICY = 1;
const TERMS_CONDITIONS = 2;

const PrivacyAndTerms: FC<IProps> = ({
  policyPageNo,
  isChecked,
  onCheckBoxClick,
  onNextClick,
  paansPolicy,
}) => {
  return (
    <div className="privacy-and-Terms">
      <div className="privacy-header">
        <div className="step-tracker">
          <div
            className={`step-tracker-line ${
              policyPageNo >= TERMS_CONDITIONS ? completeStateClass : ""
            }`}
          ></div>
          <div className="privacy-notice-icon">
            <div
              className={`inprogress-state ${
                policyPageNo !== PRIVACY_POLICY ? "no-progress-state" : ""
              } ${policyPageNo > PRIVACY_POLICY ? "progress-complete" : ""}`}
            >
              <div
                className={`icon-wrapper ${
                  policyPageNo === PRIVACY_POLICY ? completeStateClass : ""
                }`}
              >
                {policyPageNo === PRIVACY_POLICY ? (
                  <img
                    src={Current}
                    alt={t.privacy_policy_privacy_notice_small}
                  />
                ) : (
                  <img
                    src={Completed}
                    alt={t.privacy_policy_privacy_notice_small}
                  />
                )}
              </div>
            </div>
            <span className="icon-label">
              {t.privacy_policy_privacy_notice}
            </span>
          </div>
          <div className="terms-conditions-icon">
            <div
              className={`inprogress-state validate-icon ${
                policyPageNo !== TERMS_CONDITIONS ? "no-progress-state" : ""
              } ${policyPageNo > TERMS_CONDITIONS ? "progress-complete" : ""}`}
            >
              <div
                className={`icon-wrapper ${
                  policyPageNo >= TERMS_CONDITIONS ? completeStateClass : ""
                }`}
              >
                {policyPageNo === TERMS_CONDITIONS ? (
                  <img src={Current} alt={t.privacy_policy_terms_conditions} />
                ) : (
                  <img src={Pending} alt={t.privacy_policy_terms_conditions} />
                )}
                <img src={Pending} alt={t.step_three} />
              </div>
            </div>
            <span className="icon-label">
              {t.privacy_policy_terms_conditions}
            </span>
          </div>
        </div>
      </div>
      <div className="privacy-content">
        <InnerHTMLElement
          customClass="content-text"
          htmlText={
            paansPolicy.policies?.length
              ? paansPolicy.policies[policyPageNo - 1].content
              : ""
          }
        />
      </div>
      <div className="privacy-footer">
        <AppCheckbox
          className="agree-check-box"
          key={`privacy-checkbox-key-${policyPageNo}`}
          id={`privacy-checkbox-id-${policyPageNo}`}
          value={`privacy-checkbox${policyPageNo}`}
          name={`privacy-checkbox${policyPageNo}`}
          checked={isChecked}
          onChange={(e) => onCheckBoxClick(e.target.checked)}
        >
          <span>
            {t.privacy_policy_i_agree}{" "}
            <b>
              {policyPageNo === PRIVACY_POLICY
                ? t.privacy_policy_privacy_notice_small
                : t.privacy_policy_terms_conditions}
            </b>
          </span>
        </AppCheckbox>
        <div className="next-button-wrapper">
          <AppButton
            className="next-btn"
            size="medium"
            disabled={!isChecked}
            onClick={() => onNextClick()}
          >
            {policyPageNo === PRIVACY_POLICY
              ? t.privacy_policy_next
              : t.privacy_policy_continue}
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndTerms;
