import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import {
    getPowerBiReportsSuccess,
    getPowerBiReportsFailure,
    getAdminReportsSuccess,
    getAdminReportsFailure,
    addAdminReportSuccess,
    addAdminReportFailure,
    updateAdminReportSuccess,
    updateAdminReportFailure,
    deleteAdminReportSuccess,
    deleteAdminReportFailure,
    getPowerBiTokenSuccess,
    getPowerBiTokenFailure,
} from "../actions/admin-reports-actions";
import {
    getAllPowerBiReports,
    getAdminReports,
    addAdminReport,
    updateAdminReport,
    deleteAdminReport,
    getPowerBiReportToken
} from '../apis/projectApi';

function* getPowerBiReportsSaga() {
    try {
        const data = yield call(getAllPowerBiReports);
        yield put(getPowerBiReportsSuccess(data));
    } catch ({ message }) {
        yield put(getPowerBiReportsFailure(message));
    }
}

function* getPowerBiTokenSaga(action) {
    try {
        const data = yield call(getPowerBiReportToken, action);
        yield put(getPowerBiTokenSuccess(data));
    } catch ({ message }) {
        yield put(getPowerBiTokenFailure(message));
    }
}

function* getAdminReportsSaga() {
    try {
        const data = yield call(getAdminReports);
        yield put(getAdminReportsSuccess(data));
    } catch ({ message }) {
        yield put(getAdminReportsFailure(message));
    }
}

function* addAdminReportSaga(action) {
    try {
        const data = yield call(addAdminReport, action);
        yield put(addAdminReportSuccess(data));
    } catch ({ message }) {
        yield put(addAdminReportFailure(message));
    }
}

function* updateAdminReportSaga(action) {
    try {
        const data = yield call(updateAdminReport, action);
        yield put(updateAdminReportSuccess(data));
    } catch ({ message }) {
        yield put(updateAdminReportFailure(message));
    }
}

function* deleteAdminReportSaga(action) {
    try {
        const data = yield call(deleteAdminReport, action);
        yield put(deleteAdminReportSuccess(data));
    } catch ({ message }) {
        yield put(deleteAdminReportFailure(message));
    }
}

export function* adminReportsActionWatcher() {
    yield takeLatest(type.GET_POWERBI_REPORTS_REQUEST, getPowerBiReportsSaga);
    yield takeLatest(type.GET_ADMIN_REPORTS_REQUEST, getAdminReportsSaga);
    yield takeLatest(type.ADD_ADMIN_REPORT_REQUEST, addAdminReportSaga);
    yield takeLatest(type.UPDATE_ADMIN_REPORT_REQUEST, updateAdminReportSaga);
    yield takeLatest(type.DELETE_ADMIN_REPORT_REQUEST, deleteAdminReportSaga);
    yield takeLatest(type.GET_POWERBI_TOKEN_REQUEST, getPowerBiTokenSaga);
}
