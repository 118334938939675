import React from 'react';
import './appSelectionFilters.scss';
import { AppButton } from '../../theme';
import t from "../../localization/en/translation.json";

interface FiltersFooterProps {
  handleClearAll: () => void;
  handleApply: () => void;
}

const FiltersFooter: React.FC<FiltersFooterProps> = ({
  handleClearAll,
  handleApply,
}) => {
  return (
    <div className="filters-footer">
      <AppButton
        onClick={handleClearAll}
        size="medium"
        className="app-button"
        variant="outlined"
      >
        {t.app_filter_clear_all}
      </AppButton>
      <AppButton onClick={handleApply} size="medium" style={{marginLeft: 12}}>
        {t.app_filter_apply}
      </AppButton>
    </div>
  );
};

export default FiltersFooter;
