export enum SurveyTableFields {
  id = "id",
  name = "name",
  fiscalQuarter = "fiscalQuarter",
  fiscalYear = "fiscalYear",
  fiscalYearQuarter = "fiscalYearQuarter",
  url = "url",
  activeDuration = "activeDuration",
  cadence = "cadence",
  modifiedOn = "modifiedOn",
  modifiedBy = "modifiedBy",
  createdOn = "createdOn",
  createdBy = "createdBy",
  userTypes = "userTypes",
  createdByEmailAddress = "createdByEmailAddress",
  modifiedByEmailAddress = "modifiedByEmailAddress",
  fiscalYearQuarterDescription = "fiscalYearQuarterDescription",
}

export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = {
  colId: SurveyTableFields.name,
  sort: "asc",
};
export const MORE_MENU_ACTION_NAME = {
  UPDATE: "Update",
  DELETE: "Delete",
  ADD: "Add",
  PREVIEW: "Preview",
};

export const SURVEY_CADENCE = [
  { value: 1, label: "1 Day" },
  { value: 2, label: "2 Days" },
  { value: 3, label: "3 Days" },
  { value: 4, label: "4 Days" },
  { value: 5, label: "5 Days" },
  { value: 6, label: "6 Days" },
  { value: 7, label: "7 Days" },
];
