import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";

const AppIconButton: React.FC<IconButtonProps> = ({ children, ...rest }) => {
    return (
        <IconButton
            {...rest}
        >
            {children}
        </IconButton>
    )
}

export default AppIconButton;