import React, { Component } from "react";
import { connect } from 'react-redux';
import t from "../../localization/en/translation.json";
import { AppModal, AppButton } from "../../theme";
import List from "../../components/editableGrid/list/list";
import { columnConfig } from './columnConfig';
import { IPlatformCustomizationInfo, IProjectDetails } from '../../models/IPlatformCustomizationInfo';
import { Select } from "../../components/select/select";
import { IS_CUSTOMIZED_OPTIONS } from "./adminProjectsIsCustomizedConstants";
import { updateCustomizationInfoRequest } from '../../store/actions/admin-projects-actions';
import "./adminProjectsIsCustomizedModal.scss";

interface IProps {
  showModal: boolean,
  onModalClose: Function;
  projectDetails: IProjectDetails | null;
  updateCustomizationInfoRequest: Function;
}

interface IState {
  customizedPlatforms: IPlatformCustomizationInfo[];
}

class AdminProjectsIsCustomizedModal extends Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      customizedPlatforms: []
    };
  }

  gridApi;
  gridColumnApi;

  handleCellValueChanged = ({ oldValue, rowIndex, value }) => {
    if(value) {
      return
    }

    const editedRow = this.gridApi.getDisplayedRowAtIndex(rowIndex);
    editedRow && editedRow.setDataValue('customizedBy', oldValue);
  }

  handleSearchUserSelected = (selectedOption, data, rowIndex) => {
    this.gridApi.stopEditing(true)

    if(!selectedOption[0].mail) {
      return
    }
    const editedRow = this.gridApi.getDisplayedRowAtIndex(rowIndex);
    editedRow && editedRow.setDataValue('customizedBy', selectedOption[0].mail);
    this.handleCustomizationChanges(selectedOption[0].mail, data, 'customizedBy')
  }

  onGridReady = ({ api, columnApi}) => {
    this.gridApi = api;
    this.gridColumnApi = columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  handleCustomizationConfirm = () => {
    const { projectDetails, updateCustomizationInfoRequest, onModalClose } = this.props;
    const { customizedPlatforms } = this.state;

    if(!projectDetails) {
      return
    }
    const { id } = projectDetails;
    updateCustomizationInfoRequest({ projectId: id, data: customizedPlatforms });
    onModalClose()
  }

  handleCustomizationChanges = (value, row, key) => {
    const { customizedPlatforms } = this.state;
    const alreadyEditedRow = customizedPlatforms.find(p => p.appPlatformId === row.appPlatformId);
    const restPlatforms = customizedPlatforms.filter(p => p.appPlatformId !== row.appPlatformId);
    const newCustomizedRow = !alreadyEditedRow ?  {...row, [key]: value } : {...alreadyEditedRow, [key]: value };

    this.setState({ customizedPlatforms: [...restPlatforms, newCustomizedRow ]});
  }

  renderIsCustomizedDropDown = ({ value, data }) => {
    const prefilledValue = value === null ? 'none' : value.toString();
    const val = IS_CUSTOMIZED_OPTIONS.find(v => v.value === prefilledValue)

    return (
      <Select
        options={IS_CUSTOMIZED_OPTIONS}
        defaultValue={val}
        closeMenuOnSelect
        width={200}
        placeholder="-"
        onValueChange={(e) => this.handleCustomizationChanges(e.value === IS_CUSTOMIZED_OPTIONS[0].value, data, 'isCustomized')}
      />
    )
  }

  handleCustomizeByEdit = (rowIndex) => {
    this.gridApi.startEditingCell({ colKey: 'customizedBy', rowIndex  })
  }

  renderCustomizeByCell = ({ value, rowIndex }) => {
    return (
      <span>
        {value || ""}
        <AppButton variant="text" onClick={() => this.handleCustomizeByEdit(rowIndex)}>
          {t.edit}
        </AppButton>
      </span>
    )
  }

  render() {
    const { showModal, onModalClose, projectDetails } = this.props;
    const { customizedPlatforms } = this.state;

    const defaultColDef = {
      resizable: false,
      flex:1      
    };

    return (
      <AppModal
        modalClass="admin-projects-is-customized-modal"
        showModal={showModal}
        onModalClose={() => onModalClose()}
        title={`${t.is_customized_modal_title} ${projectDetails ? projectDetails.name : ""} `}
        onConfirm={this.handleCustomizationConfirm}
        confirmBtnText={t.update}
        cancelBtnText={t.cancel}
        disabledValue={!customizedPlatforms.length}
      >
            <List
              columnConfig={columnConfig}
              rowData={projectDetails ? projectDetails.customizationInfo: []}
              clientSideSorting
              paginate={false}
              defaultColDef={defaultColDef}              onCellValueChanged={this.handleCellValueChanged}
              
              onGridReady={this.onGridReady}
              frameworkComponents={{ 
                isCustomizeCellRenderer: this.renderIsCustomizedDropDown,
                customizeByCellRenderer: this.renderCustomizeByCell
              }}
              onSearchEditorUserSelect={this.handleSearchUserSelected}
            />
      </AppModal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomizationInfoRequest: payload => { dispatch(updateCustomizationInfoRequest(payload))}
  };
};

export default connect(null, mapDispatchToProps)(AdminProjectsIsCustomizedModal);
