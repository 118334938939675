import * as type from "../action-types";

export const getPowerBiReports = () => ({
    type: type.GET_POWERBI_REPORTS_REQUEST
});

export const getPowerBiReportsSuccess = (payload) => ({
    type: type.GET_POWERBI_REPORTS_SUCCESS,
    payload
});

export const getPowerBiReportsFailure = (payload) => ({
    type: type.GET_POWERBI_REPORTS_FAILURE,
    payload
});

export const getPowerBiToken = (payload) => ({
    type: type.GET_POWERBI_TOKEN_REQUEST,
    payload
});

export const getPowerBiTokenSuccess = (payload) => ({
    type: type.GET_POWERBI_TOKEN_SUCCESS,
    payload
});

export const getPowerBiTokenFailure = (payload) => ({
    type: type.GET_POWERBI_TOKEN_FAILURE,
    payload
});

export const getAdminReports = () => ({
    type: type.GET_ADMIN_REPORTS_REQUEST
});

export const getAdminReportsSuccess = (payload) => ({
    type: type.GET_ADMIN_REPORTS_SUCCESS,
    payload
});

export const getAdminReportsFailure = (payload) => ({
    type: type.GET_ADMIN_REPORTS_FAILURE,
    payload
});

export const addAdminReport = (payload) => ({
    type: type.ADD_ADMIN_REPORT_REQUEST,
    payload
});

export const addAdminReportSuccess = (payload) => ({
    type: type.ADD_ADMIN_REPORT_SUCCESS,
    payload
});

export const addAdminReportFailure = (payload) => ({
    type: type.ADD_ADMIN_REPORT_FAILURE,
    payload
});

export const updateAdminReport = (payload) => ({
    type: type.UPDATE_ADMIN_REPORT_REQUEST,
    payload
});

export const updateAdminReportSuccess = (payload) => ({
    type: type.UPDATE_ADMIN_REPORT_SUCCESS,
    payload
});

export const updateAdminReportFailure = (payload) => ({
    type: type.UPDATE_ADMIN_REPORT_FAILURE,
    payload
});

export const deleteAdminReport = (payload) => ({
    type: type.DELETE_ADMIN_REPORT_REQUEST,
    payload
});

export const deleteAdminReportSuccess = (payload) => ({
    type: type.DELETE_ADMIN_REPORT_SUCCESS,
    payload
});

export const deleteAdminReportFailure = (payload) => ({
    type: type.DELETE_ADMIN_REPORT_FAILURE,
    payload
});
