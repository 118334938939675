import React from 'react';
import './appChip.scss';
import AppIcon from '../appIcon/appIcon';
import { xMark } from '../icons';
import { AppDetail } from '../../components/createProject/models/appDetail';

interface IAppChip {
  chipDetail: AppDetail;
  onRemoveChip?: (id: string) => void;
  canRemoveChip?: boolean;
}

const AppChip: React.FC<IAppChip> = ({ chipDetail, onRemoveChip, canRemoveChip= false }) => {
  return (
    <>
      <div className='chip-container'>
        <span className='chilp-name' title={chipDetail.appClassName}>{chipDetail.appClassName}</span>
        {onRemoveChip && canRemoveChip && (
          <span
            className='remove-chip'
            onClick={() => onRemoveChip(chipDetail.appClassId)}
          >
            <AppIcon icon={xMark} />
          </span>
        )}
      </div>
    </>
  );
};

export default AppChip;
