import * as type from "../action-types";
import {IhelpAndSupportData} from "../../models/IHelp";
import moment from 'moment';

const initialState: {
  userTimeDeviation: Number | null, helpSupport: IhelpAndSupportData[]
} = { userTimeDeviation: null, helpSupport:[] };

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_SERVER_TIME:
      return { ...state };
    case type.GET_SERVER_TIME_SUCCESS:
      return { ...state, userTimeDeviation: action.payload };
    case type.GET_SERVER_TIME_FAILURE:      
      return { ...state, userTimeDeviation: moment.utc().valueOf() };
      case type.GET_HELP_AND_SUPPORT_SUCCESS:
      return { ...state, helpSupport: action.payload.data };
    case type.GET_HELP_AND_SUPPORT_FAILURE:
      return { ...state, helpSupport: [] };
    default:
      return state;
  }
};
