export const userMgtKey = "User Management";
export const projectGovernanceKey = "Project Governance";
export const WORKFLOW_TYPE = [
  {
    id: "Communication Center",
    name: "Communication Center",
  },
  {
    id: userMgtKey,
    name: userMgtKey,
  },
  {
    id: "Workflow",
    name: "Workflow",
  }
];


export const WORKFLOW_CATEGORY = [
  {
    id: "Project Management",
    name: "Project Management",
  },
  {
    id: "Financial",
    name: "Financial",
  },
  {
    id: projectGovernanceKey,
    name: projectGovernanceKey,
  },
  {
    id: userMgtKey,
    name: userMgtKey,
  }
];

export const WORKFLOW_IS_ACTIVE = [
  {
    id: "true",
    name: "Yes",
  },
  {
    id: "false",
    name: "No",
  },
];

export const WORKFLOW_SCHEDULE_TYPE = [
  {
    id: "Once",
    name: "Once",
  },
  {
    id: "Recurring",
    name: "Recurring",
  },
];

export const WORKFLOW_RECURRING_SCHEDULE_TYPE = [
  {
    id: "Schedule End Date",
    name: "Schedule End Date",
  },
  {
    id: "Repeat Count",
    name: "Repeat Count",
  },
  {
    id: "Never End",
    name: "Never End",
  },
];

export const WORKFLOW_REPEAT_INTERVAL = [
  {
    id: "Hourly",
    name: "Hourly",
  },
  {
    id: "Daily",
    name: "Daily",
  },
  {
    id: "Monthly",
    name: "Monthly",
  },
];

export enum WFFields {
  id = "id",
  title = "title",
  workflowType = "wfr_WorkflowType",
  workflowCategory = "wfr_WorkflowCategory",
  description = "wfr_Description",
  codeFile = "wfr_CodeFile",
  parameterSchema = "wfr_ParameterSchema",
  isActive = "wfr_IsActive",
  workflowLookup = "wfr_WorkflowLookup",
  parameters = "wfr_Parameters",
  scheduleLookup = "wfr_ScheduleLookup",
  runStartTime = "wfr_RunStartTime",
  runEndTime = "wfr_RunEndTime",
  processMessage = "wfr_ProcessMessage",
  processStatus = "wfr_ProcessStatus",
  scheduleType = "wfr_ScheduleType",
  runNow = "wfr_RunNow",
  scheduleStartDate = "wfr_ScheduleStartDate",
  scheduleEndDate = "wfr_ScheduleEndDate",
  recurringScheduleEndType = "wfr_RecurringScheduleEndType",
  repeatInterval = "wfr_RepeatInterval",
  numberOfRepeats = "wfr_NumberOfRepeats",
  repeatFrequency = "wfr_RepeatFrequency",
  isRunning = "wfr_IsRunning",
  lastRunTime = "wfr_LastRunTime",
  numberOfRuns = "wfr_NumberOfRuns",
  workflowDynamicVars = "wfr_WorkflowDynamicVariables",
  correlationId = "wfr_CorrelationId",
  requesterUserId = "wfr_Requester",
  key = "key",
  workflowLookupTitle = "wfr_WorkflowLookup.title"
}

export enum InputFieldsType {
  Select = "select",
  TextArea = "textArea",
  Date = "Date",
  DateTime = "DateTime",
}

export enum InputFieldCss {
  small = "app-col-xs-1",
  medium = "app-col-xs-2",
  large = "app-col-xs-4",
}

export enum CommunicationFields {
  communicationTemplateCategory = "communicationTemplateCategory",
  communicationTemplateCategoryId = "communicationTemplateCategoryId",
  communicationTemplateDescription = "communicationTemplateDescription",
  sendFromEmail = "sendFromEmail",
  sendToEmail = "sendToEmail",
  communicationEmailSubject = "communicationEmailSubject",
  communicationEmailBody = "communicationEmailBody",
  cwfiworkflowName = "cwfiworkflowName",
  cwfiworkflowParameters = "cwfiworkflowParameters",
  wfr_WorkflowLookup = "wfr_WorkflowLookup",
  wfr_WorkflowLookupId = "wfr_WorkflowLookupId",
  communicationName = "communicationName",
  communicationTemplate = "communicationTemplate",
  wfrIsActive = "wfrIsActive",
  wfrScheduleLookup = "wfrScheduleLookup",
  communicationTemplateId = "communicationTemplateId",
  wfrScheduleLookupId = "wfr_ScheduleLookupId",
  isItemActive = "isItemActive",
  communicationTemplateCategoryTitle = "communicationTemplateCategory.title",
  wfr_WorkflowLookupTitle = "wfr_WorkflowLookup.title",
  communicationTemplateTitle = "communicationTemplate.title",
  wfrScheduleLookupTitle = "wfrScheduleLookup.title"
}

export enum ExecutionFieds {
  wfr_WorkflowLookup = 'definitionTitle',
  wfr_WorkflowType = 'definitionType',
  wfr_ScheduleLookup = 'scheduleTitle',
  wfr_RunStartTime = 'runStartTime',
  wfr_RunEndTime = 'runEndTime',
  wfr_ProcessMessage = 'processMessage',
  wfr_Parameters = 'parameters',
  wfr_ProcessStatus = 'processStatus',
  wfr_CorrelationId = 'correlationId',
  wfr_RequesterId = 'requesterId'
}

export interface IResponse {
  success?: boolean;
  error?: boolean;
}