import * as type from "../action-types";
import { IUser } from '../../models/user';
import { IPagination, getDefaultPaginationModel } from "../../models/IPagination";

const initialState: {
  users: IUser[],
  usersRequested: boolean,
  userGridPagination: IPagination,
  searchLoader: boolean,
  adminSearchUserGrid: {},
  updateData: {},
  isAnyoneHasAcceptedTerms: boolean
} = {
  usersRequested: false,
  userGridPagination: getDefaultPaginationModel(),
  users: [],
  searchLoader: false,
  adminSearchUserGrid: {},
  updateData: {},
  isAnyoneHasAcceptedTerms: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        usersRequested: true,
        users: null
      }
    case type.SEARCH_ADMIN_USER_GRID:
      return { ...state, searchLoader: true, adminSearchUserGrid: {} };
    case type.UPDATE_ADMIN_USER_GRID_REQUEST:
      return {
        ...state,
        updateData: {}
      }
    case type.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersRequested: false,
        users: action?.payload?.data?.records || [],
        userGridPagination: action?.payload?.data?.pagination || {},
        isAnyoneHasAcceptedTerms: action?.payload?.data.isAnyoneHasAcceptedTerms || false
      };
    case type.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        usersRequested: false,
        users: [],
      }
    case type.SEARCH_ADMIN_USER_GRID_SUCCESS:
      return { ...state, adminSearchUserGrid: action.payload, searchLoader: false };
    case type.SEARCH_ADMIN_USER_GRID_FAILURE:
      return { ...state, adminSearchUserGrid: {}, searchLoader: false };
    case type.UPDATE_ADMIN_USER_GRID_SUCCESS:
      return { ...state, updateData: action.payload };
    case type.UPDATE_ADMIN_USER_GRID_FAILURE:
      return { ...state, updateData: {} };
    default:
      return state;
  }
};
