import * as type from "../action-types";

const initialState = {
  addProjectAppsData: {},
  updateProjectAppsData: {},
  loading: false
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_PROJECT_APPS_REQUEST:
      return { ...state, updateProjectAppsData: {}, loading: true };
    case type.UPDATE_PROJECT_APPS_SUCCESS:
      return { ...state, updateProjectAppsData: action.payload, loading: false };
    case type.UPDATE_PROJECT_APPS_FAILURE:
      return { ...state, updateProjectAppsData: {}, loading: false };
    case type.ADD_PROJECT_APPS_REQUEST:
      return { ...state, updateProjectAppsData: {}, loading: true };
    case type.ADD_PROJECT_APPS_SUCCESS:
      return { ...state, addProjectAppsData: action.payload, loading: false };
    case type.ADD_PROJECT_APPS_FAILURE:
      return { ...state, addProjectAppsData: {}, loading: false };
    default:
      return state;
  }
};
