import React, { FC, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import { MODULES, FORMATS, FONT_FAMILIES, FONT_SIZES, BACKSPACE_KEY_CODE } from "./appRichTextBoxConstants";
import "react-quill/dist/quill.snow.css";
import "./appRichTextBox.scss";

interface IProps {
  id?: string,
  onTextChange: Function;
  bannerMessage: string;
  isValidMessage?: boolean;
  errorMsg?: string,
  placeholder?: string
}

// enabling parchment for font and size from initial value as strip intact
const font = Quill.import('formats/font');
const size = Quill.import('formats/size');
size.whitelist = FONT_SIZES;
font.whitelist = FONT_FAMILIES;
Quill.register(size, true);
Quill.register(font, true);


const AppRichTextBox: FC<IProps> = ({ bannerMessage, id, onTextChange, isValidMessage, errorMsg, placeholder }) => {

  useEffect(() => {
    const Font = Quill.import("formats/font");
    const fontSizes = Quill.import('attributors/style/size');

    Font.whitelist = FONT_FAMILIES;
    fontSizes.whitelist = FONT_SIZES;
    Quill.register(Font, true);
    Quill.register(fontSizes, true);
  }, [])

  const handleKeyDown = (e) => {
    if (!isValidMessage && e.keyCode !== BACKSPACE_KEY_CODE) {
      e.preventDefault()
    }
  }

  return (
    <div className="app-rich-text-box">
      <ReactQuill
        id={id}
        onChange={(editorHtml, delta, source, editor) => {
          onTextChange(editorHtml, delta, source, editor);
        }}
        value={bannerMessage}
        modules={MODULES}
        formats={FORMATS}
        bounds={'.app-rich-text-box'}
        placeholder={placeholder || "Banner message"}
        theme={"snow"}
        onKeyDown={handleKeyDown}
      />
      {!isValidMessage && <div className="error-msg">{errorMsg}</div>}
    </div>
  )
}

export default AppRichTextBox;