import React, { FC } from "react";
import { PowerBIEmbed, EmbedType } from "powerbi-client-react";
import { models } from "powerbi-client";

interface IReport {
    reportId: string;
    reportEmbedUrl: string;
    token: string;
    tokenId?: string;
    expiration?: Date | string;
}

type IProps = {
    report: IReport;
}

const PowerBiReports: FC<IProps> = ({ report }) => {
    
    return (report && report.reportId && (
        <div id="powerBiAdminReport">
            <PowerBIEmbed
                embedConfig={{
                    type: EmbedType.Report,
                    id: report.reportId,
                    embedUrl: report.reportEmbedUrl,
                    accessToken: report.token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                    }
                }}
                cssClassName={"project-report-power-bi-embed"}
            />
        </div>
    )) || null
}

export default PowerBiReports;
