import React, { useEffect, useState, FC } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import t from '../../localization/en/translation.json';
import { AppModal } from '../../theme';

interface IProps {
  when?: boolean;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeavingGuard: FC<IProps> = ({ when, navigate, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <AppModal
        showModal={modalVisible}
        confirmBtnText={t.manage_users_cancel_continue_btn}
        onConfirm={handleConfirmNavigationClick}
        cancelBtnText={t.cancel}
        onModalClose={closeModal}
        title={t.manage_users_cancel_dialog_title}
        size={'sm'}
        newDesign={true}
        modalClass='default-modal-dialog-new-design'
      >
        {t.manage_users_cancel_redirect_message}
      </AppModal>
    </>
  )
};

export default RouteLeavingGuard;
