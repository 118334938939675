import React, { useState, FC } from "react";
import t from '../../localization/en/translation.json'
import { AppModal } from "../../theme";
import "./readMore.scss";
const TEXT_TRUNCATE_LENGTH = 100;
interface IProps {
  title?: string;
  children: string
}

const ReadMore: FC<IProps> = ({ title, children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(true);
  };

  const onClose = () => {
    setIsReadMore(false);
  }

  return (
    <>
      <p className="text-contain">
        {text && text.length > TEXT_TRUNCATE_LENGTH ? text.slice(0, TEXT_TRUNCATE_LENGTH): text}
        {text && text.length > TEXT_TRUNCATE_LENGTH ? <span onClick={toggleReadMore} className="read-text-or-hide">
          ...{t.admin_cps_error_log_read_more}
        </span> : ''}
      </p>
      <AppModal
        size="md"
        showModal={isReadMore}
        onModalClose={() => onClose()}
        title={title? title: t.admin_cps_error_log_read_more}
      >
        <div className="read-more-modal-content">
          {text}
        </div>
      </AppModal>
    </>
  );
};

export default ReadMore;