import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { createProject, deleteDraftProject, updateProject, markCloseProject, patchProject } from '../apis/projectApi';

function* postCreateProject(action) {
  try {
    const data = yield call(createProject, action);
    yield put({
      type: type.CREATE_PROJECT_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.CREATE_PROJECT_FAILURE,
      payload: message
    });
  }
}

function* putProject(action) {
  try {
    const data = yield call(updateProject, action);
    yield put({
      type: type.UPDATE_PROJECT_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_PROJECT_FAILURE,
      payload: message
    });
  }
}

function* patchProjectUpdate(action) {
  try {
    const data = yield call(patchProject, action.payload);
    yield put({
      type: type.PATCH_PROJECT_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.PATCH_PROJECT_FAILURE,
      payload: message
    });
  }
}

function* deleteProject(action) {
  try {
    const data = yield call(deleteDraftProject, action);
    yield put({
      type: type.DELETE_DRAFT_PROJECT_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.DELETE_DRAFT_PROJECT_FAILURE,
      payload: message
    });
  }
}

function* closeProject(action) {
  try {
    const data = yield call(markCloseProject, action);
    yield put({
      type: type.CLOSE_PROJECT_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.CLOSE_PROJECT_FAILURE,
      payload: message
    });
  }
}

export function* postCreateProjectActionWatcher() {
  yield takeLatest(type.CREATE_PROJECT, postCreateProject);
  yield takeLatest(type.UPDATE_PROJECT, putProject);
  yield takeLatest(type.DELETE_DRAFT_PROJECT_REQUEST, deleteProject);
  yield takeLatest(type.CLOSE_PROJECT_REQUEST, closeProject);
  yield takeLatest(type.PATCH_PROJECT, patchProjectUpdate);
}
