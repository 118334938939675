import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { rightArrow, back } from "../../theme/icons";
import AppIcon from "../../theme/appIcon/appIcon";
import { Link } from "react-router-dom";
import "./breadcrumb.scss";

interface BreadcrumbItem {
  label: string;
  href: string;
  active?: boolean;
}

interface BreadcrumbComponentProps {
  items: BreadcrumbItem[];
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ items }) => {
  return (
    <div className="breadcrumb">
      <Breadcrumb>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index === items.length - 1 ? (
              <Breadcrumb.Item active className="breadcrumb-item-active">
                {item.label}
              </Breadcrumb.Item>
            ) : (
              <>
                <Link className="breadcrumb-item-custom" to={item.href}>
                {index === 0 && <AppIcon icon={back} className="icon-link" />}
                  {item.label}
                </Link>
              </>
            )}
            {index < items.length - 1 && (
              <AppIcon className="breadcrumb-arrow" icon={rightArrow} />
            )}
          </React.Fragment>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComponent;
