import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import * as api from '../apis/projectApi';
import { getUsersListSuccess, getUsersListFail } from '../actions/admin-users-actions';

function* getUsersList(action) {
  try {
    const data = yield call(api.getUsersList, action.payload);
    yield put(getUsersListSuccess(data));
  } catch ({ message }) {
    yield put(getUsersListFail(message));
  }
}

function* getAdminSearchGrid(action) {
  try {
    const data = yield call(api.searchAdminUserGrid, action);
    yield put({
      type: type.SEARCH_ADMIN_USER_GRID_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.SEARCH_ADMIN_USER_GRID_FAILURE,
      payload: message,
    });
  }
}

function* updateAdminGrid(action) {
  try {
    const data = yield call(api.updateAdminUserGrid, action);
    yield put({
      type: type.UPDATE_ADMIN_USER_GRID_SUCCESS,
      payload: data,
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_ADMIN_USER_GRID_FAILURE,
      payload: message,
    });
  }
}


export function* adminUsersSagaWatcher() {
  yield takeLatest(type.GET_USERS_LIST_REQUEST, getUsersList);
  yield takeLatest(type.SEARCH_ADMIN_USER_GRID, getAdminSearchGrid);
  yield takeLatest(type.UPDATE_ADMIN_USER_GRID_REQUEST, updateAdminGrid);
}
