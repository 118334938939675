import React, { FC, useState, useEffect, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { MenuPlacement } from "react-select";
import { Select } from "../../select/select";
import AppSearchDropdown from "../../appSearchDropdown/appSearchDropdown";
import { USER_ACTIONS } from '../../../containers/manageUsers/listUsers/listUserConstants';
import "./selectCellEditor.scss";

const MAX_LIST_LENGTH = 500;
const SCROLL_BOTTOM = 210;
const CHUNK_LENGTH = 100;

interface IProps extends ICellRendererParams {
    OnValueChange: Function;
    data: any;
    selectValueFieldName: string;
}

interface IOption {
    value: string;
    label: string
}

export const SelectCellEditor: FC<IProps> = (props) => {
    
    const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);
    const [scrollPos, setScrollPos] = useState<number>(0);

    const { colDef, OnValueChange, data, rowIndex, selectValueFieldName, value: oldValue } = props;
    const selectedValueId = data[`${selectValueFieldName}`];

    const checkListSize = useCallback(() => {
        const { colDef, data } = props;
        const options = colDef?.cellRendererParams.data.options;
        const additionalProps = colDef?.cellRendererParams.additionalProps || null;
        const selectOptions = colDef?.cellRendererParams.data.filterOptions ? colDef?.cellRendererParams.data.filterOptions(data, options, additionalProps) : options;
        if (selectOptions.length < MAX_LIST_LENGTH) {
            return
        }

        setFilteredOptions(selectOptions.filter((o, i) => i < CHUNK_LENGTH));
    }, [props]);

    const handleNextChunk = (scrollPos, scrollHeight) => {
        const { colDef, data } = props;
        const options = colDef?.cellRendererParams.data.options;
        const additionalProps = colDef?.cellRendererParams.additionalProps || null;
        const selectOptions = colDef?.cellRendererParams.data.filterOptions ? colDef?.cellRendererParams.data.filterOptions(data, options, additionalProps) : options;

        if (scrollHeight - scrollPos > SCROLL_BOTTOM || selectOptions.length === filteredOptions.length) {
            return
        }

        setScrollPos(scrollPos);
        setFilteredOptions([...filteredOptions, ...selectOptions.filter((o, i) => i >= filteredOptions.length && i < getMaxFilterOptionLength(filteredOptions, selectOptions))]);
    }

    const getMaxFilterOptionLength = (filteredOptions, selectedOptions) => {
        if ((filteredOptions.length + CHUNK_LENGTH) >= selectedOptions.length) {
            return selectedOptions.length
        }

        return filteredOptions.length + CHUNK_LENGTH
    }

    const handleResetOptions = () => {
        setScrollPos(0);
        setFilteredOptions(filteredOptions.filter((o, i) => i < CHUNK_LENGTH));
    }


    useEffect(() => {
        checkListSize();
    }, [checkListSize]);

    const multiple = colDef?.cellRendererParams.data.multiple;
    const options = colDef?.cellRendererParams.data.options;
    const hideSelect = colDef?.cellRendererParams.hideSelect || false;
    const additionalProps = colDef?.cellRendererParams.additionalProps || null;
    const selectOptions = colDef?.cellRendererParams.data.filterOptions ? colDef?.cellRendererParams.data.filterOptions(data, options, additionalProps) : options;
    const className = colDef?.cellRendererParams.className ? colDef?.cellRendererParams.className : colDef?.cellRendererParams.additionalProps?.generateClassNameOnRender ? colDef?.cellRendererParams.additionalProps?.generateClassNameOnRender(data, colDef?.field) : '';
    const renderPreTemplete = additionalProps?.renderPreTemplete && additionalProps?.renderPreTemplete(data, colDef?.field);

    const  defaultValue = multiple ? selectOptions.filter(item => selectedValueId.includes(item.value)) : selectOptions.find(item => item.value === selectedValueId);

    let placement: MenuPlacement = 'auto';
    const minimumRows = data.minimumRows || 4;
    if (data.totalRows > minimumRows - 1 && data.totalRows === rowIndex) {
        placement = 'top';
    }
    const cellHTML = multiple ?
        <AppSearchDropdown
            width={colDef?.cellRendererParams.selectWidth || colDef?.width || 250}
            idSuffix={rowIndex?.toString()}
            options={selectOptions}
            multiSelect={multiple}
            defaultValue={defaultValue}
            placeholder={colDef?.cellRendererParams.data.placeholder}
            isDisabled={colDef?.cellRendererParams.data.isSelectDisabled || data.actionTaken === USER_ACTIONS.DELETE || false}
            onValueChange={(selectedOptions) => {
                OnValueChange(selectedOptions, { ...data, oldValue }, additionalProps);
            }}
        /> :
        <>
            {renderPreTemplete}
            <Select
                width={colDef?.cellRendererParams.selectWidth || colDef?.width || 250}
                idSuffix={rowIndex?.toString()}
                closeMenuOnSelect={!multiple}
                isMulti={multiple}
                placeholder={colDef?.cellRendererParams.data.placeholder}
                isDisabled={colDef?.cellRendererParams.data.isSelectDisabled || data.actionTaken === USER_ACTIONS.DELETE || false}
                options={selectOptions.length > MAX_LIST_LENGTH ? filteredOptions : selectOptions}
                defaultValue={defaultValue}
                menuPlacement={placement}
                className={`select-cell-editor ${className}`}
                classNamePrefix={colDef?.cellRendererParams.classNamePrefix || ''}
                onCustomMenuScroll={handleNextChunk}
                isLargeList={selectOptions.length > MAX_LIST_LENGTH}
                scrollPos={scrollPos}
                onCustomMenuClose={handleResetOptions}
                onValueChange={(selectedOptions) => {
                    OnValueChange(selectedOptions, { ...data, oldValue }, additionalProps);
                }}
            />
        </>;
    return !hideSelect ? cellHTML : null;
}
