import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { InputProps as StandardInputProps } from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/styles";
import { InputAdornment } from "@material-ui/core";
import themeConfig from "../config";
import "./motifAppInput.scss";

const useStyle = makeStyles({
  root: {
    width: "100%",
    fontFamily: themeConfig.eyInterstateRegular,
    color: themeConfig.gray,
  },
});

interface IProps {
  id?: string;
  value?: string;
  name?: string;
  isValid?: boolean;
  maxLength?: string | number;
  autoComplete?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  type?: string;
  onFocus?: StandardInputProps["onFocus"];
  onChange?: StandardInputProps["onChange"];
  className?: string;
  startAdornment?: any;
  endAdornment?: any;
}

const MotifAppInput: FC<IProps> = ({
  isValid,
  maxLength,
  type,
  autoComplete,
  placeholder,
  startAdornment,
  endAdornment,
  className,
  ...restProps
}) => {
  const classes = useStyle();

  const startAdornmentElement = (
    <InputAdornment
      position="start"
      className={isValid !== undefined && !isValid ? "in-valid" : ""}
    >
      {startAdornment}
    </InputAdornment>
  );
  const endAdornmentElement = (
    <InputAdornment
      position="end"
      className={isValid !== undefined && !isValid ? "in-valid" : ""}
    >
      {endAdornment}
    </InputAdornment>
  );

  const InputProps = {
    ...(startAdornment && { startAdornment: startAdornmentElement }),
    ...(endAdornment && { endAdornment: endAdornmentElement }),
  };

  return (
    <TextField
      className={`motif-input ${className || ""} ${
        isValid !== undefined && !isValid ? "in-valid" : ""
      }`}
      classes={{ ...classes }}
      autoComplete={autoComplete || "off"}
      variant="outlined"
      inputProps={{ maxLength, placeholder, type }}
      InputProps={InputProps}
      {...restProps}
    />
  );
};

export default MotifAppInput;
