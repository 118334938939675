import React, { FC, useState } from "react";
import { ZERO_PREFIX, MAX_MONTH_LENGTH } from "./listConstants";
import { AppButton } from "../../../theme";
import t from '../../../localization/en/translation.json';

interface IProps {
    title?: string,
    onDateEditorChange: Function;
    projectId: string;
    onCancel: Function;
}

const ListDateEditor: FC<IProps> = ({ title, onDateEditorChange, projectId, onCancel }) => {
    const [selectedDate, handleDateChange] = useState("");
    const getToday = () => {
        const date = new Date();
        let month = (date.getMonth() + 1).toString();
        month = month.length < MAX_MONTH_LENGTH ? `${ZERO_PREFIX}${month}` : month;
        return `${date.getFullYear()}-${month}-${date.getDate()}`
    }

    return (
        <div className="list-date-container">
            {title? <h3>{title}</h3>: undefined}
            <input
                className="list-date-editor"
                type="date"
                min={getToday()}
                onChange={({ target }) => handleDateChange(target.value)}
            />
            <div className="list-date-buttons">
                <AppButton disabled={!selectedDate.trim().length}
                           onClick={() => onDateEditorChange({ value: selectedDate, projectId })}>{t.update}</AppButton>
                <AppButton onClick={() => onCancel()}>{t.cancel}</AppButton>
            </div>
        </div>
    )
}

export default ListDateEditor;
