import React, { FC } from 'react';
import t from '../../../localization/en/translation.json';
import AppCard from '../../appCard/appCard';
import { AppClass } from '../../appCard/models/appClass';
import { AppCategory } from '../../appCard/models/appCategory';
import { MoreMenuOption } from '../../moreMenu/moreMenuOptions';
import { OptionActionName } from './customizeApplicationConst';
import { AppButton, AppIcon } from "../../../theme";
import { add } from "../../../theme/icons"
import './customizeApplications.scss';

interface IProps {
    appCards: AppClass[];
    appsCount: number;
    appCategories: AppCategory[];
    selectedAppCardHandler: Function;
    appCardId: string | null;
    removeAppCardHandler: Function;
    addAppHandler: Function;
}


const CustomizeApplications: FC<IProps> = ({ appCards, appsCount, appCategories,
    selectedAppCardHandler, appCardId, removeAppCardHandler, addAppHandler }) => {
    let options: MoreMenuOption[];
    const setActiveAppCard = (appCards: AppClass) => {
        selectedAppCardHandler(appCards);
    }
    const handleMoreMenuOptionClick = (actionName: string, id: string) => {
        const appCard = actionName && id && appCards.find(obj => obj.id === id);
        if (actionName === OptionActionName.ViewDetails) {
            if (appCard) {
                setActiveAppCard(appCard);
            }

        } else if (actionName === OptionActionName.Demo) {
            if (appCard && appCard.demoSiteURL) {
                window.open(appCard.demoSiteURL, "_blank");
            }
        } else if (actionName === OptionActionName.Remove) {
            removeAppCardHandler(appCard);
        }
    }

    const getOptions = (id: string, demoSiteURL: string): MoreMenuOption[] => {
        options = [
            { id, actionName: OptionActionName.Demo, title: t.customize_bundle_apps_demo }
            , { id, actionName: OptionActionName.Remove, title: t.customize_bundle_apps_remove }]
        if (!demoSiteURL || demoSiteURL === null || demoSiteURL.length === 0) {
            options = options.filter((option) => option.actionName !== OptionActionName.Demo);
        }
        return options;
    }

    const getAppClasses = (appId: string | null, itemId: string) => {
        if (!appId) {
            return;
        } else {
            return appId === itemId ? 'selectedAppCard' : 'grayedOut';
        }
    }

    const handleRemoveOptionClick = (id: string) => {
        const appCard = id && appCards.find(obj => obj.id === id);
        removeAppCardHandler(appCard);
    }

    return (
        <div className="customize-apps">
            <div className="app-row">
                <div className="app-col-xs-4 app-col-lg-12 apps-header">
                    <span className="cust-bundle-header-title">{t.customize_bundle_apps_title}</span>
                    <span className="float-right app-count">{appsCount} {t.customize_bundle_apps_selected}</span>
                </div>
            </div>
            <div className="app-row">
                <div className="app-col-xs-4 app-col-lg-12 apps-header">
                    <div className="apps-wrapper">
                        {
                            appCards.map((item, i) =>
                                <div key={i} onClick={() => setActiveAppCard(item)} className={getAppClasses(appCardId, item.id)}>
                                    <AppCard key={i}
                                        data={item}
                                        appCategories={appCategories}
                                        moreMenuEnabled={false}
                                        descriptionEnabled={false}
                                        footerEnabled={false}
                                        showDemoBtn={true}
                                        moreMenuOption={getOptions(item.id, item.demoSiteURL)}
                                        handleMoreMenuOptionClick={handleMoreMenuOptionClick}
                                        removeEnabled={!item.isRemoveDisabled}
                                        handleRemove={handleRemoveOptionClick} />
                                </div>
                            )}
                    </div>
                </div>
                <div className="bottom-btn-app">
                    <div className="add-bn-new">
                        <AppButton className="save-btn" color="primary" size="large" onClick={() => addAppHandler()}>
                            <AppIcon icon={add} />{t.customize_bundle_header_add_application}
                        </AppButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomizeApplications;
