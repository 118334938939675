import * as type from "../action-types";

type load = { id: string }

export const addProjectAppsAction = (payload?: load) => ({
    type: type.ADD_PROJECT_APPS_REQUEST,
    payload
});

export const updateProjectAppsAction = (payload?: load) => ({
    type: type.UPDATE_PROJECT_APPS_REQUEST,
    payload
});
