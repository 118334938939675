import React, { FC } from 'react';
import { Tooltip, TooltipProps } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/styles';
import ThemeConfig from '../config'
import './appTooltip.scss';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: (props: any) => props.tooltipwidth ? props.tooltipwidth: 600,
      backgroundColor: ThemeConfig.white,
      borderRadius: 0,
      padding: '10px',
      fontSize: ThemeConfig.fontSize14,
      color: ThemeConfig.black
    },
    tooltipArrow: {
      color: ThemeConfig.white,
    },
    tooltipAlt: {
      maxWidth: (props: any) => props.tooltipwidth ? props.tooltipwidth: 600,
      backgroundColor: ThemeConfig.black,
      borderRadius: 0,
      padding: '10px',
      fontSize: ThemeConfig.fontSize14,
      color: ThemeConfig.white,
    },
    tooltipArrowAlt: {
      color: ThemeConfig.black,
    }
  }),
);

interface IProps {
  hide?: boolean
  isWhite?: boolean,
  tooltipwidth?: number
}

const AppTooltip: FC<IProps & TooltipProps> = ({ children, hide, isWhite, ...rest }) => {
  const classes = useStyles(rest);

  return (!hide && (
    <Tooltip
      {...rest}
      className={`app-tooltip ${rest.className??''}`}
      arrow
      classes={{
        tooltip: isWhite ? classes.tooltip : classes.tooltipAlt,
        arrow: isWhite ? classes.tooltipArrow : classes.tooltipArrowAlt
      }}
      title={<div data-test={`tooltip-${rest.title}`}>{rest.title}</div>}
    >
      <div className="app-tooltip-container">
        {children}
      </div>
    </Tooltip>
  )) || (
      <div>
        {children}
      </div>
    )
};

export default AppTooltip;
