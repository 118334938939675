import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import "./appTextField.scss"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}));

interface IProps {
    onChange?: StandardInputProps['onChange'];
    disabled?: boolean;
    rows?: number;
    name?: string;
    maxLength?: number;
    label?: string|ReactNode;
    value?: string;
    autoComplete?: string;
    isValid?: boolean;
}

const AppTextField: FC<IProps> = ({ maxLength = 5000, rows, isValid, autoComplete, ...restProps }) => {
  const classes = useStyles();

  return (
    <TextField
      className={`app-text-field ${(isValid !== undefined && !isValid) ? 'in-valid' : ''}`}
      classes={classes}
      multiline
      variant="filled"
      autoComplete={autoComplete || "off"}
      rows={rows || 2}
      InputLabelProps={{ style: { pointerEvents: "auto" } }}
      inputProps= {{
          maxLength,
          style: {
              height: "100%"
          }
      }}
      {...restProps}
    />
  );
}

export default AppTextField