import React from 'react';
import { Switch } from '@material-ui/core';
import './appToggleSwitch.scss';

interface IProps {
  checked?: boolean
  onChange: Function
  id?: string;
  newDesign?: boolean;
  disabled?: boolean;
}

const AppToggleSwitch: React.FC<IProps> = ({checked = false, onChange, id="app-toggle-switch", newDesign = false, disabled = false}) => {

return (
  <span className={newDesign ? "new-app-toggle-switch": "app-toggle-switch"}>
      <Switch 
          disableRipple
          checked={checked}
          onChange={(e) => onChange(e)}
          id={id}
          disabled={disabled}
      />
  </span>
);
}

export default AppToggleSwitch;