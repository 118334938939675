import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import List from "../../components/editableGrid/list/list";
import { columnConfig } from './columnConfig';
import t from '../../localization/en/translation.json';
import {  refresh } from "../../theme/icons";
import { MoreMenuOption } from '../../components/moreMenu/moreMenuOptions';
import MoreMenu from '../../components/moreMenu/moreMenu';
import { getAdminSectorsRequest, updateAdminSectorsRequest } from '../../store/actions/admin-general-actions';
import { updatefilterState } from '../../store/actions/list-actions';
import { lookupModel } from '../../containers/createProject/lookupModel';
import { getAdminConsoleAccess } from '../../utils/helper-utility';
import { ISectors } from '../../models/IAdminSectors';
import { ACTION_NAME, DEFAULT_SORT, ROW_MIN_HEIGHT, DESC_PER_ROW } from "./adminSectorsConstants";
import AdminSectorsModal from '../../components/adminSectorsModal/adminSectorsModal';
import { IAccessRight } from '../../models/user';
import { ISSPPlatformRole } from '../../models/IRoles';
import { IPagination, getDefaultPaginationModel, getPaginationModel } from '../../models/IPagination';
import './adminSectors.scss';

type IProps = RouteComponentProps<any> & {
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  adminSectors: lookupModel[];
  getAdminSectorsRequest: Function;
  updatefilterState: Function;
  paginationDetails: IPagination;
  updateAdminSectorsRequest: Function;
  isSectorUpdated: boolean;
}

interface IState {
  paginationPayload: IPagination;
  isSectorModalOpen: boolean;
  selectedSector: ISectors | null;
  description: string
}

class AdminSectors extends Component<IProps, IState> {
  gridApi;
  state = {
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: []
    }),
    isSectorModalOpen: false,
    selectedSector: null,
    description: ""
  }

  componentDidMount() {
    this.getAdminSectors();
    this.updateFilterState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { paginationPayload } = this.state;
    const { isSectorUpdated } = this.props;

    if (prevState.paginationPayload !== paginationPayload) {
      this.getAdminSectors();
      this.updateFilterState();
    }

    if(prevProps.isSectorUpdated !== isSectorUpdated && isSectorUpdated) {
      this.getAdminSectors();
    }
  }

  getAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole = (accessRight?.portalRoles && accessRight.portalRoles.length > 0 && accessRight.portalRoles[0]) || '';
    return getAdminConsoleAccess(portalRole, sspRoles);
  }

  getAdminSectors = () => {
    const { paginationPayload } = this.state;

    this.props.getAdminSectorsRequest(paginationPayload);
  }

  onGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  }

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  }

  handleSorting = (sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;

    if (paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection) {
      return;
    }

    const updatedPaginationPayload = { ...paginationPayload, sortColumn, sortDirection }
    this.setState({ paginationPayload: updatedPaginationPayload })
  }

  handleFilterChange = (filters, sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;
    const updatedPayload = { ...paginationPayload, filters, sortColumn, sortDirection, pageNumber: 1 };

    this.setState({ paginationPayload: updatedPayload })
  }

  updateFilterState = () => {
    const { filters = [], sortDirection, sortColumn } = this.state.paginationPayload;
    const isDefaultSort = sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length

    this.props.updatefilterState({ filters, sortDirection, sortColumn, isClearAllDisabled })
  }

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = { ...paginationPayload, ...paginationParams };

    this.setState({ paginationPayload: updatedPaginationPayload, })
  }

  handleMoreMenuOptionClick = (actionName: string) => {
   if (actionName === ACTION_NAME.UPDATE) {
      const rows = this.gridApi.getSelectedRows();
      this.setState({ selectedSector: rows[0], description: rows[0].description || "", isSectorModalOpen: true });
    }
  }

  renderMoreMenu = () => {
    const options: MoreMenuOption[] = [
      { id: "123", title: t.update, icon: refresh, actionName: ACTION_NAME.UPDATE },
    ]

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={this.handleMoreMenuOptionClick}
        options={options}
        vertical
        leftPlacement
      />
    )
  }

  renderDescriptionRows = ({ value }) => (<span>{value}</span>)

  handleUpdateSectors = (payload) => {
    const { updateAdminSectorsRequest } = this.props;

    this.setState({ isSectorModalOpen: false });
    updateAdminSectorsRequest([payload])
  }

  handleRowHeight = ({ data }) => {
    if(!data.description || data.description.length <= DESC_PER_ROW) {
      return ROW_MIN_HEIGHT
    }

    return Math.ceil(data.description.length / DESC_PER_ROW) *  ROW_MIN_HEIGHT;
  }

  handleUpdateDescription = (description) => this.setState({ description })

  render() {
    const { adminSectors, paginationDetails  } = this.props;
    const { paginationPayload, isSectorModalOpen, selectedSector, description } = this.state;
    const { portalAdminConsole } = this.getAdminConsoleAccess();
    const filteredColumns = portalAdminConsole ? columnConfig.filter(item => item.field !== "iconButton") : columnConfig;

    if (paginationDetails) {
      paginationPayload.totalRecords = paginationDetails.totalRecords;
      paginationPayload.totalRecordsWithoutFilter = paginationDetails.totalRecords || paginationPayload.totalRecordsWithoutFilter;
    }

    return (
      <div className={`admin-sectors ${portalAdminConsole ? "read-only" : ""}`}>
          <div className={`sector-table`}>
            <List
              columnConfig={filteredColumns}
              defaultColDef={defaultColDef} 
              firstColumnBorderRight={true}
              rowData={adminSectors}
              paginate={true}
              frameworkComponents={{
                moreMenuCellRenderer: this.renderMoreMenu,
                sectorDescriptionCellRender: this.renderDescriptionRows
              }}
              onGridReady={this.onGridReady}
              onSortChanged={this.handleSorting}
              currentPage={paginationPayload.pageNumber}
              pagination={paginationPayload}
              setItemsPerPage={this.handlePageSizeChange}
              onPageChange={this.handlePageNumberChange}
              onFilterChange={this.handleFilterChange}
              defaultSort={[DEFAULT_SORT]}
              getRowHeight={this.handleRowHeight}
            />

          </div>

        <AdminSectorsModal
          isOpen={isSectorModalOpen}
          onClose={() => this.setState({ isSectorModalOpen: false })}
          onConfirm={this.handleUpdateSectors}
          selectedSector={selectedSector}
          onUpdateDescription={this.handleUpdateDescription}
          description={description}
        />
        </div>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};

const mapStateToProps = ({ adminGeneralReducer, meta, meReducer }) => {
  return {
    adminSectors: adminGeneralReducer.adminSectors,
    paginationDetails: adminGeneralReducer.paginationDetails,
    isSectorUpdated: adminGeneralReducer.isSectorUpdated,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminSectorsRequest: payload => { dispatch(getAdminSectorsRequest(payload)) },
    updatefilterState: payload => {dispatch(updatefilterState(payload))},
    updateAdminSectorsRequest: payload => {dispatch(updateAdminSectorsRequest(payload))}
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSectors));
