import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { expand, compress, leftArrow, search } from '../../theme/icons';
import { Dialog, Slide } from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import moment from "moment";

import t from '../../localization/en/translation.json';
import AppCard from '../appCard/appCard';
import { AppCategory } from '../appCard/models/appCategory';
import { AppClass } from '../../components/appCard/models/appClass';
import { BundleClass } from '../../components/bundleCard/models/bundleClass';
import { getIconPath } from '../../utils/helper-utility';
import { AppInput, AppToggleSwitch, AppIcon, AppIconButton } from "../../theme";
import CloseIcon from '@material-ui/icons/Close';
import './appDrawer.scss';
import { useSelector } from "react-redux";
import { gridAppPlatformKeys } from "../../containers/manageUsers/listUsers/listUserConstants";
import { APP_STATE } from "../../store/reducers";
import { usePrevious } from "../../utils/hooks";
import { sortAppOrder } from '../../utils/Constants';
import { Project } from '../createProject/models/project';
interface IProps {
  apps: AppClass[],
  bundleCard: BundleClass | null,
  appCategories: AppCategory[],
  closeHandler: Function,
  open: boolean,
  createProjectEnabled: boolean,
  record?: Project
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AppDrawer: FC<IProps> = (props) => {

  const [showFullscreen, setShowFullscreen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAppDetails, setShowAppDetails] = useState(false);
  const [selectedBundleCard, setSelectedBundleCard] = useState(_.cloneDeep(props.bundleCard || {}));
  const [filteredArr, setFilteredArr] = useState<AppClass[]>([]);
  const [selectedApp, setSelectedApp] = useState<AppClass>(props.apps[0]);

  const { apps, closeHandler, open, bundleCard } = props;
  const selectedAppIds = (selectedBundleCard && selectedBundleCard.selectedAppIds) || [];
  const appsArr = searchQuery.trim().length > 2 ? filteredArr : apps;

  const prevProps = usePrevious({ bundleCard });

  const activeFeatures = useSelector<APP_STATE, string[]>(
    ({ projects }) => projects.activeFeatures
  );

  const toggleFullscreen = () => {
    setShowFullscreen(!showFullscreen)
  }

  useEffect(() => {
    if (prevProps && prevProps.bundleCard !== bundleCard) {
      setSelectedBundleCard(_.cloneDeep(bundleCard || {}))
    }
  }, [bundleCard, prevProps])

  const handleSearchChange = (val: string) => {
    const searchVal = val.trim();
    if (searchVal.length > 2) {
      const filteredArr = props.apps.filter(
        app => app.name.toLowerCase().includes(searchVal.toLowerCase()) || app.description.toLowerCase().includes(searchVal.toLowerCase())
      );
      setSearchQuery(val);
      setFilteredArr(filteredArr)
    } else {
      setSearchQuery(val);
    }
  }

  const handleActiveCard = (activeApp, checked) => {
    if (!selectedBundleCard.selectedAppIds) {
      selectedBundleCard.selectedAppIds = [];
    }

    if (checked) {
      selectedBundleCard.selectedAppIds.push({ id: activeApp.id });
    } else {
      selectedBundleCard.selectedAppIds = selectedBundleCard.selectedAppIds.filter((app) => app.id !== activeApp.id)
    }
    setSelectedBundleCard({ ...selectedBundleCard })
  }

  const getActiveStatus = (itemId) => {
    const { selectedAppIds = [] } = selectedBundleCard || {};

    return !!selectedAppIds.find((app) => app.id === itemId);
  }

  const onDetailsClick = (app) => {
    setSelectedApp(app)
    setShowAppDetails(true);
  }

  const backArrowHandler = () => {
    setShowAppDetails(false);
  }

  const onCloseModal = (event, reason) => {
    if (reason !== 'backdropClick' && closeHandler) {
      closeHandler(selectedAppIds)
    }
  }

  const renderAppdetails = () => {
    const { appCategories, closeHandler } = props;
    const { appCategoryId, id, name, description } = selectedApp;
    const appCategory = appCategories.find(f => f.id === appCategoryId);
    const iconPath = getIconPath(appCategory);
    const iconBackgroundColor = {
      background: appCategory?.colorCode
    };
    const selectedAppIds = (selectedBundleCard && selectedBundleCard.selectedAppIds) || [];

    return (
      <>
        <MuiDialogTitle>
          <div className="app-details">
            <span className="back-arrow" onClick={backArrowHandler}>
              <AppIcon icon={leftArrow} />
            </span>
            <span>{t.app_drawer_app_details}</span>
          </div>
          <AppIconButton
            aria-label="close"
            onClick={() => closeHandler(selectedAppIds)}
          >
            <CloseIcon />
          </AppIconButton>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <div className="app-tile">
            <div className="icon-n-title">
              <div className="app-icon" style={iconBackgroundColor}>
                {iconPath && <img src={iconPath} className="img-app-card" alt="icon" />}
              </div>
              <div className="app-title">
                <span>{name}</span>
                <span className="category-name">{appCategory?.name}</span>
              </div>
            </div>
            <div className="app-toggle">
              <AppToggleSwitch
                checked={getActiveStatus(id)}
                onChange={e => handleActiveCard(selectedApp, e.target.checked)}
                id={`app-store-${id}`}
              />
            </div>
          </div>
          <div className="details-title">{t.app_drawer_details}</div>
          <div className="details-desc">{description}</div>
        </MuiDialogContent>
      </>
    )
  }

  const renderFullScreenIcon = () => (
    <div className="fullscreen-icon" onClick={toggleFullscreen}>
      {showFullscreen ?
        <AppIcon icon={compress} /> : <AppIcon icon={expand} />}
    </div>
  )

  const getAvailableApps = (appsArr) => {
    return props.createProjectEnabled ? appsArr : appsArr.filter((app) => {
      const isAppAvailable = moment(props.record?.createdOn).diff(moment(app.createdOn), 'days');
      return isAppAvailable > 0;
    }) || []
  }

  const renderAppList = (appsArr) => {
    const { appCategories } = props;
    // as per business requirement, SharePoint app only available to admins to add when it is part of active feature list of existing project or create project UX
    const isSharePointAppSupported = activeFeatures.map((features) => features.toLowerCase())
      .includes(gridAppPlatformKeys.SHARE_POINT.toLowerCase()) || props.createProjectEnabled;

    // Newly Provisioned apps are filtering out from the list as compared to the project created date
    const filteredAppsList = getAvailableApps(appsArr);

    if (filteredAppsList.length) {
      const filterSharePointApp = (appArr) => appArr.key !== gridAppPlatformKeys.SHARE_POINT || isSharePointAppSupported;
      filteredAppsList.sort((a, b) => sortAppOrder(a) - sortAppOrder(b));
      return filteredAppsList.filter(filterSharePointApp).map(item =>
        <AppCard
          footerEnabled
          bundleId={selectedBundleCard?.id}
          key={`app-store-${selectedBundleCard?.id}-${item.id}`}
          data={item}
          appCategories={appCategories}
          moreMenuEnabled={false}
          showDemoBtn={true}
          descriptionEnabled={true}
          onActiveCard={handleActiveCard}
          isActive={getActiveStatus(item.id)}
          onDetailsClick={onDetailsClick}
        />
      )
    } else {
      return <div className="no-result">{t.app_drawer_no_apps}</div>
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      TransitionProps={{
        in: open,
        timeout: 100
      }}
      className={`app-drawer ${showFullscreen ? "fullscreen-drawer" : ""}`}
      onClose={onCloseModal}
    >
      {!showAppDetails ?
        <>
          <MuiDialogTitle>
            <div>{t.app_drawer_app_store}</div>
            <div className="app-drawer-header-actions">
              {renderFullScreenIcon()}
              <AppIconButton
                aria-label="close"
                onClick={() => closeHandler(selectedAppIds)}
              >
                <CloseIcon />
              </AppIconButton>
            </div>
          </MuiDialogTitle>
          <MuiDialogContent dividers>
            <div className="tab-wrapper">
              <span className="all-tab">{t.app_drawer_all}</span>
              <span>{`${selectedAppIds.length} ${t.app_drawer_of} ${getAvailableApps(apps).length} ${t.app_drawer_selected}`}</span>
            </div>
            <div className="search-wrapper">
              <div className="project-form-field">
                <AppIcon icon={search} />
                <AppInput
                  placeholder={t.app_drawer_search}
                  value={searchQuery}
                  onChange={event => handleSearchChange(event.target.value)} />
              </div>
            </div>
            <div className="app-list-wrapper">
              {renderAppList(appsArr)}
              <div className="fade-overlay-cover"></div>
              {!!appsArr.length && <div className="fade-overlay"></div>}
            </div>
          </MuiDialogContent>
        </> : renderAppdetails()}
    </Dialog>
  );
}

export default AppDrawer;