import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppIcon } from '../../theme';
import { xMark } from "../../theme/icons";
import { APP_MAINTENANCE_BANNER_ID } from '../appSearchDropdown/appSearchDropdownConstants';
import { getMaintenanceStatusRequest } from '../../store/actions/admin-maintenance-actions';
import { APP_STATE } from '../../store/reducers';
import { AdminMaintenance } from './models/adminMaintenance';

interface MaintenanceBannerProps {
  getHeaderHeight: (hideBanner: boolean) => void;
}

export const MaintenanceBanner = ({ getHeaderHeight }: MaintenanceBannerProps) => {
  const [hideBanner, setHideBanner] = useState(false);
  const dispatch = useDispatch();

   const maintenanceActivity = useSelector<APP_STATE, AdminMaintenance>((state) => state.adminMaintenance.maintenanceActivity);
   const isUserBanner = useSelector<APP_STATE, boolean>((state) => state.adminMaintenance.isUserBanner);

   useEffect(() => {
    dispatch(getMaintenanceStatusRequest(true));
   
  }, [dispatch]);

  useEffect(() => {
      getHeaderHeight(hideBanner);
  }, [hideBanner, getHeaderHeight]);
  
  if (!maintenanceActivity || !isUserBanner || hideBanner) {
    return null;
  }

  return (
    <span className="app-maintenance-banner" id={APP_MAINTENANCE_BANNER_ID}>
      <span
        className="banner-msg"
        dangerouslySetInnerHTML={{
          __html: unescape(maintenanceActivity.message),
        }}
      ></span>
      <span className="close-button">
        <AppIcon
          className="icon"
          onClick={() => setHideBanner(true)}
          icon={xMark}
        />
      </span>
    </span>
  );
};