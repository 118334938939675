import { IMAGE_FILE_RESPONSE_TYPE } from '../../utils/Constants';
import { createAxiosRequest, REQUEST_TYPE } from '../api';

const API_URL = process.env.REACT_APP_API_URL;

export const getApps = () => {
    const GET_ALL_APPS_ENDPOINT = `${API_URL}/api/v1/applications`;
    return createAxiosRequest(REQUEST_TYPE.GET, GET_ALL_APPS_ENDPOINT);
}

export const getBundles = () => {
    const GET_ALL_BUNDLES_ENDPOINT = `${API_URL}/api/v1/appbundle`;
    return createAxiosRequest(REQUEST_TYPE.GET, GET_ALL_BUNDLES_ENDPOINT);
}

export const getAppCategories = () => {
    const GET_ALL_APPCATEGORY_ENDPOINT = `${API_URL}/api/v1/appcategory`;
    return createAxiosRequest(REQUEST_TYPE.GET, GET_ALL_APPCATEGORY_ENDPOINT);
}

export const getAppPlatforms = () => {
    const URL = `${API_URL}/api/v1/appplatform`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL);
}

export const getAppPlatformRoles = ({ projectId }) => {
    const GET_ALL_ROLES_ENDPOINT = `${API_URL}/api/v1/AppPlatform/roles/${projectId}`;
    return createAxiosRequest(REQUEST_TYPE.GET, GET_ALL_ROLES_ENDPOINT);
}

export const getSppPlatformRoles = (payload: any) => {
    const GET_SSP_ROLES_ENDPOINT = `${API_URL}/api/v1/select/UserRoles`;
    return createAxiosRequest(REQUEST_TYPE.GET, GET_SSP_ROLES_ENDPOINT);
}

export const getProjectIcons = (payload: any) => {
    const URL = `${API_URL}/api/v1/select/icons`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL);
}

export const getAllActiveNotifications = ()=>{
    const URL = `${API_URL}/api/v1/users/notifications`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL);
}

export const marKNotificationAsRead = ({notificationId})=>{
    return updateNotificationStatus({notificationId, status: "Read"});
}

export const marKNotificationAsClosed = ({notificationId})=>{
    return updateNotificationStatus({notificationId, status: "Closed"});
}

export const getServiceLines = () => {
    const URL = `${API_URL}/api/v1/select/ServiceLines`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const getSubServiceLines = () => {
    const URL = `${API_URL}/api/v1/select/SubServiceLines`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

const updateNotificationStatus = ({notificationId, status})=>{
    const URL = `${API_URL}/api/v1/users/notification`;
    return createAxiosRequest(REQUEST_TYPE.PUT, URL,{id: notificationId, status});
}

export const getLookupData = (payload) => {
    const URL = `${API_URL}/api/v1/select/lookup-data`;
    return createAxiosRequest(REQUEST_TYPE.POST, URL, payload);
};

export const getUserProfilePhoto = () => {
    const URL = `${process.env.REACT_APP_MS_GRAPH_API_BASE_URL}/me/photo/$value`;
    return createAxiosRequest(REQUEST_TYPE.GET, URL, {}, process.env.REACT_APP_AZURE_AD_APP_SCOPE, IMAGE_FILE_RESPONSE_TYPE);
};