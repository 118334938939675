import axios from 'axios';
import { authProvider } from '../utils/authProvider';

export const REQUEST_TYPE = {
	GET: "get",
	PUT: "put",
	DELETE: "delete",
	POST: "post",
	PATCH: "patch"
}

export const API_URL = process.env.REACT_APP_API_URL;

export const header = {
	headers: {
		"Content-type": "multipart/form-data",
	}
};

export const createAxiosRequest = (type, url, payload = {}, scope?: string, responseType?:string) => {
	let headers = {};

	const request = () => {
    // Check if the request type is GET or DELETE
    if (type === REQUEST_TYPE.GET || type === REQUEST_TYPE.DELETE) {
      // For GET and DELETE, the payload should be sent as params (for GET) or in the config object (for DELETE)
      const config = {
        headers: headers,
        ...(type === REQUEST_TYPE.DELETE && { data: payload }),
        ...(type === REQUEST_TYPE.GET && { params: payload }),
				...(responseType && { responseType: responseType })
      };
      return axios[type](url, config);
    } else {
      // For POST, PUT, and PATCH, the payload is sent as the second argument
      return axios[type](url, payload);
    }
  }

	if (scope) {
		return authProvider.getToken(scope).then(accessToken => {
			headers['Authorization'] = `Bearer ${accessToken}`;
			return request().then(response => response.data).catch(error => { throw error; });
		});
	} else {
		return request().then(response => response.data).catch(error => { throw error; });
	}
}

export const createAxiosRequestMultiPartData = (type, url, payload = {}) => {
	return axios[type](url, payload, header).then((response) => {
		return response;
	}).catch((error) => {
		throw error;
	});
}

