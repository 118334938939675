import * as type from "../action-types";
type load = any;
export const showPreviewModal = (payload: load) => {
    return {
        type: type.SHOW_PREVIEW_VIDEO,
        payload
    }
}

export const hidePreviewModal = () => {
    return {
        type: type.HIDE_PREVIEW_VIDEO
    }
}
