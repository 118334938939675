import { PaceProjectModel } from "./lookupModel";
import { IPpeddApprover } from "../../components/createProject/models/project";

export interface IFieldState<T> {
  fieldName: string;
  fieldVal: T;
  isvalid: boolean;
  valueId?: string;
  charLimit?: number;
  disable: boolean;
  fieldTranslate?: string;
  key?: string;
  isRequired?: boolean;
}

export interface IStringFieldState extends IFieldState<string> {}

export type IGeoFieldState = IFieldState<{ id: string; name: string } | null>;

export type IBooleanFieldState = IFieldState<boolean | undefined>;

export interface IFormModel {
  projectName: IStringFieldState;
  projectType: IStringFieldState;
  projectClientName: IStringFieldState;
  projectServiceLine: IStringFieldState;
  projectSector: IStringFieldState;
  projectSize: IStringFieldState;
  projectLocation: IStringFieldState;
  projectDesc: IStringFieldState;
  projectMFAEnabled: IStringFieldState;
  projectConfidential: IStringFieldState;
  area: IGeoFieldState;
  region: IGeoFieldState;
  country: IGeoFieldState;
  projectCategory: IStringFieldState;
  isPricingIncluded: IBooleanFieldState;
  iconId: IStringFieldState;
  showOnceConfirmModal?: IBooleanFieldState;
}

export interface IPaceFieldState {
  searchQuery: string;
  isvalid: boolean;
  valueId?: string;
  disable: boolean;
  isRequired: boolean;
  selectedValue?: PaceProjectModel[];
  handleSearchChange: Function;
  handleProjectSelection: Function;
  searchLoader: boolean;
  searchProjectsData: PaceProjectModel[];
  setFocus?: boolean;
}

export enum EProjectCategory {
  CLI_ENG = "b1428525-2ec7-4293-96c2-5a8b0dd75dd5",
}

export enum EProjectCategoryKey {
  CLIENG = "CLIENG",
  INTPRJ = "INTPRJ",
  CLIDEMO = "CLIDEMO",
  STDDEMO = "STDDEMO",
  TRNG = "TRNG",
  OTHR = "OTHR",
}

export interface IApproverFieldState {
  searchQuery: string;
  isvalid: boolean;
  valueId?: string;
  canDisplay: boolean;
  isRequired: boolean;
  selectedValue: IPpeddApprover[];
  handleSearchUsersChange: Function;
  handleUsersSelection: Function;
  searchUsersLoader: boolean;
  skipToken?: string;
  searchUsersData: IPpeddApprover[];
}
