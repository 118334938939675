import {put, takeLatest, call, all} from 'redux-saga/effects';
import t from "../../localization/en/translation.json";
import * as type from '../action-types';
import {fetchDataLocations, fetchProjectAreas, fetchProjectCountries, fetchProjectRegions} from '../apis/projectApi';

function* getDataLocations(action) {
    try {
        const data = yield call(fetchDataLocations);
        yield put({
            type: type.GET_DATA_LOCATIONS_SUCCESS,
            payload: data
        });
    } catch ({message}) {
        yield put({
            type: type.GET_DATA_LOCATIONS_FAILURE,
            payload: message
        });
    }
}

function* getCountriesCode() {
    try {
        const countriesCode = yield all([call(fetchProjectAreas),
            call(fetchProjectRegions),
            call(fetchProjectCountries)]);
        yield put({
            type: type.GET_PROJECT_COUNTRIES_CODE_SUCCESS,
            payload: countriesCode
        });
    } catch (e) {
        yield put({
            type: type.SHOW_NOTIFICATION,
            payload: {message: t.country_code_fetch_failed, type: "error"}
        });
    }
}

function* getRegions() {
    try {
        const data = yield call(fetchProjectRegions);
        yield put({
            type: type.GET_PROJECT_REGIONS_SUCCESS,
            payload: data
        });
    } catch ({message}) {
        yield put({
            type: type.GET_PROJECT_REGIONS_FAILURE,
            payload: message
        });
    }
}

export function* getDataLocationsActionWatcher() {
    yield takeLatest(type.GET_DATA_LOCATIONS, getDataLocations)
    yield takeLatest(type.GET_PROJECT_REGIONS, getRegions)
    yield takeLatest(type.GET_PROJECT_COUNTRIES_CODE, getCountriesCode)
}
