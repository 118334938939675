export const getColumnConfig = (maxLineLength) => [
  {
    headerName: 'Date and time',
    field: 'timestamp',
   // sortable: true,
    width: 220
  },
  {
    headerName: 'Description',
    field: 'message',
   // sortable: true,
    width: (maxLineLength * 7),
    wrapText: true
  }
];
