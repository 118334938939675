import React from 'react';

// Custom cell renderer component
export const RenderEmailCell = ({ value }) => {
  return value ? (
    <a className="mail" href={`mailto:${value}`}>
      {value}
    </a>
  ) : (
    "-"
  );
};


