import React from "react";
import AppIconButton from "../../theme/appIconButton/appIconButton";
import { AppIcon } from "../../theme";
import { add } from "../../theme/icons";
import t from "../../localization/en/translation.json";
import { addNewNotification } from "../../utils/Constants";

export const AddNotificationButton = ({ disable, onClick }) => (
  <div className="add-btn">
    <span id={"add_new_notification"} onClick={!disable && onClick}>
      <AppIconButton
        aria-label="add"
        disabled={disable}
        title={addNewNotification}
        className="add-btn-icon"
      >
        <AppIcon icon={add} className="add-icon" />
      </AppIconButton>
      <span className={`label-icon ${disable ? "label-disable" : ""}`}>
        {t.admin_cps_project_add_row}
      </span>
    </span>
  </div>
);
