import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps } from 'react-router';
import List from "../../components/editableGrid/list/list";
import { columnConfig } from './columnConfig';
import t from '../../localization/en/translation.json';
import {  refresh } from "../../theme/icons";
import { MoreMenuOption } from '../../components/moreMenu/moreMenuOptions';
import MoreMenu from '../../components/moreMenu/moreMenu';
import { getAdminRegionsRequest, updateAdminRegionsRequest } from '../../store/actions/admin-general-actions';
import { updatefilterState } from '../../store/actions/list-actions';
import { lookupModel } from '../../containers/createProject/lookupModel';
import { getAdminConsoleAccess } from '../../utils/helper-utility';
import { IRegionOwners, IRegion } from '../../models/IAdminRegions';
import { AppTooltip } from "../../theme";
import { ACTION_NAME, DEFAULT_SORT, MAX_DESC_LENGTH, REGION_OWNER_HEIGHT, ROW_MIN_HEIGHT } from "./adminRegionConstants";
import AdminRegionsModal from '../../components/adminRegionsModal/adminRegionsModal';
import { IAccessRight } from '../../models/user';
import { ISSPPlatformRole } from '../../models/IRoles';
import { IPagination, getDefaultPaginationModel, getPaginationModel } from '../../models/IPagination';
import './adminRegions.scss';

type IProps = RouteComponentProps<any> & {
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  adminRegions: lookupModel[];
  getAdminRegionsRequest: Function;
  updatefilterState: Function;
  paginationDetails: IPagination;
  updateAdminRegionsRequest: Function;
  isRegionUpdated: boolean;
}

interface IState {
  paginationPayload: IPagination;
  isRegionModalOpen: boolean;
  selectedRegion: IRegion | null;
  regionOwners: IRegionOwners[];
}

class AdminRegions extends Component<IProps, IState> {
  gridApi;
  state = {
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: []
    }),
    isRegionModalOpen: false,
    selectedRegion: null,
    regionOwners: []
  }

  componentDidMount() {
    this.getAdminRegions();
    this.updateFilterState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { paginationPayload } = this.state;
    const { isRegionUpdated } = this.props;

    if (prevState.paginationPayload !== paginationPayload) {
      this.getAdminRegions();
      this.updateFilterState();
    }

    if(prevProps.isRegionUpdated !== isRegionUpdated && isRegionUpdated) {
      this.getAdminRegions();
    }
  }

  getAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole = (accessRight?.portalRoles && accessRight.portalRoles.length > 0 && accessRight.portalRoles[0]) || '';
    return getAdminConsoleAccess(portalRole, sspRoles);
  }

  getAdminRegions = () => {
    const { paginationPayload } = this.state;

    this.props.getAdminRegionsRequest(paginationPayload);
  }

  onGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  }

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  }

  handleSorting = (sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;

    if (paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection) {
      return;
    }

    const updatedPaginationPayload = { ...paginationPayload, sortColumn, sortDirection }
    this.setState({ paginationPayload: updatedPaginationPayload })
  }

  handleFilterChange = (filters, sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;
    const updatedPayload = { ...paginationPayload, filters, sortColumn, sortDirection, pageNumber: 1 };

    this.setState({ paginationPayload: updatedPayload })
  }

  updateFilterState = () => {
    const { filters = [], sortDirection, sortColumn } = this.state.paginationPayload;
    const isDefaultSort = sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length

    this.props.updatefilterState({ filters, sortDirection, sortColumn, isClearAllDisabled })
  }

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = { ...paginationPayload, ...paginationParams };

    this.setState({ paginationPayload: updatedPaginationPayload, })
  }

  handleMoreMenuOptionClick = (actionName: string) => {
   if (actionName === ACTION_NAME.UPDATE) {
      const rows = this.gridApi.getSelectedRows();
      this.setState({ selectedRegion: rows[0], isRegionModalOpen: true, regionOwners: rows[0].regionOwners ? [...rows[0].regionOwners] : [] });
    }
  }

  renderMoreMenu = () => {
    const options: MoreMenuOption[] = [
      { id: "123", title: t.update, icon: refresh, actionName: ACTION_NAME.UPDATE },
    ]

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={this.handleMoreMenuOptionClick}
        options={options}
        vertical
        leftPlacement
      />
    )
  }

  renderDescriptionRows = ({ value }) => {  
    if(value.length <= MAX_DESC_LENGTH) {
      return <span>{value}</span>
    }

    return (
      <AppTooltip
        title={value}
        isWhite
      >
        <span>{`${value.substr(0, MAX_DESC_LENGTH)}...`}</span>
      </AppTooltip>
    )
  }

  handleRegionOwnerAdd = ([pill]) => {
    const { regionOwners } = this.state;
    // @ts-ignore:
    if(!pill || regionOwners.find(p => p.ownerEmail === pill.mail)) {
      return
    }
    const { givenName, surname, mail: ownerEmail} = pill
    const newPill = { ownerEmail, ownerName: `${givenName} ${surname}`, regionOwnerId: uuidv4() };
    
    this.setState({ regionOwners: [...regionOwners, newPill]})
  }

  handleRegionOwnerRemove = (removedEmail) => {
    const { regionOwners } = this.state;
    // @ts-ignore:
    const updatedregionOwners = regionOwners.filter((p) => p.ownerEmail !== removedEmail);

    this.setState({ regionOwners: updatedregionOwners })
  }

  handleUpdateRegions = (payload) => {
    const { updateAdminRegionsRequest } = this.props;

    this.setState({ isRegionModalOpen: false });
    updateAdminRegionsRequest([payload])
  }

  handleRowHeight = ({ data }) => {
    if(!data.regionOwners || !data.regionOwners.length) {
      return
    }
    const currentRowHeight = data.regionOwners.length * REGION_OWNER_HEIGHT;

    return currentRowHeight > ROW_MIN_HEIGHT ? currentRowHeight : ROW_MIN_HEIGHT;
  }

  renderOwners = ({ value: owners }) => {
    if (!owners || !owners.length) {
      return null
    }

    return (
      <div>
        {owners.map(owner => <div>{owner.ownerEmail}</div>)}
      </div>
    );
  }

  render() {
    const { adminRegions, paginationDetails  } = this.props;
    const { paginationPayload, isRegionModalOpen, selectedRegion, regionOwners } = this.state;
    const { portalAdminConsole } = this.getAdminConsoleAccess();
    const filteredColumns = portalAdminConsole ? columnConfig.filter(item => item.field !== "iconButton") : columnConfig;

    if (paginationDetails) {
      paginationPayload.totalRecords = paginationDetails.totalRecords;
      paginationPayload.totalRecordsWithoutFilter = paginationDetails.totalRecords || paginationPayload.totalRecordsWithoutFilter;
    }

    return (
      <div className={`admin-regions ${portalAdminConsole ? "read-only" : ""}`}>
          <div className={`region-table`}>
            <List
              columnConfig={filteredColumns}
              defaultColDef={defaultColDef} 
              firstColumnBorderRight={true}
              rowData={adminRegions}
              paginate={true}
              frameworkComponents={{
                moreMenuCellRenderer: this.renderMoreMenu,
                regionDescriptionCellRender: this.renderDescriptionRows,
                regionOwnersCellRenderer: this.renderOwners
              }}
              onGridReady={this.onGridReady}
              onSortChanged={this.handleSorting}
              currentPage={paginationPayload.pageNumber}
              pagination={paginationPayload}
              setItemsPerPage={this.handlePageSizeChange}
              onPageChange={this.handlePageNumberChange}
              onFilterChange={this.handleFilterChange}
              defaultSort={[DEFAULT_SORT]}
              getRowHeight={this.handleRowHeight}
            />

          </div>

        <AdminRegionsModal
          isOpen={isRegionModalOpen}
          onClose={() => this.setState({ isRegionModalOpen: false })}
          onConfirm={this.handleUpdateRegions}
          selectedRegion={selectedRegion}
          onRegionOwnerAdd={this.handleRegionOwnerAdd}
          onRegionOwnerRemove={this.handleRegionOwnerRemove}
          regionOwners={regionOwners}
        />
        </div>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};
const mapStateToProps = ({ adminGeneralReducer, meta, meReducer }) => {
  return {
    adminRegions: adminGeneralReducer.adminRegions,
    paginationDetails: adminGeneralReducer.paginationDetails,
    isRegionUpdated: adminGeneralReducer.isRegionUpdated,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminRegionsRequest: payload => { dispatch(getAdminRegionsRequest(payload)) },
    updatefilterState: payload => {dispatch(updatefilterState(payload))},
    updateAdminRegionsRequest: payload => {dispatch(updateAdminRegionsRequest(payload))}
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRegions));
