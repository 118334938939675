import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import * as api from '../apis/projectApi';
import {
    enrollToProjectRequestFailure,
    enrollToProjectRequestSuccess,
    getProjectDefaultRolesRequestFailure,
    getProjectDefaultRolesRequestSuccess
} from '../actions/default-project-actions';

function* getProjectDefaultRoles(action) {
    try {
        const data = yield call(api.getProjectDefaultRoles, action.payload);
        yield put(getProjectDefaultRolesRequestSuccess(data));
    } catch ({ message }) {
        yield put(getProjectDefaultRolesRequestFailure(message));
    }
}

function* enrollToDemoProject(action) {
    try {
        const data = yield call(api.enrollUserToDemoProject, action.payload);
        yield put(enrollToProjectRequestSuccess(data));
    } catch ({ message }) {
        yield put(enrollToProjectRequestFailure(message));
    }
}

export function* defaultProjectSagaWatcher() {
    yield takeLatest(type.GET_PROJECT_DEFAULT_ROLES_REQUEST, getProjectDefaultRoles);
    yield takeLatest(type.ENROLL_USER_DEMO_PROJECT_REQUEST, enrollToDemoProject);
}