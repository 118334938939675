import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { searchUsers } from '../apis/projectApi';

function* getUsers(action) {
    try {
        const data = yield call(searchUsers, action);
        yield put({
            type: type.SEARCH_USERS_SUCCESS,
            payload: data
        });
    } catch ({ message }) {
        yield put({
            type: type.SEARCH_USERS_FAILURE,
            payload: message
        });
    }
}

export function* getUsersActionWatcher() {
    yield takeLatest(type.SEARCH_USERS, getUsers);
}