import React from "react";
import "./error.scss";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import t from "../../localization/en/translation.json"

const ErrorPage = (props) => {
  const errorBody = props?.location?.state?.errorBody || t.relay_default_error_msg;
  return (
    <div className="main unauthorized">
      <div className="dialog shadow">
        <h5 className="message">
          <div>
            <ErrorOutlineOutlined className="errorIcon" />
            <div>{errorBody}</div>
          </div>
        </h5>
        <h5 className="message">
          <a className="link" href="/">
            {t.back_To_home}
          </a>
        </h5>
      </div>
    </div>
  );
};
export default ErrorPage;
