import React, { ReactNode } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { AppIcon } from "../../theme";
import { MoreMenuOption } from "./moreMenuOptions";
import { check } from "../../theme/icons";
import "./moreMenu.scss";

export interface IProps {
  controlId: string;
  options: MoreMenuOption[];
  optionClickFn: (actionName: string) => void;
  vertical?: boolean;
  leftPlacement?: boolean;
  onMenuClick?: Function;
  menuOpener?: ReactNode;
  className?: string;
  disabled?: boolean;
  stopEventBubbling?: boolean;
}

interface IState {
  isExpanded: boolean;
  currentTarget: HTMLElement | null;
  openSubMenu: boolean;
  subMenuCurrentTarget: HTMLElement | null;
}

class MoreMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isExpanded: false,
      currentTarget: null,
      openSubMenu: false,
      subMenuCurrentTarget: null,
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleClose);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleClose);
  };

  handleToggle = (e) => {
    const { onMenuClick } = this.props;
    if (this.props.stopEventBubbling) {
      e.stopPropagation();
    }
    this.setState({
      isExpanded: !this.state.isExpanded,
      currentTarget: e.currentTarget,
      openSubMenu: false,
    });
    onMenuClick && onMenuClick(!this.state.isExpanded);
  };

  handleMenuItemClick = (e, actionName: string, isDisabled, hasSubMenu) => {
    if (this.props.stopEventBubbling) {
      e.stopPropagation();
    }

    if (isDisabled) {
      return;
    }

    if (this.state.isExpanded && !hasSubMenu) {
      this.setState({ isExpanded: false, openSubMenu: false });
    }
    if (!this.state.openSubMenu && this.state.isExpanded && hasSubMenu) {
      this.setState({
        openSubMenu: true,
        subMenuCurrentTarget: e.currentTarget,
      });
      return;
    }
    this.props.optionClickFn(actionName);
  };

  handleClose = (e) => {
    const { onMenuClick } = this.props;
    if (this.props.stopEventBubbling) {
      e.stopPropagation();
    }
    this.setState({ isExpanded: false, openSubMenu: false });
    onMenuClick && onMenuClick(false);
  };

  render() {
    const { isExpanded, currentTarget, openSubMenu, subMenuCurrentTarget } =
      this.state;
    const {
      options,
      controlId,
      vertical,
      leftPlacement,
      menuOpener,
      className,
      disabled,
    } = this.props;
    const placement = leftPlacement ? "left" : "right";

    return (
      <div className="more-menu">
        {(menuOpener && (
          <div
            className="customised-more-meu"
            aria-controls={controlId}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            {menuOpener}
          </div>
        )) || (
          <IconButton
            aria-controls={controlId}
            aria-haspopup="true"
            onClick={this.handleToggle}
            disabled={!!disabled}
          >
            {vertical ? <MoreVertIcon /> : <MoreHorizIcon />}
          </IconButton>
        )}
        <Menu
          id={controlId}
          keepMounted
          open={isExpanded}
          anchorEl={currentTarget}
          getContentAnchorEl={null}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: placement }}
          transformOrigin={{ vertical: "top", horizontal: placement }}
          disableScrollLock
          className={`app-more-menu-paper ${placement} ${className || ""}`}
        >
          {options.map((option, i) => {
            return option.hasSubMenu ? (
              <MenuItem
                value={option.title}
                className="more-menu-option"
                disabled={option?.isDisabled}
                key={i}
                onClick={(e) =>
                  this.handleMenuItemClick(
                    e,
                    option.actionName,
                    option?.isDisabled,
                    option.hasSubMenu
                  )
                }
              >
                {(option.titleHTML && option.titleHTML()) || option.title}{" "}
                {<AppIcon icon={option?.icon} className="icon-right" />}
                <Menu
                  id="submenu"
                  keepMounted
                  open={openSubMenu}
                  anchorEl={subMenuCurrentTarget}
                  getContentAnchorEl={null}
                  onClose={this.handleClose}
                  disableScrollLock
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  className={`app-more-sub-menu-paper right`}
                >
                  {option.subMenuOptions.map((item, j) => (
                    <MenuItem
                      className="more-menu-option"
                      value={item.title}
                      key={j}
                      onClick={(e) =>
                        this.handleMenuItemClick(
                          e,
                          item.actionName,
                          item?.isDisabled,
                          item.hasSubMenu
                        )
                      }
                    >
                      <span className="selected-item">
                        {item.isSelected === item.actionName ||
                        (item.isSelected === null && item?.defaultSelected) ? (
                          <AppIcon icon={check} className="color-green" />
                        ) : null}
                      </span>
                      {(item.titleHTML && item.titleHTML()) || item.title}{" "}
                    </MenuItem>
                  ))}
                </Menu>
              </MenuItem>
            ) : (
              <MenuItem
                value={option.title}
                className="more-menu-option"
                disabled={option?.isDisabled}
                key={i}
                onClick={(e) =>
                  this.handleMenuItemClick(
                    e,
                    option.actionName,
                    option?.isDisabled,
                    option.hasSubMenu
                  )
                }
              >
                {option?.icon && option.iconPosition !== "right" && (
                  <AppIcon icon={option?.icon} />
                )}
                {(option.titleHTML && option.titleHTML()) || option.title}{" "}
                {option?.icon && option.iconPosition === "right" && (
                  <AppIcon icon={option?.icon} className="icon-right" />
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default MoreMenu;
