import { ICellRendererParams } from 'ag-grid-community';
import React, { Component } from 'react';

interface SelectCellRendererProps extends ICellRendererParams {
    defaultValue?: string;
}
export class SelectCellRenderer extends Component<SelectCellRendererProps> {
    private getValueCallBack: any;
    render() {
        const { data, colDef, getValue, defaultValue } = this.props;
        this.getValueCallBack = getValue;
        const fieldName = colDef?.field;
        switch (fieldName) {
            case 'user':
                return (
                    <span className="app-table-cell-renderer">
                        {this.getValueCallBack(data.id)}
                    </span>
                )
            default:
                if (fieldName && data[fieldName]) {
                    return (<span className="app-table-cell-renderer">{data[fieldName]}</span>);
                } else {
                    return (<span className="app-table-cell-renderer">{defaultValue || ''}</span>);
                }
        }
    }
}
