import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import * as api from '../apis/metaApi';
import {
    getAppPlatformRolesSuccess,
    getAppPlatformRolesFailure,
    getSppPlatformRolesSuccess,
    getSppPlatformRolesFailure,
    getProjectIconsSuccess,
    getProjectIconsFailure,
    getServiceLinesSuccess,
    getServiceLinesFailure,
    getSubServiceLinesSuccess,
    getSubServiceLinesFailure,
    getLookupDataSuccess,
    getLookupDataFailure
} from '../actions/meta-actions';

function* getApps(action) {
    try {
        const data = yield call(api.getApps);
        yield put({
            type: type.GET_ALL_APPS_SUCCESS,
            payload: data
        });
    } catch ({ message }) {
        yield put({
            type: type.GET_ALL_APPS_FAILURE,
            payload: message
        });
    }
}

export function* getAppsActionWatcher() {
    yield takeLatest(type.GET_ALL_APPS, getApps);
}

function* getBundles(action) {
    try {
        const data = yield call(api.getBundles);
        yield put({
            type: type.GET_ALL_BUNDLES_SUCCESS,
            payload: data
        });
    } catch ({ message }) {
        yield put({
            type: type.GET_ALL_BUNDLES_FAILURE,
            payload: message
        });
    }
}

export function* getBundlesActionWatcher() {
    yield takeLatest(type.GET_ALL_BUNDLES, getBundles);
}


function* getAppCategories(action) {
    try {
        const data = yield call(api.getAppCategories);
        yield put({
            type: type.GET_ALL_APP_CATEGORIES_SUCCESS,
            payload: data
        });
    } catch ({ message }) {
        yield put({
            type: type.GET_ALL_APP_CATEGORIES_FAILURE,
            payload: message
        });
    }
}

export function* getAppCategoriesActionWatcher() {
    yield takeLatest(type.GET_ALL_APP_CATEGORIES, getAppCategories);
}

function* getAppPlatforms(action) {
    try {
        const data = yield call(api.getAppPlatforms);
        yield put({
            type: type.GET_ALL_APP_PLATFORMS_SUCCESS,
            payload: data
        });
    } catch ({ message }) {
        yield put({
            type: type.GET_ALL_APP_PLATFORMS_FAILURE,
            payload: message
        });
    }
}

export function* getAppPlatformsActionWatcher() {
    yield takeLatest(type.GET_ALL_APP_PLATFORMS, getAppPlatforms);
}

function* getAppPlatformsRoles(action) {
    try {
        const data = yield call(api.getAppPlatformRoles, action.payload);
        yield put(getAppPlatformRolesSuccess(data));
    } catch ({ message }) {
        yield put(getAppPlatformRolesFailure());
    }
}

export function* getAppPlatformRolesActionWatcher() {
    yield takeLatest(type.GET_ALL_PLATFORM_ROLES_REQUEST, getAppPlatformsRoles);
}

function* getSppPlatformsRoles(action) {
    try {
        const data = yield call(api.getSppPlatformRoles, action.payload);
        yield put(getSppPlatformRolesSuccess(data));
    } catch ({ message }) {
        yield put(getSppPlatformRolesFailure());
    }
}

export function* getSppPlatformRolesActionWatcher() {
    yield takeLatest(type.GET_SSP_ROLES_REQUEST, getSppPlatformsRoles);
}

function* getProjectIcons(action) {
    try {
        const data = yield call(api.getProjectIcons, action.payload);
        yield put(getProjectIconsSuccess(data));
    } catch ({ message }) {
        yield put(getProjectIconsFailure());
    }
}

export function* getProjectIconsActionWatcher() {
    yield takeLatest(type.GET_PROJECT_ICONS_REQUEST, getProjectIcons);
}

function* getServiceLines() {
    try {
        const data = yield call(api.getServiceLines);
        yield put(getServiceLinesSuccess(data));
    } catch ({ message }) {
        yield put(getServiceLinesFailure());
    }
}

export function* getServiceLinesActionWatcher() {
    yield takeLatest(type.GET_SERVICE_LINES_REQUEST, getServiceLines);
}

function* getSubServiceLines() {
    try {
        const data = yield call(api.getSubServiceLines);
        yield put(getSubServiceLinesSuccess(data));
    } catch ({ message }) {
        yield put(getSubServiceLinesFailure());
    }
}

export function* getSubServiceLinesActionWatcher() {
    yield takeLatest(type.GET_SUB_SERVICE_LINES_REQUEST, getSubServiceLines);
}

function* getLookupData(action) {
    try {
        const data = yield call(api.getLookupData, action.payload);
        yield put(getLookupDataSuccess(data));
    } catch ({ message }) {
        yield put(getLookupDataFailure());
    }
}

export function* getLookupDataActionWatcher() {
    yield takeLatest(type.GET_LOOK_UP_DATA_REQUEST, getLookupData);
}