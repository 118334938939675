import * as type from "../action-types";

type load = any;

export const getUsersList = (payload: load) => ({
    type: type.GET_USERS_LIST_REQUEST,
    payload
});

export const getUsersListSuccess = (payload: load) => ({
    type: type.GET_USERS_LIST_SUCCESS,
    payload
});

export const getUsersListFail = (payload: load) => ({
    type: type.GET_USERS_LIST_FAILURE,
    payload
});

export const searchAdminUserGrid = (payload: load) => ({
    type: type.SEARCH_ADMIN_USER_GRID,
    payload,
});

export const updateUserGrid = (payload: load) => ({
    type: type.UPDATE_ADMIN_USER_GRID_REQUEST,
    payload,
});

export const validateAdminUserRequest = () => ({
    type: type.VALIDATE_ADMIN_USER_REQUEST
});

export const validateAdminUserSuccess = () => ({
    type: type.VALIDATE_ADMIN_USER_SUCCESS
});
