import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps, Redirect } from 'react-router';
import { IUser } from '../../models/user';
import t from '../../localization/en/translation.json';
import './notAuthorized.scss';
import {CrashBoundary} from "../../components/CrashBoundary";

type IProps = RouteComponentProps<any> & {
  currentUser: IUser;
}

const NotAuthorized: FC<IProps> = ({ currentUser, location }) => {

  const renderContent = (isPolicyErr: boolean) =>
    <div >
      <div className="head-section">
      </div>
      <div className="unauthorized-message">
      <div className="unauthorized-title">{isPolicyErr ? t.accept_policy_err_title: t.unauthorized}</div>
        <div>{isPolicyErr ? t.accept_policy_err_content1: t.unauthorized_content_1}</div>
        <div>{isPolicyErr ? t.accept_policy_err_content2 : t.unauthorized_content_2}</div>
      </div>
    </div>

  return (
      <CrashBoundary>
    <div className="unauthorized">
    {(currentUser.id && location.pathname !== "/policy-error") ? <Redirect to="/" /> :
      renderContent(location.pathname === "/policy-error")
    }
    </div>
      </CrashBoundary>
  );
}

const mapStateToProps = state => {
  return {
    currentUser: state.meReducer.currentUser
  };
};

export default withRouter(connect(mapStateToProps)(NotAuthorized));

