import {
  WFFields,
  WORKFLOW_TYPE,
  WORKFLOW_CATEGORY,
  WORKFLOW_IS_ACTIVE,
  InputFieldsType,
  InputFieldCss,
} from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = { colId: WFFields.title, sort: "asc" };
export const ROW_FIELD_NAMES = {
  WFR_ERR_STACK: "WFR_ProcessStatus",
  TITLE: WFFields.title,
  WFR_ERR_STACK_SOURCE: "WFR_ErrorSource",
  WFR_TYPE: WFFields.workflowType,
};
export const MORE_MENU_ACTION_NAME = {
  UPDATE: "Update",
  DELETE: "Delete",
  ADD: "Add",
};

export const FORM_WORKFLOW_DEFINITIONS = [
  {
    fieldLabel: t.admin_cps_form_id,
    fieldName: WFFields.id,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: true,
    className: InputFieldCss.large,
    isHidden: true,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_title,
    fieldName: WFFields.title,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isRequired: true,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_workflow_type,
    fieldName: WFFields.workflowType,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_TYPE,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_workflow_category,
    fieldName: WFFields.workflowCategory,
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_CATEGORY,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_workflow_code_file,
    fieldName: WFFields.codeFile,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isRequired: true,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_is_active,
    fieldName: WFFields.isActive,
    fieldVal: "",
    fieldType: InputFieldsType.Select,
    fieldOptions: WORKFLOW_IS_ACTIVE,
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_key,
    fieldName: WFFields.key,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_workflow_variables,
    fieldName: WFFields.workflowDynamicVars,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    className: InputFieldCss.large,
    isTouched: false,
  },
];
