import React, { FC } from 'react';

interface IProps {
  customClass?: string,
  htmlText: string
}

const InnerHTMLElement: FC<IProps> = ({ customClass, htmlText }) => {

  return (
    <div
      className={customClass || ''}
      dangerouslySetInnerHTML={{__html: htmlText || ''}}>
    </div>
  )
}

export default InnerHTMLElement;
