import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { fetchSectors } from '../apis/projectApi';

function* getSectors(action) {
	try {
    const data = yield call(fetchSectors);
    yield put({
      type: type.GET_SECTORS_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_SECTORS_FAILURE,
      payload: message
    });
  }
}

export function* getSectorsActionWatcher() {
  yield takeLatest(type.GET_SECTORS, getSectors)
}
