import React from "react";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";
import {
  DELETE_GENERAL_NOTIFICATION_ACTION,
  GET_NOTIFICATIONS_ACTION,
  CREATE_NOTIFICATION_ACTION,
  UPDATE_GENERAL_NOTIFICATION_ACTION,
  NOTIFICATION,
} from "../../../store/actions/admin-portal-communication-actions";
import "../adminPortalNotification.scss";
import CommonNotification from "../commonNotifications";

const GeneralNotifications = () => {
  const generalNotifications = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ portalCommunications }) => portalCommunications.generalNotifications
  );
  return (
    <CommonNotification
      notificationTitle={"General information update"}
      notificationType={"General"}
      commonNotificationData={generalNotifications}
      createNotificationAction={CREATE_NOTIFICATION_ACTION}
      GetNotificationAction={GET_NOTIFICATIONS_ACTION}
      updateNotificationAction={UPDATE_GENERAL_NOTIFICATION_ACTION}
      deleteNotificationAction={DELETE_GENERAL_NOTIFICATION_ACTION}
    />
  );
};

export default GeneralNotifications;
