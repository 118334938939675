import * as type from "../action-types";

const initialState = {
  adminSchedules: [],
  maintenanceActivity: null,
  isMainteneceSchduled: false,
  loading: true,
  isUserBanner: false,
  isEventChanged: false,
  isCreateProjectDisabled: false,
  isMaintanenceFlagLoaded: false,
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.SCHEDULE_MAINTENANACE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case type.SCHEDULE_MAINTENANACE_SUCCESS:
      return {
        ...state,
        isMainteneceSchduled: true,
        loading: false,
      };
    case type.GET_MAINTENANCE_STATUS_REQUEST:
      return { ...state, loading: true, isUserBanner: action.payload };
    case type.GET_MAINTENANCE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        maintenanceActivity: action.payload,
        isCreateProjectDisabled: action.payload.isCreateProjectDisabled,
        isMaintanenceFlagLoaded: true,
      };
    case type.GET_MAINTENANCE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        maintenanceActivity: null,
        isCreateProjectDisabled: false,
        isMaintanenceFlagLoaded: true,
      };
    case type.SCHEDULE_MAINTENANACE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case type.FLUSH_MAINTENANCE_NOTIFICATION:
      return { ...state, isMainteneceSchduled: false };

    case type.GET_ADMIN_SCHEDULE_SUCCESS:
      return {
        ...state,
        adminSchedules: action.payload,
      };

    case type.ADD_ADMIN_SCHEDULE_REQUEST:
      return {
        ...state,
        isEventChanged: false,
      };

    case type.ADD_ADMIN_SCHEDULE_SUCCESS:
      return {
        ...state,
        isEventChanged: true,
      };

    case type.UPDATE_ADMIN_SCHEDULE_REQUEST:
      return {
        ...state,
        isEventChanged: false,
      };

    case type.UPDATE_ADMIN_SCHEDULE_SUCCESS:
      return {
        ...state,
        isEventChanged: true,
      };

    case type.DELETE_ADMIN_SCHEDULE_REQUEST:
      return {
        ...state,
        isEventChanged: false,
      };

    case type.DELETE_ADMIN_SCHEDULE_SUCCESS:
      return {
        ...state,
        isEventChanged: true,
      };

    default:
      return state;
  }
};
