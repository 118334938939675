import React, { FC, MouseEvent } from 'react';
import en_translation from '../../localization/en/translation.json';
import { BundleClass } from './models/bundleClass';
import { BundleEvent } from './models/bundleEvent';
import { AppButton } from "../../theme";
import './bundleCard.scss';

interface IProps {
    data: BundleClass;
    bundleEventFn?: Function;
    hideCustomizeBundleLabel?: boolean;
}

const BundleCard: FC<IProps> = (props) => {

    const { data: { id, name, description, selected }, hideCustomizeBundleLabel } = props;

    const classNameValue = (!selected && !hideCustomizeBundleLabel) ? 'bundle-card disabled-div' : 'bundle-card selected-bundle';

    const handleBundleEvents = (e: MouseEvent, bundleId: string , selected = false, isCustomized = false) => {
        e.stopPropagation();
        const bundleEvent: BundleEvent = {
            selected: { id: bundleId, status: selected },
            customize: { id: bundleId, status: isCustomized }
        };
        const { bundleEventFn } = props;

        bundleEventFn && bundleEventFn(bundleEvent);
    }

    return (
        <div className={classNameValue} onClick={(e) => handleBundleEvents(e, id, true, false)}>
            <div>
                <div className="title">
                    {name}
                </div>
                <div className="description">
                    {description}
                </div>
                <br />
            </div>
            <div className={selected ? 'bottom' : 'bottom no-events'}>
                <div className="customize" onClick={(e) => handleBundleEvents(e, id, true, true)}>
                    {
                        !hideCustomizeBundleLabel && <AppButton color="secondary">{en_translation.customize_label}</AppButton>
                    }
                </div>
            </div>
        </div >
    )
}

export default BundleCard;
