import * as type from "../action-types";

type load = any;

export const getMe = () => ({
    type: type.ME
});

export const searchUsersAction =  (payload?: load) => ({
    type: type.SEARCH_USERS,
    payload
});