import React, { FC } from 'react';
import Select, {SelectProps} from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from "@material-ui/styles";
import './appSelect.scss';

const useStyle = makeStyles({
    root: {
        width: '100%'
    },
    menuPaper: {
        maxHeight: 190
    }
})

interface IProps extends SelectProps {
    id?: string,
    value?: string | string[] | null,
    name?: string,
    isValid?: boolean,
    disabled?: boolean,
    label?: string,
    options?: any[],
    multiple?: boolean,
    onChange?: SelectInputProps['onChange'],
    placeholder?: string
}

const AppSelect: FC<IProps> = ({ isValid, value, multiple, name, id, label, options,placeholder, ...restProps }) => {
    const classes = useStyle();

    const renderOption = (item) => {
        if(item.descriptionLabel) {
            return (
                <div className="option-with-description">
                    <div className="option-label">{item.label}</div>
                    <div className="option-desc">{item.descriptionLabel}</div>
                </div>
            )    
        }

        return item.label          
    }

    return (
        <FormControl className={`${classes.root} app-select ${(isValid !== undefined && !isValid) ? 'in-valid' : ''}`} variant="filled">
            <InputLabel htmlFor={id}>{label}</InputLabel>
            {multiple ?
                <Select
                    multiple
                    value={value}
                    inputProps={{
                        name: name,
                        id: id,
                    }}
                    MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    {...restProps}
                >
                    {options && options.map((item, index) => (
                        <MenuItem className="app-select-option" key={`${name}_${index}`} value={item}>
                            <Checkbox color="default" checked={!!value && value.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
                :
                <Select
                    value={value}
                    inputProps={{
                        name: name,
                        id: id,
                    }}
                    MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    {...restProps}
                >
                    {options && options.map((item, index) => <MenuItem className="app-select-option" key={`${name}_${index}`} value={item.value}>{renderOption(item)}</MenuItem>)}
                </Select>
            }
        </FormControl>
    )
}

export default AppSelect;
