import React, { FC } from 'react';
import FormHelperText, { FormHelperTextProps } from '@material-ui/core/FormHelperText';
import { makeStyles } from "@material-ui/styles";
import { AppIcon } from "../";
import { info } from "../icons";
import themeConfig from "../config";

const useStyle = makeStyles({
    root: {
        color: themeConfig.thunderbird,
        fontSize: themeConfig.fontSize13,
        fontFamily: themeConfig.fontFamilyLight,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 0,
        marginBottom: 10,
    }
})

const AppFormErrorText: FC<FormHelperTextProps> = ({ children, error, ...restProps }) => {
    const classes = useStyle();

    return (
        <FormHelperText
            classes={{ ...classes }}
            {...restProps}
        >
            {error && <AppIcon icon={info} className="app-form-helper-icon" />}
            {children}
        </FormHelperText>
    )
}

export default AppFormErrorText;
