import React from "react";
import { AppModal, AppAlert } from "../../theme";
import t from "../../localization/en/translation.json";
import "./CloseProjectConfirmationPopup.scss";

const CloseProjectConfirmationPopup = ({ show, onClosePopup, handleClose }) => {
  return (
    <AppModal
      newDesign={true}
      showModal={show}
      onModalClose={() => onClosePopup()}
      onConfirm={() => handleClose()}
      cancelBtnText={t.admin_portal_project_action_no}
      confirmBtnText={t.close_project_modal_close_button}
      title={t.close_project_confirm_content}
      size="sm"
      fullWidth={false}
    >
      <div className="modal-content project-confirmation">
        <AppAlert severity="error" className="error-alert-custom">
          {t.close_project_modal_confirm_warning}
        </AppAlert>
      </div>
    </AppModal>
  );
};

export default CloseProjectConfirmationPopup;
