import * as type from "../action-types";

type load = { id: string };

export const getWorkflowsAction = (payload: load) => ({
  type: type.GET_CPS_WORKFLOWS,
  payload,
});

export const getWorkflowsSuccess = (payload: any) => ({
  type: type.GET_CPS_WORKFLOWS_SUCCESS,
  payload,
});

export const getWorkflowsFailure = (payload: any) => ({
  type: type.GET_CPS_WORKFLOWS_FAILURE,
  payload,
});

export const clearWorkflowsAction = () => ({
  type: type.FLUSH_CPS_WORKFLOWS_DATA,
});

export const searchWorkflowsAction = (payload: load) => ({
  type: type.SEARCH_CPS_WORKFLOWS_REQUEST,
  payload,
});

export const searchWorkflowsSuccess = (payload: any) => ({
  type: type.SEARCH_CPS_WORKFLOWS_SUCCESS,
  payload,
});

export const searchWorkflowsFailure = (payload: any) => ({
  type: type.SEARCH_CPS_WORKFLOWS_FAILURE,
  payload,
});

export const clearSearchWorkflowsAction = () => ({
  type: type.FLUSH_SEARCH_CPS_WORKFLOWS,
});

export const addWorkflowsAction = (payload?: load) => ({
  type: type.ADD_CPS_WORKFLOWS_REQUEST,
  payload,
});

export const addWorkflowsSuccess = (payload: any) => ({
  type: type.ADD_CPS_WORKFLOWS_SUCCESS,
  payload,
});

export const addWorkflowsFailure = (payload: any) => ({
  type: type.ADD_CPS_WORKFLOWS_FAILURE,
  payload,
});

export const updateWorkflowsAction = (payload: load) => ({
  type: type.UPDATE_CPS_WORKFLOWS_REQUEST,
  payload,
});

export const updateWorkflowsSuccess = (payload: any) => ({
  type: type.UPDATE_CPS_WORKFLOWS_SUCCESS,
  payload,
});

export const updateWorkflowsFailure = (payload: any) => ({
  type: type.UPDATE_CPS_WORKFLOWS_FAILURE,
  payload,
});

export const deleteWorkflowsAction = (payload?: load) => ({
  type: type.DELETE_CPS_WORKFLOWS_REQUEST,
  payload,
});

export const deleteWorkflowsSuccess = (payload: any) => ({
  type: type.DELETE_CPS_WORKFLOWS_SUCCESS,
  payload,
});

export const deleteWorkflowsFailure = (payload: any) => ({
  type: type.DELETE_CPS_WORKFLOWS_FAILURE,
  payload,
});

export const flushDeleteWorkflowsData = () => ({
  type: type.FLUSH_DELETE_CPS_WORKFLOWS_DATA
});

export const getCommTemplateCategoryAction = (payload: load) => ({
  type: type.GET_CPS_COMM_TEMPLATE_CATEGORY,
  payload,
});

export const getCommTemplateCategorySuccess = (payload: any) => ({
  type: type.GET_CPS_COMM_TEMPLATE_CATEGORY_SUCCESS,
  payload,
});

export const getCommTemplateCategoryFailure = (payload: any) => ({
  type: type.GET_CPS_COMM_TEMPLATE_CATEGORY_FAILURE,
  payload,
});

export const clearCommTemplateCategoryAction = () => ({
  type: type.FLUSH_CPS_COMM_TEMPLATE_CATEGORY_DATA,
});

export const getWorkflowDefinitionsAction = (payload: load) => ({
  type: type.GET_CPS_WORKFLOW_DEFINITIONS,
  payload,
});

export const getWorkflowDefinitionsSuccess = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_DEFINITIONS_SUCCESS,
  payload,
});

export const getWorkflowDefinitionsFailure = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_DEFINITIONS_FAILURE,
  payload,
});

export const clearWorkflowDefinitionsAction = () => ({
  type: type.FLUSH_CPS_WORKFLOW_DEFINITIONS_DATA,
});

export const getCommTemplatesAction = (payload: load) => ({
  type: type.GET_CPS_COMM_TEMPLATES,
  payload,
});

export const getCommTemplatesSuccess = (payload: any) => ({
  type: type.GET_CPS_COMM_TEMPLATES_SUCCESS,
  payload,
});

export const getCommTemplatesFailure = (payload: any) => ({
  type: type.GET_CPS_COMM_TEMPLATES_FAILURE,
  payload,
});

export const clearCommTemplatesAction = () => ({
  type: type.FLUSH_CPS_COMM_TEMPLATES_DATA,
});

export const getWorkflowScheduleAction = (payload: load) => ({
  type: type.GET_CPS_WORKFLOW_SCHEDULE,
  payload,
});

export const getWorkflowScheduleSuccess = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_SCHEDULE_SUCCESS,
  payload,
});

export const getWorkflowScheduleFailure = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_SCHEDULE_FAILURE,
  payload,
});

export const clearWorkflowScheduleAction = () => ({
  type: type.FLUSH_CPS_WORKFLOW_SCHEDULE_DATA,
});

export const getWorkflowProjectAction = (payload: load) => ({
  type: type.GET_CPS_WORKFLOW_PROJECT,
  payload,
});

export const getWorkflowProjectSuccess = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_PROJECT_SUCCESS,
  payload,
});

export const getWorkflowProjectFailure = (payload: any) => ({
  type: type.GET_CPS_WORKFLOW_PROJECT_FAILURE,
  payload,
});

export const clearWorkflowProjectAction = () => ({
  type: type.FLUSH_CPS_WORKFLOW_PROJECT_DATA,
});

export const startWorkflowAction = (payload: load) => ({
  type: type.START_CPS_WORKFLOW,
  payload,
});

export const startWorkflowSuccess = (payload: any) => ({
  type: type.START_CPS_WORKFLOW_SUCCESS,
  payload,
});

export const startWorkflowFailure = (payload: any) => ({
  type: type.START_CPS_WORKFLOW_FAILURE,
  payload,
});

export const clearStartWorkflowAction = () => ({
  type: type.FLUSH_START_CPS_WORKFLOW_DATA,
});

export const getWorkflowTooltipsSettings = (payload: load) => ({
  type: type.GET_WORKFLOW_TOOLTIPS,
  payload,
});

export const getWorkflowTooltipsSettingsSuccess = (payload: any) => ({
  type: type.GET_WORKFLOW_TOOLTIPS_SUCCESS,
  payload,
});

export const getWorkflowTooltipsSettingsFailure = (payload: any) => ({
  type: type.GET_WORKFLOW_TOOLTIPS_FAILURE,
  payload,
});