import * as type from "../action-types";

type load = any;

export const createProjectAction = (payload: load) => ({
    type: type.CREATE_PROJECT,
    payload
});

export const updateProjectAction = (payload: load) => ({
    type: type.UPDATE_PROJECT,
    payload
});

export const clearProjectDataAction = () => ({
    type: type.CLEAR_PROJECT_DATA
});

export const deleteDraftProjectRequest = (payload: load) => ({
    type: type.DELETE_DRAFT_PROJECT_REQUEST,
    payload
});

export const flushDeletedProject = () => ({
    type: type.FLUSH_DELETED_PROJECT
});

export const getUpdatedBundles = (payload: load) => ({
    type: type.GET_UPDATED_BUNDLES,
    payload
})

export const updateActiveApps = (payload: load) => ({
    type: type.UPDATE_ACTIVE_APPS,
    payload
});

export const flushUpdatedBundles = () => ({
    type: type.FLUSH_UPDATED_BUNDLES,
})

export const createOwnBundle = (payload: load) => ({
    type: type.CREATE_OWN_BUNDLE,
    payload
});

export const flushCreatedBundle = () => ({
    type: type.FLUSH_CREATED_BUNDLE
});

export const closeProjectRequest = (payload: load) => ({
    type: type.CLOSE_PROJECT_REQUEST,
    payload
})

export const flushCloseProject = () => ({
    type: type.FLUSH_CLOSE_PROJECT
})

export const getSelectedApps = (payload: load) => ({
    type: type.GET_SELECTED_APPS,
    payload
})

export const updateSelectedApps = (payload: load) => ({
    type: type.UPDATE_SELECTED_APPS,
    payload
});

export const flushUpdatedSelectedApps = () => ({
    type: type.FLUSH_SELECTED_APPS,
})
