export enum NotificationColumnField {
  caption = "title",
  activeFrom = "startDateTime",
  activeTo = "endDateTime",
  modifiedOn = "modifiedOn",
  modifiedBy = "modifiedByEmailAddress",
  createdOn = "createdOn",
  createdBy = "createdByEmailAddress",
}
export const getTodayDate = () => {
  return new Date();
};
export const skipRoute = ["/policy", "/not-authorized", "/policy-error"];

export const surveyStatus = "Deferred";
