import React from "react";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../../store/reducers";
import {
  CREATE_NOTIFICATION_ACTION,
  DELETE_ADHOC_NEWS_NOTIFICATION_ACTION,
  GET_NOTIFICATIONS_ACTION,
  NOTIFICATION,
  UPDATE_ADHOC_NEWS_NOTIFICATION_ACTION,
} from "../../../store/actions/admin-portal-communication-actions";
import "../adminPortalNotification.scss";
import CommonNotification from "../commonNotifications";
import { PORTAL_COMMS_ADHOC_NEWS_LABEL } from "../../../containers/adminGeneral/adminGeneralConstants";

const AdhocNewsUpdate = () => {
  const adhocNewsUpdateNotifications = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ portalCommunications }) => portalCommunications.adhocNewsNotifiocation
  );
  return (
    <CommonNotification
      notificationTitle={PORTAL_COMMS_ADHOC_NEWS_LABEL}
      notificationType={"AdHocNews"}
      commonNotificationData={adhocNewsUpdateNotifications}
      createNotificationAction={CREATE_NOTIFICATION_ACTION}
      GetNotificationAction={GET_NOTIFICATIONS_ACTION}
      updateNotificationAction={UPDATE_ADHOC_NEWS_NOTIFICATION_ACTION}
      deleteNotificationAction={DELETE_ADHOC_NEWS_NOTIFICATION_ACTION}
    />
  );
};
export default AdhocNewsUpdate;
