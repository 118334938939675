import {
  WFFields,
  CommunicationFields,
  InputFieldsType,
  InputFieldCss,
} from "../adminWorkflowConstant";
import t from "../../../localization/en/translation.json";
export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 60;
export const DEFAULT_SORT = { colId: WFFields.title, sort: "asc" };
export const ROW_FIELD_NAMES = {
  WFR_ERR_STACK: "WFR_ProcessStatus",
  TITLE: WFFields.title,
  WFR_ERR_STACK_SOURCE: "WFR_ErrorSource",
  WFR_DESC: "communicationTemplateDescription",
};
export const MORE_MENU_ACTION_NAME = {
  UPDATE: "Update",
  DELETE: "Delete",
  ADD: "Add",
};

export const FORM_COMMUNICATION_TEMPLATES = [
  {
    fieldLabel: t.admin_cps_form_id,
    fieldName: WFFields.id,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: true,
    className: InputFieldCss.large,
    isHidden: true,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_title,
    fieldName: WFFields.title,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isRequired: true,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_workflow_category,
    fieldName: CommunicationFields.communicationTemplateCategoryId,
    fieldType: InputFieldsType.Select,
    fieldOptions: [],
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_template_description,
    fieldName: CommunicationFields.communicationTemplateDescription,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_key,
    fieldName: WFFields.key,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isRequired: true,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_send_from_mail,
    fieldName: CommunicationFields.sendFromEmail,
    // eslint-disable-next-line no-template-curly-in-string
    fieldVal: "${CALCULATED}",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_communication_send_to_mail,
    fieldName: CommunicationFields.sendToEmail,
    // eslint-disable-next-line no-template-curly-in-string
    fieldVal: "${CALCULATED}",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_communication_workflow_name,
    fieldName: CommunicationFields.cwfiworkflowName,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    isValidSpecialChar: true,
  },
  {
    fieldLabel: t.admin_cps_form_communication_workflow,
    fieldName: CommunicationFields.wfr_WorkflowLookupId,
    fieldType: InputFieldsType.Select,
    fieldOptions: [],
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    fieldVal: "",
  },
  {
    fieldLabel: t.admin_cps_form_communication_subject,
    fieldName: CommunicationFields.communicationEmailSubject,
    fieldVal: "",
    isvalid: true,
    charLimit: 200,
    disable: false,
    isTouched: false,
    className: InputFieldCss.large,
  },
  {
    fieldLabel: t.admin_cps_form_communication_message_body,
    fieldName: CommunicationFields.communicationEmailBody,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    isTouched: false,
  },
  {
    fieldLabel: t.admin_cps_form_communication_workflow_parameters,
    fieldName: CommunicationFields.cwfiworkflowParameters,
    fieldType: InputFieldsType.TextArea,
    fieldVal: "",
    isvalid: true,
    disable: false,
    isTouched: false,
  },
];
