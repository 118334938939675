const customCellRenderer = "custom-cellrenderer";
export const columnConfig = [
  {
    headerName: ' ',
    cellRenderer: 'moreMenuCellRenderer',
    field: 'iconButton',
    minWidth: 43,
    width: 43,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    cellClass: "update-region-cell-renderer"
  },
  {
    headerName: 'Region',
    field: 'name',
    sortable: true,
    width: 250,    
    filter: "customFilter",
    lockPosition: true
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 280,
    cellRenderer: 'regionDescriptionCellRender',
    cellClass: customCellRenderer,
    lockPosition: true
  },
  {
    headerName: 'Email recipients',
    field: 'regionOwners',
    cellRenderer: 'regionOwnersCellRenderer',
    width: 320,
    cellClass: 'region-owners-cell-renderer',
    lockPosition: true
  },
]
