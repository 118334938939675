import * as type from "../action-types";
import {INotification} from "../../models/INotification";

export const showActionToast = (payload: INotification) => {
    return {
        type: type.SHOW_NOTIFICATION,
        payload
    }
}

export const hideActionToast = () => {
    return {
        type: type.HIDE_NOTIFICATION
    }
}

