import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { getProjectTypesAction, updateProjectTypeAction, clearProjectTypeDataAction, addProjectTypeAction } from '../../store/actions/project-types-actions';
import { lookupModel } from '../createProject/lookupModel';
import { ISSPPlatformRole } from '../../models/IRoles';
import { IAccessRight } from '../../models/user';
import { ProjectTypesRowDataModel } from './adminProjectTypesModel';
import {IStringFieldState} from '../createProject/formStateModel';
import { columnConfig } from './columnConfig';
import List from '../../components/editableGrid/list/list';
import AppNotification from "../../components/appNotification/appNotification";
import t from '../../localization/en/translation.json';
import { AppFormErrorText, AppInput, AppButton, AppModal, AppTextField, AppIcon } from "../../theme";
import { edit } from "../../theme/icons";
import './adminProjectTypes.scss';
import {CrashBoundary} from "../../components/CrashBoundary";

type IProps = RouteComponentProps<any> & {
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  getProjectTypes: Function;
  projectTypes: lookupModel[];
  updateProjectType: Function;
  addProjectType: Function;
  updateProjectTypeData: {
    data: { id: string },
    success?: boolean,
    error?: boolean
  };
  clearProjectTypeData: Function;
}

interface IState {
  showEditModal: boolean,
  selectedProjectType: lookupModel,
  formState: {
    typeId: IStringFieldState,
    typeName: IStringFieldState,
    typeKey: IStringFieldState,
    typeDesc: IStringFieldState,
  },
  actionType: string,
  isFormValid: boolean,
  isPortalAdmin: boolean,
  renderTable: boolean,
  showNotification: boolean
}

class AdminProjectTypes extends Component<IProps, IState> {

  state = {
    showEditModal: false,
    actionType: "",
    selectedProjectType: {
      description: '',
      id: '',
      key: '',
      name: ''
    },
    formState: {
      typeId: { fieldName: "typeId", fieldVal: "", isvalid: true, charLimit: 200, disable: true },
      typeName: { fieldName: "typeName", fieldVal: "", isvalid: true, charLimit: 200, disable: false },
      typeKey: { fieldName: "typeKey", fieldVal: "", isvalid: true, charLimit: 10, disable: false },
      typeDesc: { fieldName: "typeDesc", fieldVal: "", isvalid: true, charLimit: 2000, disable: false }
    },
    isFormValid: false,
    isPortalAdmin: false,
    renderTable: false,
    showNotification: false
  }

  rowData: ProjectTypesRowDataModel[] = [];
  notificationVariant = '';
  notificationMessage = '';
  gridApi;

  componentDidMount() {
    const { projectTypes, getProjectTypes } = this.props;
    !projectTypes.length && getProjectTypes();
  }

  componentDidUpdate(prevProps) {
    const { accessRight, sspRoles, history, projectTypes,
      updateProjectTypeData, getProjectTypes } = this.props;

    if (accessRight.portalRoles && sspRoles && !this.state.isPortalAdmin) {
      const portalAdminId = sspRoles.find(role => role.key === 'POR-ADMIN')?.id;
      const isAdmin = accessRight.portalRoles.find(role => role === portalAdminId);
      if (isAdmin) {
        this.setState({ isPortalAdmin: true });
      } else {
        history.push('/');
      }
    }
    if (projectTypes !== prevProps.projectTypes) {
      this.generateProjectRowData();
    }
    if (updateProjectTypeData?.success) {
      this.rowData = [];
      this.manageDeleteNotification(true);
      getProjectTypes();
    } else if (updateProjectTypeData?.error) {
      this.manageDeleteNotification(false);
    }
  }

  manageDeleteNotification = (success: boolean) => {
    const { actionType } = this.state;
    this.notificationVariant = success ? 'success' : 'error';
    if (actionType === "edit") {
      this.notificationMessage = success ? t.admin_portal_edit_project_type_success : t.admin_portal_edit_project_type_error;
    } else {
      this.notificationMessage = success ? t.admin_portal_add_project_type_success : t.admin_portal_add_project_type_error;
    }
    this.props.clearProjectTypeData();
    this.setState({ showNotification: true });
    setTimeout(() => {
      if (this.state.showNotification) {
        this.setState({ showNotification: false });
      }
    }, 3000);
  }

  generateProjectRowData = () => {
    this.rowData = this.props.projectTypes.map((projectType) => ({
      name: projectType.name || '-',
      id: projectType.id || '-',
      key: projectType.key || '-',
      description: projectType.description || '-',
      action: (
        <AppButton variant="text" onClick={() => this.onEditClick(projectType)}>
          <AppIcon icon={edit} />
          <span>{t.admin_project_types_disable}</span>
        </AppButton>
      )
    }));
    this.setState({ renderTable: true });
  }

  onEditClick = (selectedProjectType: lookupModel) => {
    const { formState } = this.state;

    formState.typeId.fieldVal = selectedProjectType.id;
    formState.typeName.fieldVal = selectedProjectType.name;
    formState.typeKey.fieldVal = selectedProjectType.key;
    formState.typeDesc.fieldVal = selectedProjectType.description;
    this.setState({ showEditModal: true, selectedProjectType, formState, actionType: 'edit' });
  }

  onAddClick = () => {
    const { formState } = this.state;
    formState.typeId.fieldVal = "add";
    formState.typeName.fieldVal = "";
    formState.typeKey.fieldVal = "";
    formState.typeDesc.fieldVal = "";
    this.setState({ showEditModal: true, formState, actionType: 'add' });
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  }

  confirmUpdate = () => {
    const { formState, actionType } = this.state;

    if (actionType === "edit") {
      const data = {
        name: formState.typeName.fieldVal,
        key: formState.typeKey.fieldVal,
        description: formState.typeDesc.fieldVal,
        id: formState.typeId.fieldVal
      }
      this.props.updateProjectType(data);
    } else {
      const data = {
        name: formState.typeName.fieldVal,
        key: formState.typeKey.fieldVal,
        description: formState.typeDesc.fieldVal
      }
      this.props.addProjectType(data);
    }
    this.setState({ showEditModal: false });
  }

  closeNotification = () => {
    this.setState({ showNotification: false });
  }

  handleGridReady = ({ api }) => {
    this.gridApi = api;
  };

  onFormChange = (val: string, fieldName: string) => {
    const { formState } = this.state;
    formState[fieldName].fieldVal = val;
    formState[fieldName].isvalid = val.trim().length;

    this.setState({
      formState: formState,
      isFormValid: this.checkDirtyFields()
    });
  }

  checkDirtyFields = () => {
    const { formState } = this.state;
    let filledFields = 0;

    Object.entries(formState).forEach(([key]) => {
      if (key !== "typeDesc" && formState[key].fieldVal && formState[key].fieldVal.trim()) {
        filledFields++;
      }
    });
    return filledFields === (Object.keys(formState).length - 1);
  }

  renderInput = (label: string, fieldVal: string, fieldName: string, isvalid: boolean, charLimit, disable: boolean) => (
    <div className={`project-form-field ${fieldVal.trim().length && isvalid ? "validated-input" : ""}`}>
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        disabled={disable}
        label={label}
        onChange={e => this.onFormChange(e.target.value, e.target.name)} />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
    </div>
  );

  render() {
    const { showEditModal, isPortalAdmin, showNotification, isFormValid,
      formState: { typeId, typeName, typeKey, typeDesc }, actionType } = this.state;

    return (
      <CrashBoundary>
      <div className="admin-project-types">
        {isPortalAdmin &&
          <div>
            <div className="page-hdr-title-container">
              <p className="content-h4">{t.admin_portal_project_type_title}</p>
              <AppButton size="small" onClick={this.onAddClick}>{t.add_project_types}</AppButton>
            </div>
            <div className="project-types-table">
              <List
                firstColumnBorderRight={true}
                //title={t.admin_portal_project_type_table_heading}
                columnConfig={columnConfig}
                defaultColDef={defaultColDef} 
                rowData={this.rowData}
                handleGridReady={this.handleGridReady}
                onSortChanged={() => this.gridApi && this.gridApi.redrawRows()}
                paginate={false}
              />
            </div>
            <AppModal
              showModal={showEditModal}
              onModalClose={this.closeEditModal}
              onConfirm={this.confirmUpdate}
              disabledValue={!isFormValid}
              cancelBtnText={t.cancel}
              confirmBtnText={(actionType === "edit" && t.edit_project_type_modal_disable_button) || t.add_project_type_modal_disable_button}
              title={(actionType === "edit" && t.edit_project_type_modal_title) || t.add_project_type_modal_title}
            >
              <div className="edit-modal-content">
                {actionType === 'edit' && (<div className="type-id">
                  {this.renderInput(t.project_type_id_label, typeId.fieldVal, typeId.fieldName, typeId.isvalid, typeId.charLimit, typeId.disable)}
                </div>)}
                <div className="type-name-key">
                  {this.renderInput(t.project_type_name_label, typeName.fieldVal, typeName.fieldName, typeName.isvalid, typeName.charLimit, typeName.disable)}
                  {this.renderInput(t.project_type_key_label, typeKey.fieldVal, typeKey.fieldName, typeKey.isvalid, typeKey.charLimit, typeKey.disable)}
                </div>
                <div className="type-desc">
                  <div className="project-form-field text-area-field">
                    <AppTextField
                      maxLength={typeDesc.charLimit}
                      name={typeDesc.fieldName}
                      value={typeDesc.fieldVal}
                      disabled={typeDesc.disable}
                      label={t.project_type_desc_label}
                      onChange={e => this.onFormChange(e.target.value, e.target.name)} />
                  </div>
                </div>
              </div>
            </AppModal>
            {showNotification &&
              <AppNotification
                message={this.notificationMessage}
                variant={this.notificationVariant}
                onCloseNotifcation={this.closeNotification}
              />
            }
          </div>
        }
      </div>
      </CrashBoundary>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};

const mapStateToProps = ({ meReducer, meta, projectTypesReducer }) => {
  return {
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    projectTypes: projectTypesReducer.projectTypes,
    updateProjectTypeData: projectTypesReducer.updateProjectTypeData,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getProjectTypes: () => { dispatch(getProjectTypesAction()) },
    updateProjectType: payload => { dispatch(updateProjectTypeAction(payload)) },
    addProjectType: payload => { dispatch(addProjectTypeAction(payload)) },
    clearProjectTypeData: () => { dispatch(clearProjectTypeDataAction()) }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminProjectTypes));
