import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModal } from "../../../theme";

import {
  delaySurvey,
  getSurveyDetails,
} from "../../../store/actions/me-actions";
import { useLocation } from "react-router";
import { APP_STATE } from "../../../store/reducers";
import _ from "lodash";
import { ME_REDUCER_STATE } from "../../../store/reducers/me-reducer";
import { UserTypes } from "../../../utils/Constants";
import { skipRoute, surveyStatus } from "../adminPortalComunicationConstant";

interface IDelaySurvey {
  id?: string;
  status: string;
}
const Survey: React.FC = () => {
  const [showSurvey, setShowSurvey] = useState(false);
  const dispatch = useDispatch();
  const history = useLocation();
  const [surveyUrl, setSurveyUrl] = useState("");
  const { paansPolicy, accessRight, surveyDetails, loading, currentUser } =
    useSelector<APP_STATE, ME_REDUCER_STATE>(({ meReducer }) => meReducer);

  const showExpirationModel = useSelector<APP_STATE, boolean>(
    ({ projects }) => projects.showExpirationModel
  );
  const closeModel = () => {
    setShowSurvey(false);
    const delaySurveyData: IDelaySurvey = {
      id: surveyDetails?.id,
      status: surveyStatus,
    };
    dispatch(delaySurvey(delaySurveyData));
  };

  const isSurveyActive = useCallback(() => {
    let displaySurvey = false;
    const { pathname } = history;
    const pathParamas = pathname.split("/");
    const projectId = pathParamas[pathParamas.indexOf("project") + 1];

    const projectAccess = accessRight?.projects?.find(
      (item) => item?.projectId === projectId
    );

    //condition to check emgAccepted,if cproject page(check emg) else true for other page
    const isExternalUser = currentUser.userType === UserTypes.External;
    let emgAccepted = projectId ? projectAccess?.hasAcceptedEMG : true;
    if (
      !_.isEmpty(surveyDetails) &&
      // survey details must be there
      !skipRoute.includes(pathname) &&
      //it should not be privacy policy page
      paansPolicy.hasAcceptedAll && //need to CHECK WHY NOT WORKING
      //if policy pans accepted
      (emgAccepted || isExternalUser) &&
      //need to check EMG policy here if not accepted then don't push to home page
      !pathname.includes("handler") &&
      !showExpirationModel
      //If accessing direct from email
    ) {
      displaySurvey = true;
    }
    return displaySurvey;
  }, [
    surveyDetails,
    accessRight,
    paansPolicy,
    history,
    showExpirationModel,
    currentUser,
  ]);

  useEffect(() => {
    if (loading === false) {
      dispatch(getSurveyDetails());
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (surveyDetails && surveyDetails?.url) {
      setSurveyUrl(surveyDetails.url);
      setShowSurvey(isSurveyActive());
    }
  }, [surveyDetails, isSurveyActive]);

  return (
    <AppModal
      size="md"
      modalClass="app-preview-modal"
      showModal={showSurvey}
      onModalClose={() => closeModel()}
    >
      <div
        className="modal-content"
        style={{ maxHeight: "675px", height: "auto" }}
      >
        <iframe
          title="surveyIframe"
          id="surveyIframe"
          className="survey"
          src={surveyUrl}
          style={{ border: "none" }}
          height="600px"
          width="100%"
        ></iframe>
      </div>
    </AppModal>
  );
};
export default Survey;
