const initialState: {
    counter: number;
} = { counter: 0 }

const ignoreActions = [
    'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS',
];
// eslint-disable-next-line
export default (state = initialState, action) => {
    if (action.type.includes('_REQUEST') &&
        !ignoreActions.includes(action.type)) {
        return {
            ...state,
            counter: state.counter + 1,
        }
    } else if (action.type.includes('_FAILURE') ||
        action.type.includes('_SUCCESS')) {
        if (ignoreActions.indexOf(action.type) > -1) {
            return state;
        }
        return {
            ...state,
            counter: state.counter === 0 ? 0 : state.counter - 1,
        }
    } else {
        return state;
    }
}
