import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { IStringFieldState } from "../../createProject/formStateModel";
import { IAdminReport } from "../../../models/IAdminReport";
import MoreMenu from "../../../components/moreMenu/moreMenu";
import { IAdminReports } from "../../../models/IAdminReports";
import {
  AppFormErrorText,
  AppInput,
  AppButton,
  AppModal,
  AppTextField,
  AppTooltip,
} from "../../../theme";
import {
  updateAdminReport,
  deleteAdminReport,
} from "../../../store/actions/admin-reports-actions";
import { validateFormInputForSpecialChar } from "../../../utils/helper-utility";
import t from "../../../localization/en/translation.json";
import "./reportTile.scss";

type IProps = {
  report: IAdminReport;
  updateAdminReport: Function;
  deleteAdminReport: Function;
  adminReports: IAdminReports;
  onReportClick: Function;
  canEdit: boolean;
};

interface IState {
  showEditModal: boolean;
  isDeleteModalOpen: boolean;
  formState: {
    reportTitle: IStringFieldState;
    reportDescription: IStringFieldState;
    reportRlsRoles: IStringFieldState;
  };
  isFormValid: boolean;
}

class ReportTile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showEditModal: false,
      isDeleteModalOpen: false,
      isFormValid: true,
      formState: {
        reportTitle: {
          fieldName: "reportTitle",
          fieldVal: props.report.title || props.report.reportTitle,
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
        reportDescription: {
          fieldName: "reportDescription",
          fieldVal: props.report.description,
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
        reportRlsRoles: {
          fieldName: "reportRlsRoles",
          fieldVal: props.report.reportRlsRoles,
          isvalid: true,
          charLimit: 200,
          disable: false,
        },
      },
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { report } = this.props;
    if (!_.isEqual(report, prevProps.report)) {
      this.setState({
        ...this.state,
        showEditModal: false,
        formState: {
          ...this.state.formState,
          reportTitle: {
            ...this.state.formState.reportTitle,
            fieldVal: report.title || report.reportTitle,
          },
          reportDescription: {
            ...this.state.formState.reportDescription,
            fieldVal: report.description,
          },
          reportRlsRoles: {
            ...this.state.formState.reportRlsRoles,
            fieldVal: report.reportRlsRoles,
          },
        },
      });
    }
  }

  checkDirtyFields = () => {
    const { formState } = this.state;
    const requiredFormState = Object.assign({}, formState);
    delete requiredFormState[formState.reportRlsRoles.fieldName];
    let filledFields = 0;

    Object.entries(requiredFormState).forEach(([key]) => {
      if (
        requiredFormState[key].fieldVal &&
        requiredFormState[key].fieldVal.trim()
      ) {
        filledFields++;
      }
    });
    return filledFields === Object.keys(requiredFormState).length;
  };

  checkValidSpecialCharFields = () => {
    const { formState } = this.state;
    const validSpecialChar = Object.assign({}, formState);
    let validSpecialCharFields = 0;

    Object.entries(validSpecialChar).forEach(([key]) => {
      if (!validateFormInputForSpecialChar(validSpecialChar[key].fieldVal)) {
        validSpecialCharFields++;
      }
    });
    return validSpecialCharFields === Object.keys(validSpecialChar).length;
  };

  onFormChange = (val: string, fieldName: string) => {
    const { formState } = this.state;
    formState[fieldName].fieldVal = val;
    formState[fieldName].isvalid = val.trim().length;

    this.setState({
      formState: formState,
      isFormValid:
        this.checkDirtyFields() && this.checkValidSpecialCharFields(),
    });
  };

  onMoreMenuOptionClicked = (action) => {
    if (action === "edit") {
      this.setState({
        showEditModal: true,
      });
    } else if (action === "remove") {
      this.setState({
        isDeleteModalOpen: true,
      });
    }
  };

  renderTextArea = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean
  ) => (
    <div
      className={`project-form-field ${
        fieldVal.trim().length && isvalid ? "validated-input" : ""
      }`}
    >
      <AppTextField
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        label={label}
        disabled={disable}
        isValid={isvalid}
        onChange={(e) => this.onFormChange(e.target.value, e.target.name)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      {validateFormInputForSpecialChar(fieldVal) && (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  renderInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean,
    addMargin: boolean
  ) => (
    <div
      className={`project-form-field ${
        fieldVal != null && fieldVal.trim().length && isvalid
          ? "validated-input"
          : ""
      } ${addMargin && "m-r-35"} `}
    >
      <AppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        disabled={disable}
        label={label}
        isValid={isvalid}
        onChange={(e) => this.onFormChange(e.target.value, e.target.name)}
      />
      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      {validateFormInputForSpecialChar(fieldVal) && (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      )}
    </div>
  );

  onReportEditCancel = () => {
    this.setState({
      showEditModal: false,
    });
  };

  onReportEdit = () => {
    const { isFormValid, formState } = this.state;
    const { report, updateAdminReport } = this.props;

    if (isFormValid) {
      updateAdminReport({
        id: report.id,
        title: formState.reportTitle.fieldVal,
        description: formState.reportDescription.fieldVal,
        reportRlsRoles: formState.reportRlsRoles.fieldVal,
      });
    }
  };

  handleConfirmDelete = () => {
    const { report, deleteAdminReport } = this.props;
    deleteAdminReport({
      id: report.id,
    });
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  render() {
    const { report, onReportClick, canEdit } = this.props;
    const {
      isDeleteModalOpen,
      showEditModal,
      formState: { reportTitle, reportDescription, reportRlsRoles },
      isFormValid,
    } = this.state;

    const options = [
      {
        id: "edit",
        title: "Edit",
        actionName: "edit",
      },
      {
        id: "remove",
        title: "Remove",
        actionName: "remove",
      },
    ];

    const truncatedReportDescription =
      report.description.length > 100
        ? `${report.description.substring(0, 100)}...`
        : report.description;

    return (
      (!showEditModal && (
        <>
          <div
            className="report-tile-conatiner reports-item clickable"
            onClick={() => onReportClick()}
          >
            <div className="report-title-container">
              <div className="report-title">
                {report.title || report.reportTitle}
              </div>
              {(canEdit && (
                <div className="report-actions">
                  <MoreMenu
                    controlId="report-actions"
                    options={options}
                    optionClickFn={this.onMoreMenuOptionClicked}
                    stopEventBubbling
                  />
                </div>
              )) ||
                null}
            </div>
            <div className="report-roles">{report.reportRlsRoles}</div>
            <div className="report-description">
              {(truncatedReportDescription !== report.description && (
                <AppTooltip title={report.description} placement="top">
                  <>{truncatedReportDescription}</>
                </AppTooltip>
              )) ||
                report.description}
            </div>
          </div>
          <AppModal
            showModal={isDeleteModalOpen}
            onModalClose={() => this.setState({ isDeleteModalOpen: false })}
            onConfirm={this.handleConfirmDelete}
            cancelBtnText={t.cancel}
            confirmBtnText={t.admin_report_delete_modal_btn}
            title={t.admin_report_delete}
          >
            <div className="modal-content">
              <div className="app-title-bold">{`${
                t.admin_report_delete_modal_title
              } '${report.title || report.reportTitle}'.`}</div>
            </div>
          </AppModal>
        </>
      )) || (
        <div className="reports-item edit-report">
          {this.renderInput(
            t.admin_report_title_label,
            reportTitle.fieldVal,
            reportTitle.fieldName,
            reportTitle.isvalid,
            reportTitle.charLimit,
            reportTitle.disable,
            false
          )}
          {this.renderTextArea(
            t.admin_report_description_label,
            reportDescription.fieldVal,
            reportDescription.fieldName,
            reportDescription.isvalid,
            reportDescription.charLimit,
            reportDescription.disable
          )}
          {this.renderInput(
            t.admin_report_roles_label,
            reportRlsRoles.fieldVal || "",
            reportRlsRoles.fieldName,
            true,
            reportRlsRoles.charLimit,
            reportRlsRoles.disable,
            false
          )}
          <div className="btn-grp">
            <AppButton disabled={!isFormValid} onClick={this.onReportEdit}>
              {t.update}
            </AppButton>
            <AppButton color="secondary" onClick={this.onReportEditCancel}>
              {t.cancel}
            </AppButton>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = ({ adminReports }) => {
  return {
    adminReports,
  };
};

const mapDispatchToProps = () => ({
  updateAdminReport,
  deleteAdminReport,
});

export default connect(mapStateToProps, mapDispatchToProps())(ReportTile);
