import * as type from "../action-types";
import { IUrlParams } from "../../models/urlParamsStorage";

const initialState: {
  urlParams: IUrlParams | {};
} = {
  urlParams: {
    policyRedirectURL: "",
    projectFriendlyId: "",
    policyErrorCode: "",
    errorCode: "",
    urlParamsLoaded: false,
  },
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.STORE_URL_ERRORCODES:
      return {
        ...state,
        urlParams: {
          ...state.urlParams,
          [action.payload.errorCode]: action.payload.value,
        },
      };
    case type.CLEAR_URL_ERRORCODES:
      return {
        ...state,
        urlParams: {
          policyRedirectURL: "",
          projectFriendlyId: "",
          policyErrorCode: "",
          errorCode: "",
          urlParamsLoaded: false,
        },
      };

    default:
      return state;
  }
};
