import * as type from "../action-types";

export const getServerTime = () => ({
    type: type.GET_SERVER_TIME
});


export const getHelpSupportRequest = () => ({
    type: type.GET_HELP_AND_SUPPORT,
});

export const getHelpSupportRequestSuccess = (payload: any) => ({
    type: type.GET_HELP_AND_SUPPORT_SUCCESS,
    payload,
});

export const getHelpSupportRequestFailure = () => ({
    type: type.GET_HELP_AND_SUPPORT_FAILURE,
});
