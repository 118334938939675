import {
  CREATE_GENERAL_NOTIFICATION_SUCCESS,
  CREATE_RELEASE_NOTES_NOTIFICATION,
  CREATE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  DELETE_GENERAL_NOTIFICATION,
  DELETE_GENERAL_NOTIFICATION_FAILURE,
  DELETE_GENERAL_NOTIFICATION_SUCCESS,
  DELETE_RELEASE_NOTES_NOTIFICATION,
  DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE,
  DELETE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_GENERAL_NOTIFICATIONS_SUCCESS,
  GET_RELEASE_NOTES_NOTIFICATIONS,
  GET_RELEASE_NOTES_NOTIFICATIONS_SUCCESS,
  UPDATE_GENERAL_NOTIFICATION,
  UPDATE_GENERAL_NOTIFICATION_FAILURE,
  UPDATE_GENERAL_NOTIFICATION_SUCCESS,
  UPDATE_RELEASE_NOTES_NOTIFICATION,
  UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE,
  UPDATE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  GET_SURVEY_NOTIFICATIONS,
  GET_SURVEY_NOTIFICATIONS_SUCCESS,
  CREATE_SURVEY_NOTIFICATION_SUCCESS,
  UPDATE_SURVEY_NOTIFICATION,
  UPDATE_SURVEY_NOTIFICATION_FAILURE,
  UPDATE_SURVEY_NOTIFICATION_SUCCESS,
  DELETE_SURVEY_NOTIFICATION,
  DELETE_SURVEY_NOTIFICATION_FAILURE,
  DELETE_SURVEY_NOTIFICATION_SUCCESS,
  GET_ADHOC_NEWS_NOTIFICATIONS,
  GET_ADHOC_NEWS_NOTIFICATIONS_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
  CREATE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  UPDATE_ADHOC_NEWS_NOTIFICATION,
  UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE,
  UPDATE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  DELETE_ADHOC_NEWS_NOTIFICATION,
  DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE,
  DELETE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS,
  CREATE_NOTIFICATION,
} from "../action-types";
export enum notificationType {
  General,
  ReleaseNotes,
  Survey,
  AdHocNews,
}
export type NOTIFICATION = {
  title: string;
  description: string;
  message: string;
  messageDelta: string;
  type: keyof typeof notificationType;
  role?: string;
  startDateTime: number;
  endDateTime: number;
  status: "Active" | "InActive";
  id?: string;
  createdOn?: number;
  createdBy?: string;
  userEmailAddress?: string;
  modifiedOn?: number | null;
  modifiedBy?: string | null;
  deleted?: null;
  userTypes: ("Internal" | "External")[];
  userNotificationStatus?: "Read" | null;
};
export const GET_NOTIFICATIONS_ACTION = (notificationType:string) => ({
  type: GET_NOTIFICATIONS,
  notificationType
});
export const ON_GENERAL_NOTIFICATIONS_ACTION = (payload: NOTIFICATION[]) => ({
  type: GET_GENERAL_NOTIFICATIONS_SUCCESS,
  payload,
});
export const ON_NOTIFICATIONS_FAILURE = () => ({
  type: GET_NOTIFICATIONS_FAILURE,
});
export const ON_CREATE_GENERAL_NOTIFICATION = (payload: NOTIFICATION) => ({
  type: CREATE_GENERAL_NOTIFICATION_SUCCESS,
  payload,
});

export const DELETE_GENERAL_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_GENERAL_NOTIFICATION,
  payload,
});
export const ON_DELETE_GENERAL_NOTIFICATION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_GENERAL_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_DELETE_GENERAL_NOTIFICATION_FAILURE = () => ({
  type: DELETE_GENERAL_NOTIFICATION_FAILURE,
});
export const UPDATE_GENERAL_NOTIFICATION_ACTION = (payload: NOTIFICATION) => ({
  type: UPDATE_GENERAL_NOTIFICATION,
  payload,
});
export const ON_UPDATE_GENERAL_NOTIFICATION = (payload: {
  data: NOTIFICATION;
  id: string;
}) => ({
  type: UPDATE_GENERAL_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_UPDATE_GENERAL_NOTIFICATION_FAILURE = () => ({
  type: UPDATE_GENERAL_NOTIFICATION_FAILURE,
});

export const GET_RELEASE_NOTES_NOTIFICATIONS_ACTION = () => ({
  type: GET_RELEASE_NOTES_NOTIFICATIONS,
});
export const ON_RELEASE_NOTES_NOTIFICATIONS_ACTION = (
  payload: NOTIFICATION[]
) => ({
  type: GET_RELEASE_NOTES_NOTIFICATIONS_SUCCESS,
  payload,
});
export const CREATE_RELEASE_NOTES_NOTIFICATION_ACTION = (
  payload: NOTIFICATION
) => ({
  type: CREATE_RELEASE_NOTES_NOTIFICATION,
  payload,
});
export const ON_CREATE_RELEASE_NOTES_NOTIFICATION = (
  payload: NOTIFICATION
) => ({
  type: CREATE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  payload,
});
export const DELETE_RELEASE_NOTES_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_RELEASE_NOTES_NOTIFICATION,
  payload,
});
export const ON_DELETE_RELEASE_NOTES_NOTIFICATION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE = () => ({
  type: DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE,
});
export const UPDATE_RELEASE_NOTES_NOTIFICATION_ACTION = (
  payload: NOTIFICATION
) => ({
  type: UPDATE_RELEASE_NOTES_NOTIFICATION,
  payload,
});
export const ON_UPDATE_RELEASE_NOTES_NOTIFICATION = (payload: {
  data: NOTIFICATION;
  id: string;
}) => ({
  type: UPDATE_RELEASE_NOTES_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE = () => ({
  type: UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE,
});
export const GET_SURVEY_NOTIFICATIONS_ACTION = () => ({
  type: GET_SURVEY_NOTIFICATIONS,
});
export const ON_SURVEY_NOTIFICATIONS_ACTION = (payload: NOTIFICATION[]) => ({
  type: GET_SURVEY_NOTIFICATIONS_SUCCESS,
  payload,
});
export const ON_CREATE_SURVEY_NOTIFICATION = (payload: NOTIFICATION) => ({
  type: CREATE_SURVEY_NOTIFICATION_SUCCESS,
  payload,
});
export const DELETE_SURVEY_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_SURVEY_NOTIFICATION,
  payload,
});
export const ON_DELETE_SURVEY_NOTIFICATION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_SURVEY_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_DELETE_SURVEY_NOTIFICATION_FAILURE = () => ({
  type: DELETE_SURVEY_NOTIFICATION_FAILURE,
});
export const UPDATE_SURVEY_NOTIFICATION_ACTION = (payload: NOTIFICATION) => ({
  type: UPDATE_SURVEY_NOTIFICATION,
  payload,
});
export const ON_UPDATE_SURVEY_NOTIFICATION = (payload: {
  data: NOTIFICATION;
  id: string;
}) => ({
  type: UPDATE_SURVEY_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_UPDATE_SURVEY_NOTIFICATION_FAILURE = () => ({
  type: UPDATE_SURVEY_NOTIFICATION_FAILURE,
});

export const GET_ADHOC_NEWS_NOTIFICATIONS_ACTION = () => ({
  type: GET_ADHOC_NEWS_NOTIFICATIONS,
});
export const ON_ADHOC_NEWS_NOTIFICATIONS_ACTION = (
  payload: NOTIFICATION[]
) => ({
  type: GET_ADHOC_NEWS_NOTIFICATIONS_SUCCESS,
  payload,
});
export const CREATE_NOTIFICATION_ACTION = (
  payload: NOTIFICATION
) => ({
  type: CREATE_NOTIFICATION,
  payload,
});
export const ON_CREATE_ADHOC_NEWS_NOTIFICATION = (payload: NOTIFICATION) => ({
  type: CREATE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_CREATE_NOTIFICATION_FAILURE = () => ({
  type: CREATE_NOTIFICATION_FAILURE,
});

export const DELETE_ADHOC_NEWS_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_ADHOC_NEWS_NOTIFICATION,
  payload,
});
export const ON_DELETE_ADHOC_NEWS_NOTIFICATION = (payload: {
  notificationId: string;
}) => ({
  type: DELETE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE = () => ({
  type: DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE,
});
export const UPDATE_ADHOC_NEWS_NOTIFICATION_ACTION = (
  payload: NOTIFICATION
) => ({
  type: UPDATE_ADHOC_NEWS_NOTIFICATION,
  payload,
});
export const ON_UPDATE_ADHOC_NEWS_NOTIFICATION = (payload: {
  data: NOTIFICATION;
  id: string;
}) => ({
  type: UPDATE_ADHOC_NEWS_NOTIFICATION_SUCCESS,
  payload,
});
export const ON_UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE = () => ({
  type: UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE,
});
