import * as type from "../action-types";

type load = { id: string } | any;

export const searchProjectsAction = (payload: load) => ({
    type: type.SEARCH_PROJECT,
    payload,
});

export const clearSearchedProjectsAction = (payload: load) => ({
    type: type.CLEAR_SEARCHED_PROJECT,
    payload,
});
