export const MAX_ROW_HEIGHT = 120;
export const PAGE_SIZES = [10, 20, 50, 100];
export const ROW_MIN_HEIGHT = 48;
export const DEFAULT_SORT = { colId: "created", sort: "desc" };
export const DEFAULT_SEARCH = { colId: "title", sort: "asc" };
export const ROW_FIELD_NAMES = {
    WFR_ERR_STACK: "wfr_ErrStack",
    TITLE: "title",
    WFR_ERR_STACK_SOURCE: "wfr_ErrorSource"
}
export const MORE_MENU_ACTION_NAME = {
    UPDATE: "Update",
    DELETE: "Delete",
    ADD: "Add",
  };