import React, { FC } from "react";
import { AppButton, AppTooltip } from "../../theme";
import t from "../../localization/en/translation.json";
import "./projectFooter.scss";

interface IProps {
  saveBtnText: string;
  showCreateBtn?: boolean;
  saveHandler?(): void;
  createHandler?(): void;
  cancelHandler?(): void;
  disableAll?: boolean | number;
  disableSaveBtn?: boolean;
  showSaveBtn?: boolean;
  showUpdateBtn?: boolean;
  updateHandler?(): void;
  disableUpdatebtn?: boolean;
  isCreateProjectDisabled?: boolean;
  isMaintanenceFlagLoaded?: boolean;
}

const CreateProjectFooter: FC<IProps> = ({
  saveBtnText,
  showCreateBtn,
  disableAll,
  saveHandler,
  createHandler,
  cancelHandler,
  disableSaveBtn,
  showSaveBtn,
  showUpdateBtn,
  disableUpdatebtn,
  updateHandler,
  isCreateProjectDisabled,
  isMaintanenceFlagLoaded,
}) => {
  return (
    <div className="project-footer">
      <div className="left-btns">
        {showSaveBtn !== false && (
          <AppButton
            className={`save-btn ${
              (((disableAll ? true : false) || disableSaveBtn) && "disable") ||
              "active"
            }`}
            color="primary"
            disabled={(disableAll ? true : false) || disableSaveBtn}
            onClick={() => saveHandler && saveHandler()}
          >
            {saveBtnText}
          </AppButton>
        )}
        {showCreateBtn && (
          <AppTooltip
            placement="top"
            hide={!isCreateProjectDisabled}
            title={
              t.admin_portal_maintanence_flag_enabled_create_project_disabled_tooltip
            }
          >
            <AppButton
              className={
                isCreateProjectDisabled ? "btn-disabled" : "create-btn"
              }
              color="primary"
              onClick={() => createHandler && createHandler()}
            >
              {t.create_project}
            </AppButton>
          </AppTooltip>
        )}
        {showUpdateBtn && (
          <AppButton
            className={`create-btn`}
            color="primary"
            onClick={updateHandler}
            disabled={disableUpdatebtn || (disableAll ? true : false)}
          >
            {t.edit_project_update}
          </AppButton>
        )}
        <AppButton
          className="cancel-btn"
          color="secondary"
          disabled={disableAll ? true : false}
          onClick={() => cancelHandler && cancelHandler()}
        >
          {t.cancel}
        </AppButton>
      </div>
    </div>
  );
};

export default CreateProjectFooter;
