import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { IUser } from '../models/user';
import { ucwords } from './helper-utility';
import { getProjectMetaData } from "../store/apis/projectApi";
import { validate as uuidValidate } from 'uuid';
import { SessionKeys } from './Constants';

class AzureApplicationInsightsService {

    private applicationInsights: ApplicationInsights | undefined;
    private currentUser: IUser | undefined;
    private projectDetails: any;

    constructor(currentUser?: IUser) {
        this.applicationInsights = undefined;
        this.currentUser = currentUser;
        this.projectDetails = null;
    }

    updateUser = (currentUser: IUser) => {
        this.currentUser = currentUser;
    }

    updateProjectDetails = (projectDetails: any) => {
        this.projectDetails = projectDetails;
    }

    enableApplicationInsights = () => {
        const applicationInsightsKey = process.env.REACT_APP_INSIGHTS_KEY || '';
        if (this.applicationInsights !== undefined) {
            return;
            // Throw error
            // throw new Error("Application insights has already been enabled. Do not call the enable method more than once.");
        } else if (!applicationInsightsKey) {
            return;
            // Throw error
            // throw new Error("Application insights key was not provided.");
        }

        this.applicationInsights = new ApplicationInsights({
            config: {
                instrumentationKey: applicationInsightsKey,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true
            }
        });
        // Initialize application insights
        this.applicationInsights.loadAppInsights();
        // Manually call trackPageView to establish the current user/session/pageview
        this.applicationInsights.trackPageView();
        // Add telemetry initializer
        this.applicationInsights.addTelemetryInitializer((telemetryItem) => this.interceptTelemetry(telemetryItem));
    }

    public getApplicationInsights(): ApplicationInsights {
        // Check if application insights have not been enabled
        if (this.applicationInsights === undefined) {
            // Throw error
            throw new Error("Application insights has not been enabled. Call the enable method first.");
        } else {
            // Return application insights
            return this.applicationInsights;
        }
    }

    private interceptTelemetry(telemetryItem: ITelemetryItem): boolean | void {
        // Add user email
        this.addUser(telemetryItem);
        // Set page name
        this.setPageName(telemetryItem);
        // Add common data
        this.addCommonData(telemetryItem);
        if (telemetryItem && telemetryItem.data && telemetryItem.data["CapitalEdgeAppName"]
            && !!(telemetryItem.data["CapitalEdgeAppName"] + '').length) {
            return;
        } else {
            return false;
        }
    }

    private async addCommonData(telemetryItem: ITelemetryItem) {
        let data = telemetryItem.data;
        // Check if data does not exist
        if (!data) {
            // Create data
            data = {};
            // Set data
            telemetryItem.data = data;
        }

        const baseType = telemetryItem.baseType;
        // Get base data
        const baseData = telemetryItem.baseData;
        const sspName = "Self Service Portal";
        data["CapitalEdgeAppName"] = sspName;
        data["CapitalEdgeAppPlatformName"] = sspName;
        let tempPageName: string = data['CapitalEdgePageName'];
        if (tempPageName && tempPageName.toLowerCase().includes('project')
            && this.projectDetails) {
            Object.keys(this.projectDetails).forEach(key => {
                if (data) {
                    data[key] = this.projectDetails[key];
                }
            });
        } else if (tempPageName && tempPageName.toLowerCase().includes('project')
            && !tempPageName.toLowerCase().includes('admin') && !this.projectDetails
            && sessionStorage.getItem(SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK)
            && sessionStorage.getItem(SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK) !== "null") {
            if (data) {
                data['CapitalEdgeProjectFriendlyId'] =
                    sessionStorage.getItem(SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK);
            }
        }
        else if (!data['CapitalEdgeProjectFriendlyId']) {
            const browserUrl = window.location.toString().replaceAll("#", "");
            if (browserUrl && !this.projectDetails) {
                const urlArr = browserUrl.split("/")
                let id = urlArr.find((item) => uuidValidate(item));
                if (baseType?.toString().toLowerCase() === "metricdata" && baseData && baseData["name"] === "PageVisitTime"
                    && data) {
                    // Get page URL
                    if (!id) {
                        const newBrowserUrl = data["PageUrl"]
                        const newUrlArr = newBrowserUrl.split("/")
                        id = newUrlArr.find((item) => uuidValidate(item));
                    }
                    try {
                        if (id && !data["PageUrl"].includes("admin/projects")) {
                            const result = await getProjectMetaData({ id })
                            data['CapitalEdgeProjectFriendlyId'] = result?.data?.projectFriendlyId;
                        }
                    }
                    catch (error) {
                        console.log("error occurred fetching friendlyid...", error);
                    }
                }
            }
        }
        else {
            this.projectDetails = null;
        }
    }

    private addUser(telemetryItem: ITelemetryItem): void {
        // Check if current user exists
        if (this.currentUser) {
            // Get data
            let data = telemetryItem.data;
            // Check if data does not exist
            if (!data) {
                // Create data
                data = {};
                // Set data
                telemetryItem.data = data;
            }
            // Set Capital Edge user ID
            //data["CapitalEdgeUserId"] = this.currentUser.id || "";
            // Set Capital Edge user email
            data["CapitalEdgeUserEmail"] = this.currentUser.mail || "";
        }
    }

    private setPageName(telemetryItem: ITelemetryItem): void {
        // Get base type
        const baseType = telemetryItem.baseType;
        // Get base data
        const baseData = telemetryItem.baseData;
        // Get data
        let data = telemetryItem.data;
        if (!data) {
            // Create data
            data = {};
            // Set data
            telemetryItem.data = data;
        }

        // Check base type
        switch (baseType) {
            case "MetricData":
                // Check if requirements exist
                if (baseData && baseData["name"] === "PageVisitTime" && data) {
                    // Get page URL
                    const pageUrl = data["PageUrl"];
                    // Set page name based on page URL
                    this.setPageNameBasedOnPageUrl(pageUrl, (pageName) => {
                        if (data) {
                            data["PageName"] = pageName;
                            data["CapitalEdgePageName"] = pageName;
                        }
                    });
                }
                break;
            case "PageviewData":
            case "PageviewPerformanceData":
                // Check if requirements exists
                if (baseData) {
                    // Get page URL
                    const pageUrl = baseData["uri"];
                    // Set page name based on page URL
                    this.setPageNameBasedOnPageUrl(pageUrl, (pageName) => {
                        baseData["name"] = pageName;
                        if (data) {
                            data["CapitalEdgePageName"] = pageName;
                        }
                    });
                }
                break;
            default:
                // No action
                break;
        }
    }

    private setPageNameBasedOnPageUrl(pageUrl: string, setPageName: (pageName: string) => void): void {
        // Get page URL start
        const pageUrlStart = (process.env.PUBLIC_URL || '').toString();
        // Check if page URL starts with URL start
        if (pageUrl && pageUrl.startsWith(pageUrlStart) && pageUrl.length > pageUrlStart.length) {
            // Get page URL parts
            const pageUrlParts = pageUrl.substring(pageUrlStart.length).split("/").filter((pageUrlPart) => !!pageUrlPart);
            // remove # from page parts url
            const indexHash = pageUrlParts.indexOf("#");
            if (indexHash > -1) {
                pageUrlParts.splice(indexHash, 1);
            }
            // Check if page URL parts exist
            if (pageUrlParts.length > 0) {
                // Get page name
                let pageName = pageUrlParts[0];
                // Check if page name is page and next page URL part exists
                if (pageName === "project" && pageUrlParts.length > 2) {
                    // Set page name
                    const formattedPageName = pageUrlParts[2];
                    pageName = !formattedPageName.includes('project') ? `project ${formattedPageName}` : formattedPageName;
                } else if (pageName === "admin" && pageUrlParts.length > 1) {
                    pageName = `admin ${pageUrlParts[1]}`;
                }
                // Set page name
                setPageName(ucwords(pageName.replace("-", " ")));
            } else {
                setPageName("Home");
            }
        }
    }
}

export default AzureApplicationInsightsService;
