import { combineReducers } from "redux";
import meReducer, { ME_REDUCER_STATE } from "./me-reducer";
import projectTypesReducer from "./project-types-reducer";
import sectorsReducer from "./sectors-reducer";
import clientSizesReducer from "./client-sizes-reducer";
import dataLocationsReducer from "./data-locations-reducer";
import projectCategoriesReducer from "./project-categories-reducer";
import adminProjectsReducer from "./admin-projects-reducer";
import searchProjectsReducer from "./search-projects-reducer";
import projectStatusReducer from "./project-status-reducer";
import createProjectReducer from "./create_project-reducer";
import projectsReducer from "./project-reducer";
import metaReducer, { META_STATE } from "./meta-reducer";
import manageUserReducer from "./manage-user-reducer";
import loaderReducer from "./loader-reducer";
import notificationReducer from "./notification-reducer";
import previewVideoReducer from "./preview-video-reducer";
import projectAppsReducer from "./project-apps-reducer";
import adminReportsReducer from "./admin-reports-reducer";
import adminUsersReducer from "./admin-users-reducer";
import adminMaintenanceReducer from "./admin-maintenance-reducer";
import adminGeneralReducer from "./admin-general-reducer";
import acceptedPolicyReducer from "./accepted-policy-reducer";
import listReducer from "./list-reducer";
import emgPolicy from "./emg-policy-reducer";
import defaultProject from "./default-project-reducer";
import workflowsReducer from "./admin-workflows-reducer";
import searchUsersReducer from './search-users-reducer';
import portalCommunications, { PORTAL_COMMUNICATION_STATE } from "./admin-portal-communications-reducer";
import urlParamStorageReducer from "./urlParamsStorage-reducer";
import settingsReducer from "./settings-reducer";

export type APP_STATE = Readonly<{
  portalCommunications: PORTAL_COMMUNICATION_STATE,
  meReducer: ME_REDUCER_STATE,
  meta: META_STATE
} & { [key: string]: any }>;
export default combineReducers<APP_STATE>({
  meReducer,
  projectTypesReducer,
  sectorsReducer,
  clientSizesReducer,
  dataLocationsReducer,
  projectCategoriesReducer,
  adminProjectsReducer,
  searchProjectsReducer,
  projectStatusReducer,
  createProjectReducer,
  manageUserReducer,
  projects: projectsReducer,
  meta: metaReducer,
  loader: loaderReducer,
  notification: notificationReducer,
  previewVideo: previewVideoReducer,
  projectAppsReducer,
  adminReports: adminReportsReducer,
  adminUsersReducer,
  adminMaintenance: adminMaintenanceReducer,
  adminGeneralReducer,
  acceptedPolicyReducer,
  listReducer,
  emgPolicy,
  defaultProject,
  workflowsReducer,
  searchUsersReducer,
  portalCommunications,
  urlParamStorageReducer: urlParamStorageReducer,
  settings: settingsReducer
});