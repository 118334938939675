import React from "react";
import Alert from "@mui/lab/Alert";
import { AlertProps } from "@mui/lab/Alert";
import clsx from 'clsx';
import './appAlert.scss';
interface AppAlertProps extends AlertProps {
  onClose: () => void;
  severity: "error" | "warning" | "info" | "success";
  className?: string; // Add className prop
}

const AppAlert: React.FC<AppAlertProps> = ({ onClose, severity, children, className, ...rest }) => {
  return (
    <Alert severity={severity}  onClose={onClose} {...rest}  className={clsx('appAlert', className)} >
      {children}
    </Alert>
  );
};

export default AppAlert;